// Extracts unique field values from each item of the array into a result array
export const uniqueFieldValue = (arr, fieldName) => {
  const values = arr.map(item => item[fieldName]);
  return values.filter((v, i, a) => a.indexOf(v) === i);
};

// returns true if every element in arr has the same value in a fieldName field
export const isAllSame = (arr, fieldName) => {
  if (!arr) return false;
  if (arr.length === 0) return true;

  const val = arr[0][fieldName];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][val] !== val) {
      return false;
    }
  }

  return true;
};
