import styled from 'styled-components';

import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import SubTitle from '../../../../components/Texts/SubTitle';
import SubSubTitle from '../../../../components/Texts/SubSubTitle';
import Text from '../../../../components/Texts/Text';

const style = {};

style.LoaderContainer = styled(LoadingSpinner)`
  display: flex;
  align-items: center;
`;

style.Root = styled.div`
  margin-bottom: 20px;
`;

style.StyledSubTitle = styled(SubTitle)`
  margin-bottom: 10px;
`;

style.StyledSubSubTitle = styled(SubSubTitle)`
  margin-bottom: 5px;
`;

style.Text = styled(Text)`
  white-space: pre-line;
`;

export default style;
