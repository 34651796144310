import styled from 'styled-components';

const Caption = styled.span`
    color: ${({ theme }) => theme.greyPalette.grey};
    font-size: ${({ theme }) => theme.fontSize.regular};
    font-weight: ${props => props.theme.fontWeight.regular};
    letter-spacing: 0.4px;
    margin-bottom: 8px;
`;

export default Caption;
