import React, {
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';

import {
  ManikinShape,
  AlarmShape
} from '../../../../constants/types';

import Modal from '../../../../components/Modal';
import Text from '../../../../components/Texts/Text';

import s from './AdditionalInfo.style';
import { requestAlarmText } from '../../../../api/api';
import {
  decodeAlarmType,
  humanReadableAlarmType
} from '../../../../utility/manikin';
import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import { theme } from '../../../../theme/theme';
import { TooltipPosition } from '../../../../components/common/TooltipPortal';

const NO_MAINTENANCE_TEXT = 'No maintenance registered';
const NO_PRODUCTION_TEXT = 'No production registered';

const valueOr = (value, alt = 'Unknown') => (value > -1 ? value : alt);
const valueOrDefault = (value, defaultValue = '-') => value || defaultValue;

const isTrimmed = (text) => {
  const textLength = text.length;

  return text.slice(textLength - 3, textLength) === '...';
};

const InfoModal = (props) => {
  const {
    assetId,
    alarmType,
    onClose,
    open
  } = props;
  const [alarmDescription, setAlarmDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setAlarmDescription('');
    setError('');
    const fetchText = async () => {
      setLoading(true);
      try {
        const decodedAlarmType = decodeAlarmType(alarmType);
        const text = await requestAlarmText(assetId, decodedAlarmType);
        setAlarmDescription(text.data);
        setLoading(false);
      } catch (e) {
        setAlarmDescription('');
        setError(`Error on text request. ${e}`);
      }
    };

    fetchText();
  }, []);

  const text = alarmDescription.split('\n').reduce((acc, el) => acc.concat([el, <br />]), []);

  return (
    <Modal
      open={open}
      showFooter={false}
      title={humanReadableAlarmType(alarmType, assetId)}
      width={580}
      onCancel={onClose}
    >
      {loading
        ? (
          <s.LoaderContainer>
            <LoadingSpinner
              size="50"
              color={theme.secondaryColorPalette.light1}
            />
          </s.LoaderContainer>
        )
        : <Text>{text}</Text>
      }
      {error && (<Text error>{error}</Text>)}
    </Modal>
  );
};
InfoModal.propTypes = {
  assetId: PropTypes.string.isRequired,
  alarmType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};

InfoModal.defaultProps = {
  open: true
};

const AdditionalInfo = (props) => {
  const {
    alarm,
    assetId,
    history,
    manikin,
    onEditLocationClick,
    onEditRepairReport,
    onShowDecommissionDialog
  } = props;

  const [textWindowOpened, setTextWindowOpened] = useState(false);

  const snippet = alarm.alarmDescription;

  const toggleModal = () => setTextWindowOpened(!textWindowOpened);

  const {
    attrs,
    hospitalId,
    isSSM
  } = manikin;

  const {
    cartId,
    isDecommissioned,
    laptopId,
    laptopModel,
    laptopSimId
  } = attrs;

  const {
    age,
    compressions,
    lastMaintenance,
    ventilations
  } = manikin.info;

  const productionDate = (age.production || age.production === 0)
    ? DateTime.local().minus({ days: age.production }).toLocaleString()
    : 'unknown';

  const onViewManikinDataClick = () => {
    history.push(`/manikin/${manikin.assetId}`);
  };

  const noMaintenanceText = lastMaintenance === 'none' ? NO_MAINTENANCE_TEXT : '0';
  const noProductionText = productionDate === 'unknown' ? NO_PRODUCTION_TEXT : '0';

  const renderProductionDate = () => (
    <span>
      {valueOr(age.production, NO_PRODUCTION_TEXT)}
      <i>
        &nbsp;since&nbsp;
        {productionDate}
      </i>
    </span>
  );

  const rows = [{
    title: 'Age in days',
    values: [
      renderProductionDate(),
      valueOr(age.maintenance, noMaintenanceText)
    ]
  }, {
    title: 'Compressions',
    values: [
      valueOr(compressions.production, noProductionText),
      valueOr(compressions.maintenance, noMaintenanceText)
    ]
  }, {
    title: 'Ventilations',
    values: [
      valueOr(ventilations.production, noProductionText),
      valueOr(ventilations.maintenance, noMaintenanceText)
    ]
  }];

  const moreBtn = isTrimmed(snippet) ? (
    <s.MoreBtn
      color="ghost"
      className="ignoreClick"
      label="More"
      onClick={toggleModal}
    />
  ) : null;

  const renderTableRow = (row, index) => {
    const { title, values } = row;
    return (
      <tr key={`ritem_${index}`}>
        <td>{title}</td>
        <td>{values[0]}</td>
        <td>{values[1]}</td>
      </tr>
    );
  };

  const handleBtnDecommissionClick = () => {
    onShowDecommissionDialog(assetId);
  };

  return (
    <s.Root>
      <s.AdditionalText>
        {snippet.replace('\n', ' ')}
        {isTrimmed(snippet) && moreBtn}
      </s.AdditionalText>
      <s.AdditionalTable>
        <thead>
          <tr>
            <th />
            <th>Since production date</th>
            <th>Since last maintenance</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(renderTableRow)}
        </tbody>
      </s.AdditionalTable>
      <s.BottomInfo>
        <s.BottomItem>
          <s.BottomLabel>
            Station serial:
          </s.BottomLabel>
          <s.BottomValue>
            {valueOrDefault(cartId)}
          </s.BottomValue>
        </s.BottomItem>

        <s.BottomItem>
          <s.BottomLabel>
            Laptop serial:
          </s.BottomLabel>
          <s.BottomValue>
            {valueOrDefault(laptopId)}
          </s.BottomValue>
        </s.BottomItem>

        <s.BottomItem>
          <s.BottomLabel>
            Laptop model:
          </s.BottomLabel>
          <s.BottomValue>
            {valueOrDefault(laptopModel)}
          </s.BottomValue>
        </s.BottomItem>

        <s.BottomItem>
          <s.BottomLabel>
            Laptop SIM card:
          </s.BottomLabel>
          <s.BottomValue>
            {valueOrDefault(laptopSimId)}
          </s.BottomValue>
        </s.BottomItem>

        <s.ButtonContainer>
          <s.TooltipButton
            className="ignoreClick"
            color="ghost"
            disabled={isDecommissioned}
            label={(
              <>
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none">
                  <path d="M10.7 4C11.1 3.6 11.1 3 10.7 2.6L8.9 0.8C8.5 0.4 7.9 0.4 7.5 0.8L0 8.3V11.5H3.2L10.7 4ZM8.2 1.5L10 3.3L8.5 4.8L6.7 3L8.2 1.5ZM1 10.5V8.7L6 3.7L7.8 5.5L2.8 10.5H1Z" fill="#2EC1C3" />
                </svg>

                Edit asset
              </>
            )}
            onClick={onEditLocationClick}
            toolTip={isDecommissioned ? 'Cannot edit decommissioned asset' : ''}
            toolTipPosition={TooltipPosition.top}
          />

          <s.TooltipButton
            className="ignoreClick"
            color="ghost"
            disabled={!isSSM}
            label={(
              <>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M19.3375 9.7875C18.6024 7.88603 17.3262 6.24164 15.6667 5.05755C14.0072 3.87347 12.0372 3.20161 9.99998 3.125C7.9628 3.20161 5.99272 3.87347 4.33323 5.05755C2.67374 6.24164 1.39758 7.88603 0.662478 9.7875C0.612833 9.92482 0.612833 10.0752 0.662478 10.2125C1.39758 12.114 2.67374 13.7584 4.33323 14.9424C5.99272 16.1265 7.9628 16.7984 9.99998 16.875C12.0372 16.7984 14.0072 16.1265 15.6667 14.9424C17.3262 13.7584 18.6024 12.114 19.3375 10.2125C19.3871 10.0752 19.3871 9.92482 19.3375 9.7875ZM9.99998 15.625C6.68748 15.625 3.18748 13.1687 1.91873 10C3.18748 6.83125 6.68748 4.375 9.99998 4.375C13.3125 4.375 16.8125 6.83125 18.0812 10C16.8125 13.1687 13.3125 15.625 9.99998 15.625Z" fill="#7198A2" />
                  <path d="M10 6.25C9.25832 6.25 8.5333 6.46993 7.91661 6.88199C7.29993 7.29404 6.81928 7.87971 6.53545 8.56494C6.25162 9.25016 6.17736 10.0042 6.32206 10.7316C6.46675 11.459 6.8239 12.1272 7.34835 12.6517C7.8728 13.1761 8.54098 13.5333 9.26841 13.6779C9.99584 13.8226 10.7498 13.7484 11.4351 13.4646C12.1203 13.1807 12.706 12.7001 13.118 12.0834C13.5301 11.4667 13.75 10.7417 13.75 10C13.75 9.00544 13.3549 8.05161 12.6517 7.34835C11.9484 6.64509 10.9946 6.25 10 6.25ZM10 12.5C9.50555 12.5 9.0222 12.3534 8.61108 12.0787C8.19995 11.804 7.87952 11.4135 7.6903 10.9567C7.50108 10.4999 7.45158 9.99723 7.54804 9.51228C7.6445 9.02732 7.8826 8.58186 8.23223 8.23223C8.58187 7.8826 9.02732 7.6445 9.51228 7.54804C9.99723 7.45157 10.4999 7.50108 10.9567 7.6903C11.4135 7.87952 11.804 8.19995 12.0787 8.61108C12.3534 9.0222 12.5 9.50555 12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5Z" fill="#7198A2" />
                </svg>

                View manikin data
              </>
            )}
            onClick={onViewManikinDataClick}
            toolTip="Available only for SSM manikins"
            toolTipPosition={TooltipPosition.top}
          />

          <s.Button
            className="ignoreClick"
            color="ghost"
            decommission
            disabled={isDecommissioned}
            label={(
              <>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.705 10.5L7 7.7955L4.295 10.5L3.5 9.705L6.2045 7L3.5 4.2955L4.2955 3.5L7 6.2045L9.7045 3.5L10.5 4.2955L7.7955 7L10.5 9.705L9.705 10.5Z" fill="#DD3D45" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM13 7C13 3.68629 10.3137 1 7 1C3.68789 1.00386 1.00386 3.68789 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7Z" fill="#DD3D45" />
                </svg>


                Decommission
              </>
            )}
            onClick={handleBtnDecommissionClick}
          />

          {manikin.repairId ? (
            <s.Button
              className="ignoreClick"
              color="normal"
              label="Edit repair report"
              onClick={() => onEditRepairReport(manikin)}
            />
          ) : null}
        </s.ButtonContainer>
      </s.BottomInfo>
      {textWindowOpened && (
        <InfoModal
          assetId={assetId}
          alarmType={alarm.alarmType}
          onClose={toggleModal}
          open={textWindowOpened}
        />
      )}
    </s.Root>
  );
};

AdditionalInfo.propTypes = {
  alarm: AlarmShape.isRequired,
  assetId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  manikin: ManikinShape.isRequired,
  onEditLocationClick: PropTypes.func,
  onEditRepairReport: PropTypes.func.isRequired,
  onShowDecommissionDialog: PropTypes.func.isRequired
};

AdditionalInfo.defaultProps = {
  onEditLocationClick: Function.prototype
};

export default withRouter(AdditionalInfo);
