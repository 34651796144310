import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Send from './Send';
import {
  filterArray,
  filterByAlarmType,
  filterByVA,
  filterByHandled,
  filterByHealth,
  filterByHospitalId,
  filterByManikinType,
  filterByProgram,
  filterByState,
  filterByText,
  filterByDecommissioned,
  filterByCountry
} from '../../utility/manikin';
import { VA_OPTIONS } from '../../constants/constants';
import {
  setAssetUpdateAction
} from '../../actions';
import {
  setVAFilterAction,
  setFilterHospitalHighlightAction,
  setSortAction,
  setSortDirectionAction
} from '../../actions/filters';

import './ListView.scss';
import s, { titleHeight } from './ListView.style';
import AssetsListContainer from './AssetsList/AssetsListContainer';
import { isAllSame } from '../../utility/array';
import DownloadCsvButton from './DownloadCsvButton';

class ListView extends React.Component {

  static propTypes = {
    allowedFailureType: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedHandled: PropTypes.string.isRequired,
    allowedHealth: PropTypes.string.isRequired,
    allowedManikinType: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedPrograms: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
    height: PropTypes.number.isRequired,
    highlightedHospitalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hospitals: PropTypes.arrayOf(PropTypes.object).isRequired,
    decommission: PropTypes.arrayOf(PropTypes.string).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    lastUpdatedAssetAttrs: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    manikins: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchString: PropTypes.string,
    selectedHospitalId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    setHighlightedHospital: PropTypes.func.isRequired,
    setSortDirection: PropTypes.func.isRequired,
    setSortType: PropTypes.func.isRequired,
    setVAFilter: PropTypes.func.isRequired,
    sortType: PropTypes.string.isRequired,
    assetsUpdateCounter: PropTypes.number,
    vaFilter: PropTypes.string.isRequired
  };

  static defaultProps = {
    assetsUpdateCounter: 0,
    highlightedHospitalId: null,
    lastUpdatedAssetAttrs: null,
    searchString: '',
    selectedHospitalId: null
  };

  constructor(props) {
    super(props);
    this.state = {
      visibleManikins: this.getVisibleManikins()
    };
  }

  componentDidUpdate(prevProps) {
    const prevAllowedManikinType = prevProps.allowedManikinType;
    const prevFailureType = prevProps.allowedFailureType;
    const prevSearchString = prevProps.searchString;
    const prevAllowedHealth = prevProps.allowedHealth;
    const prevAllowedHandled = prevProps.allowedHandled;
    const prevAllowedPrograms = prevProps.allowedPrograms;
    const prevSelectedHospitalId = prevProps.selectedHospitalId;
    const prevVAFilter = prevProps.vaFilter;
    const prevAllowedStates = prevProps.allowedStates;
    const prevAllowedCountries = prevProps.allowedCountries;
    const prevLastUpdatedAssetAttrs = prevProps.lastUpdatedAssetAttrs;
    const prevLoading = prevProps.loading;
    const prevManikins = prevProps.manikins;
    const prevAssetsUpdateCounter = prevProps.assetsUpdateCounter;
    const prevDecommission = prevProps.decommission;
    const {
      allowedManikinType,
      allowedFailureType,
      searchString,
      allowedHealth,
      allowedHandled,
      allowedPrograms,
      assetsUpdateCounter,
      decommission,
      vaFilter,
      allowedStates,
      allowedCountries,
      lastUpdatedAssetAttrs,
      loading,
      manikins,
      selectedHospitalId,
      sortType,
      setHighlightedHospital,
      setSortType,
      setSortDirection
    } = this.props;

    if (prevAllowedManikinType.length !== allowedManikinType.length
      || prevFailureType.length !== allowedFailureType.length
      || prevSearchString !== searchString
      || prevAllowedHandled !== allowedHandled
      || prevAllowedHealth !== allowedHealth
      || prevAllowedPrograms.length !== allowedPrograms.length
      || prevSelectedHospitalId !== selectedHospitalId
      || prevVAFilter !== vaFilter
      || prevAllowedStates.length !== allowedStates.length
      || prevAllowedCountries !== allowedCountries
      || (lastUpdatedAssetAttrs !== prevLastUpdatedAssetAttrs
      || (!loading && prevLoading)
      || (manikins !== prevManikins)
      || (assetsUpdateCounter !== prevAssetsUpdateCounter)
      || (decommission !== prevDecommission))
    ) {
      const visibleManikins = this.getVisibleManikins();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        visibleManikins
      });

      // Pre-select and zoom to hospital if there's only one selected
      // when searching
      if (prevSearchString !== searchString) {
        const visibleHospitals = visibleManikins.reduce((acc, value) => (
          acc.includes(value.hospitalId) ? acc : [...acc, value.hospitalId]
        ), []);
        if (visibleHospitals.length === 1) {
          setHighlightedHospital(visibleHospitals[0]);
        }
      }
    }

    if (
      (selectedHospitalId && !prevSelectedHospitalId)
      && !sortType
    ) {
      setSortType('firmwareVersion');
      setSortDirection('asc');
    }
  }

  filterBySearchString = (manikins) => {
    const { searchString } = this.props;

    if (!searchString) {
      return manikins;
    }

    return manikins.filter(manikin => filterByText(manikin, searchString));
  };

  filterByManikinType = (manikins) => {
    const { allowedManikinType } = this.props;

    if (allowedManikinType.length === 0) {
      return manikins;
    }

    return manikins.filter(manikin => filterByManikinType(manikin, allowedManikinType));
  };

  filterByVA = (manikins) => {
    const {
      hospitals,
      vaFilter
    } = this.props;

    if (vaFilter === VA_OPTIONS.ALL) {
      return manikins;
    }

    return manikins.filter(manikin => filterByVA(manikin, hospitals, vaFilter));
  };

  filterByHealth = (manikins) => {
    const { allowedHealth } = this.props;

    if (allowedHealth === 'any') {
      return manikins;
    }

    return manikins.filter(manikin => filterByHealth(manikin, allowedHealth));
  };

  filterByAlarmType = (manikins) => {
    const { allowedFailureType } = this.props;

    if (allowedFailureType.length === 0) {
      return manikins;
    }

    return manikins.filter(manikin => filterByAlarmType(manikin, allowedFailureType));
  };

  filterByProgram = (manikins) => {
    const { allowedPrograms } = this.props;

    if (allowedPrograms.length === 0) {
      return manikins;
    }

    return manikins.filter(manikin => filterByProgram(manikin, allowedPrograms));
  };

  filterByHandled = (manikins) => {
    const { allowedHandled } = this.props;

    if (!allowedHandled) {
      return manikins;
    }

    return manikins.filter(manikin => filterByHandled(manikin, allowedHandled));
  };

  filterByDecommissioned = (manikins) => {
    const { decommission } = this.props;
    return manikins.filter(manikin => filterByDecommissioned(manikin, decommission));
  };

  filterByHospitalId = (manikins) => {
    const { selectedHospitalId } = this.props;

    if (!selectedHospitalId) {
      return manikins;
    }

    return manikins.filter(manikin => filterByHospitalId(manikin, selectedHospitalId));
  };

  // Put 'unknown' hospitals to the very bottom
  sortByUnknownPlace = (a, b) => {
    const placeA = a.placeName.toLowerCase();
    const placeB = b.placeName.toLowerCase();
    if (placeA === 'unknown' && placeB === 'unknown') return 0;
    if (placeA === 'unknown') return 1;
    if (placeB === 'unknown') return -1;
    return 0;
  }

  manikinHasHandled = (manikin) => {
    const { issues } = manikin;
    const issueTypes = Array.from(issues.keys());

    return issueTypes.find(it => issues.get(it).approved || issues.get(it).rejected);
  };

  manikinInAllowedStates = (manikins) => {
    const {
      allowedStates,
      hospitals
    } = this.props;

    if (allowedStates.length === 0) {
      return manikins;
    }

    return manikins.filter(manikin => filterByState(manikin, hospitals, allowedStates));
  };

  manikinInAllowedCountry = (manikins) => {
    const {
      allowedCountries,
      hospitals
    } = this.props;

    if (allowedCountries.length === 0) {
      return manikins;
    }

    return manikins.filter(manikin => filterByCountry(manikin, hospitals, allowedCountries));
  };

  getVisibleManikins = () => {
    const { manikins } = this.props;
    const filterList = [
      this.filterByVA,
      this.filterByHospitalId,
      this.filterByHealth,
      this.filterBySearchString,
      this.filterByManikinType,
      this.filterByAlarmType,
      this.filterByProgram,
      this.manikinInAllowedStates,
      this.manikinInAllowedCountry,
      this.filterByHandled,
      this.filterByDecommissioned
    ];

    // Put unknown manikins to the bottom
    return filterArray(manikins, filterList).sort(this.sortByUnknownPlace);
  };

  getListHeight = () => {
    const { height } = this.props;

    return height - titleHeight;
  };

  handleVAClick = (event) => {
    const { setVAFilter } = this.props;
    const { target } = event;
    const { value } = target.dataset;

    setVAFilter(value);
  };

  handleItemToggle = (assetId) => {
    const {
      highlightedHospitalId,
      setHighlightedHospital
    } = this.props;
    const { visibleManikins } = this.state;
    const manikin = visibleManikins.find(m => m.assetId === assetId);
    const { hospitalId } = manikin;

    if (hospitalId !== highlightedHospitalId) {
      setHighlightedHospital(hospitalId);
    } else {
      setHighlightedHospital(null);
    }
  };

  renderVAControl = () => {
    const { vaFilter } = this.props;
    const titleText = vaFilter === VA_OPTIONS.ALL ? 'All hospitals' : 'VA hospitals';

    return (
      <s.VAControl>
        <span>{titleText}</span>
        <s.VAControlOptions>
          <s.VAControlOption
            selected={vaFilter === VA_OPTIONS.ALL}
            data-value={VA_OPTIONS.ALL}
            onClick={this.handleVAClick}
          >
            All&nbsp;hospitals
          </s.VAControlOption>
          <s.VAControlOption
            selected={vaFilter === VA_OPTIONS.VA}
            data-value={VA_OPTIONS.VA}
            onClick={this.handleVAClick}
          >
            VA&nbsp;hospitals
          </s.VAControlOption>
        </s.VAControlOptions>
      </s.VAControl>
    );
  };

  renderListContainerHeader = () => {
    const {
      hospitals,
      loading,
      selectedHospitalId
    } = this.props;

    const { visibleManikins } = this.state;

    let forText = this.renderVAControl();

    if (selectedHospitalId) {
      const hospital = hospitals.find(item => selectedHospitalId === item.hospitalId);

      if (hospital) {
        forText = hospital.placeName;
      }
    } else if (visibleManikins.length > 0 && isAllSame(visibleManikins, 'placeName')) {
      // Render hospital name if there is the only hospital in a result list
      forText = visibleManikins[0].placeName;
    }

    return (
      <s.Header>
        <s.StyledSubTitle>
          Work items for:
          <s.SubTitleSpan>
            { forText }
          </s.SubTitleSpan>
        </s.StyledSubTitle>

        <DownloadCsvButton
          disabled={loading || !visibleManikins.length}
          manikins={visibleManikins}
        />

        {this.renderSendHeader()}
      </s.Header>
    );
  };

  renderSendHeader = () => {
    const { manikins } = this.props;

    return <Send manikins={manikins} />;
  };

  render() {
    const {
      loading,
      height
    } = this.props;
    const { visibleManikins } = this.state;

    return (
      <s.Root height={height}>
        {this.renderListContainerHeader()}
        <AssetsListContainer
          manikins={visibleManikins}
          loading={loading}
          onItemToggle={this.handleItemToggle}
          height={this.getListHeight()}
          useLastRowWorkaround
        />
      </s.Root>
    );
  }

}

const mapStateToProps = state => ({
  allowedFailureType: state.filters.failureType,
  allowedHandled: state.filters.handled,
  allowedHealth: state.filters.health,
  allowedManikinType: state.filters.manikinType,
  allowedPrograms: state.filters.program,
  allowedStates: state.filters.states === null ? state.user.settings.preferredStates : state.filters.states,
  allowedCountries: state.filters.countries === null ? [] : state.filters.countries,
  assetsUpdateCounter: state.manicDataReducer.assetsUpdateCounter,
  assetsUpdates: state.manicDataReducer.updates,
  highlightedHospitalId: state.filters.highlightHospitalId,
  hospitals: state.manicDataReducer.data ? state.manicDataReducer.data.hospitals : [],
  decommission: state.filters.decommission,
  lastUpdatedAssetAttrs: state.manicDataReducer.lastUpdatedAssetAttrs,
  loading: state.manicDataReducer.loading,
  manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : [],
  searchString: state.filters.text,
  selectedHospitalId: state.filters.hospitalId,
  sortType: state.filters.sort,
  vaFilter: state.filters.va
});
const mapDispatchToProps = dispatch => ({
  setSortType: sortType => dispatch(setSortAction(sortType)),
  setSortDirection: sortDirection => dispatch(setSortDirectionAction(sortDirection)),
  setHighlightedHospital: hospitalId => dispatch(setFilterHospitalHighlightAction(hospitalId)),
  setAssetUpdate: (assetId, update) => dispatch(setAssetUpdateAction(assetId, update)),
  setVAFilter: vaOption => dispatch(setVAFilterAction(vaOption))
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
