import styled from 'styled-components';

import SubTitle from '../../../../components/Texts/SubTitle';
import Text from '../../../../components/Texts/Text';

const style = {};

style.FailureInputBlock = styled.div`
  margin-top: 20px;
`;

style.RadioButton = styled.input`
  margin-bottom: 5px;
`;

style.RadioInputLabel = styled.label`
  color: ${props => props.theme.greyPalette.black};
  font-weight: ${props => props.theme.fontWeight.regular}
  margin-left: 5px;
  margin-top: -2px;
`;

style.Result = styled.div`
  margin-top: 10px;
`;

style.StyledSubTitle = styled(SubTitle)`
  margin-bottom: 8px;
`;

style.Toggle = styled(Text)`
  color: ${props => props.theme.secondaryColorPalette.light1};
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: ${props => props.theme.secondaryColorPalette.main};
  }
`;

export default style;
