import styled from 'styled-components';

const Popup = styled.div`
  background: ${props => props.theme.primaryBackground};
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
  padding: 30px;
  z-index: 2;
`;

export default Popup;
