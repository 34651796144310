import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';

import MarkerWrapper from './MarkerWrapper';
import { setHospitalFilterAction } from '../../actions/filters';

import './MapWrapper.scss';
import { HOSPITAL_COLORS, HOSPITAL_STATES, VA_OPTIONS, ALARM_TYPES_WEIGHT } from '../../constants/constants';
import ClusterMarker from './ClusterMarker/ClusterMarker';
import { getHospitalState, getHospitalManikinsCounters } from '../../utility/hospital';
import {
  passesAll,
  filterByAlarmType,
  filterByHandled,
  filterByHealth,
  filterByManikinType,
  filterByProgram,
  filterByText,
  filterByState,
  filterByCountry,
  filterByVA,
  filterByDecommissioned,
} from '../../utility/manikin';

const MAP_OPTIONS = {
  defaultZoom: 4,
  defaultCenter: {
    lat: 40.322846,
    lng: -100.74626,
  },
};

class MapWrapper extends Component {
  static propTypes = {
    allowedFailureType: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedHandled: PropTypes.string.isRequired,
    allowedHealth: PropTypes.string.isRequired,
    allowedManikinType: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedPrograms: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowedCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
    assetsUpdates: PropTypes.object.isRequired,
    highlightHospitalId: PropTypes.number,
    hospitals: PropTypes.arrayOf(PropTypes.object).isRequired,
    decommission: PropTypes.arrayOf(PropTypes.string).isRequired,
    manikins: PropTypes.array.isRequired,
    onHospitalSelect: PropTypes.func.isRequired,
    onMapReady: PropTypes.func,
    searchString: PropTypes.string.isRequired,
    selectedHospitalId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    vaFilter: PropTypes.string.isRequired,
    googleApiKey: PropTypes.string.isRequired,
    clusterByHospital: PropTypes.bool,
  };

  static defaultProps = {
    highlightHospitalId: null,
    onMapReady: Function.prototype,
    selectedHospitalId: null,
    clusterByHospital: true,
  };

  state = {
    clusters: [],
    mapOptions: {
      center: {
        lat: 40.322846,
        lng: -100.74626,
      },
      zoom: 4,
    },
  };

  prevMapState = MAP_OPTIONS;

  componentDidUpdate(prevProps) {
    const {
      allowedFailureType,
      allowedHandled,
      allowedHealth,
      allowedManikinType,
      allowedPrograms,
      allowedStates,
      allowedCountries,
      decommission,
      highlightHospitalId,
      hospitals,
      manikins,
      searchString,
      selectedHospitalId,
      vaFilter,
    } = this.props;
    const oldHealth = prevProps.allowedHealth;
    const newHealth = allowedHealth;
    const oldFailureTypes = prevProps.allowedFailureType;
    const newFailureTypes = allowedFailureType;
    const oldManikinTypes = prevProps.allowedManikinType;
    const newManikinTypes = allowedManikinType;
    const oldPrograms = prevProps.allowedPrograms;
    const newPrograms = allowedPrograms;
    const oldSearchString = prevProps.searchString;
    const newSearchString = searchString;
    const oldHandled = prevProps.allowedHandled;
    const newHandled = allowedHandled;
    const oldDecommission = prevProps.decommission;
    const newDecommission = decommission;
    const oldStates = prevProps.allowedStates;
    const newStates = allowedStates;
    const oldCountries = prevProps.allowedCountries;
    const newCountries = allowedCountries;
    const newSelectedHospitalId = selectedHospitalId;
    const newHighlightHospitalId = highlightHospitalId;
    const oldVAFilter = prevProps.vaFilter;
    const oldHospitals = prevProps.hospitals;
    const oldManikins = prevProps.manikins;

    if (newSelectedHospitalId !== prevProps.selectedHospitalId) {
      this.handleSelectedHospitalChange();
    } else if (newHighlightHospitalId !== prevProps.highlightHospitalId) {
      this.handleHighlightHospitalChange();
    }

    if (
      oldHealth !== newHealth ||
      oldFailureTypes.length !== newFailureTypes.length ||
      oldManikinTypes.length !== newManikinTypes.length ||
      oldPrograms.length !== newPrograms.length ||
      oldSearchString !== newSearchString ||
      oldHandled !== newHandled ||
      oldDecommission !== newDecommission ||
      oldStates !== newStates ||
      oldCountries !== newCountries ||
      oldVAFilter !== vaFilter ||
      oldManikins !== manikins ||
      oldHospitals !== hospitals
    ) {
      this.setClustersState(this.createClusters());
    }
  }

  getClusters = () => {
    const hospitalsToShow = this.getVisibleHospitals().map((h) => ({
      ...h,
      ...h.location,
    }));
    const clustersGetter = supercluster(hospitalsToShow, {
      minZoom: 0,
      maxZoom: 16,
    });
    const { mapOptions } = this.state;

    return clustersGetter(mapOptions);
  };

  getHospitalManikins = (manikins, hospitalId) => manikins.filter((m) => m.hospitalId === hospitalId);

  getVisibleHospitals() {
    const { hospitals, selectedHospitalId } = this.props;
    const resultHospitalList = hospitals.filter(this.checkHospital);

    // always display selected hospital
    if (selectedHospitalId && !resultHospitalList.find((hospital) => hospital.hospitalId === selectedHospitalId)) {
      const selectedHospital = hospitals.find((hospital) => hospital.hospitalId === selectedHospitalId);
      if (selectedHospital) {
        resultHospitalList.push(hospitals.find((hospital) => hospital.hospitalId === selectedHospitalId));
      }
    }

    return resultHospitalList;
  }

  initializeMap = ({ map }) => {
    const { onMapReady } = this.props;
    this._map = map;
    onMapReady();
  };

  checkHospital = (hospital) => {
    const { manikins } = this.props;
    const { hospitalId } = hospital;
    const hospitalManikins = manikins.filter((m) => m.hospitalId === hospitalId);
    const filters = [
      this.manikinPassesSearch,
      this.manikinPassesHealth,
      this.manikinPassesVA,
      this.manikinPassesType,
      this.manikinPassesFailureType,
      this.manikinPassesProgram,
      this.manikinPassesHandled,
      this.manikinPassesState,
      this.manikinPassesCountry,
      this.manikinPassesDecommission,
    ];

    return hospitalManikins.find((manikin) => passesAll(manikin, filters));
  };

  manikinPassesSearch = (manikin) => {
    const { searchString } = this.props;

    return filterByText(manikin, searchString);
  };

  manikinPassesType = (manikin) => {
    const { allowedManikinType } = this.props;

    return filterByManikinType(manikin, allowedManikinType);
  };

  manikinPassesFailureType = (manikin) => {
    const { allowedFailureType } = this.props;

    return filterByAlarmType(manikin, allowedFailureType);
  };

  manikinPassesProgram = (manikin) => {
    const { allowedPrograms } = this.props;

    return filterByProgram(manikin, allowedPrograms);
  };

  manikinPassesHandled = (manikin) => {
    const { allowedHandled } = this.props;

    return filterByHandled(manikin, allowedHandled);
  };

  manikinPassesDecommission = (manikin) => {
    const { decommission } = this.props;
    return filterByDecommissioned(manikin, decommission);
  };

  manikinPassesState = (manikin) => {
    const { allowedStates, hospitals } = this.props;

    return filterByState(manikin, hospitals, allowedStates);
  };

  manikinPassesCountry = (manikin) => {
    const { allowedCountries, hospitals } = this.props;

    return filterByCountry(manikin, hospitals, allowedCountries);
  };

  manikinPassesHealth = (manikin) => {
    const { allowedHealth } = this.props;

    return filterByHealth(manikin, allowedHealth);
  };

  manikinPassesVA = (manikin) => {
    const { hospitals, vaFilter } = this.props;

    if (vaFilter === VA_OPTIONS.ALL) {
      return manikin;
    }

    return filterByVA(manikin, hospitals, vaFilter);
  };

  zoomToHospital = (hospitalId) => {
    const { hospitals } = this.props;
    const { mapOptions } = this.state;
    const hospital = hospitals.find((h) => h.hospitalId === hospitalId);

    if (hospital) {
      const center = {
        lat: hospital.location.lat,
        lng: hospital.location.lng,
      };
      const { zoom: currentZoom } = mapOptions;
      const zoom = currentZoom > 13 ? currentZoom : 13;

      this.moveMap(center, zoom);

      this.setState({
        mapOptions: {
          center,
          zoom,
        },
      });
    }
  };

  zoomToCluster = (id) => {
    const { clusters, mapOptions } = this.state;
    const cluster = clusters.find((cl) => cl.id === id);
    const { lat, lng } = cluster;
    const { zoom: currentZoom } = mapOptions;
    const zoom = currentZoom + 2;

    this.moveMap(
      {
        lat,
        lng,
      },
      zoom,
    );

    this.setState({
      mapOptions: {
        center: {
          lat,
          lng,
        },
        zoom,
      },
    });
  };

  moveMap = (center = MAP_OPTIONS.defaultCenter, zoom = MAP_OPTIONS.defaultZoom) => {
    if (this._map) {
      this._map.setZoom(zoom);
      this._map.setCenter(center);
    }
  };

  setClustersState = (clusters) => {
    this.setState({
      clusters,
    });
  };

  createClusters = () => {
    const { mapOptions } = this.state;

    if (!mapOptions.bounds) {
      return [];
    }

    return this.getClusters().map(({ wx, wy, numPoints, points }) => ({
      lng: wx,
      lat: wy,
      numPoints,
      id: numPoints === 1 ? points[0].hospitalId : `${numPoints}-${points[0].hospitalId}`,
      points,
    }));
  };

  getAssetHandledIssues = (assetId) => {
    const { assetsUpdates } = this.props;
    const assetUpdates = assetsUpdates[assetId];
    let handledCount = 0;

    if (assetUpdates) {
      const updates = Object.keys(assetUpdates);

      updates.forEach((updateFailureType) => {
        const update = assetUpdates[updateFailureType];

        if (update && update.action !== '') {
          handledCount += 1;
        }
      });
    }

    return handledCount;
  };

  getHospitalHandledIssues = (hospitalId) => {
    const { manikins } = this.props;
    const hospitalManikinsIds = this.getHospitalManikins(manikins, hospitalId).map((m) => m.assetId);

    return hospitalManikinsIds.reduce((acc, manikinId) => acc + this.getAssetHandledIssues(manikinId), 0);
  };

  handleSelectedHospitalChange = () => {
    const { selectedHospitalId, highlightHospitalId } = this.props;
    const selectedIsInt = Number.isInteger(selectedHospitalId);
    const highlightIsInt = Number.isInteger(highlightHospitalId);

    if (selectedIsInt) {
      this.zoomToHospital(selectedHospitalId);
    }

    if (!selectedIsInt && !highlightIsInt) {
      const { center, zoom } = this.prevMapState;
      this.moveMap(center, zoom);
    }
  };

  handleHighlightHospitalChange = () => {
    const { highlightHospitalId } = this.props;
    const highlightIsInt = Number.isInteger(highlightHospitalId);

    if (highlightIsInt) {
      this.zoomToHospital(highlightHospitalId);
    } else {
      this.moveMap();
    }
  };

  handleMarkerClick = (hospitalId) => {
    const { onHospitalSelect } = this.props;
    const { mapOptions } = this.state;

    this.prevMapState = mapOptions;

    onHospitalSelect(hospitalId);
  };

  handleMarkerCloseClick = () => {
    const { onHospitalSelect } = this.props;

    onHospitalSelect(null);
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      () => this.setClustersState(this.createClusters()),
    );
  };

  reducePointToHospitalsCounter = (counter, hospital, hospitalIndex, hospitals) => {
    const { faulty, total } = getHospitalManikinsCounters(hospital.manikins);

    const hospitalState = getHospitalState(faulty, total);
    const number = counter[hospitalState][0] + 1;
    const percentage = Math.round((number / hospitals.length) * 100) / 100;

    return {
      ...counter,
      [hospitalState]: [number, percentage],
    };
  };

  reducePointToManikinsCounter = (counter, hospital, hospitalIndex, hospitals) => {
    const { manikins } = this.props;
    const hospitalManikins = this.getHospitalManikins(manikins, hospital.hospitalId);

    let faultyCount = counter[HOSPITAL_STATES.CRITICAL][0];
    let healthyCount = counter[HOSPITAL_STATES.HEALTHY][0];
    let warningCount = counter[HOSPITAL_STATES.WARN][0];
    let riskCount = counter[HOSPITAL_STATES.RISK][0];

    hospitalManikins.forEach((manikin) => {
      if (!manikin.alarms || manikin.alarms.length == 0) {
        healthyCount++;
      } else {
        const alarmType = manikin.alarms[0].alarmType;
        const alarmTypeWeight = ALARM_TYPES_WEIGHT[alarmType];
        if (alarmTypeWeight < 200) {
          faultyCount++;
        } else if (alarmTypeWeight < 400) {
          riskCount++;
        } else warningCount++;
      }
    });

    const totalCount = faultyCount + healthyCount + warningCount + riskCount;

    const faultyPercentage = Math.round((faultyCount / totalCount) * 100) / 100;
    const healthyPercentage = Math.round((healthyCount / totalCount) * 100) / 100;
    const warnPercentage = Math.round((warningCount / totalCount) * 100) / 100;
    const riskPercentage = Math.round((riskCount / totalCount) * 100) / 100;

    return {
      ...counter,
      [HOSPITAL_STATES.CRITICAL]: [faultyCount, isNaN(faultyPercentage) ? 0 : faultyPercentage],
      [HOSPITAL_STATES.HEALTHY]: [healthyCount, isNaN(healthyPercentage) ? 0 : healthyPercentage],
      [HOSPITAL_STATES.WARN]: [warningCount, isNaN(warnPercentage) ? 0 : warnPercentage],
      [HOSPITAL_STATES.RISK]: [riskCount, isNaN(riskPercentage) ? 0 : riskPercentage],
    };
  };

  stateCounterToSlices = (counter) => {
    const slices = [];
    const hospitalStates = Object.keys(counter);

    hospitalStates.forEach((hospitalState) => {
      const data = counter[hospitalState];
      const [number, percentage] = data;
      const slice = {
        number,
        color: HOSPITAL_COLORS[hospitalState],
        percentage,
      };

      slices.push(slice);
    });

    return slices;
  };

  renderGroupedMarkers = (hospitals, lat, lng) => {
    return (
      <div lat={lat} lng={lng} key={`grouped-lat-${lat}-lng-${lng}`}>
        <div style={{ position: 'relative' }}>
          {hospitals.map((hospital, i) => {
            return <div style={{ position: 'relative' }}>{this.renderMarkerWrapper(hospital)}</div>;
          })}
        </div>
      </div>
    );
  };

  renderMarker = (hospital) => {
    const { hospitalId: id, location } = hospital;
    const { lat, lng } = location;

    return (
      <div key={id} lat={lat} lng={lng}>
        {this.renderMarkerWrapper(hospital)}
      </div>
    );
  };

  renderMarkerWrapper = (hospital) => {
    const { hospitalId: id, manikins, placeName: name } = hospital;

    const { selectedHospitalId } = this.props;

    const selected = selectedHospitalId === id;
    const { faulty, total } = getHospitalManikinsCounters(manikins);

    const handledFailures = this.getHospitalHandledIssues(id);

    return (
      <MarkerWrapper
        id={id}
        key={id}
        manikinsCount={total}
        faultyManikinsCount={faulty}
        handledFailures={handledFailures}
        name={name}
        selected={selected}
        onClick={this.handleMarkerClick}
        onClickClose={this.handleMarkerCloseClick}
      />
    );
  };

  renderCluster = (cluster) => {
    const { clusterByHospital } = this.props;
    const { points, lat, lng, id } = cluster;
    return clusterByHospital ? this.renderClusterByHospital(points, lat, lng, id) : this.renderClusterByManikins(points, lat, lng, id);
  };

  renderMapContents = () => {
    const { clusters } = this.state;
    let nonClustered = this.renderSingleMarkersByLocation(clusters.filter((x) => x.numPoints === 1));
    return [
      nonClustered,
      clusters
        .filter((x) => x.numPoints !== 1)
        .map((item) => {
          return this.renderCluster(item);
        }),
    ];
  };

  renderSingleMarkersByLocation(singles) {
    const locations = this.groupByLocation(singles);
    return this.renderSingleMarkers(locations);
  }

  renderSingleMarkers(locations) {
    let nonClustered = [];
    locations.forEach((values) => {
      if (values.length === 1) nonClustered.push(this.renderMarker(values[0].points[0]));
      else
        nonClustered.push(
          this.renderGroupedMarkers(
            values.map((x) => x.points[0]),
            values[0].lat,
            values[0].lng,
          ),
        );
    });
    return nonClustered;
  }

  groupByLocation(singles) {
    const locations = new Map();
    singles.forEach((item) => {
      const key = `lat:${item.lat},lng:${item.lng}`;
      const location = locations.get(key);
      if (!location) {
        locations.set(key, [item]);
      } else {
        location.push(item);
      }
    });
    return locations;
  }

  renderClusterByManikins(points, lat, lng, id) {
    const clusterPoints = points.reduce(this.reducePointToManikinsCounter, {
      [HOSPITAL_STATES.CRITICAL]: [0, 0],
      [HOSPITAL_STATES.RISK]: [0, 0],
      [HOSPITAL_STATES.WARN]: [0, 0],
      [HOSPITAL_STATES.HEALTHY]: [0, 0],
    });
    const slices = this.stateCounterToSlices(clusterPoints);
    const sum = slices.reduce((p, x) => p + x.number, 0);

    return (
      <div lat={lat} lng={lng} key={id}>
        <ClusterMarker slices={slices} itemsCount={sum} onClick={this.zoomToCluster} id={id} hospitals={false} />
      </div>
    );
  }

  renderClusterByHospital(points, lat, lng, id) {
    const clusterPoints = points.reduce(this.reducePointToHospitalsCounter, {
      [HOSPITAL_STATES.CRITICAL]: [0, 0],
      [HOSPITAL_STATES.RISK]: [0, 0],
      [HOSPITAL_STATES.WARN]: [0, 0],
      [HOSPITAL_STATES.HEALTHY]: [0, 0],
    });
    const slices = this.stateCounterToSlices(clusterPoints);

    return (
      <div lat={lat} lng={lng} key={id}>
        <ClusterMarker slices={slices} itemsCount={points.length} onClick={this.zoomToCluster} id={id} hospitals={true} />
      </div>
    );
  }

  render() {
    return (
      <div className="MapWrapper">
        {this.props.googleApiKey ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: this.props.googleApiKey,
            }}
            defaultCenter={MAP_OPTIONS.defaultCenter}
            defaultZoom={MAP_OPTIONS.defaultZoom}
            onChange={this.handleMapChange}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={this.initializeMap}
          >
            {this.renderMapContents()}
          </GoogleMapReact>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allowedFailureType: state.filters.failureType,
  allowedHealth: state.filters.health,
  allowedManikinType: state.filters.manikinType,
  allowedPrograms: state.filters.program,
  allowedHandled: state.filters.handled,
  allowedStates: state.filters.states === null ? state.user.settings.preferredStates : state.filters.states,
  allowedCountries: state.filters.countries === null ? [] : state.filters.countries,
  vaFilter: state.filters.va,
  highlightHospitalId: state.filters.highlightHospitalId,
  hospitals: state.manicDataReducer.data ? state.manicDataReducer.data.hospitals : [],
  decommission: state.filters.decommission,
  manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : [],
  assetsUpdates: state.manicDataReducer.updates,
  searchString: state.filters.text,
  selectedHospitalId: state.filters.hospitalId,
  googleApiKey: state.user.googleApiKey,
});
const mapDispatchToProps = (dispatch) => ({
  onHospitalSelect: (hospitalId) => dispatch(setHospitalFilterAction(hospitalId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapWrapper);
