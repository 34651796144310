import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeaderCell, { StyledHeader } from './HeaderCell';

const HeaderFilter = (props) => {
  const {
    handleHeaderClick,
    sortDirection,
    sortType
  } = props;
  return (
    <Header>
      <HeaderCell
        centered
        headerText="Name"
        isCurrentSortType={sortType === 'filterName'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="filterName"
        width={200}
      />
      <HeaderCell
        centered
        headerText="Value"
        isCurrentSortType={sortType === 'value'}
        minWidth={70}
        onClick={handleHeaderClick}
        shrink={1}
        sortDirection={sortDirection}
        sortType="value"
        width={70}
      />
    </Header>
  );
};

HeaderFilter.propTypes = {
  handleHeaderClick: PropTypes.func,
  sortDirection: PropTypes.string,
  sortType: PropTypes.string
};

HeaderFilter.defaultProps = {
  handleHeaderClick: Function.prototype,
  sortDirection: '',
  sortType: ''
};

const Header = styled(StyledHeader)`
  padding: 0 34px;
`;

export default HeaderFilter;
