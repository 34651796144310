import styled from 'styled-components';

import Button from '../../components/Button';
import Text from '../../components/Texts/Text';
import Title from '../../components/Texts/Title';
import Select from '../../components/Select';

const s = {};

s.MessageCont = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`;

s.Block = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-right: 40px;
`;

s.Content = styled.div`
  align-items: center;
  display: flex;
`;

s.Message = styled.div`
  margin-top: 10px;
  position: absolute;
  top: 100%;
  right: 0;
`;

s.Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

s.Root = styled.div`
  background-color: ${props => props.theme.primaryBackground};
  padding: 90px 40px;
  height: calc(100% - 55px);
`;

s.SettingsTitle = styled(Title)`
  margin-bottom: 10px;
`;

export default s;
