const color = {
  greyscale: {
    black: '#253045',
    grey: '#7198a2'
  },
  blue: {
    blue_100: '#687F9B',
    blue_700: '#E9F0F5'
  },
  turquoise: {
    turquoise_700: '#0AB3B2'
  }
};

const semanticColor = {
  primary: color.turquoise.turquoise_700
};

const size = {
  size_2: '2px',
  size_5: '4px',
  size_10: '8px',
  size_20: '16px',
  size_30: '24px',
  size_40: '32px',
  size_50: '40px',
  size_60: '48px',
  size_70: '56px',
  size_80: '64px',
  size_90: '72px',
  size_100: '80px',
  size_110: '128px',
  size_120: '256px'
};

const radius = {
  radius_2: size.size_2,
  radius_5: size.size_5,
  radius_10: size.size_10,
  radius_20: size.size_20
};

const shadow = {
  shadow_i0: `inset 0px 1px 0px #E9F0F5`,
  shadow_2: `0px ${size.size_2} 6px rgba(104, 127, 155, 0.3)`,
  shadow_10: `0px ${size.size_10} 6px rgba(104, 127, 155, 0.3)`
};

export {
  color,
  radius,
  semanticColor,
  shadow,
  size
};
