import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { getData } from '../../../../actions';
import { sendCustomFailure } from '../../../../api/api';
import Input from '../../../../components/Input';
import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import Modal from '../../../../components/Modal';
import Text from '../../../../components/Texts/Text';

import s from './AddModal.style';

const failureOptions = [
  'Laptop failure (including training software)',
  'Ventilation-related failure',
  'Compression-related failure',
  'Physical damage'
];

const AddModal = (props) => {
  const [failureDate, setFailureDate] = useState(DateTime.local().toFormat('yyyy-MM-dd'));
  const [failure, setFailure] = useState('');
  const [customFailure, setCustomFailureState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const {
    assetId,
    loadManikins,
    onCloseClick,
    open
  } = props;


  useEffect(() => {
    if (!loading) {
      setError('');
      setSuccess('');
      setFailure('');
      setCustomFailureState(false);
      setFailureDate(DateTime.local().toFormat('yyyy-MM-dd'));
    }
  }, [open]);

  const send = async () => {
    setLoading(true);

    const customFailureToSend = {
      assetId,
      failureType: failure,
      failureDate,
      email: localStorage.getItem('email')
    };
    try {
      await sendCustomFailure(customFailureToSend);
      loadManikins();
      setSuccess('Manikin failure registered successfully');
    } catch (e) {
      setError(`Something went wrong. Error ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmClick = () => {
    send();
  };

  const renderFailureSelect = () => failureOptions.map(opt => (
    <div key={opt}>
      <s.RadioButton
        id={opt}
        name="failure"
        onChange={e => setFailure(e.target.value)}
        type="radio"
        value={opt}
      />
      <s.RadioInputLabel htmlFor={opt}>
        {opt}
      </s.RadioInputLabel>
    </div>
  ));

  const renderCustomFailure = () => (
    <Input
      type="text"
      value={failure}
      placeholder="Custom failure type"
      onChange={e => setFailure(e.target.value)}
      withBorder
    />
  );

  const renderButtonText = () => (success ? 'Close' : 'Add failure');
  const btnAddEnabled = !loading && (success || failure);

  return (
    <Modal
      title="Add failure type"
      confirmButtonText={loading ? <LoadingSpinner /> : renderButtonText()}
      confirmButtonDisabled={!btnAddEnabled}
      onCancel={onCloseClick}
      onConfirm={success ? onCloseClick : onConfirmClick}
      open={open}
    >
      <s.StyledSubTitle>Date of problem occurrence</s.StyledSubTitle>
      <Input
        name="inpDate"
        onChange={e => setFailureDate(e.target.value)}
        type="date"
        value={failureDate}
        withBorder
      />
      <s.FailureInputBlock>
        <s.StyledSubTitle>Failure type</s.StyledSubTitle>
        {customFailure ? renderCustomFailure() : renderFailureSelect()}
      </s.FailureInputBlock>
      <s.Toggle
        color="link"
        onClick={() => setCustomFailureState(!customFailure)}
      >
        {customFailure ? 'Select existing failure category' : 'Suggest new failure category'}
      </s.Toggle>
      <s.Result>
        {success && (<Text>{success}</Text>)}
        {error && (<Text error>{error}</Text>)}
      </s.Result>
    </Modal>
  );
};

AddModal.propTypes = {
  assetId: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  loadManikins: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch => ({
  loadManikins: () => dispatch(getData())
});

export default connect(null, mapDispatchToProps)(AddModal);
