import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { updateAssetAttributesAction } from '../../../../actions';
import {
  HOSPITAL_EDIT_MODE_NEW,
  MAX_CART_ID_SIZE,
  MAX_CART_LOCATION_SIZE,
  MAX_FACILITY_NAME_SIZE,
  MAX_LAPTOP_MODEL_SIZE,
  MAX_LAPTOP_ID_SIZE,
  MAX_LAPTOP_SIM_ID_SIZE,
  MAX_HOSPITAL_NAME_SIZE,
  MAX_HOSPITAL_ADDRESS_SIZE,
  iconTypeMap,
  ASSET_TYPE,
  PROGRAM,
  DEFAULT_FIRMWARE_VERSION,
} from '../../../../constants/constants';
import {
  getFacilitiesAction,
  setAssetAttributesAction,
  showDescriptiveLocationDialog,
  getHospitalLocationAction,
} from '../../../../actions/descriptiveLocation';
import Caption from '../../../../components/Texts/Caption';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Select from '../../../../components/Select';
import { CardContainer, CardHeader, CardContent } from '../../../../components/Card/Card';

import s from './EditLocationModal.style';
import { ManikinAttrsShape, HospitalShape, ManikinShape } from '../../../../constants/types';
import HospitalSelect from './HospitalSelect';
import HospitalMapWrapper from '../../../maps/HospitalMapWrapper';
import AddEditHospital from './AddEditHospital';
import { sortFunctions } from '../../../../utility/hospital';
import { setFilterHospitalHighlightAction, setTextFilterAndResetOtherAction } from '../../../../actions/filters';
import AssetHistory from './AssetHistory';
import { Collapse } from 'reactstrap';

export const DialogMode = {
  Edit: 'Edit',
  New: 'New',
};

const getDialogTitle = (dialogMode, params) => {
  const { currentAssetId } = params;

  switch (dialogMode) {
    case DialogMode.Edit:
      return `Edit data for manikin ${currentAssetId}`;
    default:
      return 'Create new manikin';
  }
};

const ConfirmButtonTitles = {
  [DialogMode.Edit]: 'Apply changes',
  [DialogMode.New]: 'Create',
};

const validateLength = (value, valueName, maxLength, addLineBreak = true) =>
  value && value.length > maxLength ? `"${valueName}" is too long. It should not exceed ${maxLength} characters.${addLineBreak ? '\n' : ''}` : '';

const assetProgramOptions = [
  { label: PROGRAM.RQI, value: PROGRAM.RQI },
  { label: PROGRAM.HeartCode, value: PROGRAM.HeartCode },
  { label: PROGRAM.Other, value: PROGRAM.Other },
];

const assetTypeOptions = [
  {
    label: (
      <>
        <s.AssetTypeImage src={iconTypeMap.adult} alt="Adult" />
        Adult
      </>
    ),
    value: ASSET_TYPE.adult,
  },
  {
    label: (
      <>
        <s.AssetTypeImage src={iconTypeMap.infant} alt="Infant" />
        Infant
      </>
    ),
    value: ASSET_TYPE.infant,
  },
];

const EditLocationModal = (props) => {
  const {
    attrs,
    assetId,
    dialogMode,
    errorMessage,
    facilities,
    hospitalId,
    hospitals,
    loadFacilities,
    loading,
    manikin,
    isSavingData,
    loadHospitalLocation,
    manikins,
    onCancel,
    onHospitalDecommission,
    onUpdate,
    open,
    resetShowDialogFlag,
    setAssetAttributes,
    setHighlightedHospital,
    setTextFilterAndResetOther,
    showDlDialog,
    updateManikinAttributes,
  } = props;

  const { cartId, facility, laptopId, laptopModel, laptopSimId, location } = attrs;

  const [currentAssetId, setCurrentAssetId] = useState(assetId);
  const [currentAssetProgram, setCurrentAssetProgram] = useState(manikin ? manikin.program : assetProgramOptions[0].value);
  const [currentAssetType, setCurrentAssetType] = useState(manikin ? manikin.type : assetTypeOptions[0].value);
  const [currentCartId, setCurrentCartId] = useState(cartId);
  const [currentFirmwareVersion, setCurrentFirmwareVersion] = useState(DEFAULT_FIRMWARE_VERSION.adult);
  const [currentHospitalId, setCurrentHospitalId] = useState(hospitalId || hospitals.sort(sortFunctions.placeName)[0].hospitalId);
  const [currentHospital, setCurrentHospital] = useState(null);
  const [currentLaptopId, setCurrentLaptopId] = useState(laptopId);
  const [currentLaptopModel, setCurrentLaptopModel] = useState(laptopModel);
  const [currentLaptopSimId, setCurrentLaptopSimId] = useState(laptopSimId);
  const [currentLocation, setCurrentLocation] = useState(location);
  const [currentFacility, setCurrentFacility] = useState({ label: facility, value: '' });
  const [error, setError] = useState('');
  const [collapsed, setCollapsed] = useState(true);
  const [hospitalEditMode, setHospitalEditMode] = useState('');
  const [locationData, setLocationData] = useState(null);
  const [newHospitalName, setNewHospitalName] = useState('');
  const [sameCoordHospitalCount, setSameCoordHospitalCount] = useState(0);

  const currentDate = DateTime.local().toFormat('yyyy-MM-dd');
  const lastMaintenanceValue =
    manikin && manikin.info.lastMaintenanceMs > 0 ? DateTime.fromMillis(manikin.info.lastMaintenanceMs).toFormat('yyyy-MM-dd') : undefined;

  const [lastMaintenanceDate, setLastMaintenanceDate] = useState(lastMaintenanceValue);
  const [productionDate, setProductionDate] = useState(currentDate);

  const getAssetAttributes = (useDialogValues = false) => {
    const assetAttrs = {
      assetId: currentAssetId,
      cartId: currentCartId && currentCartId.trim(),
      facilityName: currentFacility && currentFacility.label && currentFacility.label.trim(),
      laptopId: currentLaptopId && currentLaptopId.trim(),
      laptopModel: currentLaptopModel && currentLaptopModel.trim(),
      laptopSimId: currentLaptopSimId && currentLaptopSimId.trim(),
      location: currentLocation && currentLocation.trim(),
    };

    if (!Number.isNaN(+currentFacility.value)) {
      assetAttrs.facilityId = currentFacility.value;
    }

    if (hospitalEditMode) {
      // New hospital has been added

      // Note VA and Va cases!!!
      assetAttrs.isVaHospital = currentHospital.isVAHospital;

      assetAttrs.placeCoordinates = {
        ...locationData,
        placeName: newHospitalName,
      };
    } else {
      // HospitalId is picked from dropdown
      assetAttrs.hospitalId = currentHospitalId;
    }

    // Manikin's critical data
    if (useDialogValues) {
      assetAttrs.lastMaintenance = lastMaintenanceDate && DateTime.fromFormat(lastMaintenanceDate, 'yyyy-MM-dd', { zone: 'UTC' }).toJSDate();
    } else {
      // lastMaintenance has already been set by previous action and returned from BE in assetState object
      // TODO: consolidate setting asset attributes in Redux reducer
      const activeManikin = manikins.find((m) => m.assetId === currentAssetId);
      if (activeManikin && activeManikin.info && activeManikin.info.lastMaintenanceMs) {
        assetAttrs.lastMaintenance = new Date(activeManikin.info.lastMaintenanceMs);
      }
    }

    assetAttrs.program = currentAssetProgram;
    assetAttrs.type = currentAssetType;

    // Add extra parameters for 'New' asset mode
    if (dialogMode === DialogMode.New) {
      assetAttrs.firmwareVersion = currentFirmwareVersion;
      assetAttrs.productionDate = productionDate && DateTime.fromFormat(productionDate, 'yyyy-MM-dd', { zone: 'UTC' }).toJSDate();
    }

    return assetAttrs;
  };

  const getSameCoordHospitals = () => hospitals.filter((h) => h.lat === currentHospital.location.lat && h.lng === currentHospital.location.lng);

  const facilityOptions = facilities ? facilities.map((f) => ({ label: f.name, value: f.facilityId })) : [];

  const selectedFacilityOption = currentFacility
    ? (facilityOptions && facilityOptions.find((o) => o.label === currentFacility.label)) || { label: currentFacility.label, value: '' }
    : { label: '', value: '' };

  const validateForm = () => {
    let message = '';

    if (hospitalEditMode && newHospitalName.length < 5) {
      if (newHospitalName.length < 5) {
        message += "'Hospital name' should be longer than 5 characters. \n";
      }
      validateLength(newHospitalName, 'Hospital Name', MAX_HOSPITAL_NAME_SIZE);
    }

    // Validate new mode parameters
    if (dialogMode === DialogMode.New) {
      if (!currentAssetId) {
        message += 'Serial number should not be empty\n';
      } else if (currentAssetId.length < 4) {
        message += 'Serial number should be longer than 3 characters. \n';
      }

      if (currentAssetId && manikins.find((m) => m.assetId === currentAssetId)) {
        message += `Asset with serial number '${currentAssetId}' already exists\n`;
      }

      if (lastMaintenanceDate) {
        const lmaintd = DateTime.fromFormat(lastMaintenanceDate, 'yyyy-MM-dd');
        const prodd = DateTime.fromFormat(productionDate, 'yyyy-MM-dd');
        if (lmaintd.startOf('day') < prodd.startOf('day')) {
          message += "Production date can't be less than last maintenance date\n";
        }
      }
    }

    if (!selectedFacilityOption.value) {
      message += validateLength(selectedFacilityOption.label, 'Facility', MAX_FACILITY_NAME_SIZE);
    }

    message += validateLength(location, 'Cart Location', MAX_CART_LOCATION_SIZE);
    message += validateLength(cartId, 'Station Serial', MAX_CART_ID_SIZE);
    message += validateLength(laptopId, 'Laptop Serial', MAX_LAPTOP_ID_SIZE);
    message += validateLength(laptopModel, 'Laptop Model', MAX_LAPTOP_MODEL_SIZE);
    message += validateLength(laptopSimId, 'Laptop SIM card Id', MAX_LAPTOP_SIM_ID_SIZE, !(hospitalEditMode && locationData));

    if (hospitalEditMode && locationData) {
      message += validateLength(locationData.address, 'Hospital Address', MAX_HOSPITAL_ADDRESS_SIZE, true);
    }
    return message;
  };

  useEffect(() => {
    loadFacilities(currentHospitalId);
    setCurrentHospital(hospitals.find((h) => h.hospitalId === hospitalId));
  }, []);

  useEffect(() => {
    if (currentHospitalId > 0) {
      loadFacilities(currentHospitalId);
      setCurrentHospital(hospitals.find((h) => h.hospitalId === currentHospitalId));
    }
  }, [currentHospitalId]);

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    const checkShowDialog = () => {
      // Check if show flag has been changed by the process
      if (open && !showDlDialog) {
        updateManikinAttributes(getAssetAttributes());
        // Close dialog and set show flag back to true for further usage
        onUpdate(getAssetAttributes());
        resetShowDialogFlag();
      }
    };
    checkShowDialog();
    setError('');
  }, [open, showDlDialog]);

  useEffect(() => {
    if (hospitalEditMode) {
      setCurrentHospitalId(-1);
      setCurrentHospital(null);
    }
  }, [hospitalEditMode]);

  useEffect(() => {
    if (hospitalEditMode && newHospitalName.trim().length < 5) {
      setError('Hospital name should be longer than 5 characters');
    } else {
      setError('');
    }
  }, [newHospitalName, hospitalEditMode]);

  const handleHospitalChange = (hospital) => {
    // Reset facility and location when changing hospital
    setCurrentFacility({ label: '', value: '' });
    setCurrentLocation('');
    setCurrentHospitalId(hospital.hospitalId);
    setCurrentHospital(hospital);
  };

  const handleHospitalWarningCancel = () => setSameCoordHospitalCount(0);

  const onConfirmChanges = (forceSave) => {
    setError('');

    if (
      dialogMode === DialogMode.Edit &&
      !hospitalEditMode &&
      hospitalId === currentHospitalId &&
      facility === currentFacility.label &&
      location === currentLocation &&
      currentCartId === cartId &&
      currentLaptopId === laptopId &&
      currentLaptopModel === laptopModel &&
      currentLaptopSimId === laptopSimId &&
      currentAssetType === manikin.type &&
      currentAssetProgram === manikin.program &&
      lastMaintenanceValue === lastMaintenanceDate
    ) {
      onCancel();
      return;
    }

    const errMsg = validateForm();
    if (errMsg) {
      setError(errMsg);
      return;
    }

    if (hospitalEditMode) {
      const hcount = getSameCoordHospitals().length;
      if (!forceSave && hcount > 0) {
        setSameCoordHospitalCount(hcount);
        return;
      }
      setSameCoordHospitalCount(0);
    }

    const currentManikin = manikins.find((m) => m.assetId === currentAssetId);

    setAssetAttributes(currentManikin, getAssetAttributes(true), dialogMode === DialogMode.New);

    // Re-select manikin in case if hospital has been changed
    if (currentManikin && currentManikin.hospitalId !== currentHospitalId) {
      setHighlightedHospital(null);
      setTimeout(() => {
        setHighlightedHospital(currentHospitalId);
        setTextFilterAndResetOther(currentManikin.assetId);
      }, 1500);
    }
  };

  const renderHospitalWarningModal = () => (
    <Modal
      confirmButtonText="Create"
      id="hospExistModal"
      onConfirm={() => onConfirmChanges(true)}
      onCancel={handleHospitalWarningCancel}
      open={sameCoordHospitalCount}
      title="Warning"
    >
      There are ${sameCoordHospitalCount} hospital(s) with same coordinates already &nbsp;exist in the system. Do you really want to create a new hospital?
    </Modal>
  );

  const handleCreateHospital = (val) => {
    setHospitalEditMode(HOSPITAL_EDIT_MODE_NEW);
    setCurrentFacility({ label: '', value: '' });
    setCurrentHospitalId(-1);
    setCurrentLocation('');
    setNewHospitalName(val);
  };

  const handleSelectedHospitalChange = (hsp, ldata) => {
    setCurrentHospitalId(hsp && hsp.hospitalId);
    setCurrentHospital(hsp);
    setLocationData(ldata);
  };

  const handleFacilityChange = (val) => setCurrentFacility({ label: val, value: val });

  const handleNewHospitalNameChange = (val) => setNewHospitalName(val);

  const handleCurrentAssetTypeChange = (val) => {
    setCurrentFirmwareVersion(val === ASSET_TYPE.adult ? DEFAULT_FIRMWARE_VERSION.adult : DEFAULT_FIRMWARE_VERSION.infant);
    setCurrentAssetType(val);
  };

  const handleCurrentAssetProgramChange = (val) => {
    setCurrentAssetProgram(val);
  };

  const renderAssetDataBlock = () => (
    <>
      <s.Row>
        <s.Cell>
          <Caption>Program</Caption>
          <Select
            menuPortalTarget={document.body}
            onChange={(val) => handleCurrentAssetProgramChange(val.value)}
            options={assetProgramOptions}
            value={assetProgramOptions.find((o) => o.value === currentAssetProgram)}
          />
        </s.Cell>
        <s.Delimiter />
        <s.Cell>
          <Caption>Type</Caption>
          <Select
            menuPortalTarget={document.body}
            onChange={(val) => handleCurrentAssetTypeChange(val.value)}
            options={assetTypeOptions}
            value={assetTypeOptions.find((o) => o.value === currentAssetType)}
          />
        </s.Cell>
      </s.Row>
      <s.Row>
        <s.Cell>
          <Caption>Last maintenance</Caption>
          <Input onChange={(e) => setLastMaintenanceDate(e.target.value)} name="inpLastMaintenanceDate1" type="date" value={lastMaintenanceDate} withBorder />
        </s.Cell>
        <s.Delimiter />
        <s.Cell />
      </s.Row>
    </>
  );

  const currentHospitalManikins = currentHospitalId >= 0 ? manikins.filter((m) => m.hospitalId === currentHospitalId) : [];

  const confirmButtonDisabled = !currentHospital || (hospitalEditMode && newHospitalName.length < 5);

  const dialogTitle = getDialogTitle(dialogMode, {
    currentAssetId,
    placeName: currentHospital && currentHospital.placeName,
  });

  let maxHeight = hospitalEditMode ? '1060px' : '950px';
  if (dialogMode === DialogMode.New) {
    maxHeight = hospitalEditMode ? '1100px' : '990px';
  }

  let svgScale = `scale(1 ${collapsed ? 1 : -1})`;

  return (
    <Modal
      confirmButtonDisabled={Boolean(confirmButtonDisabled)}
      confirmButtonText={ConfirmButtonTitles[dialogMode]}
      loading={isSavingData}
      maxHeight={maxHeight}
      height="100%"
      open={open}
      onCancel={onCancel}
      onConfirm={() => onConfirmChanges()}
      title={dialogTitle}
      width={800}
    >
      <s.FirstRow>
        <HospitalMapWrapper
          highlightedHospitalId={currentHospitalId}
          hospitals={currentHospital ? [currentHospital] : []}
          manikins={currentHospitalManikins}
          selectedHospitalId={currentHospitalId}
        />
      </s.FirstRow>
      {!hospitalEditMode && (
        <s.Row>
          <s.Cell w="auto">
            <Caption>Hospital</Caption>
            <HospitalSelect
              hospitalId={currentHospitalId}
              hospitals={hospitals}
              isCreatable
              isDisabled={loading}
              isLoading={loading}
              loadHospitalLocation={loadHospitalLocation}
              onHospitalChange={handleHospitalChange}
              onHospitalDecommission={onHospitalDecommission}
              onHospitalCreate={(val) => handleCreateHospital(val)}
              showDecommissionButton={dialogMode === DialogMode.Edit}
            />
          </s.Cell>
        </s.Row>
      )}
      {hospitalEditMode && (
        <>
          <s.Row>
            <s.Cell w="auto">
              <Caption>Hospital name</Caption>
              <Input
                name="inpNewHospitalName"
                onChange={(e) => handleNewHospitalNameChange(e.target.value)}
                placeholder="Enter hospital name"
                value={newHospitalName}
                withBorder
              />
            </s.Cell>
          </s.Row>
          <AddEditHospital onHospitalChange={handleSelectedHospitalChange} />
        </>
      )}
      {dialogMode !== DialogMode.New && (
        <s.Row>
          <CardContainer style={{ width: '100%' }}>
            <CardHeader onClick={() => setCollapsed(!collapsed)}>
              <span className="float-right">
                <svg width="20" height="20" transform={svgScale}>
                  <path
                    fill="#7198a2"
                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                  ></path>
                </svg>
              </span>
              History
            </CardHeader>
            <Collapse isOpen={!collapsed}>
              <CardContent>
                <AssetHistory assetId={assetId} />
              </CardContent>
            </Collapse>
          </CardContainer>
        </s.Row>
      )}

      {dialogMode === DialogMode.New && (
        <>
          <s.Row>
            <s.Cell>
              <Caption>Serial number*</Caption>
              <Input
                filter="^\d*[A-Za-z]*$"
                maxLength={20}
                name="inpSerial"
                onChange={(e) => setCurrentAssetId(e.target.value)}
                placeholder="Enter serial number"
                value={currentAssetId}
                withBorder
              />
            </s.Cell>
            <s.Delimiter />
            <s.Cell>
              <Caption>Firmware version*</Caption>
              <Input
                filter="^(\d+\.?)*$"
                maxLength={15}
                name="inpFirmwareVersion"
                onChange={(e) => setCurrentFirmwareVersion(e.target.value)}
                value={currentFirmwareVersion}
                withBorder
              />
            </s.Cell>
          </s.Row>
          <s.Row>
            <s.Cell>
              <Caption>Production date*</Caption>
              <Input onChange={(e) => setProductionDate(e.target.value)} name="inpProductionDate" type="date" value={productionDate} withBorder />
            </s.Cell>
            <s.Delimiter />
            <s.Cell>
              <Caption>Last maintenance</Caption>
              <Input
                onChange={(e) => setLastMaintenanceDate(e.target.value)}
                name="inpLastMaintenanceDate"
                type="date"
                value={lastMaintenanceDate}
                withBorder
              />
            </s.Cell>
          </s.Row>
          <s.Row>
            <s.Cell>
              <Caption>Type</Caption>
              <Select
                menuPortalTarget={document.body}
                onChange={(val) => handleCurrentAssetTypeChange(val.value)}
                options={assetTypeOptions}
                value={assetTypeOptions.find((o) => o.value === currentAssetType)}
              />
            </s.Cell>
            <s.Delimiter />
            <s.Cell>
              <Caption>Program</Caption>
              <Select
                menuPortalTarget={document.body}
                onChange={(val) => handleCurrentAssetProgramChange(val.value)}
                options={assetProgramOptions}
                value={assetProgramOptions.find((o) => o.value === currentAssetProgram)}
              />
            </s.Cell>
          </s.Row>
        </>
      )}
      <>
        <s.Row>
          <s.Cell>
            <Caption>Facility</Caption>
            {hospitalEditMode === '' ? (
              <Select
                isCreatable
                isLoading={loading}
                menuPortalTarget={document.body}
                onChange={setCurrentFacility}
                options={facilityOptions}
                value={selectedFacilityOption}
              />
            ) : (
              <Input
                name="inpFacility"
                onChange={(e) => handleFacilityChange(e.target.value)}
                placeholder="Enter facility name"
                value={currentFacility.value}
                withBorder
              />
            )}
          </s.Cell>
          <s.Delimiter />
          <s.Cell>
            <Caption>Cart location</Caption>
            <Input
              name="inpCartLocation"
              onChange={(e) => setCurrentLocation(e.target.value)}
              placeholder="Enter cart location"
              value={currentLocation}
              withBorder
            />
          </s.Cell>
        </s.Row>
        <s.Row>
          <s.Cell>
            <Caption>Station serial</Caption>
            <Input
              filter="^\d*$"
              maxLength={11}
              name="inpStationSerial"
              onChange={(e) => setCurrentCartId(e.target.value)}
              placeholder="Enter station serial"
              value={currentCartId}
              withBorder
            />
          </s.Cell>
          <s.Delimiter />
          <s.Cell>
            <Caption>Laptop serial</Caption>
            <Input
              name="inpLaptopId"
              onChange={(e) => setCurrentLaptopId(e.target.value)}
              placeholder="Enter laptop serial"
              value={currentLaptopId}
              withBorder
            />
          </s.Cell>
        </s.Row>
        <s.Row>
          <s.Cell>
            <Caption>Laptop model</Caption>
            <Input
              name="inpLaptopModel"
              onChange={(e) => setCurrentLaptopModel(e.target.value)}
              placeholder="Enter laptop model"
              value={currentLaptopModel}
              withBorder
            />
          </s.Cell>
          <s.Delimiter />
          <s.Cell>
            <Caption>Laptop sim card</Caption>
            <Input
              filter="^\d*$"
              maxLength={16}
              name="inpLaptopSimCard"
              onChange={(e) => setCurrentLaptopSimId(e.target.value)}
              placeholder="Enter laptop SIM card"
              value={currentLaptopSimId}
              withBorder
            />
          </s.Cell>
        </s.Row>
      </>
      {dialogMode === DialogMode.Edit && renderAssetDataBlock()}
      <s.Row>
        <s.Cell w="auto">
          <s.MessageCont>
            {error && <s.ErrorText error>{error}</s.ErrorText>}
            {hospitalEditMode && !currentHospital && <s.AddHospitalHintText>Hint: Enter address and press &apos;Locate&apos; to proceed</s.AddHospitalHintText>}
          </s.MessageCont>
        </s.Cell>
      </s.Row>
      {!!sameCoordHospitalCount && renderHospitalWarningModal()}
    </Modal>
  );
};
EditLocationModal.propTypes = {
  attrs: ManikinAttrsShape.isRequired,
  assetId: PropTypes.string,
  dialogMode: PropTypes.oneOf([DialogMode.New, DialogMode.Edit]),
  errorMessage: PropTypes.string,
  facilities: PropTypes.array,
  hospitalId: PropTypes.number,
  hospitals: PropTypes.arrayOf(HospitalShape).isRequired,
  isSavingData: PropTypes.bool,
  loadFacilities: PropTypes.func.isRequired,
  loadHospitalLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  manikin: PropTypes.oneOfType([ManikinShape, PropTypes.oneOf([null])]),
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
  onCancel: PropTypes.func.isRequired,
  onHospitalDecommission: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool,
  resetShowDialogFlag: PropTypes.func.isRequired,
  setAssetAttributes: PropTypes.func.isRequired,
  setHighlightedHospital: PropTypes.func.isRequired,
  setTextFilterAndResetOther: PropTypes.func.isRequired,
  showDlDialog: PropTypes.bool,
  updateManikinAttributes: PropTypes.func.isRequired,
};
EditLocationModal.defaultProps = {
  assetId: '',
  dialogMode: DialogMode.Edit,
  errorMessage: '',
  facilities: [],
  hospitalId: null,
  loading: false,
  manikin: null,
  isSavingData: false,
  onHospitalDecommission: Function.prototype,
  open: false,
  showDlDialog: false,
};

const mapStateToProps = (state) => {
  const dlr = state.descriptiveLocationReducer;
  return {
    facilities: !dlr.isSavingData ? dlr.data : [],
    hospitals: state.manicDataReducer.data ? state.manicDataReducer.data.hospitals : [],
    errorMessage: dlr.error ? dlr.message : '',
    loading: dlr.loading,
    manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : [],
    isSavingData: dlr.isSavingData,
    showDlDialog: dlr.showDlDialog,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadFacilities: (hospitalId) => dispatch(getFacilitiesAction(hospitalId)),
  loadHospitalLocation: (hospitalId) => dispatch(getHospitalLocationAction(hospitalId)),
  resetShowDialogFlag: () => dispatch(showDescriptiveLocationDialog(true)),
  setAssetAttributes: (manikin, attrs, createNewAsset) => dispatch(setAssetAttributesAction(manikin, attrs, createNewAsset)),
  setHighlightedHospital: (hospitalId) => dispatch(setFilterHospitalHighlightAction(hospitalId)),
  setTextFilterAndResetOther: (assetId) => dispatch(setTextFilterAndResetOtherAction(assetId)),
  updateManikinAttributes: (attrs) => dispatch(updateAssetAttributesAction(attrs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLocationModal);
