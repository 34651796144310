import styled from 'styled-components';
import Card from '../../components/Card/Card';
import SubTitle from '../../components/Texts/SubTitle';
import Text from '../../components/Texts/Text';

const s = {};

s.Root = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  padding: 90px 30px;
  min-height: calc(100% - 55px);
`;

s.PageTitle = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

s.SubTitle = styled(SubTitle)`
  display: inline-block;
`;

s.Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

s.Card = styled(Card)`
  flex: 1 auto;
  ${({ fixed }) => fixed && `flex: 0 1 650px`};
  ${({ fixed }) => fixed && `min-width: 650px`};
`;

s.CardTitleWithDescription = styled.div`
  white-space: nowrap;
`;

s.EventCounterList = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 100%;
`;

s.EventCounterListContent = styled.div`
  display: block;
  flex: 1 auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.36rem !important;
  }

  &::-webkit-scrollbar-button {
    background-color: #ddf8f8;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f7fa;
  }

  &::-webkit-scrollbar-thumb {
    max-height: 2rem !important;
    background-color: #a1d9d5;
    width: 0.3rem !important;
  }
`;

s.Log = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
`;

s.ErrorCont = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

s.ErrorText = styled(Text)`
  align-items: center;
`;

export default s;
