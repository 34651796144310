import styled from 'styled-components';
import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import Text from '../../../../components/Texts/Text';
import SubTitle from '../../../../components/Texts/SubTitle';

const style = {};

style.Content = styled.div`
  display: flex;
  padding: 0 34px;
  height: 40px;

  border-bottom: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : theme.greyPalette.grey6
  )};
`;


style.Cell = styled.div`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.regular};
  justify-content: flex-start;

  ${({ label }) => label && `
    flex: 1 auto;
    flex-wrap: wrap;
    min-width: 100px;
    margin-right: 17px;
    position: relative;
  `}

  ${({ value }) => value && `
    flex: 0 1 70px;
    min-width: 70px;
    justify-content: center;
  `}

  ${({ action }) => action && `
    flex: 0 1 100px;
    min-width: 70px;
    justify-content: flex-end;
  `}

  &>svg {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
`;

style.ListItem = styled.div`
  background-color: ${({ theme, opened }) => (opened ? theme.greyPalette.grey6 : theme.primaryBackground)};
  border-bottom: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : theme.greyPalette.grey6
  )};
  border-top: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : 'transparent'
  )};
  border-left: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : 'transparent'
  )};
  border-right: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : 'transparent'
  )};

  color: ${props => props.theme.greyPalette.black};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fontSize.medium};
  justify-content: center;

  &:hover {
    background-color: ${props => props.theme.greyPalette.grey6};
  }
`;

style.Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

style.ActionButton = styled.div`
  color: ${props => props.theme.greyPalette.grey};
  margin-left: 15px;

  &:hover {
    color: ${props => props.theme.secondaryColorPalette.main};
  }

  &>svg {
    width: 18px;
    height: 18px;
  }
`;

style.Loader = styled(LoadingSpinner)`
  margin-left: 10px;
`;

style.ErrorText = styled(Text)`
  font-size: 11px;
  position: absolute;
  bottom: 0;
`;

style.LogContainer = styled.div`
`;

style.Log = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 0 34px 0 58px;
`;

style.LogSubTitleCont = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

style.LogSubTitle = styled(SubTitle)`
  flex: 1 auto;
`;

style.LogText = styled(Text)`
  white-space: pre-line;
`;

export default style;
