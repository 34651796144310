import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { IoIosArrowDropdown, IoIosArrowDropright } from 'react-icons/io';

import s from './ManikinPartGroup.style';

import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import { ManikinSubPartShape } from '../../../../constants/types';
import { MANIKIN_PART_GROUP_TYPE } from '../../../../constants/constants';

const ManikinPartGroup = (props) => {
  const {
    assetSubParts,
    buttonType,
    columns,
    defaultSelectedItems,
    loading,
    onToggle,
    showColumnHeader,
    title,
    type
  } = props;

  const [isOpen, setIsOpen] = useState(true);

  const isSingleColumn = !showColumnHeader && columns.length === 1;

  const handleItemToggle = (opt, col) => {
    if (onToggle) {
      onToggle(opt.assetSubPartId, col && col.replacementTypeId);
    }
  };

  const renderSingleModeItems = () => assetSubParts.map(optn => (
    <div key={optn.assetSubPartId}>
      <s.Input
        defaultChecked={defaultSelectedItems && defaultSelectedItems.indexOf(optn.assetSubPartId) >= 0}
        id={`${optn.assetSubPartId}`}
        name={optn.assetSubPartId}
        onChange={() => handleItemToggle(optn)}
        type={buttonType}
        value={optn.assetSubPartId}
      />
      <s.InputLabel htmlFor={`${optn.assetSubPartId}`}>
        {optn.name}
      </s.InputLabel>
    </div>
  ));

  const renderMultipleModeItem = (optn, column) => {
    let groupName = optn.assetSubPartId;
    if (buttonType === 'checkbox') {
      groupName = `${column.replacementTypeId}-${groupName}`;
    } else if (isSingleColumn) {
      // For single column radio group should be spreaded vertically
      groupName = `${column.replacementTypeId}`;
    }
    return (
      <s.MultiModeItemCont key={column.replacementTypeId}>
        <s.MultiModeItem
          defaultChecked={
            defaultSelectedItems && defaultSelectedItems[column.replacementTypeId]
              .indexOf(optn.assetSubPartId) >= 0
          }
          id={`${column.replacementTypeId}-${optn.assetSubPartId}`}
          name={groupName}
          onChange={() => handleItemToggle(optn, column)}
          type={buttonType}
          value={`${column.replacementTypeId}-${optn.assetSubPartId}`}
        />
        <s.MultiModeItemLabel htmlFor={`${column.replacementTypeId}-${optn.assetSubPartId}`} />
      </s.MultiModeItemCont>
    );
  };

  const renderMultipleModeItems = () => assetSubParts.map(optn => (
    <s.ItemGroup key={optn.assetSubPartId}>
      <s.ItemGroupLabel singleColumn={!showColumnHeader && columns.length === 1}>{optn.name}</s.ItemGroupLabel>
      {columns.map(column => renderMultipleModeItem(optn, column))}
    </s.ItemGroup>
  ));

  const renderColumnHeader = () => (
    <s.ColumnHeader>
      <s.ColumnHeaderStubCell />
      { columns.map(col => (
        <s.ColumnHeaderCell key={col.replacementTypeId}>
          {col.name}
        </s.ColumnHeaderCell>
      )) }
    </s.ColumnHeader>
  );

  return (
    <>
      <s.Row pointer>
        <s.Title onClick={() => setIsOpen(!isOpen)}>
          {title}
          {isOpen ? <IoIosArrowDropdown /> : <IoIosArrowDropright />}
        </s.Title>
        {loading && (
          <LoadingSpinner />
        )}
      </s.Row>
      <s.Row>
        {isOpen && (
          <s.Items width={isSingleColumn ? '60%' : '100%'} padded={isSingleColumn}>
            {type !== MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN && showColumnHeader && renderColumnHeader()}
            {type === MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN ? renderSingleModeItems() : renderMultipleModeItems()}
          </s.Items>
        )}
      </s.Row>
    </>
  );
};

ManikinPartGroup.propTypes = {
  assetSubParts: PropTypes.arrayOf(ManikinSubPartShape).isRequired,
  buttonType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object),
  defaultSelectedItems: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  loading: PropTypes.bool,
  onToggle: PropTypes.func,
  showColumnHeader: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN, MANIKIN_PART_GROUP_TYPE.MULTIPLE_COLUMN])
};

ManikinPartGroup.defautProps = {
  columns: [],
  defaultSelectedItems: undefined,
  loading: false,
  onToggle: Function.prototype,
  showColumnHeader: true,
  type: MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN
};

export default ManikinPartGroup;
