import styled from 'styled-components';

export const progressHeight = '10';
const style = {};

style.Root = styled.div`
  flex: 0 0 calc(100% - 60px);
  width: ${({ width }) => width || 'auto'}px;
  height: ${progressHeight}px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
`;

style.Bar = styled.div`
  position: relative;
  width: 100%;
  height: ${progressHeight}px;
  border-radius: 5px;
`;

export default style;
