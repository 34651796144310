import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IoIosCloseCircleOutline,
  IoMdDownload
} from 'react-icons/io';
import styled from 'styled-components';

import { theme } from '../../../../theme/theme';

import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';

import { downloadSSJ, downloadCounterSSJ } from '../../../../api/api';
import Button from '../../../../components/Button';

const DownloadSSJButton = (props) => {
  const {
    assetId,
    ruleId,
    sessionId
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onDownloadButtonClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setError('');
    setLoading(true);
    try {
      if (sessionId) {
        await downloadSSJ(sessionId);
      } else {
        await downloadCounterSSJ(assetId, ruleId);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <LoaderCont>
          <LoadingSpinner color={theme.secondaryColorPalette.light1} />
        </LoaderCont>
      )}
      {!loading && (
        <DownloadButton onClick={e => onDownloadButtonClick(e)}>
          <IoMdDownload />
        </DownloadButton>
      )}
      {error && <ErrorIcon />}
    </>
  );
};

DownloadSSJButton.propTypes = {
  assetId: PropTypes.string,
  ruleId: PropTypes.string,
  sessionId: PropTypes.string
};

DownloadSSJButton.defaultProps = {
  assetId: 0,
  ruleId: '',
  sessionId: ''
};


export default DownloadSSJButton;

const LoaderCont = styled.div`
  margin-left: 15px;
`;

const DownloadButton = styled.div`
  color: ${props => props.theme.greyPalette.grey};
  margin-left: 15px;

  &:hover {
    color: ${props => props.theme.secondaryColorPalette.main};
  }

  &>svg {
    width: 18px;
    height: 18px;
  }
`;

const ErrorIcon = styled(IoIosCloseCircleOutline)`
  color: ${props => props.theme.redColorPalette.main}
`;
