import React, {
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HospitalShape } from '../../../../constants/types';

import s from './EditLocationModal.style';
import Caption from '../../../../components/Texts/Caption';
import Textarea from '../../../../components/Textarea';
import { theme } from '../../../../theme/theme';
import { getGeoData } from '../../../../api/api';
import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import CheckBoxSquare from '../../../../components/CheckBoxSquare/CheckBoxSquare';

const SearchLabel = (
  <>
    <svg height="16" viewBox="0 0 24 24" width="16">
      <path fill={theme.inverseColor} d="M23.486,20.96,17.329,14.8l-.33.33-.33-.33A9.263,9.263,0,0,0,2.7,2.7,9.253,9.253,0,0,0,14.776,16.666l.33.33-.33.33,6.157,6.156a1.8,1.8,0,0,0,2.523,0,1.767,1.767,0,0,0,.03-2.522Zm-9.22-6.7a7.114,7.114,0,1,1,0-10.059,7.143,7.143,0,0,1,0,10.059Zm0,0" />
    </svg>
    &nbsp;Locate
  </>
);

const AddEditHospital = (props) => {
  const {
    hospital,
    onHospitalChange
  } = props;

  const [address, setAddress] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [isVAHospital, setIsVAHospital] = useState(false);

  // returns fake hospital to render it properly
  const getSelectedHospital = () => {
    if (!locationData) {
      return null;
    }

    const {
      lat,
      lon: lng,
      usState
    } = locationData;

    return { // Hospital shape
      hospitalId: -1,
      placeName: '',
      manikins: '0/0',
      unresolvedIssues: 0,
      isVAHospital,
      location: { // HospitalLocation shape
        address,
        lat,
        lng,
        usState
      }
    };

  };

  const setHospitalInfo = () => {
    if (hospital) {
      setAddress(hospital.location.address);
    }
  };

  useEffect(() => {
    setHospitalInfo();
  }, []);

  useEffect(() => {
    setHospitalInfo();
  }, [hospital]);

  useEffect(() => onHospitalChange(getSelectedHospital(), locationData), [locationData]);

  const handleLocateClick = async () => {
    setError('');
    setIsLoading(true);
    try {
      const res = await getGeoData(address);
      if (res.status === 200) {
        setLocationData(res.data);
      } else {
        setError(res.data);
      }
    } catch (err) {
      setError((err.response && err.response.data && err.response.data.message) || err);
    }
    setIsLoading(false);
  };

  const handleAddressChange = (addr) => {
    setLocationData(null);
    setAddress(addr);
  };

  const isLocateEnabled = !locationData && address.trim().length >= 5;

  return (
    <>
      <s.Row>
        <s.Cell col w="auto">
          <Caption>
            Hospital address
          </Caption>
          <Textarea
            name="inpHospitalAddress"
            onChange={e => handleAddressChange(e.target.value)}
            placeholder="Enter hospital address"
            value={address}
            withBorder
          />
        </s.Cell>
        <s.Delimiter />
        <s.Cell col rel>
          <CheckBox
            checked={isVAHospital}
            label="Is VA Hospital"
            onClick={() => setIsVAHospital(!isVAHospital)}
          />
          <s.HospitalButton
            disabled={!isLoading && !isLocateEnabled}
            label={isLoading ? <LoadingSpinner /> : SearchLabel}
            onClick={handleLocateClick}
          />
        </s.Cell>
      </s.Row>
      {error && <s.AddHospitalErrorText error>{error}</s.AddHospitalErrorText>}
    </>
  );
};

AddEditHospital.propTypes = {
  hospital: HospitalShape,
  isLoading: PropTypes.bool,
  onHospitalChange: PropTypes.func
};

AddEditHospital.defaultProps = {
  hospital: null,
  isLoading: false,
  onHospitalChange: () => true
};

export default AddEditHospital;

const CheckBox = styled(CheckBoxSquare)`
  position: absolute;
  top: 0;
`;
