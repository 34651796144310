import { SET_CURRENT_ALARMS, SET_DAILY_ALARMS, SET_PREFERRED_STATES, SET_SETTINGS, SET_KEYS, SET_MONITOR_QUERIES } from '../constants/constants';

const initial = {
  email: '',
  currentAlarms: 5,
  settings: {
    alarmsPerDay: 5,
    preferredStates: [],
    monitorQueries: [],
  },
  googleApiKey: '',
};

export default (state = initial, action) => {
  switch (action.type) {
    case SET_CURRENT_ALARMS:
      return {
        ...state,
        currentAlarms: action.numberOfAlarms,
      };
    case SET_DAILY_ALARMS:
      return {
        ...state,
        currentAlarms: action.numberOfAlarms,
        settings: {
          ...state.settings,
          alarmsPerDay: action.numberOfAlarms,
        },
      };
    case SET_PREFERRED_STATES:
      return {
        ...state,
        settings: {
          ...state.settings,
          preferredStates: action.states,
        },
      };
    case SET_MONITOR_QUERIES:
      return {
        ...state,
        settings: {
          ...state.settings,
          monitorQueries: action.monitorQueries ?? [],
        },
      };
    case SET_SETTINGS:
      return {
        ...state,
        currentAlarms: action.settings.alarmsPerDay,
        email: action.email,
        settings: {
          alarmsPerDay: action.settings.alarmsPerDay ?? 5,
          preferredStates: action.settings.preferredStates ?? [],
          monitorQueries: action.settings.monitorQueries ?? [],
        },
      };
    case SET_KEYS:
      return {
        ...state,
        googleApiKey: action.googleApiKey,
      };
    default:
      return state;
  }
};
