import {
  SENT_EVENT_COUNTER_DATA_REQUEST,
  RECEIVED_EVENT_COUNTER_DATA_REQUEST,
  ERROR_EVENT_COUNTER_DATA_REQUEST
} from '../constants/constants';
import { requestAssetEventCounters } from '../api/api';

const raiseError = (data, type, message, dispatch) => {
  const msg = `${message} ${data}`;
  // eslint-disable-next-line no-console
  console.warn(msg);
  dispatch({
    type,
    payload: msg
  });
};

export const getAssetEventCountersAction = (assetId, getRuleList) => async (dispatch) => {
  dispatch({
    type: SENT_EVENT_COUNTER_DATA_REQUEST,
    payload: assetId
  });

  const setError = data => raiseError(data, ERROR_EVENT_COUNTER_DATA_REQUEST, 'Error getting event counter data.', dispatch);

  try {
    const res = await requestAssetEventCounters(assetId, getRuleList);
    if (res.status === 200) {
      dispatch({
        type: RECEIVED_EVENT_COUNTER_DATA_REQUEST,
        payload: res.data
      });
    } else {
      setError(res.data);
    }
  } catch (err) {
    setError((err.response && err.response.data && err.response.data.message) || err);
  }
};

export default getAssetEventCountersAction;
