import styled from 'styled-components';

const Title = styled.h1`
  color: ${(props) => props.theme.greyPalette.black};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  letter-spacing: 0.15px;
  margin: 0;
  padding: 0;
  text-align: left;
`;

export default Title;
