import {
  ACTION_TYPES,
  ALARM_TYPES, ALARM_TYPES_WEIGHT
} from '../constants/constants';

export const countIssues = manikins => manikins.reduce((acc, manikin) => {
  const { alarms } = manikin;
  const realIssues = Array.from(alarms.values())
    .filter(alarm => alarm.alarmType !== ALARM_TYPES.HEALTHY);

  return acc + realIssues.length;
}, 0);

export const getActionsUpdates = (assetsUpdates, manikins) => {
  const updatedIds = Object.keys(assetsUpdates);

  return updatedIds.reduce((res, assetId) => {
    const manikin = manikins.find(m => m.assetId === assetId);

    if (manikin) {
      const { action } = manikin;
      const assetUpdate = assetsUpdates[assetId];
      // Not sure if this behavior is correct
      // const updated = action.actionType !== assetUpdate.actionType;
      const updated = action.actionType !== assetUpdate.actionType
        || action.actionId !== assetUpdate.actionId;

      if (updated) {
        return res.concat({
          ...assetUpdate,
          assetId: manikin.assetId
        });
      }
    }

    return res;
  }, []);
};

export const getNewHandledAlarms = (actionsUpdates, manikins) => actionsUpdates.reduce((res, actionUpdate) => {
  if (actionUpdate.actionType.length > 0) {
    const manikin = manikins.find(m => m.assetId === actionUpdate.assetId);

    return res.concat(manikin.alarms);
  }

  return res;
}, []);

export const getCanceledActions = actionUpdates => actionUpdates
  .filter(actionUpdate => actionUpdate.actionType.length === 0);

export const getApproveActions = (actionsUpdates, manikins) => manikins.reduce((res, manikin) => {
  const { assetId } = manikin;
  const { action } = manikin;
  const actionUpdate = actionsUpdates[assetId];

  if (
    action.actionType === ACTION_TYPES.APPROVED
    || (actionUpdate && actionUpdate.actionType === ACTION_TYPES.APPROVED)
  ) {
    return res.concat(action);
  }

  return res;
}, []);

export const getRejectActions = (actionsUpdates, manikins) => manikins.reduce((res, manikin) => {
  const { assetId } = manikin;
  const { action } = manikin;
  const actionUpdate = actionsUpdates[assetId];

  if (
    action.actionType === ACTION_TYPES.REJECTED
    || (actionUpdate && actionUpdate.actionType === ACTION_TYPES.REJECTED)
  ) {
    return res.concat(action);
  }

  return res;
}, []);

export const sortAlarmTypes = (a, b) => ALARM_TYPES_WEIGHT[a.alarmType] - ALARM_TYPES_WEIGHT[b.alarmType];

export default {
  countIssues,
  getNewHandledAlarms,
  getCanceledActions,
  getActionsUpdates,
  getApproveActions,
  getRejectActions
};
