import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { sendActions } from '../../../api/api';
import {
  getData,
  resetAssetUpdates
} from '../../../actions';
import ProgressBar from './ProgressBar/ProgressBar';
import {
  getNewHandledAlarms,
  getCanceledActions,
  getActionsUpdates
} from '../../../utility/alarm';
import { ManikinShape } from '../../../constants/types';
import Button from '../../../components/Button';
import Caption from '../../../components/Texts/Caption';
import Modal from '../../../components/Modal';
import Text from '../../../components/Texts/Text';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';

const SendWithProgress = (props) => {
  const {
    primaryValue,
    secondaryValue,
    primaryText,
    secondaryText,
    onSendClick,
    displayNumber
  } = props;
  return (
    <SendContainer>
      <div>
        <StyledCaption>
          Handled alerts
        </StyledCaption>
        <ProgressBar
          primaryValue={primaryValue}
          primaryText={primaryText}
          secondaryValue={secondaryValue}
          secondaryText={secondaryText}
          width={210}
        />
      </div>
      <StyledButton
        color="normal"
        label={`Send ${displayNumber}`}
        onClick={displayNumber === 0 ? Function.prototype : onSendClick}
        disabled={displayNumber === 0}
      />
    </SendContainer>
  );
};

SendWithProgress.propTypes = {
  primaryValue: PropTypes.number.isRequired,
  secondaryValue: PropTypes.number.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
  onSendClick: PropTypes.func.isRequired,
  displayNumber: PropTypes.number.isRequired
};

export const Send = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const {
    loadManikins,
    resetUpdates,
    assetsUpdates,
    manikins,
    startScreen
  } = props;
  const email = localStorage.getItem('email');
  const oldHandledAlarmsCount = manikins.reduce((acc, manikin) => {
    const {
      alarms: manikinAlarms,
      action
    } = manikin;
    const handled = action.actionType.length > 0;

    return acc + (handled ? manikinAlarms.length : 0);
  }, 0);
  const actionUpdates = getActionsUpdates(assetsUpdates, manikins);
  const addedActionsCount = getNewHandledAlarms(actionUpdates, manikins).length;
  const canceledActionsCount = getCanceledActions(actionUpdates).length;
  const allHandledAlarmsCount = oldHandledAlarmsCount + addedActionsCount - canceledActionsCount;
  const allAlarmsCount = manikins.reduce((acc, manikin) => {
    const { alarms } = manikin;

    return acc + alarms.length;
  }, 0);

  const toggleModal = () => {
    if (!showModal) {
      setSuccess('');
      setError('');
      setLoading(false);
    }
    setShowModal(!showModal);
  };

  const handleSend = async () => {
    let actionUpdatesToSend = [...actionUpdates];

    if (startScreen) {
      actionUpdatesToSend = actionUpdatesToSend.map(update => ({
        ...update,
        fromStartScreen: true
      }));
    }
    try {
      setLoading(true);
      setError('');
      await sendActions(actionUpdatesToSend);
      setSuccess('Data sent successfully.');
      resetUpdates();
      loadManikins();
    } catch (e) {
      setError(`Send data error. ${e}`);
      throw new Error(e);
    } finally {
      setLoading(false);
    }
  };

  const renderButtonText = () => (success ? 'Close' : 'Send');

  return (
    <div>
      <SendWithProgress
        primaryValue={(oldHandledAlarmsCount / allAlarmsCount) * 100}
        primaryText={`${oldHandledAlarmsCount} / ${allAlarmsCount}`}
        secondaryValue={(allHandledAlarmsCount / allAlarmsCount) * 100}
        secondaryText={`${allHandledAlarmsCount} / ${allAlarmsCount}`}
        onSendClick={toggleModal}
        displayNumber={actionUpdates.length}
      />
      <Modal
        open={showModal}
        title="Send updates"
        confirmButtonText={loading ? <LoadingSpinner /> : renderButtonText()}
        onCancel={toggleModal}
        onConfirm={success ? toggleModal : handleSend}
      >
        {!success && (
          <Text>
            {
              `Are you sure you want to send ${actionUpdates.length} selected actions?
                 You will receive an email at ${email}.`
            }
          </Text>
        )}
        {success && (<Text>{success}</Text>)}
        {error && (<Text error>{error}</Text>)}
      </Modal>
    </div>
  );
};

Send.propTypes = {
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
  assetsUpdates: PropTypes.object.isRequired,
  loadManikins: PropTypes.func.isRequired,
  resetUpdates: PropTypes.func.isRequired,
  startScreen: PropTypes.bool
};

Send.defaultProps = {
  startScreen: false
};

const mapStateToProps = state => ({
  assetsUpdates: state.manicDataReducer.updates
});

const mapDispatchToProps = dispatch => ({
  loadManikins: () => dispatch(getData()),
  resetUpdates: () => dispatch(resetAssetUpdates())
});

export default connect(mapStateToProps, mapDispatchToProps)(Send);

const StyledButton = styled(Button)`
  margin-left: 20px;
`;

const StyledCaption = styled(Caption)`
  position: absolute;
  top: -3px;
`;

const SendContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;
