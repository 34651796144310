import * as React from 'react';
import PropTypes from 'prop-types';

import s from './ProgressBar.style';
import Progress from './Progress';

const ProgressBar = (props) => {
  const {
    primaryValue,
    secondaryValue,
    primaryText,
    secondaryText,
    width
  } = props;

  return (
    <s.Root width={width}>
      <s.Bar>
        <Progress
          value={secondaryValue}
          text={secondaryText}
          warn={secondaryValue < primaryValue}
          secondary
        />
        <Progress
          value={primaryValue}
          text={primaryText}
        />
      </s.Bar>
    </s.Root>
  );
};

ProgressBar.propTypes = {
  primaryValue: PropTypes.number.isRequired,
  secondaryValue: PropTypes.number,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  width: PropTypes.number
};

ProgressBar.defaultProps = {
  secondaryValue: 0,
  primaryText: '',
  secondaryText: '',
  width: 0
};

export default ProgressBar;
