import {
  ADD_ALARM_TYPE_FILTER,
  ADD_MANIKIN_TYPE_FILTER,
  ADD_PROGRAM_FILTER,
  REMOVE_ALARM_TYPE_FILTER,
  REMOVE_MANIKIN_TYPE_FILTER,
  REMOVE_PROGRAM_FILTER,
  RESET_FILTER,
  SET_VA_FILTER,
  SET_HANDLED_FILTER,
  SET_HEALTH_FILTER,
  SET_SORT,
  SET_SORT_DIRECTION,
  SET_STATE_FILTER,
  SET_COUNTRY_FILTER,
  SET_AVAILABLE_COUNTRIES,
  UPDATE_PRE_SELECTED_ASSET_ID,
  UPDATE_FILTER_HIGHLIGHT_HOSPITAL,
  UPDATE_FILTER_HOSPITAL,
  UPDATE_LAST_SELECTED_HOSPITAL_ID,
  UPDATE_TEXT_FILTER,
  UPDATE_TEXT_FILTER_AND_RESET_OTHER,
  ADD_DECOMMISSIONED_FILTER,
  REMOVE_DECOMMISSIONED_FILTER
} from '../constants/constants';

export const resetFiltersAction = () => dispatch => dispatch({
  type: RESET_FILTER
});

export const addDecommissionedFilterAction = decommissionType => dispatch => dispatch({
  type: ADD_DECOMMISSIONED_FILTER,
  decommissionType
});
export const removeDecommissionedFilterAction = decommissionType => dispatch => dispatch({
  type: REMOVE_DECOMMISSIONED_FILTER,
  decommissionType
});


export const setHospitalFilterAction = (hospitalId, hospitalName) => dispatch => dispatch({
  type: UPDATE_FILTER_HOSPITAL,
  hospitalId
});
export const setFilterHospitalHighlightAction = hospitalId => dispatch => dispatch({
  type: UPDATE_FILTER_HIGHLIGHT_HOSPITAL,
  hospitalId
});
export const setLastSelectedHospitalAction = hospitalId => dispatch => dispatch({
  type: UPDATE_LAST_SELECTED_HOSPITAL_ID,
  hospitalId
});
export const setPreSelectedAssetAction = preSelectedAssetId => dispatch => dispatch({
  type: UPDATE_PRE_SELECTED_ASSET_ID,
  preSelectedAssetId
});
export const setTextFilterAction = text => dispatch => dispatch({
  type: UPDATE_TEXT_FILTER,
  text
});
export const setTextFilterAndResetOtherAction = text => dispatch => dispatch({
  type: UPDATE_TEXT_FILTER_AND_RESET_OTHER,
  text
});
export const addManikinTypeFilterAction = manikinType => dispatch => dispatch({
  type: ADD_MANIKIN_TYPE_FILTER,
  manikinType
});
export const removeManikinTypeFilterAction = manikinType => dispatch => dispatch({
  type: REMOVE_MANIKIN_TYPE_FILTER,
  manikinType
});
export const addFailureTypeFilterAction = failureType => dispatch => dispatch({
  type: ADD_ALARM_TYPE_FILTER,
  failureType
});
export const removeFailureTypeFilterAction = failureType => dispatch => dispatch({
  type: REMOVE_ALARM_TYPE_FILTER,
  failureType
});
export const setHealthFilterAction = healthState => dispatch => dispatch({
  type: SET_HEALTH_FILTER,
  healthState
});
export const setHandledFilterAction = handled => dispatch => dispatch({
  type: SET_HANDLED_FILTER,
  handled
});
export const setVAFilterAction = vaOption => dispatch => dispatch({
  type: SET_VA_FILTER,
  vaOption
});
export const setStateFilterAction = states => dispatch => dispatch({
  type: SET_STATE_FILTER,
  states
});
export const setCountryFilterAction = countries => dispatch => dispatch({
  type: SET_COUNTRY_FILTER,
  countries
});
export const setAvailableCountriesAction = availableCountries => dispatch => dispatch({
  type: SET_AVAILABLE_COUNTRIES,
  availableCountries
});
export const setSortAction = sort => dispatch => dispatch({
  type: SET_SORT,
  sort
});
export const addProgramFilterAction = program => dispatch => dispatch({
  type: ADD_PROGRAM_FILTER,
  program
});
export const removeProgramFilterAction = program => dispatch => dispatch({
  type: REMOVE_PROGRAM_FILTER,
  program
});
export const setSortDirectionAction = sortDirection => dispatch => dispatch({
  type: SET_SORT_DIRECTION,
  sortDirection
});
