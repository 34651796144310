import styled from 'styled-components';
import s from '../ListItem.style';

const style = {};

style.Root = styled(s.Column)`
  flex: 0 1 120px;
  min-width: 100px;
  flex-wrap: wrap;
  svg {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.tall &&
    `
    position: relative;
    flex-direction: column;
    padding-top: 64px;
    height: 164px;
    flex-basis: 60px;
    width: 60px;
    min-width: 60px;
    flex-wrap: wrap;
    z-index: 150;
  `}
`;

style.IconWrapper = styled.div`
  position: relative;
  outline: none;
  color: ${(props) => props.theme.laerdalPalette.primary.default};

  &:hover {
    color: ${(props) => props.theme.laerdalPalette.primary.heavy};
  }

  & svg {
    fill: ${(props) => props.theme.laerdalPalette.primary.default};
  }

  & svg:hover {
    fill: ${(props) => props.theme.laerdalPalette.primary.heavy};
  }

  & > svg {
    max-width: 20px;
  }

  ${(props) =>
    props.selected &&
    `
    color: rgb(32, 188, 173);

    :before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -8px;
      margin-top: -4px;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid;
    }
  `}
`;

export default style;
