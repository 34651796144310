import styled from 'styled-components';

const SubTitle = styled.h2`
    color: ${props => props.theme.greyPalette.black};
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.medium};
    letter-spacing: 0.11px;
    text-align: left;
    margin: 0;
    padding: 0;
`;

export default SubTitle;
