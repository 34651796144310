import { DateTime } from 'luxon';
import {
  ADD_NEW_ASSET,
  CHANGE_MANIKIN_HOSPITAL,
  ERROR_DATA_REQUEST,
  MOVE_MANIKIN_TO_HOSPITAL,
  RECEIVE_DATA,
  RECEIVED_ADD_HOSPITAL,
  RECEIVED_HOSPITAL_ADDRESS,
  REMOVE_ASSET_UPDATE, RESET_UPDATES,
  RESET_ASSET_ALARM,
  SENT_DATA_REQUEST,
  SENT_HOSPITAL_LOCATION_REQUEST,
  SET_ASSET_ALARMS,
  SET_ASSET_IS_DECOMMISSIONED,
  SET_ASSET_UPDATE,
  UPDATE_ASSET_ATTRS,
  UPDATE_REQ_DATA_MANIC,
  RECEIVED_SET_ASSET_ATTR_REQUEST,
  CLEANUP_EMPTY_HOSPITALS,
  REFRESH_HOSPITAL_ALARMS
} from '../constants/constants';
import updateHospitalAttrsReducer, { updateManikinHospitalReducer } from './hospitalReducer';
import { decorateManikins } from '../actions';
import { formatDate } from '../utility/manikin';
import { sortAlarmTypes } from '../utility/alarm';

function setAssetUpdateReducer(state = {}, action) {
  const {
    assetId,
    update
  } = action;

  return {
    ...state,
    [assetId]: update
  };
}

function removeAssetUpdateReducer(state = {}, action) {
  const { assetId } = action;
  const newState = {
    ...state
  };
  delete newState[assetId];

  return newState;
}

function setAssetIsDecommissionedReducer(state = {}, action) {
  const { assetId } = action;

  const data = { ...state.data };
  data.manikins = data.manikins.map((m) => {
    const manikin = { ...m };
    if (manikin.assetId === assetId) {
      manikin.attrs = {
        ...manikin.attrs,
        isDecommissioned: true
      };
    }
    return manikin;
  });

  const newState = {
    ...state,
    data
  };
  return newState;
}

function updateAssetAttrsReducer(state = {}, action) {
  const { assetId, ...attrs } = action.attrs;

  const {
    lastMaintenance,
    program,
    productionDate,
    type
  } = attrs;

  const data = { ...state.data };
  data.manikins = data.manikins.map((m) => {

    if (m.assetId === assetId) {
      const manikin = {
        ...m,
        program,
        type
      };

      manikin.attrs = {
        ...attrs,
        facility: attrs.facilityName
      };

      if (lastMaintenance) {
        manikin.info.lastMaintenance = formatDate(lastMaintenance);
        manikin.info.lastMaintenanceMs = lastMaintenance.getTime();
      } else {
        manikin.info.lastMaintenance = 'none';
        manikin.info.lastMaintenanceMs = 0;
      }

      if (productionDate) {
        manikin.info.productionDate = formatDate(productionDate);
        manikin.info.productionDateMs = productionDate.getTime();
      } else {
        manikin.info.productionDate = 'none';
        manikin.info.productionDateMs = 0;
      }

      return manikin;
    }

    return m;
  });

  const newState = {
    lastUpdatedAssetAttrs: { ...action.attrs },
    data
  };
  return newState;
}

function resetAssetAlarmReducer(state = {}, action) {
  const { assetId, alarmTypeId } = action;

  const assetsUpdateCounter = state.assetsUpdateCounter || 0;

  const data = { ...state.data };
  // data.manikins = [...data.manikins];
  const manikin = data.manikins.find(man => man.assetId === assetId);
  if (manikin) {
    manikin.alarms = manikin.alarms.filter(alarm => alarm.alarmType.toUpperCase() !== alarmTypeId.toUpperCase());
  }
  return { data, assetsUpdateCounter: assetsUpdateCounter + 1 };
}

const hasNoAction = manikin => !manikin.action || (!manikin.action.actionDate && !manikin.action.actionType);

function refreshHospitalIssuesReducer(state = {}, action) {

  // Action may contain hospitalId or a list of hospitalIds
  const { hospitalId, hospitalIds } = action;

  const affectedHospitalIds = hospitalId ? [hospitalId] : hospitalIds;

  if (!affectedHospitalIds || affectedHospitalIds.length === 0) {
    return state;
  }

  const data = { ...state.data };

  affectedHospitalIds.forEach((hid) => {
    const hospitalIndex = data.hospitals.findIndex(m => m.hospitalId === hid);
    if (hospitalIndex < 0) {
      return;
    }

    let hospital = data.hospitals[hospitalIndex];

    const hospitalManikins = data.manikins.filter(m => m.hospitalId === hid);
    const totalManikins = hospitalManikins.length;
    const unresolvedIssues = hospitalManikins.filter(m => hasNoAction(m) && m.alarms && m.alarms.length).length;

    hospital = { ...hospital };
    hospital.unresolvedIssues = unresolvedIssues;
    hospital.manikins = `${unresolvedIssues}/${totalManikins}`;
    data.hospitals[hospitalIndex] = hospital;
  });

  // need to change hospitals to force re-rendering
  data.hospitals = [...data.hospitals];

  return { data };
}

function setAssetAlarmsReducer(state = {}, action) {
  const { assetId, alarms } = action;

  const assetsUpdateCounter = state.assetsUpdateCounter || 0;

  const data = { ...state.data };

  const manikin = data.manikins.find(man => man.assetId === assetId);
  if (manikin) {
    manikin.alarms = [...alarms].sort(sortAlarmTypes);
  }

  data.manikins[data.manikins.indexOf(manikin)] = { ...manikin };

  return { data, assetsUpdateCounter: assetsUpdateCounter + 1 };

}

function addNewAssetReducer(state, action) {
  const { type: actionType, payload } = action;
  const { manikins, hospitals } = state;

  switch (actionType) {
    case ADD_NEW_ASSET: {
      const { attrs, hospitalId } = payload;
      const {
        ageMaintenance,
        ageProduction,
        assetId,
        cartId,
        facility,
        firmwareVersion,
        // isSSM,
        laptopId,
        laptopSimId,
        lastMaintenance,
        location,
        program,
        type
      } = attrs;

      const hospital = hospitals.find(m => m.hospitalId === hospitalId);
      const { placeName } = hospital;

      const manikin = {
        assetId,
        // isSSM,
        hospitalId,
        placeName,
        type,
        program,
        notUsedIn: 0,
        alarmImportanceRating: -1,
        firmwareVersion,
        info: {
          lastMaintenance: lastMaintenance && lastMaintenance.toString(),
          compressions: {
            maintenance: -1,
            production: -1
          },
          ventilations: {
            maintenance: -1,
            production: -1
          },
          age: {
            maintenance: ageMaintenance,
            production: ageProduction
          }
        },
        alarms: [],
        action: {
          actionDate: null,
          actionId: [],
          actionType: '',
          fromStartScreen: false
        },
        attrs: {
          cartId,
          facility,
          hospitalId,
          laptopId,
          laptopSimId,
          location,
          isDecommissioned: false
        }
      };
      return [...manikins, ...decorateManikins([manikin])];
    }
    default:
      return manikins;
  }
}

function updateAssetInfoReducer(state, action) {
  const { type: actionType, payload } = action;
  const { manikins } = state;

  switch (actionType) {
    case RECEIVED_SET_ASSET_ATTR_REQUEST: {
      const { attrs } = payload;
      const { ageMaintenance, ageProduction } = attrs;
      const manikinIndex = manikins.findIndex(m => m.assetId === attrs.assetId);
      let manikin = manikins[manikinIndex];

      manikin = {
        ...manikin,
        info: {
          ...manikin.info,
          age: {
            production: ageProduction === undefined || ageProduction === null
              ? manikin.info.age.production
              : ageProduction,
            maintenance: ageMaintenance === undefined || ageMaintenance === null
              ? manikin.info.age.maintenance
              : ageMaintenance
          }
        }
      };

      if (attrs.lastMaintenance) {
        manikin.info.lastMaintenance = formatDate(attrs.lastMaintenance);
        manikin.info.lastMaintenanceMs = attrs.lastMaintenance.getTime();
      }

      const result = [...manikins];
      result[manikinIndex] = manikin;
      return result;
    }
    default:
      return manikins;
  }
}

export default (state = {
  loading: false,
  error: false,
  data: null,
  updates: {}
}, action) => {
  switch (action.type) {
    case SENT_DATA_REQUEST:
      return ({
        ...state,
        loading: true,
        error: false,
        data: null
      });
    case ERROR_DATA_REQUEST:
      return ({
        ...state,
        loading: false,
        error: true,
        data: null
      });
    case RECEIVE_DATA:
      return ({
        ...state,
        loading: false,
        error: false,
        data: action.payload
      });
    case UPDATE_REQ_DATA_MANIC:
      return (action.payload);
    case SET_ASSET_UPDATE:
      return {
        ...state,
        updates: setAssetUpdateReducer(state.updates, action)
      };
    case REMOVE_ASSET_UPDATE:
      return {
        ...state,
        updates: removeAssetUpdateReducer(state.updates, action)
      };
    case RESET_UPDATES:
      return {
        ...state,
        updates: {}
      };
    case ADD_NEW_ASSET:
      return {
        ...state,
        data: {
          ...state.data,
          manikins: addNewAssetReducer(state.data, action)
        }
      };
    case RECEIVED_SET_ASSET_ATTR_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          manikins: updateAssetInfoReducer(state.data, action)
        }
      };
    case SET_ASSET_IS_DECOMMISSIONED:
      return {
        ...state,
        ...setAssetIsDecommissionedReducer(state, action)
      };
    case UPDATE_ASSET_ATTRS:
      return {
        ...state,
        ...updateAssetAttrsReducer(state, action)
      };
    case RESET_ASSET_ALARM:
      return {
        ...state,
        ...resetAssetAlarmReducer(state, action)
      };
    case SET_ASSET_ALARMS:
      return {
        ...state,
        ...setAssetAlarmsReducer(state, action)
      };
    case REFRESH_HOSPITAL_ALARMS:
      return {
        ...state,
        ...refreshHospitalIssuesReducer(state, action)
      };
    case CHANGE_MANIKIN_HOSPITAL:
      return {
        ...state,
        data: {
          ...state.data,
          manikins: updateManikinHospitalReducer(state.data.manikins, state.data.hospitals, action)
        }
      };
    case MOVE_MANIKIN_TO_HOSPITAL:
    case RECEIVED_ADD_HOSPITAL:
    case RECEIVED_HOSPITAL_ADDRESS:
    case SENT_HOSPITAL_LOCATION_REQUEST:
    case CLEANUP_EMPTY_HOSPITALS:
      return {
        ...state,
        data: {
          ...state.data,
          hospitals: updateHospitalAttrsReducer(state.data.hospitals, action, state.data.manikins)
        }
      };
    default:
      return state;
  }
};
