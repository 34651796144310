import {
  CHANGE_MANIKIN_HOSPITAL,
  MOVE_MANIKIN_TO_HOSPITAL,
  RECEIVED_ADD_HOSPITAL,
  RECEIVED_HOSPITAL_ADDRESS,
  SENT_HOSPITAL_LOCATION_REQUEST,
  CLEANUP_EMPTY_HOSPITALS
} from '../constants/constants';
import { getHospitalManikinsCounters } from '../utility/hospital';

const addRemoveManikinToHospital = (manikin, hospital, addFlag = false) => {
  const inc = addFlag ? 1 : -1;

  if (!hospital) {
    return null;
  }

  const result = { ...hospital };
  const totalManikins = getHospitalManikinsCounters(result.manikins).total + inc;
  if (!manikin) {

    return {
      ...result,
      manikins: `${result.unresolvedIssues}/${totalManikins}`
    };
  }

  let hasNoAction = Boolean(manikin.action);
  if (manikin.action) {
    const {
      actionDate,
      actionType
    } = manikin;

    hasNoAction = Boolean(actionDate && actionType);
  }

  if (manikin.alarms && manikin.alarms.length && hasNoAction) {
    result.unresolvedIssues += inc;
  }

  result.manikins = `${result.unresolvedIssues}/${totalManikins}`;

  return result;
};


export const updateManikinHospitalReducer = (manikins = [], hospitals = [], action) => {
  switch (action.type) {
    case CHANGE_MANIKIN_HOSPITAL: {
      const result = [...manikins];
      const {
        assetId,
        facility,
        hospitalId,
        location,
        resetFacility
      } = action.payload;
      const { placeName } = hospitals.find(h => h.hospitalId === hospitalId);
      const idx = result.findIndex(m => m.assetId === assetId);
      if (idx >= 0) {

        result[idx] = {
          ...result[idx],
          hospitalId,
          placeName
        };

        const manikin = result[idx];

        const attrs = { ...manikin.attrs };
        // facility reset only required if facility has been changed
        if (resetFacility) {
          // Clear facility and location when moving to another hospital
          if (!facility || attrs.facility !== facility) {
            delete attrs.facility;
            delete attrs.facilityId;
          }

          if (!location || attrs.location !== location) {
            delete attrs.location;
          }

          manikin.attrs = attrs;
        }
      }
      return result;
    }
    default:
      return manikins;
  }
};

const updateHospitalAttrsReducer = (hospitals = [], action, manikins) => {

  switch (action.type) {
    case MOVE_MANIKIN_TO_HOSPITAL: {
      const {
        hospitalId: toHospitalId,
        manikin,
        isNewManikin
      } = action.payload;
      const fromHospitalId = manikin ? manikin.hospitalId : undefined;
      const result = [...hospitals];
      const fromHospitalIndex = result.findIndex(h => h.hospitalId === fromHospitalId);
      const toHospitalIndex = result.findIndex(h => h.hospitalId === toHospitalId);

      if (!isNewManikin && fromHospitalIndex >= 0) {
        result[fromHospitalIndex] = addRemoveManikinToHospital(manikin, result[fromHospitalIndex], false);
      }

      if (toHospitalIndex >= 0) {
        result[toHospitalIndex] = addRemoveManikinToHospital(manikin, result[toHospitalIndex], true);
      }

      return result;
    }
    case RECEIVED_ADD_HOSPITAL: {
      const newHospital = {
        ...action.payload,
        isVAHospital: action.payload.isVaHospital
      };
      // Remove camel case property
      delete newHospital.isVaHospital;

      return [...hospitals, action.payload];
    }
    case SENT_HOSPITAL_LOCATION_REQUEST:
      hospitals = [...hospitals];
      action.payload.forEach((val) => {
        const idx = hospitals.findIndex(h => h.hospitalId === val.hospitalId);
        if (idx >= 0 && !hospitals[idx].location.address) {
          const hosp = hospitals[idx];
          const { location } = hosp;
          hosp.location = {
            ...location,
            isLoading: true
          };
          hospitals[idx] = { ...hosp };
        }
      });
      return hospitals;
    case RECEIVED_HOSPITAL_ADDRESS:
      hospitals = [...hospitals];
      action.payload.forEach((val) => {
        const idx = hospitals.findIndex(h => h.hospitalId === val.hospitalId);
        if (idx >= 0) {
          const hosp = hospitals[idx];
          const { location } = hosp;

          const newAddress = val.address || location.address;

          hosp.location = {
            ...location,
            address: newAddress,
            isLoading: false
          };
          hospitals[idx] = { ...hosp };
        }
      });
      return hospitals;
    case CLEANUP_EMPTY_HOSPITALS:
      hospitals = hospitals.filter(h => h.manikins !== '0/0');
      if (manikins) {
        hospitals = hospitals.filter(h => manikins.find(m => m.hospitalId === h.hospitalId));
      }
      return hospitals;
    default:
      return hospitals;
  }
};

export default updateHospitalAttrsReducer;
