import PropTypes from 'prop-types';

export const EventCounterRuleShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  ruleId: PropTypes.string.isRequired
});

export const EventCounterValueShape = PropTypes.shape({
  eventCounterValue: PropTypes.number.isRequired,
  ruleId: PropTypes.string.isRequired
});

export const EventCounterDataShape = PropTypes.shape({
  counters: PropTypes.arrayOf(EventCounterValueShape),
  rules: PropTypes.arrayOf(EventCounterRuleShape)
});
