import _ from 'lodash';
import {
  SENT_FACILITY_LIST_REQUEST,
  SHOW_DL_DIALOG,
  ERROR_FACILITY_LIST_REQUEST,
  RECEIVED_FACILITY_LIST,
  SENT_HOSPITAL_LOCATION_REQUEST,
  SENT_SET_ASSET_ATTR_REQUEST,
  ERROR_SET_ASSET_ATTR_REQUEST,
  RECEIVED_HOSPITAL_ADDRESS,
  RECEIVED_SET_ASSET_ATTR_REQUEST,
  RECEIVED_ADD_HOSPITAL,
  CHANGE_MANIKIN_HOSPITAL,
  MOVE_MANIKIN_TO_HOSPITAL,
  ADD_NEW_ASSET,
  CLEANUP_EMPTY_HOSPITALS,
  ALARM_TYPES_DIC,
  SENT_ASSET_HISTORY_REQUEST,
  ERROR_ASSET_HISTORY_REQUEST,
  RECEIVED_ASSET_HISTORY,
} from '../constants/constants';
import { getHospitalAddress, getHospitalAttrs, createUpdateAsset, getAssetLocations } from '../api/api';
import debounceReduce from '../utility/func';
import { refreshHospitalAlarmsAction, setAlarmsAction } from '.';

const raiseError = (data, type, message, dispatch) => {
  const msg = `${message} ${data}`;
  // eslint-disable-next-line no-console
  console.warn(msg);
  dispatch({
    type,
    payload: msg,
  });
};

export const showDescriptiveLocationDialog = (showFlag) => (dispatch) => {
  dispatch({ type: SHOW_DL_DIALOG, payload: showFlag });
};

export const getFacilitiesAction = (hospitalId) => async (dispatch) => {
  dispatch({
    type: SENT_FACILITY_LIST_REQUEST,
    payload: hospitalId,
  });

  const setError = (data) => raiseError(data, ERROR_FACILITY_LIST_REQUEST, 'Error getting facilities.', dispatch);

  try {
    const res = await getHospitalAttrs(hospitalId);
    if (res.status === 200) {
      dispatch({
        type: RECEIVED_FACILITY_LIST,
        payload: res.data.facilities,
      });

      if (res.data.address) {
        dispatch({
          type: RECEIVED_HOSPITAL_ADDRESS,
          payload: [res.data],
        });
      }
    } else {
      setError(res.data);
    }
  } catch (err) {
    setError((err.response && err.response.data && err.response.data.message) || err);
  }
};

export const getAssetHistoryAction = (assetId) => async (dispatch) => {
  dispatch({
    type: SENT_ASSET_HISTORY_REQUEST,
    payload: assetId,
  });

  const setError = (data) => raiseError(data, ERROR_ASSET_HISTORY_REQUEST, 'Error getting facilities.', dispatch);

  try {
    const res = await getAssetLocations(assetId);
    if (res.status === 200) {
      dispatch({
        type: RECEIVED_ASSET_HISTORY,
        payload: res.data,
      });
    } else {
      setError(res.data);
    }
  } catch (err) {
    setError((err.response && err.response.data && err.response.data.message) || err);
  }
};

/**
 * Handles asset attributes result
 * skipSettingAttributes - do not set attributes, but apply subsequent logic (change hospitals, move alarms etc)
 */
export const handleAssetAttributesResultAction =
  ({ attrs, createNew, editAssetAttrsResult, manikin, skipSettingAttributes }) =>
  (dispatch) => {
    const hospital = editAssetAttrsResult && editAssetAttrsResult.hospital && { ...editAssetAttrsResult.hospital };
    const assetState = editAssetAttrsResult && editAssetAttrsResult.assetState && { ...editAssetAttrsResult.assetState };
    const assetAlarms = editAssetAttrsResult && editAssetAttrsResult.assetAlarms && [...editAssetAttrsResult.assetAlarms];

    const assetId = (manikin && manikin.assetId) || attrs.assetId;
    const oldHospitalId = manikin && manikin.hospitalId;

    // New hospital has been added
    if (hospital) {
      dispatch({
        type: RECEIVED_ADD_HOSPITAL,
        payload: hospital,
      });
    }

    const hospitalId = hospital || attrs.hospitalId ? (hospital && hospital.hospitalId) || attrs.hospitalId : undefined;

    const ageMaintenance = assetState && assetState.ageMaintenance;
    const ageProduction = assetState && assetState.ageProduction;
    const lastMaintenance = assetState && assetState.lastMaintenance;

    if (ageMaintenance !== undefined && ageMaintenance !== null) {
      attrs.ageMaintenance = ageMaintenance;
    }

    if (ageProduction !== undefined && ageProduction !== null) {
      attrs.ageProduction = ageProduction;
    }

    if (lastMaintenance) {
      attrs.lastMaintenance = new Date(lastMaintenance);
    }

    if (createNew) {
      dispatch({
        type: ADD_NEW_ASSET,
        payload: {
          attrs: {
            ...attrs,
          },
          hospitalId,
        },
      });

      // This dispatch will force attributes to update
      // So we have to call it after adding new asset
      dispatch({
        type: RECEIVED_SET_ASSET_ATTR_REQUEST,
        payload: { attrs },
      });
      dispatch({
        type: MOVE_MANIKIN_TO_HOSPITAL,
        payload: { manikin, hospitalId, isNewManikin: true },
      });
    } else if (hospitalId) {
      if (!skipSettingAttributes) {
        dispatch({
          type: RECEIVED_SET_ASSET_ATTR_REQUEST,
          payload: { attrs },
        });
      }

      if (manikin.hospitalId !== hospitalId) {
        const { facilityName, location } = attrs;

        dispatch({
          type: MOVE_MANIKIN_TO_HOSPITAL,
          payload: { manikin, hospitalId },
        });

        dispatch({
          type: CHANGE_MANIKIN_HOSPITAL,
          payload: {
            assetId,
            facility: facilityName,
            hospitalId,
            location,
            resetFacility: true,
          },
        });
      }
    }

    // Set and refresh new asset alarms if needed
    if (assetAlarms && hospitalId) {
      dispatch(
        setAlarmsAction(
          assetId,
          assetAlarms.map((m) => ({ alarmDescription: m.alarmDescription, alarmType: ALARM_TYPES_DIC[m.alarmType] })),
        ),
      );

      if (oldHospitalId) {
        dispatch(refreshHospitalAlarmsAction(oldHospitalId));
      }
      dispatch(refreshHospitalAlarmsAction(hospitalId));
    }

    dispatch({ type: CLEANUP_EMPTY_HOSPITALS });
  };

// Asset attributes (attrs) are { email, facilityName?, facilityId?, location? }
// facilityId has a higher priority than facilityName
// createNew=true means we are going to create new asset
export const setAssetAttributesAction =
  (manikin, attrs, createNew = false) =>
  async (dispatch) => {
    dispatch({
      type: SENT_SET_ASSET_ATTR_REQUEST,
      payload: attrs,
    });

    const setError = (data) =>
      raiseError(data, ERROR_SET_ASSET_ATTR_REQUEST, createNew ? 'Error creating asset.' : 'Error setting asset attributes.', dispatch);

    try {
      const res = await createUpdateAsset(attrs);

      if (res.status === 200) {
        dispatch(
          handleAssetAttributesResultAction({
            manikin,
            attrs,
            editAssetAttrsResult: res.data,
            createNew,
          }),
        );
      } else {
        setError(res.data);
      }
    } catch (err) {
      setError((err.response && err.response.data && err.response.data.message) || err);
    }
  };

const stopLoadingHospitalLocations = (hospitalIdArr, dispatch) => {
  dispatch({
    type: RECEIVED_HOSPITAL_ADDRESS,
    payload: hospitalIdArr.map((hid) => ({ hospitalId: hid })),
  });
};

const sendHospitalLocationRequest = async (hospitalIdArr) => {
  if (hospitalIdArr.length === 0) {
    return;
  }

  const { dispatch } = hospitalIdArr[0];

  dispatch({
    type: SENT_HOSPITAL_LOCATION_REQUEST,
    payload: hospitalIdArr,
  });

  try {
    const res = await getHospitalAddress(hospitalIdArr.map((item) => item.hospitalId));
    if (res.status === 200) {
      dispatch({
        type: RECEIVED_HOSPITAL_ADDRESS,
        payload: res.data || [],
      });
    } else {
      stopLoadingHospitalLocations(hospitalIdArr, dispatch);
    }
  } catch (err) {
    stopLoadingHospitalLocations(hospitalIdArr, dispatch);
  }
};

const debounceSendHospitalLocationRequest = debounceReduce(sendHospitalLocationRequest, 50);

export const getHospitalLocationAction = (hospitalId) => (dispatch) => debounceSendHospitalLocationRequest({ hospitalId, dispatch });

export const moveManikinsToHospitalAction = (manikins, newHospitalId) => (dispatch) =>
  manikins.forEach((manikin) => {
    const { assetId } = manikin;

    dispatch({
      type: MOVE_MANIKIN_TO_HOSPITAL,
      payload: { manikin, hospitalId: newHospitalId },
    });
    dispatch({
      type: CHANGE_MANIKIN_HOSPITAL,
      payload: { assetId, hospitalId: newHospitalId, resetFacility: true },
    });
    dispatch({ type: CLEANUP_EMPTY_HOSPITALS });
  });
