import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';

import Portal from './Portal';

export const TooltipPosition = {
  bottom: 0,
  left: 1,
  right: 2,
  top: 3,
  none: 4
};

export const ArrowLocation = {
  top: 'top',
  bottom: 'bottom',
  right: 'right',
  none: 'none'
};

const getTooltipPosition = (tooltip, parent, position, offset) => {
  const ttRectangle = tooltip.getBoundingClientRect();
  const parentRectangle = parent.getBoundingClientRect();
  const { scrollX, scrollY, innerWidth } = window;

  let left;
  let top;
  let shiftArrowLeft = false;
  let shiftArrowRight = false;

  switch (position) {
    case TooltipPosition.top:
      left = scrollX + parentRectangle.left + (parentRectangle.width / 2) - (ttRectangle.width / 2);

      if (left < 0) {
        left = scrollX + parentRectangle.left;
        shiftArrowLeft = true;
      } else if (left + ttRectangle.width > innerWidth) {
        left = scrollX + parentRectangle.left + parentRectangle.width - ttRectangle.width;
        shiftArrowRight = true;
      }


      top = scrollY + parentRectangle.top - ttRectangle.height - offset;
      break;
    case TooltipPosition.right:
      left = scrollX + parentRectangle.left + parentRectangle.width + offset;
      top = scrollY + parentRectangle.top + (parentRectangle.height / 2) - (ttRectangle.height / 2);
      break;
    case TooltipPosition.bottom:
      left = scrollX + parentRectangle.left + (parentRectangle.width / 2) - (ttRectangle.width / 2);
      top = scrollY + parentRectangle.top + parentRectangle.height + offset;
      break;
    case TooltipPosition.left:
      left = scrollX + parentRectangle.left - ttRectangle.width - offset;
      top = scrollY + parentRectangle.top + (parentRectangle.height / 2) - (ttRectangle.height / 2);
      break;
    case TooltipPosition.overlay:
      left = scrollX + parentRectangle.left - 4;
      top = scrollY + parentRectangle.top - 2;
      break;
    default:
      top = 0;
      left = 0;
  }


  return {
    left,
    top,
    shiftArrowLeft,
    shiftArrowRight
  };
};

const Tooltip = ({
  arrowLocation,
  children,
  className = '',
  maxWidth,
  offset = 0,
  parent,
  position,
  visible
}) => {
  const [coords, setCoords] = useState(null);
  const tooltipRef = useRef();

  const updateCoords = useCallback(debounce(() => {
    if (parent && tooltipRef.current) {
      const currentCoords = getTooltipPosition(tooltipRef.current, parent, position, offset);
      setCoords(currentCoords);
    }
  }, 100), [parent, position, offset, tooltipRef, tooltipRef.current]);

  useEffect(() => {
    window.addEventListener('resize', updateCoords);
    return () => window.removeEventListener('resize', updateCoords);
  }, []);

  useLayoutEffect(() => {
    updateCoords();
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Portal>
      <Root
        maxWidth={maxWidth}
        ref={tooltipRef}
        style={coords || { opacity: 0 }}
      >
        <Text className={className}>
          {children}
        </Text>

        { arrowLocation && arrowLocation === ArrowLocation.bottom && (
          <ArrowBottom
            shiftArrowLeft={coords !== null && coords.shiftArrowLeft}
            shiftArrowRight={coords !== null && coords.shiftArrowRight}
          />
        )}
      </Root>
    </Portal>
  );
};

Tooltip.propTypes = {
  arrowLocation: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.oneOf([null])]),
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  offset: PropTypes.number,
  parent: PropTypes.object,
  position: PropTypes.number,
  visible: PropTypes.bool
};

Tooltip.defaultProps = {
  arrowLocation: '',
  children: null,
  className: '',
  maxWidth: '',
  offset: 0,
  parent: undefined,
  position: 0,
  visible: false
};

export default styled(Tooltip)``;

const Text = styled.div`
  ${({ theme }) => `
    background-color: ${theme.color.greyscale.black};
    border-radius: ${theme.radius.radius_2};
    color: white;
    font-size: 13px;
    line-height: 20px;
    padding: ${theme.size.size_5} ${theme.size.size_10};
    position: relative;
  `}
`;

const ArrowBottom = `
  ${({ theme, shiftArrowLeft, shiftArrowRight }) => `
    position: absolute;
    border-left: ${theme.size.size_5} solid transparent;
    border-right: ${theme.size.size_5} solid transparent;
    border-top: ${theme.size.size_5} solid ${theme.color.greyscale.black};
    bottom: -${theme.size.size_5};
    margin-left: -${theme.size.size_5};
    height: 0;
    width: 0;
    left: ${
  /* eslint-disable no-nested-ternary */
  shiftArrowLeft ? theme.size.size_20 : (shiftArrowRight ? `calc(100% - ${theme.size.size_20})` : '50%')
};
`}
`;

const Root = styled.div`
  max-width: ${({ maxWidth, theme }) => (maxWidth || theme.size.size_120)};
  position: absolute;
  z-index: 10000;
`;
