import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IoIosClose } from 'react-icons/io';

import './Marker.scss';
import { getHospitalState } from '../../utility/hospital';

class MarkerWrapper extends Component {

  static propTypes = {
    manikinsCount: PropTypes.number.isRequired,
    faultyManikinsCount: PropTypes.number.isRequired,
    handledFailures: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickClose: PropTypes.func.isRequired,
    renderCloseIcon: PropTypes.bool
  };

  static defaultProps = {
    renderCloseIcon: true
  };

  get iconCode() {
    const { manikinsCount, faultyManikinsCount } = this.props;

    return getHospitalState(faultyManikinsCount, manikinsCount);
  }

  handleClick = (event) => {
    const { onClick, id } = this.props;
    event.preventDefault();

    onClick(id);
  };

  handleClickCloseTooltip = (event) => {
    const { onClickClose, id } = this.props;
    event.preventDefault();

    onClickClose(id);
  };

  renderIcon = () => {
    const {
      id,
      faultyManikinsCount
    } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={`MarkerIcon MarkerIcon_${this.iconCode}`}
        id={`HospitalMarkerTooltip-${id}`}
        onClick={this.handleClick}
        role="button"
        tabIndex={0}
      >
        {faultyManikinsCount}
      </div>
    );
  };

  renderTooltip = () => {
    const {
      renderCloseIcon,
      selected
    } = this.props;

    if (!selected) {
      return null;
    }

    const {
      name,
      manikinsCount,
      faultyManikinsCount,
      handledFailures
    } = this.props;

    return (
      <div className="MarkerTooltip">
        {renderCloseIcon && (
          <IoIosClose
            className="MarkerTooltip__close"
            onClick={this.handleClickCloseTooltip}
            size={25}
          />
        )}
        <h6>{name}</h6>
        <p>
          Manikins:&nbsp;
          {manikinsCount}
        </p>
        <p>
          Unhandled issues:&nbsp;
          {faultyManikinsCount}
        </p>
        <p>
          Current actions:&nbsp;
          {handledFailures}
        </p>
      </div>
    );
  };

  render() {
    const {
      id,
      selected
    } = this.props;

    return (
      <div
        id={`idHospital-${id}`}
        className="map-container-marker Marker"
        style={{
          zIndex: selected ? 100 : 50
        }}
      >
        {this.renderIcon()}
        {this.renderTooltip()}
      </div>
    );
  }

}

export default MarkerWrapper;
