import React from 'react';
import styled, { keyframes } from 'styled-components';

const TableSkeleton = () => (
  <Root />
);

export default TableSkeleton;

const blink = keyframes`
0%{
  opacity: 1
}
50%{
  opacity: 0.4
}
100%{
  opacity: 1
}
`;

const Root = styled.div`
  animation: ${blink} 3s infinite;
  background: ${props => props.theme.greyPalette.grey6}
  height: 100%;
  width: 100%;
`;
