// Normally should return only one reason/sub part ('inf_firmware_firmware' or 'ad_firmware_firmware')
export const filterFirmwareRepairReasonPart = assetSubParts => (
  assetSubParts.filter(subPart => subPart.assetSubPartId.endsWith('_firmware_firmware')));

// Should return all subparts except a subpart which is a repair reason
// and ends by  '_firmware_firmware' ('inf_firmware_firmware' or 'ad_firmware_firmware')
export const filterFirmwareReplacementParts = assetSubParts => (
  assetSubParts.filter(subPart => !subPart.assetSubPartId.endsWith('_firmware_firmware')));

export const filterFirmwareReplacementTypes = replacementType => (
  replacementType.filter(rt => rt.replacementTypeId === 'reported'));

// Filter parts for old/infant manikins
export const filterAssetParts = (parts, assetTypeId) => (
  parts.filter(part => part.assetPartId.indexOf(assetTypeId === 'infant' ? 'inf_' : 'ad_') >= 0));

export function createNewReplacedPartsObj(replacementType) {
  const result = {};
  replacementType.forEach((rt) => { result[rt.replacementTypeId] = []; });
  return result;
}
