import { AnonymousCredential, uploadBrowserDataToBlockBlob, Aborter, BlobURL, BlockBlobURL, ContainerURL, ServiceURL, StorageURL } from '@azure/storage-blob';
import axios from './axios';
import config from '../resources/config';
import { downloadFileContent } from '../utility/download';
import { msalConfig } from '../index';

// const getAuthHeaders = () => ({ Authorization: `Bearer ${getCookie('Token')}` });
const getAuthToken = async () => {
  const account = msalConfig.msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const request = {
    ...msalConfig.msalConfiguration,
    scopes: msalConfig.loginRequest.scopes,
    account: account,
  };

  const response = await msalConfig.msalInstance.acquireTokenSilent(request);
  return response.accessToken;
};

const getAuthHeaders = async () => ({
  Authorization: `Bearer ${await getAuthToken()}`,
});

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Handle '401 Unauthorized' status
    // Note that getToken is not covered by this interceptor
    if (error.response && error.response.status === 401) {
      // Reset cookie and sign out
      // setCookie('Token', '', true);
      console.error('Endpoint returned 401.');
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export async function checkToken() {
  const url = config.endpoints.checkToken;

  return axios.get(url);
}

export async function getConfig() {
  const url = config.endpoints.getConfig;
  return axios.get(url);
}

export async function requestData() {
  const url = config.endpoints.equipmentRequest;

  return axios.get(url, {
    method: 'get',
    headers: await getAuthHeaders(),
    responseType: 'json',
  });
}

export async function getKeys() {
  const url = config.endpoints.getKeys;

  return axios.get(url, {
    headers: await getAuthHeaders(),
  });
}

export const requestAssetLog = async (assetId) => {
  const url = `${config.endpoints.assetLogRequest}/${assetId}`;

  return axios.get(url, {
    headers: await getAuthHeaders(),
  });
};

// Delay for duration milliseconds. Use this function to emulate data downloading
const delay = (duration) => new Promise((resolve) => setTimeout(resolve, duration));

export const requestAssetEventCounters = async (assetId, requestRuleList) => {
  const url = `${config.endpoints.assetEventCountersRequest}/${assetId}?getRuleList=${requestRuleList}`;

  return axios.get(url, {
    headers: await getAuthHeaders(),
  });
};

export const requestAssetEventCounterLog = async (assetId, ruleId) => {
  const url = `${config.endpoints.assetEventCounterLogRequest}/${assetId}/${ruleId}`;

  return axios.get(url, {
    headers: await getAuthHeaders(),
  });
};

export const requestAlarmText = async (assetId, alarmType) => {
  const url = `${config.endpoints.alarmTextRequest}/${alarmType}/${assetId}`;

  return axios.get(url, {
    headers: await getAuthHeaders(),
  });
};

export const sendCustomFailure = async (customFailure) => {
  const url = config.endpoints.customFailure;

  return axios.post(url, customFailure, {
    headers: await getAuthHeaders(),
  });
};

/*
 * Returns extension from file name
 * @param {string} fileName

function getFileExtension(fileName) {
  const re = /(?:\.([^.]+))?$/;

  return re.exec(fileName)[1];
}
 */
const sendFeedbackAttachment = async ({ blobUri, sasKey, containerName }, attachment) => {
  const anonymousCredential = new AnonymousCredential();
  const pipeline = StorageURL.newPipeline(anonymousCredential, {
    retryOptions: { maxTries: 4 }, // Retry options
  });

  const serviceURL = new ServiceURL(`${blobUri}${sasKey}`, pipeline);
  const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);
  // Create a blob
  // const fileExtension = getFileExtension(attachment.name);
  const blobName = `feedback-blob_${new Date().getTime()}_${attachment.name}`;
  const blobURL = BlobURL.fromContainerURL(containerURL, blobName);
  const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

  return uploadBrowserDataToBlockBlob(Aborter.none, attachment, blockBlobURL, {
    blockSize: 4 * 1024 * 1024, // 4MB block size
    parallelism: 20, // 20 concurrency
    progress: (ev) => ev,
  });
};

export const sendFeedback = async ({ type, email, description, attachment }) => {
  const hasAttachment = Boolean(attachment);
  const data = {
    type,
    description,
    email,
    hasAttachment,
  };
  const url = config.endpoints.feedback;

  const response = await axios.post(url, data, {
    headers: await getAuthHeaders(),
  });
  // const response = await fetch(config.dataUrl.feedback, {
  //   headers: headers,
  //   cors: 'cors',
  //   method: 'post',
  //   body: JSON.stringify(data)
  // });
  const responseData = response.data;

  if (hasAttachment) {
    const uploadJobs = attachment.map(async (att) => sendFeedbackAttachment(responseData, att));
    return Promise.all(uploadJobs);
  }

  return responseData;
};

export const sendActions = async (actions) => {
  const url = config.endpoints.saveAction;
  const userEmail = localStorage.getItem('email');

  return axios.post(
    url,
    {
      userActions: actions,
      email: userEmail,
    },
    {
      headers: await getAuthHeaders(),
    },
  );
};

export const getSettings = async () => {
  const url = config.endpoints.getSettings;
  const headers = await getAuthHeaders();

  return axios.get(url, { headers });
};

export const saveSettings = async (settings) => {
  const url = config.endpoints.saveSettings;
  return axios.post(
    url,
    {
      settings,
    },
    { headers: await getAuthHeaders() },
  );
};

// There is no endpoint to create hospital
export const createHospital = async (hospitalData) => {
  const url = config.endpoints.createHospital;
  return axios.post(url, hospitalData, { headers: await getAuthHeaders() });
};

export const getHospitalAttrs = async (hospitalId) => {
  const url = config.endpoints.getHospitalAttrs;
  return axios.get(`${url}/${hospitalId}`, { headers: await getAuthHeaders() });
};

export const getAssetLocations = async (assetId) => {
  const url = config.endpoints.getAssetLocations;
  return axios.get(`${url}/${assetId}/locations`, { headers: await getAuthHeaders() });
};

export const getHospitalAddress = async (hospitalIds) => {
  const url = config.endpoints.getHospitalAddress;
  return axios.post(url, hospitalIds, { headers: await getAuthHeaders() });
};

export const getGeoData = async (address) => {
  const url = config.endpoints.getGeoData;
  return axios.post(url, { address }, { headers: await getAuthHeaders() });
};

export const createUpdateAsset = async (assetParams) => {
  const url = `${config.endpoints.createUpdateAsset}/${assetParams.assetId}`;
  const email = localStorage.getItem('email');
  return axios.post(`${url}`, { email, ...assetParams }, { headers: await getAuthHeaders() });
};

export const setAssetIsDecommissioned = async (assetId) => {
  const url = `${config.endpoints.setIsDecommissioned}/${assetId}/decomission`;
  return axios.post(`${url}`, {}, { headers: await getAuthHeaders() });
};

export const setAssetAttributes = async (attributes) => {
  const url = `${config.endpoints.saveAssetAttributes}/${attributes.assetId}/attributes`;
  const email = localStorage.getItem('email');
  return axios.post(`${url}`, { email, ...attributes }, { headers: await getAuthHeaders() });
};

// skipSchemaLoading - only load repairReport and skip
// loading schema data for repair report (manikin parts/subparts, replace reasons etc.)
export const getRepairReportData = async (assetRepairId, skipSchemaLoading) => {
  let url = `${config.endpoints.getRepairReportData}`;

  if (assetRepairId) {
    url = `${url}/${assetRepairId}`;
  }

  if (skipSchemaLoading) {
    url = `${url}?skipSchema=true`;
  }

  return axios.get(url, { headers: await getAuthHeaders() });
};

export const sendRepairReport = async (repairReportObj) => {
  const url = config.endpoints.saveRepairReport;
  const email = localStorage.getItem('email');
  return axios.post(`${url}`, { email, ...repairReportObj }, { headers: await getAuthHeaders() });
};

export const downloadSSJ = async (sessionId) =>
  axios({
    url: `${config.endpoints.downloadSessionSSJ}/${sessionId}`,
    method: 'GET',
    headers: await getAuthHeaders(),
    responseType: 'blob',
  }).then((response) => downloadFileContent(`${config.downloadSSJFilePrefix}${sessionId}.ssj`, response.data, true));

export const downloadCounterSSJ = async (assetId, ruleId) =>
  axios({
    url: `${config.endpoints.downloadCounterSSJ}/${assetId}/${ruleId}`,
    method: 'GET',
    headers: await getAuthHeaders(),
    responseType: 'blob',
  }).then((response) => downloadFileContent(`${config.downloadSSJFilePrefix}${assetId}_${ruleId}.zip`, response.data, true));

export const resetAssetEventCounter = async (assetId, ruleId) => {
  const url = `${config.endpoints.resetAssetEventCounter}/${assetId}/${ruleId}`;
  return axios.post(url, {}, { headers: await getAuthHeaders() });
};

export const decommissionHospital = async (assetIds, newHospitalId) => {
  const url = `${config.endpoints.moveAssetsToHospital}/${newHospitalId}/assets`;
  return axios.post(`${url}`, { assetIds }, { headers: await getAuthHeaders() });
};

export default {
  requestData,
  sendFeedback,
  sendActions,
  getConfig,
  getKeys,
  getHospitalAttrs,
};
