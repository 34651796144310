import React, {
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { components } from 'react-select';
import { HospitalShape } from '../../../../constants/types';
import Select from '../../../../components/Select';
import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import { theme } from '../../../../theme/theme';
import { sortFunctions } from '../../../../utility/manikin';
import { TooltipPosition } from '../../../../components/common/TooltipPortal';
import TooltipButton from '../../../../components/Button/TooltipButton';

const MAX_DROPDOWN_ITEMS = 150;

const getOption = (hospital) => {
  const {
    placeName
  } = hospital;

  return {
    label: placeName,
    value: hospital
  };
};

const getCurrentOptions = (hospitals, filterValue) => hospitals
  .filter(h => h.placeName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
  .sort(sortFunctions.hospitalFiltered(filterValue))
  .slice(0, MAX_DROPDOWN_ITEMS)
  .map(getOption);

const getCurrentOptionsUnrestricted = (hospitals, filterValue) => hospitals
  .sort(sortFunctions.hospital)
  .map(getOption);

const renderValue = (values, selectProps) => {
  const option = values[0];
  const { hospitalId, location } = option.value;
  const {
    isLoading,
    onHospitalDecommission,
    showDecommissionButton
  } = selectProps;

  const onHospitalDecommissionClick = (e) => {
    e.preventDefault();
    onHospitalDecommission(hospitalId);
  };

  return (
    <HospitalValueCont>
      <Cell>
        <HospitalValueItem>{option.label}</HospitalValueItem>
        {location.address && <HospitalValueItem address>{location.address}</HospitalValueItem>}
      </Cell>
      <Cell>
        {!isLoading && showDecommissionButton && (
          <StyledToolTipButton
            color="ghost"
            label={(
              <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11Z" fill="#7198A2" />
                <path d="M3.5 3.5L7.5 7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.5 3.5L3.5 7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
            onClick={e => e.preventDefault()}
            onMouseDown={e => onHospitalDecommissionClick(e)}
            onMouseUp={e => e.preventDefault()}
            toolTip="Decommission"
            toolTipPosition={TooltipPosition.top}
          />
        )}
      </Cell>
    </HospitalValueCont>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const {
    getValue,
    hasValue,
    selectProps
  } = props;

  // const input = children.find(ch => ch && ch.type.displayName === 'Input');

  // This is kind of hack because in production we can't get ch.type.displayName
  // because of JS minimization (obfuscation)
  // So we are assuming that Input is always passed as the 2nd children
  const input = children && children.length > 1 && children[1];

  const inputHasValue = input && input.props.value.trim();

  if (!hasValue) {
    return (
      <components.ValueContainer
        {...props}
      >
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer
      {...props}
    >
      {input}
      {!inputHasValue && renderValue(getValue(), selectProps)}
    </components.ValueContainer>
  );
};

const Option = (props) => {
  const {
    hasValue,
    selectProps,
    value
  } = props;

  const {
    loadHospitalLocation,
    onHospitalDecommission,
    showDecommissionButton
  } = selectProps;

  const onHospitalDecommissionClick = (e, hospitalId) => {
    e.preventDefault();
    onHospitalDecommission(hospitalId);
  };

  let currentAddress = '';
  let isAddressLoading = false;

  let hospitalId;

  if (hasValue) {

    if (typeof value === 'string' || !value) {
      return (
        <components.Option {...props}>
          {props.children}
        </components.Option>
      );
    }

    const {
      hospitalId: currentHospitalId,
      location
    } = value;

    hospitalId = currentHospitalId;

    const {
      address,
      isLoading
    } = location;

    if (!isLoading && !address) {
      loadHospitalLocation(hospitalId);
    }

    currentAddress = address;
    isAddressLoading = isLoading && !address;
  }

  return (
    <components.Option {...props}>
      {hasValue && (
        <HospitalValueCont>
          <Cell>
            <HospitalValueItem>{hasValue && value.placeName}</HospitalValueItem>
            <HospitalValueItem address>
              {isAddressLoading && <LoadingSpinner color={theme.greyPalette.grey} />}
              {currentAddress}
            </HospitalValueItem>
          </Cell>
          {showDecommissionButton && (
            <Cell>
              <StyledToolTipButton
                color="ghost"
                inline
                label={(
                  <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11Z" fill="#7198A2" />
                    <path d="M3.5 3.5L7.5 7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 3.5L3.5 7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
                onClick={e => e.preventDefault()}
                onMouseDown={e => onHospitalDecommissionClick(e, hospitalId)}
                onMouseUp={e => e.preventDefault()}
                toolTip="Decommission"
                toolTipPosition={TooltipPosition.top}
              />
            </Cell>
          )}
        </HospitalValueCont>
      )}
    </components.Option>
  );
};

const HospitalSelect = (props) => {

  const {
    hospitalId,
    hospitals,
    isCreatable,
    isDisabled,
    isLoading,
    isSearchable,
    loadHospitalLocation,
    onHospitalChange,
    onHospitalCreate,
    onHospitalDecommission,
    showAllOptions,
    showDecommissionButton
  } = props;

  const [currentValue, setCurrentValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const optionsGetter = showAllOptions ? getCurrentOptionsUnrestricted : getCurrentOptions;

  useEffect(() => {
    const currentHospital = hospitals.find(h => h.hospitalId === hospitalId);
    if (currentHospital) {
      setCurrentValue(getOption(currentHospital));
    }
    setOptions((inputValue || showAllOptions) ? optionsGetter(hospitals, inputValue) : []);
  }, [hospitalId]);

  useEffect(() => {
    setInputValue(currentValue ? currentValue.value.placeName : '');
  }, [currentValue]);

  useEffect(() => {
    setOptions((inputValue || showAllOptions) ? optionsGetter(hospitals, inputValue) : []);
  }, [hospitals, inputValue]);

  useEffect(() => {
    const currentHospital = hospitals.find(h => h.hospitalId === hospitalId);
    if (currentHospital) {
      const opt = getOption(currentHospital);
      if (opt && opt.value && opt.value.location && opt.value.location.address
        && currentValue && currentValue.value && currentValue.value.location
        && !currentValue.value.location.address) {
        setCurrentValue(opt);
      }
    }
  }, [hospitals]);


  const handleHospitalChange = (optn) => {
    setCurrentValue(optn);
    onHospitalChange(optn.value);
  };

  const handleInputChange = (value, { action }) => {
    if (action !== 'set-value') {
      setInputValue(value);
      return value;
    }

    return inputValue;
  };

  const styles = {
    input: defaultStyles => ({
      ...defaultStyles,
      zIndex: 10
    }),
    valueContainer: defaultStyles => ({
      ...defaultStyles,
      flexWrap: 'nowrap'
    })
  };

  return (
    <Select
      components={{ Option, ValueContainer }}
      componentStyles={styles}
      isCreatable={isCreatable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable={isSearchable}
      loadHospitalLocation={loadHospitalLocation}
      onHospitalDecommission={onHospitalDecommission}
      menuPortalTarget={document.body}
      noOptionsMessage={({ inputValue: val }) => (val ? 'No options' : 'Start typing a value')}
      onChange={handleHospitalChange}
      onCreateOption={val => onHospitalCreate(val)}
      onInputChange={handleInputChange}
      options={options}
      showDecommissionButton={showDecommissionButton}
      value={currentValue}
    />
  );
};

HospitalSelect.propTypes = {
  hospitalId: PropTypes.number,
  hospitals: PropTypes.arrayOf(HospitalShape).isRequired,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearchable: PropTypes.bool,
  loadHospitalLocation: PropTypes.func,
  onHospitalChange: PropTypes.func,
  onHospitalCreate: PropTypes.func,
  onHospitalDecommission: PropTypes.func,
  showAllOptions: PropTypes.bool,
  showDecommissionButton: PropTypes.bool
};

HospitalSelect.defaultProps = {
  hospitalId: 0,
  isCreatable: true,
  isDisabled: false,
  isLoading: false,
  isSearchable: true,
  loadHospitalLocation: Function.prototype,
  onHospitalChange: Function.prototype,
  onHospitalCreate: Function.prototype,
  onHospitalDecommission: Function.prototype,
  showAllOptions: false,
  showDecommissionButton: true
};

export default HospitalSelect;

const HospitalValueCont = styled.div`
  align-items: center;
  display flex;
  justify-content: space-between;
  flex: 1 auto;
  flex-direction: row;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
`;

const HospitalValueItem = styled.div`
  display: block;
  white-space: normal;
  ${props => props.address && `
    color: ${props.theme.greyPalette.grey};
  `}
`;

const StyledToolTipButton = styled(TooltipButton)`

  color: ${props => props.theme.redColorPalette.main};
  padding: 8px;
  text-transform: none;

  ${props => !props.inline && `
    left: 15px;
    position: relative;
  `}

  &:disabled {
    svg {
      path {
        fill: ${props => props.theme.greyPalette.grey};
      }
    }
  }

  &:hover {
    color: ${props => props.theme.redColorPalette.main};
    background: ${props => props.theme.primaryBackground};
  }
`;
