import { HOSPITAL_STATES } from '../constants/constants';

export function getHospitalState(faulty, total) {
  const faultyPercent = (faulty / total) * 100;

  if (faultyPercent === 0) {
    return HOSPITAL_STATES.HEALTHY;
  }
  if (faultyPercent <= 32) {
    return HOSPITAL_STATES.WARN;
  }
  if (faultyPercent <= 64) {
    return HOSPITAL_STATES.RISK;
  }

  return HOSPITAL_STATES.CRITICAL;
}

export const sortFunctions = {
  placeName: (a, b) => {
    const placeA = a.placeName;
    const placeB = b.placeName;
    if (placeA === placeB) return 0;
    return placeA < placeB ? -1 : 1;
  }
};

export function getHospitalManikinsCounters(hospitalManikinsStr) {
  if (!hospitalManikinsStr) {
    return {
      faulty: 0,
      total: 0
    };
  }

  const manikinsCounters = hospitalManikinsStr.split('/');

  return {
    faulty: parseInt(manikinsCounters[0], 10),
    total: parseInt(manikinsCounters[1], 10)
  };
}

export default {
  getHospitalState
};
