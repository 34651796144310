import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  let mount = document.getElementById('portal-root');

  if (!mount) {
    const portalEl = document.createElement('div');
    portalEl.id = 'portal-root';
    portalEl.style.position = 'absolute';
    portalEl.style.left = '0';
    portalEl.style.top = '0';
    portalEl.style.width = '100%';

    document.body.appendChild(portalEl);

    mount = portalEl;
  }

  const el = document.createElement('div');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mount) {
      mount.appendChild(el);

      return () => {
        mount.removeChild(el);
      };
    }
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
