import React from 'react';
import styled, { keyframes } from 'styled-components';

const TableSkeleton = () => (
  <Root>
    <Bar duration="14" />
    <Line />
    <Bar duration="20" />
    <Line />
    <Bar delay="1" duration="24" />
    <Line />
    <Bar delay="2" duration="28" />
    <Line />
    <Bar delay="1" duration="19" />
    <Line />
  </Root>
);

export default TableSkeleton;

const Root = styled.div`
  height: 100%;
  width: 100%
`;

const blink = keyframes`
0% {
  width: 0;
}
90% {
  width: 100%;
}
100% {
  width: 0
}
`;

const Bar = styled.div`
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation: ${blink} infinite;
  background: ${props => props.theme.greyPalette.grey6};
  border-radius: ${props => props.theme.borderRadius};
  height: 15px;
  margin: 24px 0;
  width: 0;

  ${({ delay }) => delay && `
    animation-delay: ${delay}s;
  `}

  ${({ duration }) => duration && `
    animation-duration: ${duration}s;
  `}
`;
const Line = styled.div`
  background-color: ${props => props.theme.greyPalette.grey6};
  content: '';
  height: 1px;
  width: 100%;
`;
