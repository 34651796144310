import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

const s = {};

s.th = styled.th`
  color: ${({ theme }) => theme.greyPalette.grey};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? theme.greyPalette.black : theme.greyPalette.grey)};
  font-family: Roboto,sans-serif:
`;

export default s;
