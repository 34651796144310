import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IoIosArrowDropdown,
  IoIosArrowDropright,
  IoIosRefresh
} from 'react-icons/io';
import { DateTime } from 'luxon';

import s from './ManikinEventListItem.style';

import { requestAssetEventCounterLog } from '../../../../api/api';
import { theme } from '../../../../theme/theme';
import DownloadSSJButton from './DownloadSSJButton';
import ConfirmResetCounterDialog from './ConfirmResetCounterDialog';

const getEventCounterLogs = logs => logs.map(log => (
  {
    description: log.description,
    eventCounterValue: log.eventCounterValue,
    sessionId: log.sessionId,
    dateTime: DateTime.fromISO(log.time).toLocaleString(DateTime.DATETIME_SHORT)
  }
));

const ManikinEventListItem = (props) => {
  const {
    assetId,
    index,
    onConfirmResetCounter,
    onItemToggle,
    value
  } = props;

  if (!value) {
    return null;
  }

  const {
    value: counterObject,
    label
  } = value;

  const {
    ruleId
  } = counterObject;

  const [confirmResetCounterDialogVisible, setConfirmResetCounterDialogVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [assetEventCounterLog, setAssetEventCounterLog] = useState(null);

  const onConfirmResetCounterDialogSuccess = (alarmTypeId) => {
    setConfirmResetCounterDialogVisible(false);
    onConfirmResetCounter(alarmTypeId);
  };

  const onResetButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmResetCounterDialogVisible(true);
  };

  const onContentClick = async () => {
    setOpen(!open);

    if (!open && error) {
      setError('');
    }

    if (!assetEventCounterLog && !loading && !open) {
      setError('');
      setLoading(true);

      try {
        const response = await requestAssetEventCounterLog(assetId, ruleId);
        const receivedLogs = getEventCounterLogs(response.data.logs);
        setAssetEventCounterLog(receivedLogs);
      } catch (e) {
        setOpen(false);
        setError('Error while loading event counter logs. Please try again later.');
      }

      setLoading(false);
    }
  };

  const renderLog = (log) => {
    const {
      dateTime,
      description,
      eventCounterValue,
      sessionId
    } = log;

    return (
      <s.Log key={`${dateTime}_${sessionId}`}>
        <s.LogSubTitleCont>
          <s.LogSubTitle>{dateTime}</s.LogSubTitle>
          <s.Cell value>{eventCounterValue || ''}</s.Cell>
          <s.Cell action>
            {sessionId && <DownloadSSJButton sessionId={sessionId} />}
          </s.Cell>
        </s.LogSubTitleCont>
        <s.LogText>
          {description}
        </s.LogText>
      </s.Log>
    );
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (assetEventCounterLog && assetEventCounterLog.length) {
      return (
        <s.LogContainer>
          { /*  className={`animated fade${open ? 'InDown' : 'OutUp'} faster`} */}
          {assetEventCounterLog.map(renderLog)}
        </s.LogContainer>
      );
    }
    return null;
  };

  return (
    <s.ListItem
      className="ListItem"
      onClick={() => onItemToggle(value, index)}
      opened={open}
      style={{
        overflow: 'visible'
      }}
    >
      <s.Content
        onClick={onContentClick}
        opened={open}
      >
        <s.Cell label={label}>
          { open ? <IoIosArrowDropdown /> : <IoIosArrowDropright />}
          { label || '-'}
          {loading && <s.Loader color={theme.secondaryColorPalette.light1} />}
          {error && (
            <>
              <s.Break />
              <s.ErrorText error>{error}</s.ErrorText>
            </>
          )}
        </s.Cell>
        <s.Cell value>
          {counterObject.eventCounterValue || '0'}
        </s.Cell>
        <s.Cell action>
          {counterObject.eventCounterValue ? (
            <>
              <s.ActionButton onClick={e => onResetButtonClick(e)}>
                <IoIosRefresh />
              </s.ActionButton>
              <DownloadSSJButton assetId={assetId} ruleId={ruleId} />
            </>
          ) : null}
        </s.Cell>
      </s.Content>
      {open && renderContent()}
      {confirmResetCounterDialogVisible && (
        <ConfirmResetCounterDialog
          assetId={assetId}
          onClose={() => setConfirmResetCounterDialogVisible(false)}
          onSuccess={onConfirmResetCounterDialogSuccess}
          ruleId={ruleId}
          ruleName={label}
        />
      )}
    </s.ListItem>
  );
};

ManikinEventListItem.propTypes = {
  assetId: PropTypes.string.isRequired,
  index: PropTypes.number,
  onConfirmResetCounter: PropTypes.func,
  onItemToggle: PropTypes.func,
  value: PropTypes.object
};

ManikinEventListItem.defaultProps = {
  index: -1,
  onConfirmResetCounter: Function.prototype,
  onItemToggle: Function.prototype,
  value: null
};


export default ManikinEventListItem;
