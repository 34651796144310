import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IoIosPulse, IoMdClock } from 'react-icons/io';
import { AutoSizer, Table } from 'react-virtualized';
import { withRouter } from 'react-router-dom';

import { getSortedManikins } from '../../../utility/manikin';
import { ALARM_TYPES, ALARM_TYPES_DIC, DECOMMISSIONED_STATE, MANIKIN_SUBMISSION_TYPE } from '../../../constants/constants';
import { ManikinShape } from '../../../constants/types';
import AddModal from './AddModal';
import HeaderLocation from '../Header/HeaderLocation';
import HeaderNormal from '../Header/HeaderNormal';
import ListItem from '../ListItem/ListItem';
import RepairReportModal from './RepairReportModal';
import ListItemLocation from '../ListItem/ListItemLocation';

import { mainInfoHeight } from '../ListItem/ListItem.style';
import { additionalInfoHeight, bottomInfoHeight } from '../ListItem/AdditionalInfo/AdditionalInfo.style';

import s, { defaultAssetsVisible, defaultHeight, getListHeight, headerHeight } from './AssetsList.style';
import {
  addDecommissionedFilterAction,
  setLastSelectedHospitalAction,
  setPreSelectedAssetAction,
  setSortAction,
  setSortDirectionAction,
  setTextFilterAndResetOtherAction,
} from '../../../actions/filters';
import Skeleton from '../../../components/Loading/TableSkeleton';
import SubmissionTypeModal from './SubmissionTypeModal/SubmissionTypeModal';
import DecommissionModal from '../ListItem/AdditionalInfo/DecommissionModal';
import { refreshHospitalAlarmsAction, setAlarmsAction, setAssetIsDecommissionedAction } from '../../../actions';

const AssetsList = (props) => {
  const {
    approved,
    assetsVisible,
    expandedAssetId,
    height,
    history,
    listItemType,
    loading,
    onHospitalSelect,
    onItemToggle,
    onAssetActionRemove,
    onAssetActionSelect,
    preSelectedAssetId,
    rawManikins,
    refreshHospitalAlarms,
    rejected,
    setAssetAlarms,
    setAssetDecommissioned,
    setSortDirection,
    setSortType,
    sortDirection,
    setLastSelectedHospitalId,
    setPreSelectedAsset,
    sortType,
    useLastRowWorkaround,
  } = props;
  // const [sortType, setSortType] = useState('');
  // const [sortDirection, setSortDirection] = useState('desc');
  const [manikins, setManikins] = useState(getSortedManikins(rawManikins, sortType, setSortDirection));
  const [selectedManikinId, setSelectedManikinId] = useState('');
  const [createAlarmWindowOpen, setCreateAlarmWindowOpen] = useState(false);
  const [createAlarmAsset, setCreateAlarmAsset] = useState(null);

  const [currentAssetId, setCurrentAssetId] = useState('');
  const [showDecommissionDialog, setShowDecommissionDialog] = useState(false);

  const [createRepairReportWindowOpen, setCreateRepairReportWindowOpen] = useState(false);
  const listElement = useRef(null);

  useEffect(() => {
    // Activate asset pre-selected from another page
    // Note that the map is loaded later so
    // reset code for pre-selected assetId is there
    // Handles decommission and redirect from 'Critical alerts' case
    if (preSelectedAssetId) {
      setSelectedManikinId(preSelectedAssetId);
      const manikin = manikins.find((m) => m.assetId === preSelectedAssetId);
      if (manikin) {
        setLastSelectedHospitalId(manikin.hospitalId);
      }
    }
  }, []);

  useEffect(() => {
    setManikins(getSortedManikins(rawManikins, sortType, sortDirection));
    if (listElement.current) {
      listElement.current.recomputeRowHeights();
    }
  }, [sortType, sortDirection, rawManikins, selectedManikinId]);

  useEffect(() => {
    if (preSelectedAssetId) {
      setSelectedManikinId(preSelectedAssetId);
      const manikin = manikins.find((m) => m.assetId === preSelectedAssetId);
      // Handles add manikin case
      if (manikin) {
        setLastSelectedHospitalId(manikin.hospitalId);
        onItemToggle(preSelectedAssetId);
        setPreSelectedAsset('');
      }
    }
  }, [preSelectedAssetId]);

  useEffect(() => {
    setSelectedManikinId(expandedAssetId);
  }, [expandedAssetId]);

  const reverseSortDirection = () => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';

    setSortDirection(newSortDirection);
  };

  const handleHeaderClick = (event) => {
    const { sorttype: newSortType } = event.currentTarget.dataset;

    if (sortType === newSortType) {
      reverseSortDirection();
      return;
    }

    setSortType(newSortType);
  };

  const handleCreateAlarmClick = (asset) => {
    const alarmTypes = asset.alarms.map((alarm) => alarm.alarmType);
    const showRepairReportOnly = alarmTypes.includes(ALARM_TYPES.USER_GENERATED) || alarmTypes.includes(ALARM_TYPES.IN_REPAIR);

    setCreateAlarmAsset(asset);
    if (showRepairReportOnly) {
      setCreateRepairReportWindowOpen(true);
    }
  };

  const handleEditRepairReport = (asset) => {
    setCreateAlarmAsset(asset);
    setCreateRepairReportWindowOpen(true);
  };

  const handleSubmissionTypeResult = (result) => {
    if (result === MANIKIN_SUBMISSION_TYPE.REPAIR_REPORT) {
      setCreateRepairReportWindowOpen(true);
    } else {
      setCreateAlarmWindowOpen(true);
    }
  };

  const handleShowDecommissionDialog = (assetId) => {
    setCurrentAssetId(assetId);
    setShowDecommissionDialog(true);
  };

  const handleItemClick = (assetId, { hospitalId, skipToggle }) => {
    if (!skipToggle && selectedManikinId === assetId) {
      setSelectedManikinId('');
    } else {
      setSelectedManikinId(assetId);
    }

    // Select latest selected hospital Id
    setLastSelectedHospitalId(hospitalId);

    onItemToggle(assetId);
  };

  const handleDecommissionSuccess = (assetId, assetAlarms) => {
    const manikin = manikins.find((m) => m.assetId === assetId);

    // 1. Unselect manikin
    // 2. Set as decommissioned
    // 3. Set text filter = assetId, indlude decommissioned assets
    // 4. Mark asset as pre-selected to automatically select it on '/dashboard'
    // 4. Redirec to '/dashboard' if needed
    // 5. Re-select manikin back

    handleItemClick(assetId, 0, {});
    setAssetDecommissioned(assetId);

    // Updated alarms will be returned by the call
    // So far BE should automatically add a f 'asset' alarm
    if (assetAlarms) {
      setAssetAlarms(
        assetId,
        assetAlarms.map((m) => ({
          alarmDescription: m.alarmDescription,
          alarmType: ALARM_TYPES_DIC[m.alarmType],
        })),
      );
      refreshHospitalAlarms(manikin.hospitalId);
    }

    setShowDecommissionDialog(false);

    if (history.location.pathname !== '/dashboard') {
      setPreSelectedAsset(assetId);
      history.push('/dashboard');
    } else {
      setSelectedManikinId(assetId);
      setLastSelectedHospitalId(manikins.find((m) => m.assetId === assetId).hospitalId);
      onItemToggle(assetId);
    }
  };

  const ageHeaderContent = <IoMdClock id="IoMdClock" size={18} />;

  const compressionsHeaderContent = <IoIosPulse id="IoIosPulse" size={18} />;

  const getRowHeight = ({ index }) =>
    manikins[index].assetId === selectedManikinId ? additionalInfoHeight + bottomInfoHeight + mainInfoHeight : mainInfoHeight;

  // eslint-disable-next-line react/prop-types
  const renderAsset = ({ rowData: manikin, style, index }) => (
    <ListItem
      key={manikin.assetId}
      index={index}
      style={style}
      manikin={manikin}
      onItemToggle={handleItemClick}
      opened={selectedManikinId === manikin.assetId}
      onActionSelect={onAssetActionSelect}
      onActionRemove={onAssetActionRemove}
      onHospitalSelect={onHospitalSelect}
      onCreateAlarm={handleCreateAlarmClick}
      onShowDecommissionDialog={handleShowDecommissionDialog}
      useLastRowWorkaround={useLastRowWorkaround}
    />
  );

  // eslint-disable-next-line react/prop-types
  const renderHospitalManikinAsset = ({ rowData: manikin, style, index }) => (
    <ListItemLocation
      key={manikin.assetId}
      index={index}
      style={style}
      manikin={manikin}
      onItemToggle={handleItemClick}
      opened={selectedManikinId === manikin.assetId}
      onActionSelect={onAssetActionSelect}
      onActionRemove={onAssetActionRemove}
      onEditRepairReport={(asset) => handleEditRepairReport(asset)}
      onHospitalSelect={onHospitalSelect}
      onCreateAlarm={handleCreateAlarmClick}
      useLastRowWorkaround={useLastRowWorkaround}
    />
  );

  const renderList = () => (
    <AutoSizer disableHeight>
      {({ width }) => {
        const tableHeight = getListHeight({ hasSelected: Boolean(selectedManikinId), assetsVisible, height });

        return (
          <Table
            ref={listElement}
            rowHeight={getRowHeight}
            width={width}
            height={tableHeight}
            rowGetter={({ index }) => manikins[index]}
            rowRenderer={listItemType === 'hospital_manikin' ? renderHospitalManikinAsset : renderAsset}
            rowCount={manikins.length}
            overscanRowCount={8}
            disableHeader
          />
        );
      }}
    </AutoSizer>
  );

  const renderSubmissionTypeModal = () => <SubmissionTypeModal onCancel={() => setCreateAlarmAsset(null)} onResult={handleSubmissionTypeResult} open />;

  const renderCreateAlarmWindow = () => (
    <AddModal
      assetId={createAlarmAsset.assetId}
      onCloseClick={(failure) => {
        setCreateAlarmWindowOpen(false);
        setCreateAlarmAsset(null);
      }}
      open
    />
  );

  const renderCreateRepairReportWindow = () =>
    createRepairReportWindowOpen && (
      <RepairReportModal
        manikin={createAlarmAsset}
        onCloseClick={() => {
          setCreateRepairReportWindowOpen(false);
          setCreateAlarmAsset(null);
        }}
        open={createRepairReportWindowOpen}
      />
    );

  const renderContent = () => <>{renderList()}</>;

  const renderHeader = () => {
    const headerProps = {
      ageHeaderContent,
      approved,
      compressionsHeaderContent,
      handleHeaderClick,
      rejected,
      sortDirection,
      sortType,
    };
    switch (listItemType) {
      case 'hospital_manikin':
        return <HeaderLocation {...headerProps} />;
      default:
        return <HeaderNormal {...headerProps} />;
    }
  };

  const renderDecommissionDialog = () => (
    <DecommissionModal
      assetId={currentAssetId}
      onClose={() => setShowDecommissionDialog(false)}
      onSuccess={(assetId, assetAlarms) => handleDecommissionSuccess(assetId, assetAlarms)}
    />
  );

  const submissionTypeWindowOpen = createAlarmAsset && !createAlarmWindowOpen && !createRepairReportWindowOpen;

  return (
    <s.Root hasSelected={Boolean(selectedManikinId)} assetsVisible={assetsVisible} height={height || defaultHeight * defaultAssetsVisible}>
      {renderHeader()}
      {loading ? <Skeleton /> : renderContent()}
      {createAlarmWindowOpen && renderCreateAlarmWindow()}
      {createRepairReportWindowOpen && renderCreateRepairReportWindow()}
      {submissionTypeWindowOpen && renderSubmissionTypeModal()}
      {showDecommissionDialog && renderDecommissionDialog()}
    </s.Root>
  );
};

AssetsList.propTypes = {
  approved: PropTypes.number.isRequired,
  assetsVisible: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  expandedAssetId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  height: PropTypes.number,
  history: PropTypes.object.isRequired,
  listItemType: PropTypes.oneOf(['normal', 'hospital_manikin']),
  loading: PropTypes.bool,
  onAssetActionRemove: PropTypes.func.isRequired,
  onAssetActionSelect: PropTypes.func.isRequired,
  onHospitalSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onItemToggle: PropTypes.func,
  rawManikins: PropTypes.arrayOf(ManikinShape).isRequired,
  refreshHospitalAlarms: PropTypes.func.isRequired,
  rejected: PropTypes.number.isRequired,
  setAssetAlarms: PropTypes.func.isRequired,
  setAssetDecommissioned: PropTypes.func.isRequired,
  setLastSelectedHospitalId: PropTypes.func.isRequired,
  setSortDirection: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired,
  useLastRowWorkaround: PropTypes.bool,
};

AssetsList.defaultProps = {
  assetsVisible: defaultAssetsVisible,
  expandedAssetId: null,
  height: defaultHeight + headerHeight,
  listItemType: 'normal',
  loading: false,
  onHospitalSelect: null,
  onItemToggle: Function.prototype,
  useLastRowWorkaround: false,
};

const mapStateToProps = (state) => ({
  assetsUpdates: state.manicDataReducer.updates,
  preSelectedAssetId: state.filters.preSelectedAssetId,
  sortType: state.filters.sort,
  sortDirection: state.filters.sortDirection,
});

const mapDispatchToProps = (dispatch) => ({
  refreshHospitalAlarms: (hospitalId) => dispatch(refreshHospitalAlarmsAction(hospitalId)),
  setAssetAlarms: (assetId, alarms) => dispatch(setAlarmsAction(assetId, alarms)),
  setAssetDecommissioned: (assetId) => {
    // Set asset as decommissioned
    dispatch(setAssetIsDecommissionedAction(assetId));
    // Pre-select asset
    dispatch(setTextFilterAndResetOtherAction(assetId));
    // Set decommissioned filter
    dispatch(addDecommissionedFilterAction(DECOMMISSIONED_STATE.DECOMMISSIONED));
  },
  setSortType: (sortType) => dispatch(setSortAction(sortType)),
  setSortDirection: (sortDirection) => dispatch(setSortDirectionAction(sortDirection)),
  setLastSelectedHospitalId: (hospitalId) => dispatch(setLastSelectedHospitalAction(hospitalId)),
  setPreSelectedAsset: (assetId) => dispatch(setPreSelectedAssetAction(assetId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsList));
