/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import api from './api/api';

const config = async (): Promise<{ clientId: string; tenantId: string; scopes: string[] }> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const result = await api.getConfig();
  return result.data as { clientId: string; tenantId: string; scopes: string[] };
};
export default config;
