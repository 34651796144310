import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../components/Button';
import { ManikinShape } from '../../constants/types';
import { generateCsv } from '../../utility/manikin';
import { downloadFileContent } from '../../utility/download';

const DownloadCsvButton = (props) => {
  const { disabled, manikins } = props;

  const handleDownloadClick = () => {
    const csv = generateCsv(manikins);
    downloadFileContent('RQI-predictive-maintenance.csv', csv, true);
  };

  return (
    <s.DownloadButton
      color="ghost"
      disabled={disabled}
      label={
        <>
          <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26 15L24.59 13.59L17 21.17V2H15V21.17L7.41 13.59L6 15L16 25L26 15ZM26 24V28H6V24H4V28C4 29.1046 4.89543 30 6 30H26C27.1046 30 28 29.1046 28 28V24H26Z"
            />
          </svg>
          Download CSV
        </>
      }
      onClick={() => handleDownloadClick()}
    />
  );
};

DownloadCsvButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
};

export default DownloadCsvButton;

const s = {};

s.DownloadButton = styled(Button)`
  bottom: 1px;
  margin-right: auto;
  position: relative;
  text-transform: none;

  & > svg {
    fill: ${(props) => props.theme.laerdalPalette.accent1.default};
    margin-right: 8px;
    width: 16px;
  }

  &:disabled > svg {
    fill: ${(props) => props.theme.greyPalette.grey};
  }
`;
