import styled from 'styled-components';
import Card from '../../components/Card/Card';
import SubTitle from '../../components/Texts/SubTitle';

const s = {};

s.Root = styled.div`
  background-color: ${props => props.theme.primaryBackground};
  padding: 90px 30px;
  min-height: calc(100% - 55px);
`;

s.PageTitle = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

s.SubTitle = styled(SubTitle)`
  display: inline-block;
`;

s.Cards = styled.div`
  display: flex;
  flex-direction: row;
`;

s.Card = styled(Card)`
  flex: 1 auto;
  ${({ fixed }) => fixed && `flex: 0 1 400px`};
  ${({ fixed }) => fixed && `min-width: 400px`};
  ${({ fixed }) => fixed && `margin-left: 40px`};
`;

s.CardTitleWithDescription = styled.div`
  white-space: nowrap;
`;

export default s;
