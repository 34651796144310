import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FailureTypeCell from './FailureTypeCell';
import Log from './LogItem';
import AdditionalInfo from './AdditionalInfo';
import EditLocationModal, { DialogMode } from './EditLocationModal/EditLocationModal';
import { setHospitalFilterAction, setTextFilterAction } from '../../../actions/filters';
import { ACTION_TYPES, ALARM_TYPES, iconTypeMap } from '../../../constants/constants';

import s from './ListItem.style';
import './ListItem.scss';
import { theme } from '../../../theme/theme';
import DecommissionHospitalModal from './EditLocationModal/DecommissionHospitalModal';

// NOTE: value represents action.action_id value from the DB
// while shorthand and label are used just for rendering

const rejectOptions = [
  { value: 'NaF', label: 'Not a failure', shorthand: 'NaF' },
  { value: 'Postp', label: 'Postpone', shorthand: 'Postp' },
  {
    value: 'Pstp. CO',
    label: 'Postpone: Customer owned',
    shorthand: 'Pstp. CO',
  },
  { value: 'Proc', label: 'Already processed', shorthand: 'Proc' },
];

const approveOptions = [
  // Note that action_id='Call' in the DB but it's rendered as 'Contact' on FE
  { value: 'Call', label: 'Contact client', shorthand: 'Contact' },
  { value: 'Diagnose', label: 'Diagnose manikin', shorthand: 'Diagnose' },
  { value: 'FW upd', label: 'Schedule firmware update', shorthand: 'FW upd' },
  { value: 'Replace', label: 'Replace manikin', shorthand: 'Replace' },
  // { value: 'SW upd', label: 'Schedule software update', shorthand: 'SW upd' },
  { value: 'RMA PR', label: 'RMA proactive replacement', shorthand: 'RMA PR' },
];

class ListItem extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    manikin: PropTypes.object.isRequired,
    onCreateAlarm: PropTypes.func.isRequired,
    onShowDecommissionDialog: PropTypes.func.isRequired,
    onEditRepairReport: PropTypes.func,
    onHospitalSelect: PropTypes.func.isRequired,
    onItemToggle: PropTypes.func.isRequired,
    onActionRemove: PropTypes.func.isRequired,
    onActionSelect: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    style: PropTypes.object,
    useLastRowWorkaround: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
    useLastRowWorkaround: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      decommissionHospitalId: undefined,
      decommissionHospitalModalOpened: false,
      editLocationDialogMode: DialogMode.Edit,
      editLocationModalOpened: false,
      dropdownRejectOpen: false,
      dropdownApproveOpen: false,
      selectedAlarmType: null,
    };

    this.handleCollapse = this.handleCollapse.bind(this);
    this.toggleDropdownReject = this.toggleDropdownReject.bind(this);
    this.toggleDropdownApprove = this.toggleDropdownApprove.bind(this);
    this.toggle = this.toggle.bind(this);

    this.itemRef = React.createRef();
  }

  componentDidMount() {
    this.itemRef.current.addEventListener('click', this.toggle);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.windowScrollHandler);
    this.itemRef.current.removeEventListener('click', this.toggle);
  }

  getCurrentAlarm = () => {
    const { manikin } = this.props;
    const { selectedAlarmType } = this.state;
    const { alarms } = manikin;

    if (!alarms.length) {
      return {
        alarmType: ALARM_TYPES.HEALTHY,
        alarmDescription: 'This manikin appears to be healthy',
      };
    }

    return selectedAlarmType ? alarms.find((alarm) => alarm.alarmType === selectedAlarmType) : alarms[0];
  };

  handleAlarmTypeClick = (alarmType) => {
    this.setState({
      selectedAlarmType: alarmType,
    });
  };

  handleAddAlarmClick = (assetId) => {
    const { onCreateAlarm, manikin } = this.props;

    onCreateAlarm(manikin);
  };

  handleActionCancel = (event) => {
    event.stopPropagation();
    const { manikin, onActionRemove, onActionSelect } = this.props;

    const alarm = this.getCurrentAlarm();
    const action = alarm.alarmType;

    if (!onActionRemove && onActionSelect) {
      onActionSelect(manikin.assetId, action);
    }

    if (onActionRemove) {
      onActionRemove(manikin.assetId);
    }
  };

  handleRejectItemClick = (selectedOption) => {
    const { onActionSelect, manikin } = this.props;

    if (onActionSelect) {
      const { value } = selectedOption;
      onActionSelect(manikin.assetId, ACTION_TYPES.REJECTED, value);
    }
  };

  handleApproveItemClick = (selectedOption) => {
    const { onActionSelect, manikin } = this.props;

    if (onActionSelect) {
      const { value } = selectedOption;
      onActionSelect(manikin.assetId, ACTION_TYPES.APPROVED, value);
    }
  };

  windowScrollHandler = () => {
    this.applyLastRowWorkaround(true);
    window.removeEventListener('scroll', this.windowScrollHandler);
  };

  handleMenuOpen = () => {
    const { useLastRowWorkaround } = this.props;
    // Footer workaround
    if (useLastRowWorkaround) {
      window.addEventListener('scroll', this.windowScrollHandler);
    }
  };

  handleMenuClose = () => {
    const { useLastRowWorkaround } = this.props;
    window.removeEventListener('scroll', this.windowScrollHandler);
    // Footer workaround (return position back)
    if (useLastRowWorkaround) {
      this.applyLastRowWorkaround(false);
    }
  };

  applyLastRowWorkaround = (isOpen) => {
    const footer = document.getElementById('#footer');
    const bounds = footer.getBoundingClientRect();
    const bottomBorder = bounds.top + bounds.height;
    if (isOpen && bottomBorder < window.innerHeight) {
      footer.style.bottom = `${-(window.innerHeight - bottomBorder)}px`;
    } else if (!isOpen) {
      footer.style.bottom = 0;
    }
  };

  failureTypeIsApproved = () => {
    const { manikin } = this.props;
    const { action } = manikin;

    return action.actionType === ACTION_TYPES.APPROVED;
  };

  handlePlaceNameClick = () => {
    const { manikin, onHospitalSelect } = this.props;
    const { hospitalId, placeName: hospitalName } = manikin;

    onHospitalSelect(hospitalId);
  };

  toggle(event) {
    if (event) {
      const { target } = event;
      const ignoreClick = Array.from(document.querySelectorAll('.ignoreClick').values()).find((el) => el.contains(target));
      const failureTypeCell = this.itemRef.current.querySelector('.FailureTypeCell');

      if (target.type === 'button' || ignoreClick) {
        return;
      }

      const { opened } = this.props;

      if (failureTypeCell.contains(target) && opened) {
        // in open state failureTypeCell should toggle
        // only if target alarm type is equal to current alarm type
        // otherwise click just changes alarmType and leaves the list item open

        const currentAlarm = this.getCurrentAlarm();

        // browser returns different elements as target
        let svgId = target.getAttribute('id');

        if (!svgId) {
          svgId = target.parentNode.getAttribute('id');
        }

        if (!svgId) {
          svgId = target.parentNode.children[0].getAttribute('id');
        }

        if (!svgId || svgId.indexOf(currentAlarm.alarmType) < 0) {
          return;
        }
      }
    }

    const { onItemToggle, manikin, index } = this.props;
    const id = manikin.assetId;

    // Pass hospital id to the top to avoid extra searching
    onItemToggle(id, index, { hospitalId: manikin.hospitalId });
  }

  handleCollapse() {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  }

  handleHospitalWarningClick(serialNumber) {}

  toggleDropdownReject() {
    this.setState((prevState) => ({
      dropdownRejectOpen: !prevState.dropdownRejectOpen,
    }));
  }

  toggleEditLocationModal() {
    this.setState((prevState) => ({
      editLocationModalOpened: !prevState.editLocationModalOpened,
    }));
  }

  toggleDecommissionHospitalModal(hid) {
    this.setState((prevState) => ({
      editLocationModalOpened: false,
      decommissionHospitalId: hid,
      decommissionHospitalModalOpened: !prevState.decommissionHospitalModalOpened,
    }));
  }

  handleHospitalDecommission(hid) {
    this.toggleEditLocationModal();
    this.toggleDecommissionHospitalModal(hid);
  }

  decommissionHospitalSuccess(hid) {
    const { index, manikin, onItemToggle } = this.props;

    const { collapsed } = this.state;

    const { assetId, hospitalId } = manikin;

    if (!collapsed) {
      onItemToggle(assetId, index, { hospitalId, skipToggle: true });
    }
  }

  handleAssetAttrsUpdate(attribs) {
    this.toggleEditLocationModal();
  }

  toggleDropdownApprove(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState((prevState) => ({
      dropdownApproveOpen: !prevState.dropdownApproveOpen,
    }));
  }

  renderActionValue = ({ data }) => data.shorthand || data.value;

  renderActionSelects = () => {
    const { manikin } = this.props;
    const { action } = manikin;
    const rejectDefaultValue = { value: 'Reject', label: 'Reject' };
    const approveDefaultValue = { value: 'Approve', label: 'Approve' };
    const approveValue = approveOptions.find((approveOption) => approveOption.value === action.actionId);
    const rejectValue = rejectOptions.find((rejectOption) => rejectOption.value === action.actionId);
    const styles = {
      control: (defaultStyles, { isFocused, selectProps }) => {
        const { value, actionType } = selectProps;
        let backgroundColor = 'white';

        if (value.shorthand) {
          backgroundColor = actionType === 'approve' ? theme.laerdalPalette.primary.default : theme.laerdalPalette.critical;
        }
        let style = {
          ...defaultStyles,
          backgroundColor,
          borderColor: backgroundColor === 'white' ? theme.greyPalette.grey : backgroundColor,
          borderRadius: theme.borderRadius,
          color: backgroundColor === 'white' ? theme.greyPalette.black : 'white',
          fontSize: theme.fontSize.medium,
          fontWeight: theme.fontWeight.regular,
          width: '109px',
          ':hover': {
            borderColor: theme.greyPalette.black,
            cursor: 'pointer',
          },
        };
        if (isFocused) {
          style = {
            ...style,
            borderColor: theme.greyPalette.black,
            boxShadow: 'none',
            outline: 'none',
          };
        }

        return style;
      },

      dropdownIndicator: (defaultStyles, { selectProps }) => {
        const { value } = selectProps;
        let color = '';

        if (value.shorthand) {
          color = 'white';
        }

        return {
          ...defaultStyles,
          backgroundColor: 'transparent',
          color,
          width: '24px',
          height: '40px',
          padding: '0 5px',
          alignItems: 'center',
          ':hover': {
            color: theme.greyPalette.black,
          },
        };
      },
      menuPortal: (defaultStyles) => ({
        ...defaultStyles,
        minWidth: '200px',
      }),
      option: (defaultStyles, { isFocused, isSelected }) => {
        let style = {
          ...defaultStyles,
          color: theme.greyPalette.grey,
          fontSize: theme.fontSize.medium,
          fontWeight: theme.fontWeight.regular,
          ':hover': {
            backgroundColor: theme.laerdalPalette.primary.light1,
            color: theme.inverseColor,
          },
        };
        if (isFocused) {
          style = {
            ...style,
            backgroundColor: theme.laerdalPalette.primary.light1,
            color: theme.inverseColor,
          };
        }
        if (isSelected) {
          style = {
            ...style,
            backgroundColor: theme.laerdalPalette.primary.default,
            color: theme.greyPalette.black,
          };
        }

        return style;
      },
      indicatorSeparator: (defaultStyles) => ({
        ...defaultStyles,
        display: 'none',
      }),
      indicatorsContainer: (defaultStyles) => ({
        ...defaultStyles,
        height: '40px',
      }),
      valueContainer: (defaultStyles) => ({
        ...defaultStyles,
        width: '85px',
        height: '40px',
        padding: '0 0.25rem 0 0.5rem',
      }),
      input: (defaultStyles) => ({
        ...defaultStyles,
        width: 0,
        margin: 0,
        display: 'inline-block',
      }),
    };

    const rejectionStyles = {
      ...styles,
      menu: (defaultStyles) => ({
        ...defaultStyles,
        left: -21,
      }),
    };

    return (
      <s.ActionControls>
        <s.ActionSelect
          className="ignoreClick"
          options={approveOptions}
          value={approveValue || approveDefaultValue}
          components={{
            SingleValue: this.renderActionValue,
          }}
          styles={styles}
          menuPortalTarget={document.body}
          actionType="approve"
          onChange={this.handleApproveItemClick}
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          hideSelectedOptions
        />
        <s.ActionSelect
          className="ignoreClick"
          options={rejectOptions}
          value={rejectValue || rejectDefaultValue}
          components={{
            SingleValue: this.renderActionValue,
          }}
          styles={rejectionStyles}
          menuPortalTarget={document.body}
          actionType="reject"
          onChange={this.handleRejectItemClick}
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          hideSelectedOptions
        />
        {(approveValue || rejectValue) && <s.ActionControlsCancel size={24} onClick={this.handleActionCancel} className="ignoreClick" />}
      </s.ActionControls>
    );
  };

  renderFailureTypes = () => {
    const { manikin } = this.props;
    const currentAlarm = this.getCurrentAlarm();
    const { alarms, assetId } = manikin;
    const alarmTypes = alarms.map((alarm) => alarm.alarmType);

    return (
      <FailureTypeCell
        alarmTypes={alarmTypes}
        id={assetId}
        onAlarmTypeClick={this.handleAlarmTypeClick}
        selectedType={currentAlarm.alarmType}
        onAddClick={this.handleAddAlarmClick}
        tall={false}
      />
    );
  };

  renderHospitalName = (text, serialNumber, edited) => (
    <s.LengthyTextCell toolTipUid={`placeName-${serialNumber}`} text={text} onClick={this.handlePlaceNameClick} edited={edited} />
  );

  renderLengthyText = (text, toolTipUid, maxLength) => <s.LengthyTextCell maxTextLength={maxLength || 50} toolTipUid={toolTipUid} text={text} />;

  renderManikinType = () => {
    const { manikin } = this.props;
    const { program } = manikin;
    const type = manikin.type || 'not-defined';
    const icon = iconTypeMap[type];

    return (
      <s.ManikinType>
        <img src={icon} alt={`${program} ${type}`} />
        {program}
      </s.ManikinType>
    );
  };

  render() {
    const { manikin, onEditRepairReport, onShowDecommissionDialog, opened, style } = this.props;

    const { assetId, attrs, hospitalId, info, placeName } = manikin;

    const { facility, location } = manikin.attrs;

    const { lastMaintenance, age, compressions } = info;

    const { decommissionHospitalId, decommissionHospitalModalOpened, editLocationDialogMode, editLocationModalOpened } = this.state;

    const currentAlarm = this.getCurrentAlarm();
    const displayAge = age.maintenance > -1 ? age.maintenance : age.production;
    let displayCompressions = compressions.maintenance > -1 ? compressions.maintenance : compressions.production;

    if (displayCompressions === -1) {
      displayCompressions = null;
    }

    return (
      <s.ListItem
        ref={this.itemRef}
        className="ListItem"
        style={{
          ...style,
          overflow: 'visible',
        }}
        opened={opened}
      >
        <s.Content>
          <s.Cell>{this.renderFailureTypes()}</s.Cell>
          <s.Cell hospital>{this.renderHospitalName(placeName, assetId, attrs.hospitalId > 0 ? attrs.dateTime : null)}</s.Cell>
          <s.Cell>{this.renderManikinType()}</s.Cell>
          <s.Cell facility>{this.renderLengthyText(facility || '-', `facility-${assetId}`, 46)}</s.Cell>
          <s.Cell cartLocation>{this.renderLengthyText(location || '-', `location-${assetId}`)}</s.Cell>
          <s.Cell serial>{assetId}</s.Cell>
          <s.Cell maintenance>{lastMaintenance}</s.Cell>
          <s.Cell age>{displayAge}</s.Cell>
          <s.Cell compressions>{displayCompressions}</s.Cell>
          {this.renderActionSelects()}
          <s.Cell log className="ignoreClick">
            <Log assetId={assetId} />
          </s.Cell>
        </s.Content>
        {opened && (
          <AdditionalInfo
            alarm={currentAlarm}
            assetId={assetId}
            manikin={manikin}
            onEditLocationClick={() => this.toggleEditLocationModal()}
            onEditRepairReport={(man) => onEditRepairReport(man)}
            onShowDecommissionDialog={onShowDecommissionDialog}
          />
        )}
        {editLocationModalOpened && (
          <EditLocationModal
            assetId={assetId}
            attrs={attrs}
            dialogMode={editLocationDialogMode}
            hospitalId={hospitalId}
            manikin={manikin}
            onCancel={() => this.toggleEditLocationModal()}
            onUpdate={() => this.toggleEditLocationModal()}
            onHospitalDecommission={(hid) => this.handleHospitalDecommission(hid)}
            open={editLocationModalOpened}
          />
        )}
        {decommissionHospitalModalOpened && (
          <DecommissionHospitalModal
            hospitalId={decommissionHospitalId}
            onCancel={() => this.toggleDecommissionHospitalModal()}
            onConfirm={() => this.toggleDecommissionHospitalModal()}
            onSuccess={(hid) => this.decommissionHospitalSuccess(hid)}
            open={decommissionHospitalModalOpened}
          />
        )}
      </s.ListItem>
    );
  }
}

const mapStateToProps = (state) => ({
  manicDataReducer: Object.assign({}, state.manicDataReducer),
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onHospitalSelect:
    ownProps.onHospitalSelect ||
    ((hospitalId) => {
      dispatch(setHospitalFilterAction(hospitalId));
      dispatch(setTextFilterAction(''));
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
