import styled from 'styled-components';
import Option from './Option/Option';
import Popup from '../../../components/Popup';

const style = {};

style.Block = styled.div`
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding-bottom: 10px;
  margin-bottom: 5px;
`;

style.ContentBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
  max-width: 360px;
`;

style.Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

style.Root = styled.div`
  z-index: 101;
`;

style.Label = styled.div`
  color: ${({ theme, panelOpened }) => (panelOpened ? theme.secondaryColorPalette.main : theme.greyPalette.grey)};
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.regular};
  letter-spacing: 0.25px;
  margin-left: 10px;

  &:hover {
    color: ${({ theme }) => theme.secondaryColorPalette.main};
  }
`;

style.StyledPopup = styled(Popup)`
  padding: 30px 28px;
  position: absolute;
  top: 50px;
  left: 0;
  width: 360px;

`;

style.Toggle = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position absolute;
  left: 0;
  top: 20px;
`;

export default style;
