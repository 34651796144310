import styled from 'styled-components';
import { IoIosCloseCircleOutline, IoIosWarning } from 'react-icons/io';

import CustomSelect from '../../../components/Select';
import { additionalInfoHeight, bottomInfoHeight } from './AdditionalInfo/AdditionalInfo.style';
import LengthyTextCell from './LengthyTextCell';

const style = {};
export const mainInfoHeight = 64;

style.ActionControls = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 280px;
  height: ${mainInfoHeight}px;
`;

style.ActionSelect = styled(CustomSelect)`
  flex: 0 0 100px;
`;

style.ActionControlsCancel = styled(IoIosCloseCircleOutline)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -11px;
  cursor: pointer;
`;

style.AdditionalInfo = styled.div`
  display: flex;
  z-index: 100;
  position: relative;
  flex: 0 0 ${additionalInfoHeight + bottomInfoHeight}px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

style.AdditionalText = styled.div`
  position: relative;
  margin-left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 calc(50% - 60px);
  height: ${additionalInfoHeight}px;
  padding: 10px;
  border: 4px solid rgb(32, 188, 173);
  background-color: #fff;
`;

style.AdditionalTable = styled.table`
  flex: 1 1 50%;
  height: ${additionalInfoHeight}px;
  box-sizing: border-box;
  border: none;
  font-size: 14px;
  background-color: #fff;

  td,
  th {
    padding-left: 10px;
    border: 1px solid rgb(32, 188, 173);
  }

  th {
    border-top: 0;
  }
`;

style.Column = styled.div`
  display: flex;
  height: ${mainInfoHeight}px;
  align-items: center;
  justify-content: left;
`;

style.Content = styled.div`
  display: flex;
`;

style.Cell = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  justify-content: flex-start;
  flex: 0 1 120px;
  min-width: 100px;

  ${({ hospital }) =>
    hospital &&
    `
    flex: 1 1 260px;
    min-width: 150px;
  `}

  ${({ facility }) =>
    facility &&
    `
    flex: 0 1 140px;
    min-width: 120px;
    overflow-wrap: anywhere;
  `}

  ${({ cartLocation }) =>
    cartLocation &&
    `
    flex: 0 1 150px;
    min-width: 100px;
    overflow-wrap: anywhere;
  `}

  ${({ maintenance }) =>
    maintenance &&
    `
    flex: 0 1 150px;
    min-width: 100px;
  `}

  ${({ serial }) =>
    serial &&
    `
    flex: 0 1 120px;
    min-width: 100px;
    overflow-wrap: anywhere;
  `}

  ${({ age }) =>
    age &&
    `
    flex: 0 2 140px;
    min-width: 60px;
  `}

  ${({ compressions }) =>
    compressions &&
    `
    flex: 0 2 140px;
    min-width: 60px;
  `}

  ${({ log }) =>
    log &&
    `
    flex: 0 0 40px;
    min-width: 40px;
    justify-content: center;
  `}
`;

style.LengthyTextCell = styled(LengthyTextCell)`
  outline: none;
`;

style.ListItem = styled.div`
  background-color: ${({ theme, opened }) => (opened ? theme.greyPalette.grey6 : theme.primaryBackground)};
  border-bottom: 1px solid ${({ theme, opened }) => (opened ? theme.secondaryColorPalette.light1 : theme.greyPalette.grey6)};
  border-top: 1px solid ${({ theme, opened }) => (opened ? theme.secondaryColorPalette.light1 : 'transparent')};
  border-left: 1px solid ${({ theme, opened }) => (opened ? theme.secondaryColorPalette.light1 : 'transparent')};
  border-right: 1px solid ${({ theme, opened }) => (opened ? theme.secondaryColorPalette.light1 : 'transparent')};
  color: ${(props) => props.theme.greyPalette.black};
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSize.medium};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.greyPalette.grey6};
  }
`;

style.ManikinType = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 0 1 120px;
  min-width: 100px;
  justify-content: center;

  img {
    width: 16px;
  }
`;

style.Wide = styled.div`
  width: 100%;
  display: inline;
  margin-right: 5px;
`;

export default style;
