import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';

import MarkerWrapper from './MarkerWrapper';
import config from '../../resources/config';

import './MapWrapper.scss';
import { HOSPITAL_COLORS, HOSPITAL_STATES } from '../../constants/constants';
import ClusterMarker from './ClusterMarker/ClusterMarker';
import { getHospitalManikinsCounters, getHospitalState } from '../../utility/hospital';

const MAP_OPTIONS = {
  defaultZoom: 4,
  defaultCenter: {
    lat: 40.322846,
    lng: -100.74626,
  },
};

class HospitalMapWrapper extends Component {
  static propTypes = {
    assetsUpdates: PropTypes.object.isRequired,
    hospitals: PropTypes.arrayOf(PropTypes.object).isRequired,
    manikins: PropTypes.array.isRequired,
    selectedHospitalId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    googleApiKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selectedHospitalId: null,
  };

  state = {
    clusters: [],
    mapOptions: {
      center: {
        lat: 40.322846,
        lng: -100.74626,
      },
      zoom: 4,
    },
  };

  prevMapState = MAP_OPTIONS;

  _isMounted = false;

  componentDidUpdate(prevProps) {
    const { hospitals, selectedHospitalId } = this.props;
    const newSelectedHospitalId = selectedHospitalId;
    const newHospitals = hospitals;

    if (!this._isMounted || newSelectedHospitalId !== prevProps.selectedHospitalId || newHospitals !== prevProps.hospitals) {
      if (this._map) {
        this._isMounted = true;
        this.handleSelectedHospitalChange();
      }
    }
  }

  getClusters = () => {
    const hospitalsToShow = this.getVisibleHospitals().map((h) => ({
      ...h,
      ...h.location,
    }));
    const clustersGetter = supercluster(hospitalsToShow, {
      minZoom: 0,
      maxZoom: 16,
    });
    const { mapOptions } = this.state;

    return clustersGetter(mapOptions);
  };

  getHospitalManikins = (manikins, hospitalId) => manikins.filter((m) => m.hospitalId === hospitalId);

  getVisibleHospitals() {
    const { hospitals, selectedHospitalId } = this.props;
    const resultHospitalList = hospitals;

    if (!hospitals || hospitals.length === 0) {
      return [];
    }

    // always display selected hospital
    if (selectedHospitalId && !resultHospitalList.find((hospital) => hospital.hospitalId === selectedHospitalId)) {
      resultHospitalList.push(hospitals.find((hospital) => hospital.hospitalId === selectedHospitalId));
    }

    return resultHospitalList;
  }

  initializeMap = ({ map }) => {
    this._map = map;
  };

  zoomToHospital = (hospitalId) => {
    const { hospitals } = this.props;
    const { mapOptions } = this.state;
    const hospital = hospitals.find((h) => h.hospitalId === hospitalId);

    if (hospital) {
      const center = {
        lat: hospital.location.lat,
        lng: hospital.location.lng,
      };
      const { zoom: currentZoom } = mapOptions;
      const zoom = currentZoom > 13 ? currentZoom : 13;

      this.moveMap(center, zoom);

      this.setState({
        mapOptions: {
          center,
          zoom,
        },
      });
    }
  };

  zoomToCluster = (id) => {
    const { clusters, mapOptions } = this.state;
    const cluster = clusters.find((cl) => cl.id === id);
    const { lat, lng } = cluster;
    const { zoom: currentZoom } = mapOptions;
    const zoom = currentZoom + 2;

    this.moveMap(
      {
        lat,
        lng,
      },
      zoom,
    );

    this.setState({
      mapOptions: {
        center: {
          lat,
          lng,
        },
        zoom,
      },
    });
  };

  moveMap = (center = MAP_OPTIONS.defaultCenter, zoom = MAP_OPTIONS.defaultZoom) => {
    this._map.setZoom(zoom);
    this._map.setCenter(center);
  };

  setClustersState = (clusters) => {
    this.setState({
      clusters,
    });
  };

  createClusters = () => {
    const { mapOptions } = this.state;

    if (!mapOptions.bounds) {
      return [];
    }

    return this.getClusters().map(({ wx, wy, numPoints, points }) => ({
      lng: wx,
      lat: wy,
      numPoints,
      id: numPoints === 1 ? points[0].hospitalId : `${numPoints}-${points[0].hospitalId}`,
      points,
    }));
  };

  getAssetHandledIssues = (assetId) => {
    const { assetsUpdates } = this.props;
    const assetUpdates = assetsUpdates[assetId];
    let handledCount = 0;

    if (assetUpdates) {
      const updates = Object.keys(assetUpdates);

      updates.forEach((updateFailureType) => {
        const update = assetUpdates[updateFailureType];

        if (update && update.action !== '') {
          handledCount += 1;
        }
      });
    }

    return handledCount;
  };

  getHospitalHandledIssues = (hospitalId) => {
    const { manikins } = this.props;
    const hospitalManikinsIds = this.getHospitalManikins(manikins, hospitalId).map((m) => m.assetId);

    return hospitalManikinsIds.reduce((acc, manikinId) => acc + this.getAssetHandledIssues(manikinId), 0);
  };

  handleSelectedHospitalChange = () => {
    const { hospitals, selectedHospitalId } = this.props;
    const selectedIsNumber = Number.isInteger(selectedHospitalId);
    const hasHospitals = hospitals && hospitals.length > 0;

    if (selectedIsNumber && hasHospitals) {
      this.zoomToHospital(selectedHospitalId);
    } else {
      const { center, zoom } = this.prevMapState;
      this.moveMap(center, zoom);
    }
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      () => this.setClustersState(this.createClusters()),
    );
  };

  reducePointToHospitalsCounter = (counter, hospital, hospitalIndex, hospitals) => {
    const { faulty, total } = getHospitalManikinsCounters(hospital.manikins);

    const hospitalState = getHospitalState(faulty, total);
    const number = counter[hospitalState][0] + 1;
    const percentage = Math.round((number / hospitals.length) * 100) / 100;

    return {
      ...counter,
      [hospitalState]: [number, percentage],
    };
  };

  hospitalsCounterToSlices = (counter) => {
    const slices = [];
    const hospitalStates = Object.keys(counter);

    hospitalStates.forEach((hospitalState) => {
      const data = counter[hospitalState];
      const [number, percentage] = data;
      const slice = {
        number,
        color: HOSPITAL_COLORS[hospitalState],
        percentage,
      };

      slices.push(slice);
    });

    return slices;
  };

  renderMarker = (hospital) => {
    const { selectedHospitalId } = this.props;
    const { hospitalId: id, manikins, placeName: name, location } = hospital;

    const { faulty, total } = getHospitalManikinsCounters(manikins);

    const { lat, lng } = location;
    const selected = selectedHospitalId === id;
    const handledFailures = this.getHospitalHandledIssues(id);

    return (
      <MarkerWrapper
        key={id}
        manikinsCount={total}
        faultyManikinsCount={faulty}
        handledFailures={handledFailures}
        name={name}
        selected={selected}
        lat={lat}
        lng={lng}
        id={id}
        onMar
        onClick={() => true}
        onClickClose={() => true}
        renderCloseIcon={false}
      />
    );
  };

  renderCluster = (cluster) => {
    const { points, lat, lng, id } = cluster;
    const clusterHospitals = points.reduce(this.reducePointToHospitalsCounter, {
      [HOSPITAL_STATES.CRITICAL]: [0, 0],
      [HOSPITAL_STATES.RISK]: [0, 0],
      [HOSPITAL_STATES.WARN]: [0, 0],
      [HOSPITAL_STATES.HEALTHY]: [0, 0],
    });
    const slices = this.hospitalsCounterToSlices(clusterHospitals);

    return (
      <div lat={lat} lng={lng} key={id}>
        <ClusterMarker slices={slices} itemsCount={points.length} onClick={this.zoomToCluster} id={id} />
      </div>
    );
  };

  renderMapContents = () => {
    const { clusters } = this.state;

    return clusters.map((item) => {
      if (item.numPoints === 1) {
        return this.renderMarker(item.points[0]);
      }

      return this.renderCluster(item);
    });
  };

  render() {
    return (
      <div className="MapWrapper">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: this.props.googleApiKey,
          }}
          defaultCenter={MAP_OPTIONS.defaultCenter}
          defaultZoom={MAP_OPTIONS.defaultZoom}
          onChange={this.handleMapChange}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.initializeMap}
        >
          {this.renderMapContents()}
        </GoogleMapReact>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assetsUpdates: state.manicDataReducer.updates,
  googleApiKey: state.user.googleApiKey,
});

export default connect(mapStateToProps, null)(HospitalMapWrapper);
