import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { getAssetHistoryAction } from '../../../../actions/descriptiveLocation';
import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import s from './EditLocationModal.style';
import { DateTime } from 'luxon';
import sas from './AssetHistory.style';

const AssetHistory = (props: AssetHistoryProps) => {
  const { assetId, locations, errorMessage, loading, loadAssetHistory } = props;

  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    loadAssetHistory(assetId);
  }, [assetId]);

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  const renderRow = (x: any) => {
    return (
      <tr>
        <td>{DateTime.fromISO(x.dateTime).toLocaleString()}</td>
        <td>{x.placeName}</td>
        <td>{x.address}</td>
        <td>{x.location}</td>
        <td>{x.source}</td>
      </tr>
    );
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
      {error && <s.ErrorText>{error}</s.ErrorText>}
      <Table>
        <thead>
          <tr>
            <sas.th>Date</sas.th>
            <sas.th>Name</sas.th>
            <sas.th>Address</sas.th>
            <sas.th>Location</sas.th>
            <sas.th>Source</sas.th>
          </tr>
        </thead>
        <tbody>{locations.length > 0 ? locations.map((x) => renderRow(x)) : <tr>No history</tr>}</tbody>
      </Table>
    </>
  );
};

type AssetHistoryProps = {
  assetId: string;
  locations: any;
  errorMessage: any;
  loading: any;
  loadAssetHistory: any;
};

const mapStateToProps = (state) => {
  const ahr = state.assetHistoryReducer;
  return {
    locations: ahr.data ? ahr.data : [],
    errorMessage: ahr.error ? ahr.message : '',
    loading: ahr.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadAssetHistory: (assetId) => dispatch(getAssetHistoryAction(assetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetHistory);
