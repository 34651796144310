import PropTypes from 'prop-types';
import React from 'react';

import styled from 'styled-components';

import SubTitle from '../Texts/SubTitle';

const Card = (props) => {
  const { title, children, className } = props;
  return (
    <CardContainer className={className}>
      <CardHeader>{React.isValidElement(title) ? title : <SubTitle>{title}</SubTitle>}</CardHeader>
      <CardContent>{children}</CardContent>
    </CardContainer>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Card.defaultProps = {
  children: null,
  className: '',
  title: '',
};

export const CardContainer = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

export const CardHeader = styled.div`
  background-color: #fafbfc;
  box-shadow: inset 0 -1px 0 0 #ccdadd;
  line-height: 1;
  padding: 19px 31px;
`;

export const CardContent = styled.div`
  padding: 0;
  display: flex;
`;

export const CardDescription = styled.span`
  font-size: ${(props) => props.theme.fontSize.large};
  color: ${(props) => props.theme.greyPalette.grey3};
`;

export default Card;
