import React from 'react';
import styled from 'styled-components';

import packageJson from '../../../package.json';

const Footer = () => (
  <Root id="#footer">
    <Version>v.{packageJson.version}</Version>
  </Root>
);

export default Footer;

const Link = styled.a`
  color: ${(props) => props.theme.greyPalette.grey3};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.regular};

  &:hover {
    color: ${(props) => props.theme.inverseColor};
  }
`;

const Version = styled.div`
  color: ${(props) => props.theme.greyPalette.grey3};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  position: absolute;
  right: 30px;
`;

const Root = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.laerdalPalette.primary.default};
  bottom: 0;
  display: flex;
  height: 55px;
  justify-content: center;
  left: 0;
  position: relative;
  width: 100%;
`;
