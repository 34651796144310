import PropTypes from 'prop-types';

export const RepairReasonShape = PropTypes.shape({
  repairReasonId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});


export const ReplacementTypeShape = PropTypes.shape({
  replacementTypeId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const ManikinSubPartShape = PropTypes.shape({
  assetSubPartId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const ManikinPartShape = PropTypes.shape({
  assetPartId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subParts: PropTypes.arrayOf(ManikinSubPartShape)
});

export const RepairReportDataShape = PropTypes.shape({
  repairReason: PropTypes.arrayOf(RepairReasonShape),
  replacementType: PropTypes.arrayOf(ReplacementTypeShape),
  asserParts: PropTypes.arrayOf(ManikinPartShape),
  repairReport: PropTypes.object
});
