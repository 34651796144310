import styled from 'styled-components';

const style = {};
const markerRadius = 17;
const toolTipWidth = 150;

style.Tooltip = styled.div`
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% + 6px);
  left: -${toolTipWidth / 2 - markerRadius}px;
  z-index: 150;
  width: 150px;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px 10px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  text-align: left;

  &:after {
    content: "";
    position: absolute;
    top: -4px;
    left: 50%;
    display: block;
    margin-left: -4px;
    border-bottom: 4px solid;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  p {
    margin: 4px;
  }
`;

style.Root = styled.div`
  position: relative;
  left: -17px;
  top: -17px;
  width: ${markerRadius * 2}px;
  height: ${markerRadius * 2}px;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: ${markerRadius * 2}px;
    height: ${markerRadius * 2}px;
    border-radius: 50%;
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.1),
      inset 0 -10px 15px rgba(0, 0, 0, 0.03);
  }
  
  &:hover {
    ${style.Tooltip} {
      display: block;
    }
  }
  
  svg {
    border-radius: 50%;
  }
`;

style.Content = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  width: ${markerRadius * 2 - 6}px;
  height: ${markerRadius * 2 - 6}px;
  border-radius: 50%;
  background-color: white;
  line-height: 30px;
  color: #0a4453;
  text-align: center;
`;

export default style;
