import styled from 'styled-components';
import Button from '../../../../components/Button';
import Text from '../../../../components/Texts/Text';

const s = {};

s.FirstRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 250px;
  justify-content: space-between;
`;

s.Row = styled(s.FirstRow)`
  height: auto;
  margin-top: 10px;
`;

s.Cell = styled.div`
  flex: 1 1 ${props => (props.half ? '25%' : '50%')};
  ${props => props.col && `
    display: flex;
    flex: 0 0 120px;
    flex-direction: column;
    justify-content: flex-end;
  `}
  ${props => props.w && `
    flex 1 1 ${props.w};
  `}
  ${props => props.col && `
    position: relative;
  `}
`;

s.Delimiter = styled.div`
  flex: 0 0 20px;
  ${props => props.inline && `
    display: inline-block;
    width: 20px;
  `}
`;

s.HospitalButton = styled(Button)`
  padding: 5px 12px;
  margin: 3px 0;
`;

s.MessageCont = styled.div`
  margin-top: 15px;
`;

s.AddHospitalErrorText = styled(Text)`
  min-height: 14px;
`;

s.AddHospitalHintText = styled(Text)`
  color: ${({ theme }) => theme.secondaryColorPalette.light1};
`;

s.AssetTypeImage = styled.img`
  width: 20px;
`;

s.ErrorText = styled(Text)`
  white-space: pre-wrap;
`;

s.SuccessText = styled(Text)`
  color: ${({ theme }) => theme.secondaryColorPalette.light1};
`;

s.IconWarningCont = styled.div`
  color: #fecd37;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: 2px;

  & > svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

s.IconWarningBg = styled.div`
  position:absolute;
  top: 7px;
  left: 8px;
  width: 5px;
  height: 10px;
  background-color: ${({ theme }) => theme.greyPalette.black};
`;

export default s;
