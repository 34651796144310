import styled from 'styled-components';
import Button from '../../components/Button';

const s = {};

s.Root = styled.div`
  background-color: ${props => props.theme.primaryBackground};
  padding: 90px 30px 20px;
  min-height: calc(100% - 55px);
`;

s.Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
`;

s.HistoryHeader = styled(s.Header)`
  margin-top: 40px;
`;

s.MoreBtn = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -25px;
`;

export default s;
