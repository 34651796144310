import styled from 'styled-components';

import Button from '../../../components/Button';
import Popup from '../../../components/Popup';
import Text from '../../../components/Texts/Text';

const style = {};

style.FeedbackItem = styled.div`
  margin-bottom: 15px;
`;

style.Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`;

style.StyledButton = styled(Button)`
  margin-left: 10px;
`;

style.Alert = styled(Text)`
  color: ${props => props.theme.redColorPalette.main};
`;

style.Error = styled.div`
  margin: 10px 0;
`;

style.StyledPopup = styled(Popup)`
  width: 380px;
`;

export default style;
