import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { sendFeedback } from '../../../api/api';
import { MAX_FILE_SIZE } from '../../../constants/constants';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import Input from '../../../components/Input';
import Text from '../../../components/Texts/Text';
import Textarea from '../../../components/Textarea';

import s from './Feedback.style';

const Feedback = ({ className, onCancel }) => {
  const [submissionType, setSubmissionType] = useState('bug');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [attachments, setAttachments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState('');
  const [sent, setSent] = useState(false);

  const options = [{
    label: 'Bug',
    value: 'bug'
  }, {
    label: 'Suggestion',
    value: 'suggestion'
  }, {
    label: 'Predictive maintenance',
    value: 'maintenance'
  }, {
    label: 'Other',
    value: 'other'
  }];
  const resetForm = () => {
    setSubmissionType('bug');
    setDescription('');
    setAttachments(null);
    setEmail('');
    setError([]);
    setSuccess('');

    const attachmentsInput = document.getElementById('FeedbackAttachment');
    attachmentsInput.value = '';
  };

  const handleSelectSubmissionType = (value) => {
    setSubmissionType(value);
  };
  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  const handleAttachmentChange = (event) => {
    const { files } = event.target;
    setAttachments(Array.from(files));
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleCancelClick = () => {
    if (onCancel) {
      onCancel();
    } else {
      resetForm();
    }
  };

  const handleSendError = () => {
    setLoading(false);
    setError(error.concat('Error while sending form.'));
  };

  const handleSuccess = () => {
    resetForm();
    setLoading(false);
    setSent(true);
    setSuccess('Your feedback is sent');
  };

  const validateAttachments = () => attachments.reduce((res, att) => (!res ? res : att.size <= MAX_FILE_SIZE), true);

  const validate = () => {
    const errors = [];

    if (description === '') {
      errors.push('Description is required');
    }
    if (attachments) {
      if (attachments.length > 5) {
        errors.push('Files should be not more then 5 attachments');
      }

      if (!validateAttachments()) {
        errors.push('Files should be not more then 5MB each');
      }
    }
    if (email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!re.test(email)) {
        errors.push('Please check email format');
      }
    }

    setError(errors);
    return errors.length === 0;
  };

  const sendForm = async () => {
    if (validate()) {
      setLoading(true);

      const data = {
        type: submissionType,
        description,
        email,
        attachment: attachments
      };

      sendFeedback(data)
        .then(handleSuccess)
        .catch(handleSendError);
    }
  };

  const renderError = errorText => (<s.Alert>{errorText}</s.Alert>);
  const typeValue = options.find(option => option.value === submissionType);

  return (
    <s.StyledPopup className={className}>
      <s.FeedbackItem>
        <Text>
          1. Choose type of submission:
        </Text>
        <Select
          name="FeedbackType"
          onChange={handleSelectSubmissionType}
          options={options}
          value={typeValue}
        />
      </s.FeedbackItem>
      <s.FeedbackItem>
        <Text>
          2. Describe the issue*:
        </Text>
        <Textarea
          value={description}
          onChange={handleDescriptionChange}
        />
      </s.FeedbackItem>
      <s.FeedbackItem>
        <Text>
          3. Attach file (&lt;= 5MB):
        </Text>
        <input
          id="FeedbackAttachment"
          multiple
          name="file"
          onChange={handleAttachmentChange}
          type="file"
        />
      </s.FeedbackItem>
      <s.FeedbackItem>
        <Text>
          4. Email (if you want the answer):
        </Text>
        <Input
          onChange={handleEmailChange}
          type="text"
          value={email}
          withBorder
        />
      </s.FeedbackItem>
      {error.length > 0
        ? error.map(renderError)
        : null
      }
      {success && (<Text>{success}</Text>)}
      <s.Buttons>
        <Button
          color="ghost"
          label="Cancel"
          onClick={handleCancelClick}
        />
        <s.StyledButton
          color="normal"
          label={sent ? 'Sent' : 'Send'}
          onClick={sendForm}
          disabled={sent}
          loading={loading}
        />
      </s.Buttons>
    </s.StyledPopup>
  );
};

Feedback.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

Feedback.defaultProps = {
  className: ''
};

export default Feedback;
