import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  iconTypeMap
} from '../../../constants/constants';

import EditLocationModal from './EditLocationModal/EditLocationModal';

import s from './ListItemLocation.style';

const ListItemLocation = (props) => {

  const {
    index,
    manikin,
    onItemToggle,
    opened,
    style
  } = props;

  const {
    adultId,
    assetId,
    facility,
    hospitalId,
    infantId,
    laptopId,
    laptopModel,
    location,
    program,
    stationId
  } = manikin;

  const itemRef = useRef();

  const toggle = (event) => {
    const { target } = event;
    const ignoreClick = Array.from(document.querySelectorAll('.ignoreClick').values())
      .find(el => el.contains(target));
    const failureTypeCell = itemRef.current.querySelector('.FailureTypeCell');

    if (target.type === 'button' || ignoreClick) {
      return;
    }

    // Pass hospital id to the top to avoid extra searching
    onItemToggle(assetId, index, { hospitalId });
  };

  useEffect(() => {
    if (itemRef) {
      itemRef.current.addEventListener('click', toggle);
    }

    return () => {
      itemRef.current.removeEventListener('click', toggle);
    };
  }, []);

  const [editLocationModalOpened, setEditLocationWindowOpened] = useState(false);

  const renderManikinType = () => {
    const type = manikin.type || 'not-defined';
    const icon = iconTypeMap[type];

    return (
      <s.ManikinType>
        <img src={icon} alt={`${program} ${type}`} />
        {program}
      </s.ManikinType>
    );
  };

  const toggleEditLocationModal = () => setEditLocationWindowOpened(!editLocationModalOpened);

  return (
    <s.ListItem
      ref={itemRef}
      className="ListItem"
      style={{
        ...style,
        overflow: 'visible'
      }}
      opened={opened}
    >
      <s.Content>
        <s.Cell manikinType>
          {renderManikinType()}
        </s.Cell>
        <s.Cell facility className="ListItem__column">
          {facility || '-'}
        </s.Cell>
        <s.Cell location className="ListItem__column">
          {location || '-'}
        </s.Cell>
        <s.Cell className="ListItem__column">
          {assetId}
        </s.Cell>
        <s.Cell className="ListItem__column">
          {stationId || '-'}
        </s.Cell>
        <s.Cell className="ListItem__column">
          {laptopId || '-'}
        </s.Cell>
        <s.Cell className="ListItem__column">
          {adultId || '-'}
        </s.Cell>
        <s.Cell className="ListItem__column">
          {infantId || '-'}
        </s.Cell>
        <s.Cell className="ListItem__column">
          {laptopModel || '-'}
        </s.Cell>
        <s.Cell editBtn className="ListItem__column ignoreClick">
          <s.EditButton
            className="ignoreClick"
            color="normal"
            label="Edit"
            onClick={toggleEditLocationModal}
          />
          {editLocationModalOpened && (
            <EditLocationModal
              assetId={assetId}
              facility={facility}
              cartLocation={location}
              hospitalId={hospitalId}
              onCancel={toggleEditLocationModal}
              onUpdate={() => {}}
              open={editLocationModalOpened}
            />
          )}
        </s.Cell>
      </s.Content>
    </s.ListItem>
  );
};

ListItemLocation.propTypes = {
  manikin: PropTypes.object.isRequired,
  style: PropTypes.object,
  opened: PropTypes.bool.isRequired,
  onItemToggle: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

ListItemLocation.defaultProps = {
  style: {}
};

const mapStateToProps = state => ({
  manicDataReducer: Object.assign({}, state.manicDataReducer)
});

export default connect(mapStateToProps)(ListItemLocation);
