import * as React from 'react';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import './custom.css';
import Login from './ui/login/Login';
import AppRouter from './AppRouter';
import { PublicClientApplication } from '@azure/msal-browser';

const App = ({ instance }: { instance: PublicClientApplication }) => {
  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <AppRouter />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="h-100-per">
          <Login instance={instance} />
        </div>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
