import styled from 'styled-components';

const style = {};

style.Content = styled.div`
  display: flex;
  padding: 0 34px;
`;


style.Cell = styled.div`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.regular};
  justify-content: flex-start;

  ${({ label }) => label && `
    flex: 0 1 200px;
    min-width: 100px;
    margin-right: 17px;
  `}

  ${({ value }) => value && `
    flex: 0 1 70px;
    min-width: 70px;
  `}

`;

style.ListItem = styled.div`
  background-color: ${({ theme, opened }) => (opened ? theme.greyPalette.grey6 : theme.primaryBackground)};
  border-bottom: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : theme.greyPalette.grey6
  )};
  border-top: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : 'transparent'
  )};
  border-left: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : 'transparent'
  )};
  border-right: 1px solid ${({ theme, opened }) => (
    opened ? theme.secondaryColorPalette.light1 : 'transparent'
  )};

  color: ${props => props.theme.greyPalette.black};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fontSize.medium};
  height: 32px;
  justify-content: center;

  &:hover {
    background-color: ${props => props.theme.greyPalette.grey6};
  }
`;

export default style;
