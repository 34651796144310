import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { uniqBy } from 'lodash';

import AssetsListContainer from '../listview/AssetsList/AssetsListContainer';
import Send from '../listview/Send';
import Title from '../../components/Texts/Title';

import {
  HospitalShape,
  ManikinShape
} from '../../constants/types';
import {
  manikinFromStartScreen,
  manikinHandled,
  sortFunctions
} from '../../utility/manikin';
import { setCurrentAlarmsAction } from '../../actions/user';

import s from './DailyReport.style';
import { setLastSelectedHospitalAction } from '../../actions/filters';
import { ALARM_TYPES, PROGRAM } from '../../constants/constants';

const countHandledDailyAlarms = (manikins) => {
  const today = DateTime.local();

  return manikins.filter((manikin) => {
    const { action } = manikin;
    const actionDate = DateTime.fromISO(action.actionDate);

    return action.fromStartScreen && today.hasSame(actionDate, 'day');
  }).length;
};

const manikinPassesStates = (manikin, states, hospitals) => {
  if (!states.length) {
    return true;
  }

  const { hospitalId } = manikin;
  const hospital = hospitals.find(h => h.hospitalId === hospitalId);
  const { usState } = hospital.location;

  return states.includes(usState);
};

// So far there are 3 types of hospitals accepted for daily alerts:
// 1. Not 'Healthstream' hospitals
// 2. isVAHospital === false
// 3. Not it Zone51 (has address)
const getAcceptedForDailyAlertsHospitals = hospitals => hospitals.filter((h) => {
  const {
    isVAHospital,
    placeName
  } = h;

  // Default location is 'Zone 51'
  const location = h.location || {
    lat: 36.643767,
    lng: -116.395984,
    usState: 'NV'
  };

  const {
    lat,
    lng,
    usState
  } = location;
  return placeName.toLowerCase().indexOf('healthstream') < 0
    && !isVAHospital
    && lat !== 36.643767
    && lng !== -116.395984
    && usState !== 'NV';
});

// For 'Daily alerts' we only show alarms:
// 1. With importance raiting !== -1
// 2. Only selected US states if applied before
// 4. Only RQI program assets
// 4. Don't show 'in_repair' manikins
// 5. Only accepted hospitals using getAcceptedForDailyAlertsHospitals
// 6. Don't show if isDecommissioned === true
const getInitialManikins = (manikins, states, hospitals) => {
  const checkStates = Boolean(states.length);
  const acceptedHospitals = getAcceptedForDailyAlertsHospitals(hospitals);
  return manikins
    .filter(manikin => (
      !manikinHandled(manikin)
      // Alarms might be deactivated so importance raiting doesn't make sense
      && manikin.alarms && manikin.alarms.length > 0 && manikin.alarmImportanceRating !== -1
      && (!checkStates || (checkStates && manikinPassesStates(manikin, states, hospitals)))
      && (manikin.program === PROGRAM.RQI)
      && acceptedHospitals.find(h => h.hospitalId === manikin.hospitalId)
      && (!manikin.alarms.find(al => al.alarmType === ALARM_TYPES.IN_REPAIR))
      && !manikin.attrs.isDecommissioned
    ))
    .sort(sortFunctions.alarmImportanceRating);
};

// Don't show if isDecommissioned === true
const appendHospitalManikins = (displayManikins, allManikins, hospitalId) => {
  const displayManikin = displayManikins.find(manikin => manikin.hospitalId === hospitalId);
  const displayManikinIndex = displayManikins.findIndex(manikin => manikin.hospitalId === hospitalId);
  const manikinsToAppend = allManikins.filter(manikin => (
    manikin.hospitalId === hospitalId
    && manikin.assetId !== displayManikin.assetId
    && !manikin.isDecommissioned
  ));

  manikinsToAppend.sort(sortFunctions.firmwareVersionAsc);

  return displayManikins
    .slice(0, displayManikinIndex + 1)
    .concat(manikinsToAppend)
    .concat(
      displayManikins.slice(displayManikinIndex + 1)
    );
};

const getAlertManikins = (manikins, selectedHospitalId, size, states, hospitals) => {
  let displayManikins = getInitialManikins(manikins, states, hospitals).slice(0, size);

  if (selectedHospitalId) {
    displayManikins = appendHospitalManikins(displayManikins, manikins, selectedHospitalId);
  }

  return displayManikins;
};

const getHistoryManikins = (manikins, states, hospitals) => {
  const checkStates = Boolean(states.length);

  return manikins
    .filter(manikin => (
      manikinHandled(manikin)
      && manikinFromStartScreen(manikin)
      && (!checkStates || (checkStates && manikinPassesStates(manikin, states, hospitals)))
      && !manikin.attrs.isDecommissioned
    ));
};

const DailyReport = (props) => {
  const {
    loading,
    manikins,
    hospitals,
    dailyAlarmsSetting,
    currentAlarms,
    preferredStatesSetting,
    setCurrentAlarms,
    setLastSelectedHospitalId
  } = props;

  const decrement = countHandledDailyAlarms(manikins);
  const getAlarmsToShowNumber = value => (value < 0 ? 0 : value);
  const [selectedHospitalId, setSelectedHospitalId] = useState('');
  const [alarmsToShow, setAlarmsToShow] = useState(getAlarmsToShowNumber(currentAlarms - decrement));
  const alertManikins = useMemo(() => getAlertManikins(
    manikins,
    selectedHospitalId,
    alarmsToShow,
    preferredStatesSetting,
    hospitals
  ), [manikins, selectedHospitalId, alarmsToShow, preferredStatesSetting, hospitals]);

  const historyManikins = useMemo(
    () => getHistoryManikins(manikins, preferredStatesSetting, hospitals),
    [manikins, preferredStatesSetting, hospitals]
  );

  const dailyReportManikins = useMemo(
    () => uniqBy(historyManikins.concat(...alertManikins), m => m.assetId),
    [historyManikins, alertManikins]
  );

  useEffect(() => {
    setAlarmsToShow(getAlarmsToShowNumber(currentAlarms - decrement));
  }, [loading, currentAlarms]);

  const handleHospitalSelect = (hospitalId) => {
    if (hospitalId === selectedHospitalId) {
      setSelectedHospitalId('');
    } else {
      setSelectedHospitalId(hospitalId);
    }
    // Store filter should not be toggled (always set)
    setLastSelectedHospitalId(hospitalId);
  };

  const renderMoreBtn = () => (
    <s.MoreBtn
      color="outline"
      label={`Get ${dailyAlarmsSetting} more alerts`}
      onClick={() => {
        const size = decrement + dailyAlarmsSetting;
        setCurrentAlarms(size);
      }}
    />
  );

  return (
    <s.Root>
      <s.Header>
        <Title>
            Daily Alerts
        </Title>
        <Send
          manikins={dailyReportManikins}
          startScreen
        />
      </s.Header>
      <AssetsListContainer
        manikins={alertManikins}
        onHospitalSelect={handleHospitalSelect}
        loading={loading}
      />
      {
        alarmsToShow === 0 && !loading
          ? renderMoreBtn()
          : null
      }

      <s.HistoryHeader>
        <Title>Alerts history</Title>
      </s.HistoryHeader>
      <AssetsListContainer
        loading={loading}
        manikins={historyManikins}
        onHospitalSelect={Function.prototype}
        useLastRowWorkaround
      />
    </s.Root>
  );
};

DailyReport.propTypes = {
  loading: PropTypes.bool.isRequired,
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
  hospitals: PropTypes.arrayOf(HospitalShape).isRequired,
  dailyAlarmsSetting: PropTypes.number.isRequired,
  currentAlarms: PropTypes.number.isRequired,
  preferredStatesSetting: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCurrentAlarms: PropTypes.func.isRequired,
  setLastSelectedHospitalId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : [],
  hospitals: state.manicDataReducer.data ? state.manicDataReducer.data.hospitals : [],
  dailyAlarmsSetting: state.user.settings.alarmsPerDay,
  currentAlarms: state.user.currentAlarms,
  preferredStatesSetting: state.user.settings.preferredStates
});

const mapDispatchToProps = dispatch => ({
  setCurrentAlarms: numberOfAlarms => dispatch(setCurrentAlarmsAction(numberOfAlarms)),
  setLastSelectedHospitalId: hospitalId => dispatch(setLastSelectedHospitalAction(hospitalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyReport);
