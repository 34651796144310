import styled from 'styled-components';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Caption from '../../components/Texts/Caption';

const style = {};

style.Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.laerdalPalette.background};
`;

style.MainLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 20vh;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;

  img {
    max-width: 330px;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`;

style.Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 264px;

  .loader {
    margin: 1rem auto;
    width: 42px !important;
    height: 42px !important;
    border-width: 1px !important;
  }
`;

style.LoginButton = styled(Button)`
  margin: 10px 0 5px;
`;

style.LoginOffice365Button = styled(Button)`
  margin: 10px 0 60px;
  border: 1px solid;
  border-color: grey;
  img {
    width: 120px;
  }
`;

style.Logo = styled.div`
  width: 116px;
  margin: 0 auto;
`;

style.BottomBlock = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 20vh;
  justify-content: space-between;
  left: 0;
  padding: 0 4rem;
  position: absolute;
  width: 100%;

  img {
    width: auto;
    height: auto;

    &.laerdalLogo {
      max-width: 214px;
      max-height: 112px;
    }
    &.ahaLogo {
      max-width: 145px;
      max-height: 87px;
    }
  }
`;

style.LogoContainer = styled.div`
  width: 241px;

  &:first-of-type img {
    margin-left: -10px;
  }
  &:last-of-type {
    text-align: right;
  }
`;

style.Motto = styled.div`
  flex: 1 1 calc(100% - 482px);
  text-align: center;
  font-size: 2.5rem;
`;

style.StyledCaption = styled(Caption)`
  color: ${({ theme }) => theme.laerdalPalette.primary.default};
  text-align: left;
  margin-top: 10px;
`;

style.StyledInput = styled(Input)`
  margin: 10px 0;
`;

export default style;
