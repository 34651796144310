import axios from 'axios';
import conf from '../resources/config.json';
import {
  SENT_DATA_REQUEST,
  REQUEST_TOKEN,
  RESPOMS_TOKEN,
  ERROR_TOKEN,
  RECEIVE_DATA,
  SET_ASSET_UPDATE,
  REMOVE_ASSET_UPDATE,
  RESET_UPDATES,
  RESET_ASSET_ALARM,
  SET_ASSET_ALARMS,
  SET_SETTINGS,
  UPDATE_ASSET_ATTRS,
  SET_ASSET_IS_DECOMMISSIONED,
  REFRESH_HOSPITAL_ALARMS,
  SET_KEYS,
  SET_AVAILABLE_COUNTRIES,
} from '../constants/constants';
import { ManikinDecorator } from '../utility/manikin';
import { getSettings, requestData, getKeys } from '../api/api';

import { msalConfig } from '../index';

// Logout user. No need to wait just call and forget.
export const logout = (username) => {
  msalConfig.msalInstance.logoutRedirect();
};

export const toggleSelect =
  (
    id,
    data = {
      targetData: null,
      dataList: [],
    },
  ) =>
  (dispatch) => {
    dispatch({
      type: 'UPDATE_REQ_DATA_MANIC',
      payload: data.dataList.map((item, index) => {
        if (id === index) {
          return data.targetData;
        }
        return item;
      }),
    });
  };

export const setAssetUpdateAction = (assetId, update) => (dispatch) =>
  dispatch({
    type: SET_ASSET_UPDATE,
    assetId,
    update,
  });
export const removeAssetUpdateAction = (assetId) => (dispatch) =>
  dispatch({
    type: REMOVE_ASSET_UPDATE,
    assetId,
  });
export const resetAssetUpdates = () => (dispatch) =>
  dispatch({
    type: RESET_UPDATES,
  });

export function decorateManikins(rawManikins) {
  const manikinDecorator = new ManikinDecorator();
  return rawManikins.map((manikin) => manikinDecorator.decorate(manikin));
}

export const getData = () => async (dispatch) => {
  dispatch({
    type: SENT_DATA_REQUEST,
  });

  let receivedData = {
    manikins: [],
    hospitals: [],
  };

  try {
    const response = await requestData();
    receivedData = response.data;
  } catch (error) {
    console.error(error);
  }

  const { hospitals, manikins } = receivedData;

  dispatch({
    type: RECEIVE_DATA,
    payload: {
      manikins: decorateManikins(manikins),
      hospitals,
    },
  });

  const countries = hospitals.map(h=> h.location.country)
    .filter((v, i , a) => a.indexOf(v) == i)
    .sort()
    .map(x=> { return { value: x, label: x };}) ?? null;
  dispatch({
    type: SET_AVAILABLE_COUNTRIES,
    availableCountries:  countries    
  })
};

export const getKeysAction = () => async (dispatch) => {
  try {
    const response = await getKeys();
    const { googleApiKey } = response.data;
    dispatch({
      type: SET_KEYS,
      googleApiKey,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Error getting keys. ${e}`);
  }
};

export const getSettingsAction = () => async (dispatch) => {
  try {
    const response = await getSettings();
    const { settings, email } = response.data;

    dispatch({
      type: SET_SETTINGS,
      email,
      settings,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Error getting settings. ${e}`);
  }
};

export const resetAlarmAction = (assetId, alarmTypeId) => (dispatch) =>
  dispatch({
    type: RESET_ASSET_ALARM,
    assetId,
    alarmTypeId,
  });

export const setAlarmsAction = (assetId, alarms) => (dispatch) =>
  dispatch({
    type: SET_ASSET_ALARMS,
    assetId,
    alarms,
  });

export const refreshHospitalAlarmsAction = (hospitalId) => (dispatch) =>
  dispatch({
    type: REFRESH_HOSPITAL_ALARMS,
    hospitalId,
  });

export const setAssetIsDecommissionedAction = (assetId) => (dispatch) =>
  dispatch({
    type: SET_ASSET_IS_DECOMMISSIONED,
    assetId,
  });

export const updateAssetAttributesAction = (attrs) => (dispatch) => dispatch({ type: UPDATE_ASSET_ATTRS, attrs });
export * from './repairReport';
