import {
  ERROR_FACILITY_LIST_REQUEST,
  ERROR_SET_ASSET_ATTR_REQUEST,
  RECEIVED_FACILITY_LIST,
  RECEIVED_SET_ASSET_ATTR_REQUEST,
  SENT_FACILITY_LIST_REQUEST,
  SENT_SET_ASSET_ATTR_REQUEST,
  SHOW_DL_DIALOG,
} from '../constants/constants';

const defaultState = {
  error: false,
  data: [],
  hospitalId: null,
  isSavingData: false,
  loading: false,
  message: '',
  showDlDialog: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_DL_DIALOG:
      return {
        ...state,
        showDlDialog: action.payload,
      };
    case SENT_FACILITY_LIST_REQUEST:
      return {
        ...defaultState,
        showDlDialog: state.showDlDialog,
        hospitalId: action.payload,
        loading: true,
      };
    case SENT_SET_ASSET_ATTR_REQUEST:
      return {
        ...defaultState,
        showDlDialog: state.showDlDialog,
        isSavingData: true,
      };
    case RECEIVED_FACILITY_LIST:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case RECEIVED_SET_ASSET_ATTR_REQUEST:
      return {
        ...state,
        showDlDialog: false,
        loading: false,
        error: false,
        data: action.payload,
      };
    case ERROR_FACILITY_LIST_REQUEST:
    case ERROR_SET_ASSET_ATTR_REQUEST:
      return {
        ...defaultState,
        showDlDialog: state.showDlDialog,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};
