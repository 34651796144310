import {
  SENT_REPAIR_REPORT_DATA_REQUEST,
  RECEIVED_REPAIR_REPORT_DATA_REQUEST,
  ERROR_REPAIR_REPORT_DATA_REQUEST
} from '../constants/constants';
import { getRepairReportData } from '../api/api';

const raiseError = (data, type, message, dispatch) => {
  const msg = `${message} ${data}`;
  // eslint-disable-next-line no-console
  console.warn(msg);
  dispatch({
    type,
    payload: msg
  });
};

export const getRepairReportDataAction = assetRepairId => async (dispatch) => {
  dispatch({
    type: SENT_REPAIR_REPORT_DATA_REQUEST,
    payload: assetRepairId
  });

  const setError = data => raiseError(data, ERROR_REPAIR_REPORT_DATA_REQUEST, 'Error getting Repair report related data.', dispatch);

  try {
    const res = await getRepairReportData(assetRepairId);
    if (res.status === 200) {
      dispatch({
        type: RECEIVED_REPAIR_REPORT_DATA_REQUEST,
        payload: res.data
      });
    } else {
      setError(res.data);
    }
  } catch (err) {
    setError((err.response && err.response.data && err.response.data.message) || err);
  }
};

export default getRepairReportDataAction;
