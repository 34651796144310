import { connect } from 'react-redux';
import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { IoIosWarning } from 'react-icons/io';

import Caption from '../../../../components/Texts/Caption';
import { getHospitalLocationAction, moveManikinsToHospitalAction } from '../../../../actions/descriptiveLocation';
import HospitalSelect from './HospitalSelect';
import { HospitalShape, ManikinShape } from '../../../../constants/types';
import Modal from '../../../../components/Modal';
import s from './EditLocationModal.style';
import { sortFunctions, getHospitalManikinsCounters } from '../../../../utility/hospital';
import Text from '../../../../components/Texts/Text';
import { decommissionHospital } from '../../../../api/api';
import { ALARM_TYPES_DIC } from '../../../../constants/constants';
import { refreshHospitalAlarmsAction, setAlarmsAction } from '../../../../actions';


const DecommissionHospitalModal = (props) => {
  const {
    hospitalId,
    hospitals,
    loadHospitalLocation,
    manikins,
    moveManikinsToHospital,
    onCancel,
    onConfirm,
    onSuccess,
    open,
    refreshHospitalAlarms,
    setAssetAlarms
  } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sourceHospitalId, setSourceHospitalId] = useState(hospitalId);

  const hospitalsToSelect = useMemo(
    () => hospitals.filter(h => h.hospitalId !== sourceHospitalId),
    [hospitals]
  );

  const [destinationHospitalId, setDestinationHospitalId] = useState(
    hospitalsToSelect.sort(sortFunctions.placeName).find(h => h.placeName).hospitalId
  );

  const sourceHospital = useMemo(
    () => hospitals.find(h => h.hospitalId === sourceHospitalId),
    [sourceHospitalId]
  );

  const hospitalManikinsCount = useMemo(
    () => getHospitalManikinsCounters(sourceHospital && sourceHospital.manikins).total,
    [sourceHospital]
  );

  const destinationHospital = useMemo(
    () => hospitals.find(h => h.hospitalId === destinationHospitalId),
    [destinationHospitalId, hospitals]
  );

  // Preload location for the first item
  useEffect(() => {
    if (destinationHospital && (!destinationHospital.location || !destinationHospital.location.address)) {
      loadHospitalLocation(destinationHospitalId);
    }
  }, [destinationHospital]);

  const handleHospitalChange = (hospital) => {
    setDestinationHospitalId(hospital.hospitalId);
  };

  const onConfirmChanges = async () => {
    setError('');


    if (success) {
      onConfirm();
      return;
    }

    setLoading(true);

    const manikinsToMove = manikins.filter(m => m.hospitalId === sourceHospitalId);

    try {
      const res = await decommissionHospital(
        manikinsToMove.map(m => m.assetId),
        destinationHospitalId
      );

      moveManikinsToHospital(manikinsToMove, destinationHospitalId);

      // Update hospital alerts
      const assetAlarms = res.data;

      if (assetAlarms) {
        manikinsToMove.forEach(m => setAssetAlarms(
          m.assetId,
          assetAlarms.filter(aal => aal.assetId === m.assetId)
            .map(aal => ({
              alarmDescription: aal.alarmDescription,
              alarmType: ALARM_TYPES_DIC[aal.alarmType]
            }))));
      }

      refreshHospitalAlarms(sourceHospitalId);
      refreshHospitalAlarms(destinationHospitalId);

      setSuccess(true);
      onSuccess(destinationHospitalId);

    } catch (err) {
      setError(`Decommission error: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const {
    placeName
  } = sourceHospital;

  return (
    <Modal
      cancelButtonDisabled={loading}
      cancelButtonVisible={!success}
      confirmButtonDisabled={loading}
      confirmButtonText={success ? 'Close' : 'Move and decommission'}
      loading={loading}
      maxHeight="420px"
      height="100%"
      open={open}
      onCancel={onCancel}
      onConfirm={() => onConfirmChanges()}
      title={`Decommission ${placeName}`}
      width={800}
    >
      <s.Row>
        <s.Cell>
          <Text>
            {sourceHospital.placeName} contains <b>{hospitalManikinsCount} manikin(s)</b>
            &nbsp;which should be moved to another site. Please select a new site to accommodate the manikin(s).
          </Text>
        </s.Cell>
      </s.Row>
      <s.Row>
        <s.Cell>
          <Text>
            <s.IconWarningCont>
              <s.IconWarningBg />
              <IoIosWarning />
            </s.IconWarningCont>
            &nbsp;Please note that Facility and Cart location of these manikins will be erased,
            while Station serial and Laptop serial, model and sim card data will be retained
          </Text>
        </s.Cell>
      </s.Row>
      <s.Row>
        <s.Cell w="auto">
          <Caption>
            Hospital
          </Caption>
          <HospitalSelect
            hospitalId={destinationHospitalId}
            hospitals={hospitalsToSelect}
            isCreatable={false}
            isDisabled={loading || success}
            isLoading={loading}
            loadHospitalLocation={loadHospitalLocation}
            onHospitalChange={handleHospitalChange}
            showDecommissionButton={false}
          />
        </s.Cell>
      </s.Row>
      <s.Row>
        <s.Cell w="auto">
          <s.MessageCont>
            {error && <s.ErrorText error>{error}</s.ErrorText>}
            {success && <s.SuccessText>Site has been successfully decommissioned.</s.SuccessText>}
          </s.MessageCont>
        </s.Cell>
      </s.Row>
    </Modal>
  );
};

DecommissionHospitalModal.propTypes = {
  hospitalId: PropTypes.number,
  hospitals: PropTypes.arrayOf(HospitalShape).isRequired,
  loadHospitalLocation: PropTypes.func.isRequired,
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
  moveManikinsToHospital: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool,
  refreshHospitalAlarms: PropTypes.func.isRequired,
  setAssetAlarms: PropTypes.func.isRequired
};

DecommissionHospitalModal.defaultProps = {
  hospitalId: null,
  open: false
};

const mapStateToProps = state => ({
  hospitals: state.manicDataReducer.data ? state.manicDataReducer.data.hospitals : [],
  manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : []
});


const mapDispatchToProps = dispatch => ({
  loadHospitalLocation: hospitalId => dispatch(getHospitalLocationAction(hospitalId)),
  moveManikinsToHospital: (manikins, newHospitalId) => dispatch(moveManikinsToHospitalAction(manikins, newHospitalId)),
  refreshHospitalAlarms: hospitalId => dispatch(refreshHospitalAlarmsAction(hospitalId)),
  setAssetAlarms: (assetId, alarms) => dispatch(setAlarmsAction(assetId, alarms))
});

export default connect(mapStateToProps, mapDispatchToProps)(DecommissionHospitalModal);
