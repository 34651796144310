import React from 'react';
import PropTypes from 'prop-types';

import HeaderCell, { StyledHeader } from './HeaderCell';

const HeaderNormal = (props) => {
  const {
    ageHeaderContent,
    approved,
    compressionsHeaderContent,
    handleHeaderClick,
    rejected,
    sortDirection,
    sortType
  } = props;
  return (
    <StyledHeader>
      <HeaderCell
        sortType="failureType"
        sortDirection={sortDirection}
        onClick={handleHeaderClick}
        isCurrentSortType={sortType === 'failureType'}
        headerText="Failure type"
        alignCenter
        width={120}
        minWidth={100}
      />
      <HeaderCell
        sortType="hospital"
        sortDirection={sortDirection}
        onClick={handleHeaderClick}
        isCurrentSortType={sortType === 'hospital'}
        headerText="Hospital"
        width={260}
        minWidth={150}
        grow={1}
      />
      <HeaderCell
        centered
        headerText="Type"
        isCurrentSortType={sortType === 'manikinType'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="manikinType"
        width={120}
      />
      <HeaderCell
        centered
        headerText="Facility"
        isCurrentSortType={sortType === 'facility'}
        minWidth={120}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="facility"
        width={140}
      />
      <HeaderCell
        centered
        headerText="Cart location"
        isCurrentSortType={sortType === 'cartLocation'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="cartLocation"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Serial #"
        isCurrentSortType={sortType === 'assetId'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="assetId"
        width={120}
      />
      <HeaderCell
        centered
        headerText="Last maintenance"
        isCurrentSortType={sortType === 'maintenance'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="maintenance"
        width={150}
      />
      <HeaderCell
        centered
        headerText={ageHeaderContent}
        isCurrentSortType={sortType === 'age'}
        minWidth={60}
        onClick={handleHeaderClick}
        shrink={2}
        sortDirection={sortDirection}
        sortType="age"
        width={140}
      />
      <HeaderCell
        centered
        headerText={compressionsHeaderContent}
        isCurrentSortType={sortType === 'compressions'}
        minWidth={60}
        onClick={handleHeaderClick}
        shrink={2}
        sortDirection={sortDirection}
        sortType="compressions"
        width={140}
      />
      <HeaderCell
        headerText={<>Approved: <span>{approved}</span></>}
        width={170}
        shrink={0}
        pointer={false}
      />
      <HeaderCell
        headerText={<>Rejected: <span>{rejected}</span></>}
        width={110}
        shrink={0}
        pointer={false}
      />
      <HeaderCell
        width={45}
        shrink={0}
      />
    </StyledHeader>
  );
};

HeaderNormal.propTypes = {
  ageHeaderContent: PropTypes.element.isRequired,
  approved: PropTypes.number.isRequired,
  compressionsHeaderContent: PropTypes.element.isRequired,
  handleHeaderClick: PropTypes.func.isRequired,
  rejected: PropTypes.number.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired
};

export default HeaderNormal;
