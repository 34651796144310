import { connect } from 'react-redux';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { getRepairReportDataAction, refreshHospitalAlarmsAction, setAlarmsAction } from '../../../../actions';
import { sendRepairReport } from '../../../../api/api';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import Modal, {
  Footer,
  HiddenLabel,
  LabelCont,
  LoaderCont,
  StyledButton
} from '../../../../components/Modal/Modal';
import Text from '../../../../components/Texts/Text';
import Textarea from '../../../../components/Textarea';
import {
  MANIKIN_PART_GROUP_TYPE,
  MULTI_VALUE_OPTIONS,
  MULTI_VALUE_FLAG_DIC,
  ALARM_TYPES_DIC
} from '../../../../constants/constants';
import { RepairReportDataShape, ManikinShape, HospitalShape } from '../../../../constants/types';
import ManikinPartGroup from './ManikinPartGroup';
import {
  createNewReplacedPartsObj,
  filterAssetParts,
  filterFirmwareRepairReasonPart,
  filterFirmwareReplacementParts,
  filterFirmwareReplacementTypes
} from '../../../../utility/repairReport';

import s from './RepairReportModal.style';
import { getHospitalLocationAction, handleAssetAttributesResultAction } from '../../../../actions/descriptiveLocation';
import HospitalSelect from '../../ListItem/EditLocationModal/HospitalSelect';
import { sortFunctions } from '../../../../utility/hospital';
import { setFilterHospitalHighlightAction, setPreSelectedAssetAction, setTextFilterAndResetOtherAction } from '../../../../actions/filters';

const getRepairCenters = hospitals => hospitals.filter(m => m.isRepairCenter).sort(sortFunctions.placeName);

const repairReportModalTitles = [
  'Step 1 - General',
  'Step 2 - Failure check in workshop',
  'Step 3 - Parts of manikin referenced by support call or on-site review',
  'Step 4 - Replaced parts'
];

const RepairReportModal = (props) => {
  const {
    error,
    hospitals,
    loading,
    loadHospitalLocation,
    loadRepairReportData,
    manikin,
    moveToARepairCenter,
    onCloseClick,
    open,
    refreshHospitalAlarms,
    repairReportData,
    setAssetAlarms,
    setTextFilterAndResetOther
  } = props;

  const {
    assetId,
    hospitalId,
    repairId
  } = manikin;

  const [description, setDescription] = useState('');
  const [isIssueReported, setIsIssueReported] = useState('');
  const [isManikinFunctional, setIsManikinFunctional] = useState('');
  const [stockAcceptancePassedDate, setStockAcceptancePassedDate] = useState('');
  const [repairReason, setRepairReason] = useState('');
  const [isSaving, setIsSaving] = useState('');
  const [savingError, setSavingError] = useState('');
  const [success, setSuccess] = useState('');
  const [repairReasonParts, setRepairReasonParts] = useState([]);
  const [replacedParts, setReplacedParts] = useState(null);
  const [wizardStep, setWizardStep] = useState(0);
  const [repairCenterId, setRepairCenterId] = useState(-1);

  const assetTypeId = manikin.type;
  const currentError = (error || savingError).substr(0, 200);

  const repairCenterHospitals = useMemo(() => getRepairCenters(hospitals), [hospitals]);

  useEffect(() => {
    let repairCenterIndex = repairCenterHospitals.findIndex(m => m.hospitalId === hospitalId);
    if (repairCenterIndex < 0) {
      repairCenterIndex = repairCenterHospitals.length > 0 ? 0 : -1;
    }

    setRepairCenterId(repairCenterIndex < 0 ? -1 : repairCenterHospitals[repairCenterIndex].hospitalId);
  }, [repairCenterHospitals]);

  useEffect(() => {
    const repairCenterHospital = repairCenterHospitals.find(m => m.hospitalId === repairCenterId);

    if (repairCenterHospital && (!repairCenterHospital.location || !repairCenterHospital.location.address)) {
      loadHospitalLocation(repairCenterId);
    }
  }, [repairCenterHospitals, repairCenterId]);

  useEffect(() => {
    if (open) {
      setSuccess('');
      setRepairReason('');
      setStockAcceptancePassedDate('');
      if (!repairReportData || repairId) {
        loadRepairReportData(repairId, repairReportData);
      } else {
        setReplacedParts(createNewReplacedPartsObj(repairReportData.replacementType));
      }
    }
  }, [open]);

  useEffect(() => {
    if (!loading && repairReportData) {
      if (repairId && repairReportData.repairReport) {
        const { repairReport } = repairReportData;
        if (repairReport.assetRepairId === repairId) {
          // Set all data by the date received
          setDescription(repairReport.description);
          setIsManikinFunctional(MULTI_VALUE_FLAG_DIC[repairReport.isManikinFunctional]);
          setIsIssueReported(MULTI_VALUE_FLAG_DIC[repairReport.isReproduced]);
          setRepairReason(repairReport.repairReason);
          setRepairReasonParts(repairReport.repairReasonParts);
          setReplacedParts(Object.assign(
            createNewReplacedPartsObj(repairReportData.replacementType),
            repairReport.replacedParts));
          setStockAcceptancePassedDate(DateTime.fromISO(repairReport.acceptanceTestDate).toFormat('yyyy-MM-dd'));
        }
      } else if (!replacedParts) {
        setReplacedParts(createNewReplacedPartsObj(repairReportData.replacementType));
      }
    }
  }, [loading]);

  const send = async () => {
    setIsSaving(true);

    const invertedMultiValueFlagDic = _.invert(MULTI_VALUE_FLAG_DIC);

    const repairReportToSend = {
      acceptanceTestDate: stockAcceptancePassedDate,
      assetId: manikin.assetId,
      assetRepairId: repairId,
      description,
      isManikinFunctional: invertedMultiValueFlagDic[isManikinFunctional],
      isReproduced: invertedMultiValueFlagDic[isIssueReported],
      repairCenterId,
      repairReason,
      repairReasonParts,
      replacedParts
    };

    try {
      const result = await sendRepairReport(repairReportToSend);
      // Uncomment for getting repair id data out of the manikin
      // manikin.repairId = result.data.data;
      setSuccess('Repair report has been saved successfully');


      // Updated alarms will be returned by the call
      // So far BE should automatically add an 'in_repair' alarm
      if (result.data.assetAlarms) {

        const {
          assetAlarms
        } = result.data;


        if (assetAlarms) {
          setAssetAlarms(
            assetId,
            assetAlarms.map(m => ({
              alarmDescription: m.alarmDescription,
              alarmType: ALARM_TYPES_DIC[m.alarmType]
            }))
          );
        }

        refreshHospitalAlarms(manikin.hospitalId);
        moveToARepairCenter(repairCenterId, manikin);
        refreshHospitalAlarms(repairCenterId);
      }

      setTextFilterAndResetOther(manikin.assetId);
    } catch (e) {
      setSavingError(`Something went wrong. ${e.repsonse && e.response.data && e.response.data.message
        ? e.response.data.message
        : e}`);
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfirmClick = () => {

    if (success || currentError) {
      onCloseClick();
      return;
    }

    if (wizardStep === 3) {
      // Send data to the server
      send();
      return;
    }

    setWizardStep(wizardStep + 1);

    // scoll modal to the top
    document.querySelector('#repairReportModal>div').scrollTop = 0;
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  const handleHospitalChange = hospital => setRepairCenterId(hospital.hospitalId);

  const renderRepairReason = () => repairReportData.repairReason.map(opt => (
    <div key={opt.repairReasonId}>
      <s.RadioButton
        type="radio"
        name="failure"
        value={opt.repairReasonId}
        id={opt.repairReasonId}
        onChange={e => setRepairReason(e.target.value)}
        checked={repairReason === opt.repairReasonId}
      />
      <s.RadioInputLabel htmlFor={opt.repairReasonId}>
        {opt.name}
      </s.RadioInputLabel>
    </div>
  ));

  const renderMultiValueOptions = (groupName, selectedValue, onChange) => MULTI_VALUE_OPTIONS.map(opt => (
    <div key={opt.value}>
      <s.RadioButton
        type="radio"
        name={groupName}
        value={opt.value}
        id={`${groupName}_${opt.value}`}
        onChange={e => onChange(e.target.value)}
        checked={selectedValue === opt.value}
      />
      <s.RadioInputLabel htmlFor={`${groupName}_${opt.value}`}>
        {opt.label}
      </s.RadioInputLabel>
    </div>
  ));

  const handleManikinPartChange = (value, columnValue) => {
    let result = _.clone(columnValue ? replacedParts : repairReasonParts);
    let toggleArr = columnValue ? replacedParts[columnValue] : result;

    if (columnValue) {
      result = Object.fromEntries(
        Object.entries(result).map(([key, tarr]) => [key, tarr.filter(val => val !== value)])
      );
    }

    if (toggleArr.indexOf(value) >= 0) {
      toggleArr = toggleArr.filter(val => val !== value);
    } else {
      toggleArr.push(value);
    }

    if (columnValue) {
      // Firmware can only contain one selected value
      result[columnValue] = value.indexOf('_firmware') > 0
        ? toggleArr.filter(val => val === value || val.indexOf('_firmware') < 0)
        : toggleArr;
      setReplacedParts(result);
    } else {
      setRepairReasonParts(toggleArr);
    }
  };

  const renderManikinPartsBlock = (controlType, assetParts) => assetParts.map(
    (assetPart) => {
      let { assetSubParts } = assetPart;
      const isFirmware = assetPart.assetPartId.endsWith('_firmware');

      // Title for step 4 is different for firmware
      const title = isFirmware && controlType !== MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN
        ? 'Firmware on arrival'
        : assetPart.name;

      const buttonType = controlType === MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN
        ? 'checkbox'
        : 'radio';

      // Firmware has only one column in case of replacement
      // we don't show a header for this column
      // and radio buttons are grouped vertically
      let columns = controlType === MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN
        ? []
        : repairReportData.replacementType;

      // Special case for firmware asset parts/repair reasons
      if (isFirmware) {
        assetSubParts = controlType === MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN
          ? filterFirmwareRepairReasonPart(assetSubParts)
          : filterFirmwareReplacementParts(assetSubParts);
        columns = filterFirmwareReplacementTypes(columns);
      }

      return (
        <ManikinPartGroup
          assetSubParts={assetSubParts}
          buttonType={buttonType}
          columns={columns}
          defaultSelectedItems={controlType === MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN
            ? repairReasonParts
            : replacedParts}
          key={assetPart.assetPartId}
          onToggle={handleManikinPartChange}
          showColumnHeader={!isFirmware}
          title={title}
          type={controlType}
        />);
    });


  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return repairReason;
      case 1:
        return isIssueReported && isManikinFunctional;
      case 2:
        return repairReasonParts.length > 0;
      case 3:
        // There should be at least one item selected && stockAcceptancePassedDate is mandatory
        return Object.values(replacedParts).reduce((len, part) => len || part.length > 0, 0)
          && stockAcceptancePassedDate;
      default:
        return true;
    }
  };

  const renderNextButtonText = () => {
    if (currentError || success) {
      return 'Close';
    }
    return wizardStep < 3 ? 'Next' : 'Save report';
  };

  const renderFooter = () => (
    <Footer>
      <Button
        color="ghost"
        onClick={onCloseClick}
        label="Cancel"
      />
      {wizardStep > 0 && !currentError && !success
        && (
          <StyledButton
            color="normal"
            disabled={loading || isSaving}
            onClick={() => setWizardStep(wizardStep - 1)}
            label={loading || isSaving
              ? (
                <LabelCont>
                  <LoaderCont>
                    <LoadingSpinner />
                  </LoaderCont>
                  <HiddenLabel>Back</HiddenLabel>
                </LabelCont>
              )
              : 'Back'}
          />
        )}
      <StyledButton
        color="normal"
        disabled={(!isStepValid(wizardStep) || loading || isSaving)
          && !(currentError || success)}
        onClick={handleConfirmClick}
        label={loading || isSaving
          ? (
            <LabelCont>
              <LoaderCont>
                <LoadingSpinner />
              </LoaderCont>
              <HiddenLabel>{renderNextButtonText()}</HiddenLabel>
            </LabelCont>
          )
          : renderNextButtonText()}
      />
    </Footer>
  );

  return (
    <Modal
      id="repairReportModal"
      title={`${repairId ? 'Repair' : 'Add repair'} report for ${assetId}`}
      onCancel={onCloseClick}
      open={open}
      showFooter={false}
      width={580}
    >
      <s.StyledSubTitle>{repairReportModalTitles[wizardStep]}</s.StyledSubTitle>
      {wizardStep === 0 && (
        <>
          <s.Row>
            <s.Column fullWidth>
              <s.StyledSubTitle>Repair center *</s.StyledSubTitle>
              <HospitalSelect
                hospitalId={repairCenterId}
                hospitals={repairCenterHospitals}
                isCreatable={false}
                isDisabled={loading}
                isSearchable={false}
                loadHospitalLocation={loadHospitalLocation}
                onHospitalChange={handleHospitalChange}
                showAllOptions
                showDecommissionButton={false}
              />
            </s.Column>
          </s.Row>
          <s.Row>
            <s.Column fullWidth>
              <s.StyledSubTitle>Comments</s.StyledSubTitle>
              <Textarea
                value={description}
                onChange={handleDescriptionChange}
              />
            </s.Column>
          </s.Row>
          <s.Row>
            <s.Column fullWidth>
              <s.RadioButtonGroup>
                <s.StyledSubTitle>Reason for repair *</s.StyledSubTitle>
                {repairReportData && repairReportData.repairReason && renderRepairReason()}
              </s.RadioButtonGroup>
            </s.Column>
          </s.Row>
        </>
      )}
      {wizardStep === 1 && (
        <>
          <s.RadioButtonGroup>
            <s.StyledSubTitle>Was the behavior (issue) reproduced in the lab as expected *</s.StyledSubTitle>
            {renderMultiValueOptions('isreported', isIssueReported, setIsIssueReported)}
          </s.RadioButtonGroup>
          <s.RadioButtonGroup>
            <s.StyledSubTitle>Was the manikin fully functional on arrival? *</s.StyledSubTitle>
            {renderMultiValueOptions('ismanfunc', isManikinFunctional, setIsManikinFunctional)}
          </s.RadioButtonGroup>
        </>
      )}
      {wizardStep === 2
        && renderManikinPartsBlock(
          MANIKIN_PART_GROUP_TYPE.SINGLE_COLUMN,
          filterAssetParts(repairReportData.assetParts, assetTypeId))}
      {wizardStep === 3
        && (
          <>
            {renderManikinPartsBlock(
              MANIKIN_PART_GROUP_TYPE.MULTIPLE_COLUMN,
              filterAssetParts(repairReportData.assetParts, assetTypeId))}
            <s.Row>
              <s.Column>
                <s.StyledSubTitle>Stock acceptance passed *</s.StyledSubTitle>
                <Input
                  type="date"
                  value={stockAcceptancePassedDate}
                  onChange={e => setStockAcceptancePassedDate(e.target.value)}
                  withBorder
                />
              </s.Column>
            </s.Row>
          </>
        )}
      <s.Result>
        {success && (<Text>Your repair report has been saved successfully.</Text>)}
        {currentError && (<Text error>{currentError}</Text>)}
      </s.Result>

      {renderFooter()}

    </Modal>
  );
};

RepairReportModal.propTypes = {
  error: PropTypes.string.isRequired,
  hospitals: PropTypes.arrayOf(HospitalShape).isRequired,
  loadHospitalLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadRepairReportData: PropTypes.func.isRequired,
  manikin: ManikinShape.isRequired,
  moveToARepairCenter: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refreshHospitalAlarms: PropTypes.func.isRequired,
  repairReportData: RepairReportDataShape,
  setAssetAlarms: PropTypes.func.isRequired,
  setTextFilterAndResetOther: PropTypes.func.isRequired
};

RepairReportModal.defaultProps = {
  repairReportData: null
};

const mapStateToProps = state => ({
  hospitals: state.manicDataReducer.data ? state.manicDataReducer.data.hospitals : [],
  error: state.repairReportDataReducer.error,
  loading: state.repairReportDataReducer.loading,
  repairReportData: state.repairReportDataReducer.data
});

const mapDispatchToProps = dispatch => ({
  moveToARepairCenter: (hospitalId, manikin) => (
    dispatch(handleAssetAttributesResultAction({
      attrs: { hospitalId },
      manikin,
      skipSettingAttributes: true
    }))),
  loadHospitalLocation: hospitalId => dispatch(getHospitalLocationAction(hospitalId)),
  loadRepairReportData: (assetRepairId, skipSchemaLoading) => (
    dispatch(getRepairReportDataAction(assetRepairId, skipSchemaLoading))),
  refreshHospitalAlarms: hospitalId => dispatch(refreshHospitalAlarmsAction(hospitalId)),
  setAssetAlarms: (assetId, alarms) => dispatch(setAlarmsAction(assetId, alarms)),
  setTextFilterAndResetOther: assetId => dispatch(setTextFilterAndResetOtherAction(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(RepairReportModal);
