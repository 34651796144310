import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HeaderManikinEventCounter from '../listview/Header/HeaderManikinEventCounter';
import ManikinEventListItem from '../listview/ListItem/ManikinEventListItem/ManikinEventListItem';
import Skeleton from '../../components/Loading/TableSkeleton';
import Title from '../../components/Texts/Title';

import {
  EventCounterDataShape,
  ManikinShape
} from '../../constants/types';

import { getAssetEventCountersAction } from '../../actions/eventCounter';
import { resetAlarmAction } from '../../actions/index';

import s from './ManikinData.style';

const getCurrentManikin = (manikins, manikinId) => manikins.find(m => m.assetId === manikinId) || null;

const extractEventCounterItems = data => data.counters.map(counter => (
  {
    label: data.rules.find(rule => rule.ruleId === counter.ruleId).name,
    value: counter
  }
));

const ManikinData = (props) => {
  const {
    countersLoading,
    countersLoadingError,
    eventCounterData,
    loading,
    loadEventCounterData,
    manikins,
    match,
    resetAlarm
  } = props;

  const [manikin, setManikin] = useState(null);
  const [eventCounterItems, setEventCounterItems] = useState([]);

  const { params: { manikinId: currentManikinId } } = match;
  const manikinId = Number.parseInt(currentManikinId, 10);

  useEffect(() => {
    setManikin(getCurrentManikin(manikins, manikinId));
  }, [match]);

  useEffect(() => {
    loadEventCounterData(manikinId, !eventCounterData || !eventCounterData.rules);
  }, []);

  useEffect(() => {
    if (eventCounterData && eventCounterData.rules && eventCounterData.counters) {
      setEventCounterItems(extractEventCounterItems(eventCounterData));
    }
  }, [eventCounterData]);

  const onConfirmResetCounter = (alarmTypeId) => {
    // reset alarms in case if alarmTypeId is available
    if (alarmTypeId) {
      resetAlarm(manikinId, alarmTypeId);
    }
    // reload counters in case of reset
    loadEventCounterData(manikinId, !eventCounterData || !eventCounterData.rules);
  };

  const renderListContent = () => (
    <s.EventCounterList>
      <HeaderManikinEventCounter />
      <s.EventCounterListContent>
        {eventCounterItems.map((item, index) => (
          <ManikinEventListItem
            assetId={manikinId}
            index={index}
            key={`${item.value.ruleId}`}
            onConfirmResetCounter={alarmTypeId => onConfirmResetCounter(alarmTypeId)}
            style={{}}
            value={item}
          />
        ))}
      </s.EventCounterListContent>
    </s.EventCounterList>
  );

  return (
    <s.Root>
      <s.PageTitle>
        <Title>
          Manikin data for {manikinId}
        </Title>
      </s.PageTitle>
      <s.Cards>
        <s.Card
          fixed
          title="Event counters"
        >
          {
            !countersLoadingError
            && (loading || countersLoading
              ? <Skeleton />
              : renderListContent())
          }
          {
            countersLoadingError
              && (
                <s.ErrorCont>
                  <s.ErrorText error>{countersLoadingError}</s.ErrorText>
                </s.ErrorCont>
              )
          }
        </s.Card>
      </s.Cards>
    </s.Root>
  );
};

ManikinData.propTypes = {
  countersLoading: PropTypes.bool.isRequired,
  countersLoadingError: PropTypes.string.isRequired,
  eventCounterData: EventCounterDataShape.isRequired,
  loading: PropTypes.bool.isRequired,
  loadEventCounterData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
  resetAlarm: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : [],
  countersLoadingError: state.eventCounterDataReducer.error,
  countersLoading: state.eventCounterDataReducer.loading,
  eventCounterData: state.eventCounterDataReducer.data,
  loading: state.manicDataReducer.loading
});

const mapDispatchToProps = dispatch => ({
  loadEventCounterData: (assetId, getRuleList) => (
    dispatch(getAssetEventCountersAction(assetId, getRuleList))),
  resetAlarm: (assetId, alarmTypeId) => (
    dispatch(resetAlarmAction(assetId, alarmTypeId)))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManikinData));
