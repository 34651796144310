import React from 'react';
import PropTypes from 'prop-types';

import HeaderCell, { StyledHeader } from './HeaderCell';

const HeaderLocation = (props) => {
  const {
    handleHeaderClick,
    sortDirection,
    sortType
  } = props;
  return (
    <StyledHeader>
      <HeaderCell
        centered
        headerText="Type"
        isCurrentSortType={sortType === 'manikinType'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="manikinType"
        width={120}
      />
      <HeaderCell
        centered
        headerText="Facility"
        isCurrentSortType={sortType === 'facility'}
        minWidth={100}
        onClick={handleHeaderClick}
        shrink={0}
        sortDirection={sortDirection}
        sortType="facility"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Cart location"
        isCurrentSortType={sortType === 'cartLocation'}
        minWidth={100}
        onClick={handleHeaderClick}
        shrink={0}
        sortDirection={sortDirection}
        sortType="cartLocation"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Serial #"
        isCurrentSortType={sortType === 'assetId'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="assetId"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Station serial"
        isCurrentSortType={sortType === 'stationSerial'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="stationSerial"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Laptop serial"
        isCurrentSortType={sortType === 'laptopId'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="laptopId"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Adult manikin serial"
        isCurrentSortType={sortType === 'adultManikinSerial'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="adultManikinSerial"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Infant manikin serial"
        isCurrentSortType={sortType === 'infantManikinSerial'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="infantManikinSerial"
        width={150}
      />
      <HeaderCell
        centered
        headerText="Laptop model"
        isCurrentSortType={sortType === 'laptopModel'}
        minWidth={100}
        onClick={handleHeaderClick}
        sortDirection={sortDirection}
        sortType="laptopModel"
        width={150}
      />
      <HeaderCell
        grow={1}
        minWidth={100}
        shrink={1}
        width={100}
      />
    </StyledHeader>
  );
};

HeaderLocation.propTypes = {
  handleHeaderClick: PropTypes.func.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired
};

export default HeaderLocation;
