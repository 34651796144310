import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

import { ALARM_TYPES, ALARM_TYPES_TEXT, ALARM_TYPES_DIC } from '../../../../constants/constants';
import { requestAssetLog } from '../../../../api/api';
import { theme } from '../../../../theme/theme';
import Modal from '../../../../components/Modal';
import Text from '../../../../components/Texts/Text';

import s from './Log.style';

const Log = ({ assetId }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [logs, setLogs] = useState([]);

  const toggleOpen = () => setOpen(!open);

  async function openLog() {
    setOpen(true);
    setError('');
    setLoading(true);

    try {
      const response = await requestAssetLog(assetId);
      const receivedLogs = response.data.logs;
      setLogs(receivedLogs);
    } catch (e) {
      setError('Error while loading asset logs. Please try again later.');
    }

    setLoading(false);
  }

  function renderLog(log, i) {
    const { action, actionType, descriptionLong, healingDateTime, email, failureType, time } = log;
    const displayTime = DateTime.fromISO(time).toLocaleString(DateTime.DATE_SHORT);
    const healingDateTimeObj = DateTime.fromISO(healingDateTime);
    const healingTimeInfo = healingDateTime
      ? `(auto-healed on ${healingDateTimeObj.toLocaleString(DateTime.DATE_SHORT)}
        at ${healingDateTimeObj.toLocaleString(DateTime.TIME_SIMPLE)})`
      : '';
    const alarmTypeInfo = actionType ? _.invert(ALARM_TYPES_TEXT)[ALARM_TYPES_DIC[actionType]] || actionType : '';
    return (
      <s.Root key={`${i}`}>
        <s.StyledSubTitle>{displayTime}</s.StyledSubTitle>
        <s.StyledSubTitle>{email}</s.StyledSubTitle>
        {action ? (
          <s.Text>{`${failureType || ALARM_TYPES.HEALTHY} -> ${actionType.toUpperCase()}${action ? ` -> ${action}` : ''}`}</s.Text>
        ) : (
          <>
            <s.StyledSubSubTitle>{`${alarmTypeInfo} ${healingTimeInfo}`}</s.StyledSubSubTitle>
            <s.Text dangerouslySetInnerHTML={{ __html: `${descriptionLong ? descriptionLong.trim() : ''}` }} />
          </>
        )}
      </s.Root>
    );
  }

  function renderContent() {
    if (loading) {
      return <s.LoaderContainer color={theme.secondaryColorPalette.light1} />;
    }

    if (logs && logs.length) {
      return logs.map((log, i) => renderLog(log, i));
    }

    if (error) {
      return error;
    }

    return <Text>The manikin has no historical data</Text>;
  }

  return (
    <>
      <IoIosInformationCircleOutline size={24} onClick={openLog} />
      <Modal open={open} showFooter={false} title={`Log for ${assetId}`} width={580} onCancel={toggleOpen}>
        {renderContent()}
      </Modal>
    </>
  );
};

Log.propTypes = {
  assetId: PropTypes.string.isRequired,
};

export default Log;
