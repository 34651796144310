import React from 'react';
import PropTypes from 'prop-types';

import s from './Option.style';

const Option = (props) => {
  const {
    content,
    type,
    selected,
    onClick,
    tooltipText
  } = props;
  const tooltipId = `${type.replace(' ', '_')}-filterTooltip`;


  return (
    <s.Root
      selected={selected}
      data-type={type}
      onClick={onClick}
    >
      <s.Icon id={tooltipId}>
        {content}
      </s.Icon>
      {tooltipText}
    </s.Root>
  );
};

Option.propTypes = {
  selected: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  content: PropTypes.element.isRequired,
  tooltipText: PropTypes.string.isRequired
};

export default Option;
