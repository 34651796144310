import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ManikinShape } from '../../../constants/types';
import AssetsList from './AssetsList';
import {
  removeAssetUpdateAction,
  setAssetUpdateAction
} from '../../../actions';
import {
  getApproveActions,
  getRejectActions
} from '../../../utility/alarm';

const AssetsListContainer = (props) => {
  const {
    assetsVisible,
    assetsUpdates,
    height,
    loading,
    manikins,
    onHospitalSelect,
    onItemToggle,
    removeAssetUpdate,
    setAssetUpdate,
    useLastRowWorkaround
  } = props;

  // merge updates into assets
  const assets = manikins.map((manikin) => {
    let asset = {
      ...manikin
    };
    const { assetId } = asset;
    const assetUpdate = assetsUpdates[assetId];

    if (assetUpdate) {
      asset = {
        ...asset,
        action: assetUpdate
      };
    }

    return asset;
  });

  const handleAssetActionSelect = (assetId, decision, actionId = '') => {
    const update = {
      actionType: decision,
      actionId
    };

    setAssetUpdate(assetId, update);
  };

  const handleActionRemove = (assetId) => {
    if (assetsUpdates[assetId]) {
      removeAssetUpdate(assetId);
    } else {
      const update = {
        actionType: '',
        actionId: ''
      };

      setAssetUpdate(assetId, update);
    }
  };

  return (
    <AssetsList
      expandedAssetId={assets.length === 1 ? assets[0].assetId : null}
      rawManikins={assets}
      approved={getApproveActions(assetsUpdates, manikins).length}
      rejected={getRejectActions(assetsUpdates, manikins).length}
      height={height}
      assetsVisible={assetsVisible}
      onAssetActionSelect={handleAssetActionSelect}
      onAssetActionRemove={handleActionRemove}
      onHospitalSelect={onHospitalSelect}
      loading={loading}
      onItemToggle={onItemToggle}
      useLastRowWorkaround={useLastRowWorkaround}
    />
  );
};

// useLastRowWorkaround: Use special JS to make footer placed to the very botom
// when a dropdown is opened in a last row
AssetsListContainer.propTypes = {
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
  height: PropTypes.number,
  assetsVisible: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  assetsUpdates: PropTypes.object.isRequired,
  setAssetUpdate: PropTypes.func.isRequired,
  removeAssetUpdate: PropTypes.func.isRequired,
  onHospitalSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  loading: PropTypes.bool,
  onItemToggle: PropTypes.func,
  useLastRowWorkaround: PropTypes.bool
};

AssetsListContainer.defaultProps = {
  height: undefined,
  assetsVisible: false,
  loading: false,
  onHospitalSelect: null,
  onItemToggle: Function.prototype,
  useLastRowWorkaround: false
};

const mapStateToProps = state => ({
  assetsUpdates: state.manicDataReducer.updates
});

const mapDispatchToProps = dispatch => ({
  setAssetUpdate: (assetId, failureType, update) => dispatch(setAssetUpdateAction(assetId, failureType, update)),
  removeAssetUpdate: (assetId, failureType) => dispatch(removeAssetUpdateAction(assetId, failureType))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetsListContainer);
