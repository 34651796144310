import * as React from 'react';
import PropTypes from 'prop-types';

import s from './ClusterMarker.style';

function coordinatesFromPercent(percent) {
  const x = Math.cos(2 * Math.PI * percent);
  const y = Math.sin(2 * Math.PI * percent);

  return [x, y];
}

const Path = (props) => {
  const { percentage, color, startPercent } = props;

  if (percentage === 100) {
    return (
      <circle
        r={1}
        style={{
          fill: color,
        }}
      />
    );
  }

  const [startX, startY] = coordinatesFromPercent(startPercent);
  const [endX, endY] = coordinatesFromPercent(startPercent + percentage);

  const useLargeArc = percentage > 0.5 ? 1 : 0;
  const path = `M ${startX} ${startY} A 1 1 0 ${useLargeArc} 1 ${endX} ${endY} L 0 0`;

  return <path d={path} fill={color} />;
};
Path.propTypes = {
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  startPercent: PropTypes.number.isRequired,
};

const getPaths = (slices) => {
  let cumulative = 0;

  return slices.map((slice) => {
    const path = <Path key={`slc_${Math.random()}}`} percentage={slice.percentage} color={slice.color} startPercent={cumulative} />;
    cumulative += slice.percentage;

    return path;
  });
};

const PieChart = (props) => {
  const { slices } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 2 2" style={{ transform: 'rotate(-90deg)' }}>
      {getPaths(slices)}
    </svg>
  );
};
PieChart.propTypes = {
  slices: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequires,
      color: PropTypes.string.isRequired,
      percentage: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const ClusterMarker = (props) => {
  const { slices, itemsCount, onClick, id, hospitals } = props;

  const handleClick = () => {
    onClick(id);
  };

  return (
    <s.Root onClick={handleClick}>
      <PieChart slices={slices} />
      <s.Content>{itemsCount}</s.Content>
      <s.Tooltip>
        <p>{hospitals ? 'Hospitals' : 'Assets'} with status:</p>
        <p>
          Critical:
          {slices[0].number}
        </p>
        <p>
          At risk:
          {slices[1].number}
        </p>
        <p>
          Warnings:
          {slices[2].number}
        </p>
        <p>Healthy:{slices[3].number}</p>
      </s.Tooltip>
    </s.Root>
  );
};
ClusterMarker.propTypes = {
  slices: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequires,
      color: PropTypes.string.isRequired,
      percentage: PropTypes.number.isRequired,
    }),
  ).isRequired,
  itemsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  hospitals: PropTypes.bool,
};

export default ClusterMarker;
