import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeaderCell, { StyledHeader } from './HeaderCell';

const HeaderManikinEventCounter = (props) => {
  const {
    handleHeaderClick,
    sortDirection,
    sortType
  } = props;
  return (
    <Header>
      <HeaderCell
        centered
        grow={1}
        headerText="Name"
        isCurrentSortType={sortType === 'filterName'}
        minWidth={100}
        onClick={handleHeaderClick}
        shrink={1}
        sortDirection={sortDirection}
        sortType="filterName"
        width="auto"
      />
      <HeaderCell
        centered
        headerText="Value"
        isCurrentSortType={sortType === 'value'}
        minWidth={70}
        onClick={handleHeaderClick}
        shrink={1}
        sortDirection={sortDirection}
        sortType="value"
        width={70}
      />
      <HeaderCell
        centered
        headerText=""
        minWidth={80}
        shrink={1}
        width={80}
      />
    </Header>
  );
};

HeaderManikinEventCounter.propTypes = {
  handleHeaderClick: PropTypes.func,
  sortDirection: PropTypes.string,
  sortType: PropTypes.string
};

HeaderManikinEventCounter.defaultProps = {
  handleHeaderClick: Function.prototype,
  sortDirection: '',
  sortType: ''
};

const Header = styled(StyledHeader)`
  padding: 0 34px;
  height: 45px;
  min-height: 45px;
`;

export default HeaderManikinEventCounter;
