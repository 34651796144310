import PropTypes from 'prop-types';
import React from 'react';

const Icon = (props) => {
  const {
    id,
    onClick,
    size
  } = props;

  const realSize = size - 3;

  return (
    <div id={id} onClick={() => onClick()} role="button" tabIndex={0}>
      <svg width={realSize} height={realSize} viewBox="0 0 14 14" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.705 10.5L7 7.7955L4.295 10.5L3.5 9.705L6.2045 7L3.5 4.2955L4.2955 3.5L7 6.2045L9.7045 3.5L10.5 4.2955L7.7955 7L10.5 9.705L9.705 10.5Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM13 7C13 3.68629 10.3137 1 7 1C3.68789 1.00386 1.00386 3.68789 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7Z" />
      </svg>
    </div>
  );
};

Icon.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number
};

Icon.defaultProps = {
  id: '',
  onClick: Function.prototype,
  size: 20
};

export default Icon;
