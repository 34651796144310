import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

const ShyText = styled.span`
  font-style: italic;
  font-size: 10px;
  color: #7198a2;
  margin-right: 10px;
  margin-left: 5px;
`;

const EditedLocation = ({ edited }: EditedLocationProps) => {
  const time = DateTime.fromISO(edited);
  const title = `The asset has had its details edited ${time.toLocaleString()}. ` + 'It will no longer change location based on changes in other systems.';
  return (
    <span className="float-right" title={title}>
      <ShyText>Edited</ShyText>
    </span>
  );
};

type EditedLocationProps = {
  edited: string;
};

export default EditedLocation;
