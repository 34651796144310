/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/**
 * Loads/caches repair report data (repair reasons, manikin parts/sub parts, replace types etc.)
 */
import { SENT_EVENT_COUNTER_DATA_REQUEST, RECEIVED_EVENT_COUNTER_DATA_REQUEST, ERROR_EVENT_COUNTER_DATA_REQUEST } from '../constants/constants';

function updateEventCounterData(oldData: any, newData: any) {
  if (!oldData) return newData;
  const result = Object.assign({}, oldData);

  result.counters = newData.counters;

  // We are only caching rules so far
  if (!result.rules) {
    result.rules = newData.rules;
  }

  return result;
}

export default (
  state: any = {
    loading: false,
    error: '',
    data: { counters: null, rules: null },
  },
  action: any,
) => {
  switch (action.type) {
    case SENT_EVENT_COUNTER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        data: { ...state.data, counters: null },
      };
    case ERROR_EVENT_COUNTER_DATA_REQUEST:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: null,
      };
    case RECEIVED_EVENT_COUNTER_DATA_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
        data: updateEventCounterData(state.data, action.payload),
      };
    default:
      return state;
  }
};
