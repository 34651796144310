import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Table
} from 'react-virtualized';

import { mainInfoHeight } from '../ListItem/ListItem.style';

import s, {
  defaultItemsVisible,
  defaultHeight,
  getListHeight,
  headerHeight
} from './SimpleList.style';
import Skeleton from '../../../components/Loading/TableSkeleton';

export const SimpleListItemShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  key: PropTypes.string,
  value: PropTypes.object
});

const SimpleList = (props) => {
  const {
    header,
    height,
    items,
    itemsVisible,
    loading,
    onItemToggle,
    rowHeight,
    rowRenderer,
    onSort
  } = props;

  const [sortType, setSortType] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [openedItems, setOpenedItems] = useState([]);

  const listElement = useRef(null);

  const cache = new CellMeasurerCache({
    defaultHeight: 40,
    minHeight: 40,
    fixedWidth: true
  });

  useEffect(() => {
    if (listElement.current) {
      listElement.current.recomputeRowHeights();
    }
  }, [sortType, sortDirection, items]);

  useEffect(() => {
    if (listElement.current) {
      listElement.current.recomputeRowHeights();
    }
  }, [openedItems]);

  const reverseSortDirection = () => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
  };

  const handleHeaderClick = (event) => {
    const { sorttype: newSortType } = event.currentTarget.dataset;

    if (sortType === newSortType) {
      reverseSortDirection();
      return;
    }

    setSortType(newSortType);
  };

  const handleItemClick = (item) => {

    if (openedItems.indexOf(item) >= 0) {
      setOpenedItems(openedItems.filter(oi => oi !== item));
    } else {
      const result = [...openedItems];
      result.push(item);
      setOpenedItems(result);
    }

    onItemToggle(item);
  };

  const handleItemToggled = (item, index) => {
    if (listElement.current) {
      cache.clear(index);
      listElement.current.recomputeRowHeights(index);
    }
  };

  const renderHeader = () => {
    if (!header) return null;
    return React.cloneElement(header, {
      handleHeaderClick: event => handleHeaderClick(event)
    });
  };

  const renderRow = (renderProps) => {
    const {
      rowData: itemData,
      style,
      parent,
      rowIndex,
      dataKey
    } = renderProps;
    if (!rowRenderer) return null;
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
        style={style}
      >
        {React.cloneElement(rowRenderer, {
          index: rowIndex,
          isOpened: openedItems.indexOf(itemData) >= 0,
          key: dataKey,
          onItemToggle: val => handleItemClick(val),
          onItemToggled: (item, index) => handleItemToggled(item, index),
          style,
          value: itemData
        })}
      </CellMeasurer>
    );
  };

  const renderContent = () => (
    <AutoSizer disableHeight>
      {({ width }) => {
        const tableHeight = getListHeight({ itemsVisible, height });

        return (
          <Table
            deferredMeasurementCache={cache}
            ref={listElement}
            rowHeight={cache.rowHeight}
            width={width}
            height={tableHeight}
            rowGetter={({ index }) => items[index]}
            rowCount={items.length}
            overscanRowCount={8}
            disableHeader
          >
            <Column
              cellRenderer={renderRow}
              dataKey="name"
              label=""
              width={width}
            />
          </Table>
        );
      }}
    </AutoSizer>
  );

  return (
    <s.Root
      hasSelected={false}
      height={height || (defaultHeight * itemsVisible)}
    >
      {renderHeader()}
      {
        loading
          ? <Skeleton />
          : renderContent()
      }
    </s.Root>
  );
};

SimpleList.propTypes = {
  header: PropTypes.element,
  height: PropTypes.number,
  items: PropTypes.arrayOf(SimpleListItemShape),
  itemsVisible: PropTypes.number,
  loading: PropTypes.bool,
  onItemToggle: PropTypes.func,
  onSort: PropTypes.func,
  rowHeight: PropTypes.number,
  rowRenderer: PropTypes.element
};

SimpleList.defaultProps = {
  header: null,
  height: defaultHeight + headerHeight,
  items: [],
  itemsVisible: defaultItemsVisible,
  loading: false,
  onItemToggle: Function.prototype,
  onSort: Function.prototype,
  rowHeight: mainInfoHeight,
  rowRenderer: null
};

export default SimpleList;
