import { connect } from 'react-redux';
import { Link, matchPath, NavLink, withRouter } from 'react-router-dom';
import { getSettingsAction } from '../../actions';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { setTextFilterAndResetOtherAction, setLastSelectedHospitalAction } from '../../actions/filters';
import Feedback from './Feedback';
import Search from '../../components/Search';
import Title from '../../components/Texts/Title';
import User from './User';

import logo from '../../styles/images/brand-laerdal-logo.svg';

// Cut text by maxLen and add a suffix if needed
// The result won't exceed maxLen
const cutText = (text, maxLen, suffix) => {
  let slen = 0;
  if (suffix) {
    slen = suffix.length;
  }

  if (text.length > maxLen) {
    return text.substr(0, maxLen - slen) + suffix;
  }

  return text;
};

const Header = (props) => {
  const [feedbackOpened, setFeedbackOpened] = useState(false);

  const handleSearch = (str) => {
    const { setTextFilterAndResetOther } = props;
    setTextFilterAndResetOther(str.trim());
  };
  const { location, hospitalId, searchText, history, setLastSelectedHospitalId, settings, loadSettings } = props;

  // Reset selected hospital filter when jumping back from hospital statistics page
  useEffect(() => {
    const { pathname } = location;
    const isOnHospitalStatsPage = Boolean(
      matchPath(pathname, {
        path: '/hospitalstats/',
      }),
    );
    if (!isOnHospitalStatsPage) {
      setLastSelectedHospitalId('');
    }
  }, [location]);

  const { pathname } = location;
  const displaySearch = Boolean(
    matchPath(pathname, {
      path: '/dashboard',
    }),
  );

  const disableEditHospitalStats = Boolean(
    matchPath(pathname, {
      path: '/hospitalstats/',
    }),
  );

  const onEditHospitalStatisticsClick = () => {
    history.push(`/hospitalstats/${hospitalId}`);
  };

  return (
    <Root>
      <Link to="/">
        <Logo src={logo} alt="logo" />
      </Link>

      <AppTitle>Predictive Maintenance Portal</AppTitle>
      {!displaySearch && <SearchStub />}
      {displaySearch && <Search placeholder="Search by hospital or id" searchFor={handleSearch} searchText={searchText} settings={settings} loadSettings={loadSettings} />}
      <RightContent>
        <StyledLink to="/daily_report">Critical Alerts</StyledLink>
        <StyledLink to="/dashboard">All Assets</StyledLink>
        <HelpLink to="/help">
          <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
            <g id="Approved" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Header-States" transform="translate(-1297.000000, -20.000000)" fillRule="nonzero">
                <g id="docs" transform="translate(1297.000000, 20.000000)">
                  <path
                    d="M13,0 L13,6 L11,5 L9,6 L9,0 L3,0 C1.3,0 0,1.3 0,3
                    L0,13 C0,14.7 1.3,16 3,16 L13,16 C14.7,16 16,14.7 16,13 L16,3
                    C16,1.3 14.7,0 13,0 Z M14,13 C14,13.6 13.6,14 13,14 L3,14 C2.4,14
                    2,13.6 2,13 C2,12.4 2.4,12 3,12 L13,12 C13.6,12 13.9,11.9 14,11.8 L14,13 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </svg>
        </HelpLink>

        <StyledUser />
      </RightContent>
    </Root>
  );
};

Header.propTypes = {
  hospitalId: PropTypes.string,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  setLastSelectedHospitalId: PropTypes.func.isRequired,
  setTextFilterAndResetOther: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  loadSettings: PropTypes.func.isRequired
};

Header.defaultProps = {
  hospitalId: null,
};

const mapStateToProps = (state) => ({
  searchText: state.filters.text,
  hospitalId: state.filters.lastSelectedHospitalId,
  settings: state.user.settings,
});
const mapDispatchToProps = (dispatch) => ({
  setTextFilterAndResetOther: (text) => dispatch(setTextFilterAndResetOtherAction(text)),
  setLastSelectedHospitalId: (hospitalId) => dispatch(setLastSelectedHospitalAction(hospitalId)),
  loadSettings: () => dispatch(getSettingsAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

const AppTitle = styled(Title)`
  display: flex;
  flex: 1 auto;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const FeedbackLink = styled.div`
  cursor: pointer;
  margin-left: 20px;

  svg {
    g {
      fill: ${({ active, theme }) => (active ? theme.secondaryColorPalette.main : theme.greyPalette.grey)};
    }
  }
  &:hover,
  &.active {
    svg {
      g {
        fill: ${(props) => props.theme.secondaryColorPalette.main};
      }
    }
  }
`;

const HelpLink = styled(NavLink)`
  margin-left: 40px;
  svg {
    g {
      fill: ${({ theme }) => theme.greyPalette.grey};
    }
  }
  &:hover,
  &.active {
    svg {
      g {
        fill: ${(props) => props.theme.secondaryColorPalette.main};
      }
    }
  }
`;

const Logo = styled.img`
  position: relative;
  top: -2px;
  width: 96px;
`;

const RightContent = styled.div`
  align-items: center;
  display: flex;
`;

const Root = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.primaryBackground};
  box-shadow: ${(props) => props.theme.boxShadow};
  display: flex;
  height: 55px;
  justify-content: space-between;
  left: 0;
  padding: 0 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const SearchStub = styled.div`
  width: 260px;
`;

const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.laerdalPalette.black};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  letter-spacing: 0.4px;
  margin-left: 20px;
  text-transform: uppercase;

  &:hover,
  &.active {
    color: ${(props) => props.theme.laerdalPalette.primary.default};
  }
`;

const StyledUser = styled(User)`
  margin-left: 20px;
`;

const StyledFeedback = styled(Feedback)`
  position: absolute;
  top: 100%;
  margin-top: -10px;
  right: 20px;
`;
