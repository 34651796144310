import {
  SET_CURRENT_ALARMS,
  SET_DAILY_ALARMS,
  SET_PREFERRED_STATES,
  SET_MONITOR_QUERIES
} from '../constants/constants';

export const setCurrentAlarmsAction = numberOfAlarms => dispatch => dispatch({
  type: SET_CURRENT_ALARMS,
  numberOfAlarms
});

export const setDailyAlarmsAction = numberOfAlarms => dispatch => dispatch({
  type: SET_DAILY_ALARMS,
  numberOfAlarms
});

export const setPreferredStatesAction = states => dispatch => dispatch({
  type: SET_PREFERRED_STATES,
  states
});

export const setMonitorQueriesAction = monitorQueries => dispatch => dispatch({
  type: SET_MONITOR_QUERIES,
  monitorQueries
});

export default {
  setCurrentAlarmsAction,
  setDailyAlarmsAction
};
