import PropTypes from 'prop-types';

export const ManikinPropertyCounterShape = PropTypes.shape({
  maintenance: PropTypes.number,
  production: PropTypes.number
});

export const ManikinInfoShape = PropTypes.shape({
  age: ManikinPropertyCounterShape,
  compressions: ManikinPropertyCounterShape,
  ventilations: ManikinPropertyCounterShape,
  lastMaintenance: PropTypes.string.isRequired
});

export const ManikinAttrsShape = PropTypes.shape({
  cartId: PropTypes.string,
  facility: PropTypes.string,
  laptopId: PropTypes.string,
  laptopModel: PropTypes.string,
  laptopSimId: PropTypes.string,
  location: PropTypes.string
});

export const EmptyAssetAttributes = {
  cartId: null,
  facility: null,
  laptopId: null,
  laptopModel: null,
  laptopSimId: null,
  location: null
};

export const AlarmShape = PropTypes.shape({
  alarmType: PropTypes.string.isRequired,
  alarmDescription: PropTypes.string.isRequired
});

// Only available when getting alarms back
// when editing attributes/adding repair report/creating asset
export const AlarmResultShape = PropTypes.shape({
  alarmType: PropTypes.string.isRequired,
  alarmDescription: PropTypes.string.isRequired,
  assetId: PropTypes.string
});

export const ManikinShape = PropTypes.shape({
  alarms: PropTypes.arrayOf(AlarmShape).isRequired,
  attrs: ManikinAttrsShape,
  assetId: PropTypes.string.isRequired,
  hospitalId: PropTypes.number.isRequired,
  info: ManikinInfoShape,
  isSSM: PropTypes.bool,
  notUsedIn: PropTypes.number.isRequired,
  placeName: PropTypes.string.isRequired,
  // Will be used later to retrieve a repair report for given manikin
  repairId: PropTypes.number,
  // status: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
});

export const HospitalLocationShape = PropTypes.shape({
  address: PropTypes.string,
  isLoading: PropTypes.bool,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  usState: PropTypes.string
});

HospitalLocationShape.defaultProps = {
  address: '',
  isLoading: false
};

const HospitalShape = PropTypes.shape({
  hospitalId: PropTypes.number.isRequired,
  isRepairCenter: PropTypes.bool,
  isVAHospital: PropTypes.bool,
  location: HospitalLocationShape.isRequired,
  manikins: PropTypes.string.isRequired,
  placeName: PropTypes.string.isRequired,
  unresolvedIssues: PropTypes.number.isRequired
});

HospitalShape.defaultProps = {
  isVAHospital: false
};

export { HospitalShape };

export {
  ManikinPartShape,
  ManikinSubPartShape,
  RepairReportDataShape,
  RepairReasonShape,
  ReplacementTypeShape
} from './repairReportTypes';

export {
  EventCounterDataShape,
  EventCounterRuleShape,
  EventCounterValueShape
} from './eventCounterTypes';
