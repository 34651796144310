/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { saveSettings } from '../../api/api';

import Button from '../Button/Button';
import Input from '../Input/Input';
import { theme } from '../../theme/theme';
import { BsFillPinAngleFill } from 'react-icons/bs';

const SearchIcon = (
  <svg height="16" viewBox="0 0 24 24" width="16">
    <path
      fill={theme.inverseColor}
      d="M23.486,20.96,17.329,14.8l-.33.33-.33-.33A9.263,9.263,0,0,0,2.7,2.7,9.253,9.253,0,0,0,14.776,16.666l.33.33-.33.33,6.157,6.156a1.8,1.8,0,0,0,2.523,0,1.767,1.767,0,0,0,.03-2.522Zm-9.22-6.7a7.114,7.114,0,1,1,0-10.059,7.143,7.143,0,0,1,0,10.059Zm0,0"
    />
  </svg>
);

const SearchBox = (props) => {
  const [value, setValue] = useState('');

  const { placeholder, searchText, settings, loadSettings } = props;

  useEffect(() => {
    setValue(searchText);
  }, [searchText]);

  const handleClick = () => {
    const { searchFor } = props;
    searchFor(value);
  };

  const handlePinClick = async () => {
    const { settings } = props;
    let queries = settings.monitorQueries;
    queries.push(searchText);
    await saveSettings({
      alarmsPerDay: settings.alarmsPerDay,
      preferredStates: settings.preferredStates,
      monitorQueries: queries,
    });
    await loadSettings();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <SearchBoxContainer>
      <StyledInput name="text" onChange={(e) => setValue(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder={placeholder} value={value} />
      <StyledButton color="normal" label={SearchIcon} onClick={handleClick} />
      <StyledButton
        color="outline"
        label={<BsFillPinAngleFill size="16px" />}
        onClick={handlePinClick}
        disabled={searchText == '' || settings.monitorQueries.includes(searchText)}
      />
    </SearchBoxContainer>
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  searchFor: PropTypes.func.isRequired,

  // Current search text applied from the outside
  // value state will become equal to searchText initially and
  // when searchText property has been changed
  searchText: PropTypes.string,
  settings: PropTypes.object.isRequired,
  loadSettings: PropTypes.func.isRequired,
};

SearchBox.defaultProps = {
  placeholder: '',
  searchText: '',
};

export default SearchBox;

const SearchBoxContainer = styled.div`
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  height: auto;
  width: 300px;
`;

const StyledButton = styled(Button)`
  border-radius: 0;
`;

const StyledInput = styled(Input)`
  background: transparent;
  border: none;
  width: 100%;
  padding: 0 16px;

  &:focus {
    border: none;
    outline: none;
  }
`;
