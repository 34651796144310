import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../../components/Modal';
import Text from '../../../../components/Texts/Text';
import { setAssetIsDecommissioned } from '../../../../api/api';

const DecommissionModal = (props) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    assetId,
    onClose,
    onSuccess
  } = props;


  const send = async () => {

    if (success) {
      onClose();
      return;
    }

    setLoading(true);
    setError('');
    try {
      const result = await setAssetIsDecommissioned(assetId);
      onSuccess(assetId, result && result.data && result.data.assetAlarms);
    } catch (e) {
      setError(`Something went wrong. ${e}`);
      setLoading(false);
    }
  };

  const onConfirmClick = () => {
    send();
  };

  const confirmButtonText = success ? 'Close' : 'Confirm';

  return (
    <Modal
      confirmButtonText={confirmButtonText}
      cancelButtonDisabled={loading}
      confirmButtonDisabled={loading}
      id="dcmModal"
      loading={loading}
      open
      onCancel={onClose}
      onConfirm={onConfirmClick}
      title=""
    >
      {!success && !error && (
        <>
          Please confirm decommissioning this manikin
        </>
      )}
      {success && (
        <>
          {success}
        </>
      )}
      {error && <Text error>{error}</Text>}
    </Modal>
  );
};

DecommissionModal.propTypes = {
  assetId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};

DecommissionModal.defaultProps = {
  onClose: Function.prototype,
  onSuccess: Function.prototype
};

export default DecommissionModal;
