import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { theme } from '../../theme/theme';

export const BUTTON_TYPE = {
  alert: 'alert',
  ghost: 'ghost',
  normal: 'normal',
  outline: 'outline',
  success: 'success',
};

const BUTTON_COLORS = {
  [BUTTON_TYPE.ghost]: theme.laerdalPalette.accent1.default,
  [BUTTON_TYPE.outline]: theme.laerdalPalette.accent1.default,
};

const BUTTON_COLORS_ON_HOVER = {
  [BUTTON_TYPE.ghost]: theme.secondaryColorPalette.main,
  [BUTTON_TYPE.outline]: theme.secondaryColorPalette.main,
};

const BUTTON_DISABLED_BG_COLORS = {
  [BUTTON_TYPE.ghost]: 'transparent',
};

const BUTTON_BG_COLORS = {
  [BUTTON_TYPE.alert]: theme.redColorPalette.light1,
  [BUTTON_TYPE.ghost]: 'transparent',
  [BUTTON_TYPE.normal]: theme.laerdalPalette.primary.default,
  [BUTTON_TYPE.outline]: 'transparent',
  [BUTTON_TYPE.success]: theme.successColor,
};

const BUTTON_BG_COLORS_ON_HOVER = {
  [BUTTON_TYPE.alert]: theme.redColorPalette.main,
  [BUTTON_TYPE.ghost]: theme.secondaryColorPalette.light5,
  [BUTTON_TYPE.normal]: theme.laerdalPalette.primary.heavy,
  [BUTTON_TYPE.outline]: 'transparent',
  [BUTTON_TYPE.success]: theme.successColor,
};

const BUTTON_BORDER_COLORS = {
  [BUTTON_TYPE.alert]: theme.redColorPalette.light1,
  [BUTTON_TYPE.ghost]: 'transparent',
  [BUTTON_TYPE.normal]: theme.laerdalPalette.primary.default,
  [BUTTON_TYPE.outline]: theme.secondaryColorPalette.light1,
  [BUTTON_TYPE.success]: theme.successColor,
};

const BUTTON_BORDER_COLORS_ON_HOVER = {
  [BUTTON_TYPE.alert]: theme.redColorPalette.main,
  [BUTTON_TYPE.ghost]: 'transparent',
  [BUTTON_TYPE.normal]: theme.laerdalPalette.primary.heavy,
  [BUTTON_TYPE.outline]: theme.secondaryColorPalette.main,
  [BUTTON_TYPE.success]: theme.successColor,
};

const getButtonBgColor = (buttonType) => BUTTON_BG_COLORS[buttonType] || theme.greyPalette.grey;
const getButtonBgColorOnHover = (buttonType) => BUTTON_BG_COLORS_ON_HOVER[buttonType] || theme.greyPalette.grey;
const getButtonBorderColor = (buttonType) => BUTTON_BORDER_COLORS[buttonType] || theme.greyPalette.grey;
const getButtonBorderColorOnHover = (buttonType) => BUTTON_BORDER_COLORS_ON_HOVER[buttonType] || theme.greyPalette.grey;
const getButtonColor = (buttonType) => BUTTON_COLORS[buttonType] || theme.inverseColor;
const getButtonColorOnHover = (buttonType) => BUTTON_COLORS_ON_HOVER[buttonType] || theme.inverseColor;
const getDisabledButtonBgColor = (buttonType) => BUTTON_DISABLED_BG_COLORS[buttonType] || theme.greyPalette.grey4;

const Button = (props) => {
  const { big, children, className, color, disabled, label, onClick, onMouseDown, onMouseUp, title } = props;

  return (
    <>
      <ButtonContainer
        big={big}
        className={className}
        color={color}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        title={title}
      >
        {label}
      </ButtonContainer>

      {children}
    </>
  );
};

Button.propTypes = {
  big: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  title: PropTypes.string,
};

Button.defaultProps = {
  big: false,
  className: '',
  color: theme.inverseColor,
  disabled: false,
  label: '',
  onClick: Function.prototype,
  onMouseDown: Function.prototype,
  onMouseUp: Function.prototype,
  title: '',
};

export default Button;

const ButtonContainer = styled.button`
  align-items: center;
  background-color: ${({ color }) => getButtonBgColor(color)};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1.5px solid;
  border-color: ${({ color }) => getButtonBorderColor(color)};
  color: ${({ color }) => getButtonColor(color)};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  height: auto;
  justify-content: center;
  letter-spacing: 0.75px;
  padding: ${({ big }) => (big ? '13px 26px' : '8px 16px')};
  text-transform: uppercase;
  width: auto;
  white-space: nowrap;

  &:disabled {
    background-color: ${({ color }) => getDisabledButtonBgColor(color)};
    border-color: ${({ color }) => getDisabledButtonBgColor(color)};
    color: ${(props) => props.theme.greyPalette.grey};
    cursor: default;

    &:hover {
      background-color: ${({ color }) => getDisabledButtonBgColor(color)};
      border-color: ${({ color }) => getDisabledButtonBgColor(color)};
      color: ${(props) => props.theme.greyPalette.grey};
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ color }) => getButtonBgColorOnHover(color)};
    border-color: ${({ color }) => getButtonBorderColorOnHover(color)};
    color: ${({ color }) => getButtonColorOnHover(color)};
  }
`;
