import * as React from 'react';
import PropTypes from 'prop-types';
import {
  IoIosAlert,
  IoIosBarcode,
  IoIosBug,
  IoIosBuild, IoIosInformationCircleOutline,
  IoIosSad,
  IoMdCloseCircle,
  IoMdConstruct
} from 'react-icons/io';
import styled from 'styled-components';

import SubTitle from '../../components/Texts/SubTitle';
import Text from '../../components/Texts/Text';
import Title from '../../components/Texts/Title';
import { theme } from '../../theme/theme';

import overviewScreen from './search1.png';
import mapScreen from './map1.png';
import clustersScreen from './clusters1.png';
import tooltipScreen from './selected-hospital1.png';
import filtersScreen from './filters.png';
import assetsScreen from './list.png';

const nav = [{
  text: 'Overview',
  target: 'overview'
}, {
  text: 'Map',
  target: 'map'
}, {
  text: 'Failure types',
  target: 'failureTypes'
}, {
  text: 'Filters',
  target: 'filters'
}, {
  text: 'Issue list',
  target: 'issueList'
}];

function handleNavClick(event) {
  const { dataset } = event.target;
  const { target: linkTarget } = dataset;
  const targetElement = document.querySelector(`#${linkTarget}`);
  const targetElementOffset = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop - 80;

  document.documentElement.scrollTop = targetElementOffset;
}

const HelpNavLink = ({ text, target }) => (
  <Link
    data-target={target}
    onClick={handleNavClick}
    role="link"
    tabIndex={0}
  >
    {text}
  </Link>
);
HelpNavLink.propTypes = {
  text: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired
};

function Help() {
  return (
    <Root>
      <TableOfContents>
        <FixedContent>
          {nav.map(HelpNavLink)}
        </FixedContent>
      </TableOfContents>
      <Content>
        <StyledTitle id="overview">
          Overview
        </StyledTitle>
        <Text>
          The RQI dashboard gives a status overview of Lærdal’s assets in an easy-to-use intuitive interface.
        </Text>
        <Text>
          The dashboard summarizes problematic assets and flags assets at risk of failure.
          The data is updated daily at 4 a.m. Los Angeles time
        </Text>
        <Img
          src={overviewScreen}
          alt="The RQI dashboard has a search bar on top. You can search by serial number (even partial) and
          hospital name (also supports partial)​"
        />
        <Text>
          The RQI Dashboard has a search bar on the top.
          You can search by serial number (even partial) and hospital name (also supports partial).
        </Text>
        <StyledTitle id="map">
          Map
        </StyledTitle>
        <Img
          src={mapScreen}
          alt="RQI Dashboard map screenshot"
        />
        <Text>
          The map shows locations with manikins that have issues.
        </Text>
        <Text>
          Number on colored markers (square shaped markers are individual hospitals)
          indicates how many unhealthy manikins there is in this place.
        </Text>
        <Text>
          By clicking on the dots you can see how many manikins are there at the location, and the number of issues.
        </Text>
        <Text>
          (Note: the number of issues can be more than the number of manikins due to multiple issues per manikin).
        </Text>
        <Img
          src={tooltipScreen}
          alt="Screenshot of map marker tooltip"
          className="SideBlock SideBlock_tooltip"
        />
        <Text>
          The color of the dot represents the percentage of issues. If it is red, there are lots of manikins with
          issues and a little without. If it is green - there are no issues.
        </Text>
        <Img
          src={clustersScreen}
          alt="Screenshot of map marker tooltip"
        />
        <Text>
          Big white circles represents number of places of manikin installs in the area. Zooming in of the map will
          show individual places. Color of a circle border displays statuses of places with different number of
          manikins with failures. For example, if number in circle is 10 and half of border is red then 5 of 10
          hospitals in the area have lots of manikins with issues.
        </Text>
        <StyledTitle id="failureTypes">
          Failure types
        </StyledTitle>
        <Block>
          <IoMdConstruct
            size={32}
            color={theme.greyPalette.black}
          />
          <StyledSubTitle>
            Predicted failure
          </StyledSubTitle>
          <Text>
            Globus.ai machine learning model predicts failure of a manikin and
            suggests an action to handle it.
          </Text>
        </Block>
        <Block>
          <IoIosBarcode
            size={32}
            color={theme.greyPalette.black}
          />
          <StyledSubTitle>
            Firmware issue
          </StyledSubTitle>
          <Text>
            Firmware issue indicates problems with manikin type (adult/infant). This appears if a manikin doesn’t
            have a defined manikin variant. It also appears if the serial number and manikin variant contradict.
            This also indicates issues with lifetime information of a manikin.
          </Text>
        </Block>
        <Block>
          <IoIosBug
            size={32}
            color={theme.greyPalette.black}
          />
          <StyledSubTitle>
            Software issue
          </StyledSubTitle>
          <Text>
            The manikin has more than 50% missing data or redundant data in CPR sessions.
          </Text>
        </Block>
        <Block>
          <IoIosAlert
            size={32}
            color={theme.greyPalette.black}
          />
          <StyledSubTitle>
            Anomaly
          </StyledSubTitle>
          <Text>
            Indicates that more that 30% of sessions have statistically abnormal data.
          </Text>
        </Block>
        <Block>
          <IoIosSad
            size={32}
            color={theme.greyPalette.black}
          />
          <StyledSubTitle>
            Underutilized manikin
          </StyledSubTitle>
          <Text>
            This appears if manikins was not used 90 and more days and was not used weekly last quarter and last
            year.
          </Text>
        </Block>
        <Block>
          <IoIosBuild
            size={32}
            color={theme.greyPalette.black}
          />
          <StyledSubTitle>
            Manikin in repair
          </StyledSubTitle>
          <Text>
            This appears when the Service Request date is later than the last usage date. If the manikin is used
            again, it stops being &quot;in repair&quot;.
          </Text>
        </Block>
        <Block>
          <IoMdCloseCircle
            size={32}
            color={theme.greyPalette.black}
          />
          <StyledSubTitle>
            User generated failure
          </StyledSubTitle>
          <Text>
            This appears when user set manikin failure in RQI Dashboard
          </Text>
        </Block>
        <StyledTitle id="filters">
          Filters
        </StyledTitle>

        <Row>
          <Img
            small
            src={filtersScreen}
            alt="Screenshot of dashboard filters"
          />
          <Col>
            <StyledSubTitle>
              By manikin type
            </StyledSubTitle>
            <Text>Adult / Infant / Not defined</Text>

            <StyledSubTitle>
              By healthy/unhealthy
            </StyledSubTitle>
            <Text>Show only healthy manikins</Text>
            <Text>Show all manikins</Text>
            <Text>Show manikins with issues</Text>

            <StyledSubTitle>
              By failure type
            </StyledSubTitle>
            <Text>Predicted failure</Text>
            <Text>Firmware issue</Text>
            <Text>Software issue</Text>
            <Text>Anomaly</Text>
            <Text>Underutilized manikin</Text>
            <Text>Manikin in repair</Text>

            <StyledSubTitle>
              By program
            </StyledSubTitle>
            <Text>HeartCode / RQI / Other</Text>

            <StyledSubTitle>
              Handled
            </StyledSubTitle>
            <Text>Manikins with handled issue</Text>
            <Text>Manikins with unhandled issues</Text>
          </Col>
        </Row>
        <StyledTitle id="issueList">
          Issue list
        </StyledTitle>
        <Text>
          This is the list of all assets in the RQI programme.
        </Text>
        <Text>
          It shows the failure types, hospital, serial number,
          date of last maintenance, age and the lifetime number of compressions for each manikin.
        </Text>
        <Img
          src={assetsScreen}
          alt="Screenshot of dashboard assets list"
        />
        <Text>
          Age is the number of days since production date till today for the manikins that never been to maintenance,
          and it&apos;s the number of days since maintenance for the ones that were in repair.
        </Text>
        <Text>
          You can sort by any columns by clicking on them, the direction of sort can be changed by clicking on the
          same column again.
        </Text>
        <Text>
          You can mark manikin as having some issue by clicking on plus sign in Failure Type column amd filling the
          form.
        </Text>
        <Text>
          Sometimes you can see that manikin was in maintenance (it has last maintenance date), but in additional
          info there are “no maintenance registered” for age, compressions and ventilations. It indicates, that
          this manikin was sent to maintenance, but there was defined as &quot;without errors&quot;
        </Text>
        <Text>
          If you click on an item in the list, it will show additional information related to failure of this
          manikin.
        </Text>
        <Text>
          Buttons “Approve” and “Reject” contain actions that can be made to handle the failure of a manikin.
          After action is selected you can send it by clicking on Send button above list. All not sent actions
          are not saved.
        </Text>
        <Text>
          You can see manikin history by clicking on
          &nbsp;
          <IoIosInformationCircleOutline />
          &nbsp;
          icon.
        </Text>
        <Text>
          Progress bar near Send button displays how many of all alerts are handled now.
        </Text>
      </Content>
    </Root>
  );
}

export default Help;

const Block = styled.div`
  margin: 20px 0
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-top: 15px;
`;

const Content = styled.div`
  margin: 40px auto 0;
  max-width: 900px;
  padding-bottom: 60px;
  position: relative;
`;

const FixedContent = styled.div`
  position: fixed;
  padding: 90px 0 0 40px;
`;

const Img = styled.img`
  box-shadow: ${props => props.theme.boxShadow};
  margin: 20px 0;
  width: ${props => (props.small ? '300px' : '100%')};
`;

const Link = styled.span`
  color: ${props => props.theme.greyPalette.black};
  cursor: pointer;
  display: block;
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.medium};
  margin-top: 5px;
  outline: none;
  transition: 0.2s;

  &:hover {
    color: ${props => props.theme.secondaryColorPalette.light1};
  }
`;

const Row = styled.div`
    display: flex
`;

const StyledTitle = styled(Title)`
    padding: 55px 0 15px;
`;

const StyledSubTitle = styled(SubTitle)`
    padding: 5px 0;
`;

const Root = styled.div`
  background-color: ${props => props.theme.primaryBackground};
`;

const TableOfContents = styled.div`
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;
