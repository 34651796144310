import { createGlobalStyle } from 'styled-components';
import { color, size, semanticColor, shadow, radius } from './tokens';

export const theme = {
  borderColor: '#ccdadd',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,69,85,0.12), 0 1px 2px rgba(0,69,85,0.24)',
  breakpoint: {
    desktop: '1024px',
    tablet: '768px',
  },
  color,
  fontFamily: 'Roboto, sans-serif',
  fontSize: {
    small: '10px',
    regular: '12px',
    medium: '14px',
    large: '16px',
    extraLarge: '20px',
  },
  fontWeight: {
    bold: '700',
    medium: '500',
    regular: '400',
  },
  inverseColor: '#ffffff',
  greyPalette: {
    black: '#1f1f1f',
    grey: '#7198a2',
    grey3: '#adc3c7',
    grey4: '#ccdadd',
    grey5: '#e7edef',
    grey6: '#f3f6f7',
    grey7: '#F6F8F9',
    grey8: '#f9f9f9',
    background: '#e3ecef',
    transparent: 'rgba(0, 69, 85, 0.55)',
  },
  primaryBackground: '#ffffff',
  redColorPalette: {
    main: '#da4c49',
    light1: '#ef5350',
    light2: '#ff8a80',
  },
  radius,
  size,
  secondaryColorPalette: {
    main: '#40B4A8',
    light1: '#47C7BB',
    light2: '#71d3c9',
    light3: '#b3e7e2',
    light4: '#cef0ec',
    light5: '#e7f7f6',
    transparent: 'rgba(71, 199, 187, 0.07)',
  },
  laerdalPalette: {
    primary: { default: '#2b809d', heavy: '#124456', light1: '#b0d7e7', light2: '#e6f7ff' },
    accent1: { default: '#299791', heavy: '#094847', light1: '#a1d9d5', light2: '#ddf8f8' },
    accent2: { default: '#f7d6a5', heavy: '#673327', medium: '#af8566', light: '#fcf3e5' },
    black: '#1F1F1F',
    grey: '#949494',
    correct: '#37A851',
    critical: '#DC3449',
    warning: '#E97116',
    background: 'rgba(255, 255, 255, 1)',
  },
};

export const GlobalStyle = createGlobalStyle`
    * {
        font-smoothing: antialiased;
        font-stretch: normal;
        font-style: normal;
        text-rendering: optimizeLegibility;
    }

    body {
        background-color: ${(props) => props.theme.primaryBackground};
        font-family: ${(props) => props.theme.fontFamily};
        font-size: ${(props) => props.theme.fontSize.regular};
        font-weight: ${(props) => props.theme.fontWeight.regular};
        height: 100%;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    img {
        width: 100%;
    }

    a {
        text-decoration:none
    }

    html,
    body,
    #root {
        height: 100%;
        width: 100%;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
`;
