import styled from 'styled-components';
import SubTitle from '../../../../components/Texts/SubTitle';
import Text from '../../../../components/Texts/Text';

const style = {};

style.Row = styled.div`
  display: flex;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  flex-direction: row;
`;

style.Input = styled.input`
  margin-bottom: 5px;
`;

style.InputLabel = styled.label`
  color: ${props => props.theme.greyPalette.black};
  font-weight: ${props => props.theme.fontWeight.regular}
  margin-left: 5px;
  margin-top: -2px;
`;

style.Items = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 7px;
  ${({ padded }) => (padded && 'padding-top: 7px')};
  width: ${props => props.width || '100%'};
`;

style.Title = styled(SubTitle)`
  color: ${props => props.theme.secondaryColorPalette.light1};
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    color: ${props => props.theme.secondaryColorPalette.main};
  }

  &>svg {
    margin-left: 5px;
  }
`;

style.ItemGroup = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  padding: 0;

  &:nth-child(odd) {
    background-color: ${props => props.theme.greyPalette.background}
  }

  &:nth-child(even) {
    background-color: ${props => props.theme.greyPalette.grey8}
  }
`;

style.ItemGroupLabel = styled(Text)`
  flex: 0 1 150px;
  ${props => props.singleColumn && `
    flex: 0 1 180px;
  `}
  margin: 0;
  padding: 7px;
`;

style.MultiModeItem = styled.input`
`;

style.MultiModeItemLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

style.MultiModeItemCont = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 30%;
  justify-content: center;
  position: relative;
`;

style.ColumnHeader = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
`;

style.ColumnHeaderStubCell = styled.div`
  flex: 0 1 150px;
`;

style.ColumnHeaderCell = styled(Text)`
  margin-bottom: 5px;
  flex: 1 1 30%;
  text-align: center;
`;

export default style;
