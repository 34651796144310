import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import Button from './Button';
import { theme } from '../../theme/theme';
import TooltipPortal from '../common/TooltipPortal';

const TooltipButton = (props) => {
  const {
    big,
    children,
    className,
    color,
    disabled,
    label,
    onClick,
    onMouseDown,
    onMouseUp,
    title,
    toolTip,
    toolTipOffset,
    toolTipPosition
  } = props;

  const btnRef = useRef();
  const [toolTipVisible, setToolTipVisible] = useState(false);

  const handleMouseEnter = () => {
    setToolTipVisible(true);
  };

  const handleMouseLeave = () => {
    setToolTipVisible(false);
  };

  const handleMouseOut = () => {
    // setToolTipVisible(false);
  };


  return (
    <Root
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      onMouseOut={() => handleMouseOut()}
      ref={btnRef}
    >
      <Button
        big={big}
        className={className}
        color={color}
        disabled={disabled}
        label={label}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        title={title}
      >
        {children}
      </Button>
      {disabled && <Cover />}

      <StyledTooltip
        offset={toolTipOffset}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        parent={btnRef.current}
        position={toolTipPosition}
        visible={Boolean(toolTip && toolTipVisible)}
      >
        {toolTip}
      </StyledTooltip>
    </Root>
  );
};

TooltipButton.propTypes = {
  big: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.oneOf([null])]),
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  title: PropTypes.string,
  toolTip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toolTipOffset: PropTypes.number,
  toolTipPosition: PropTypes.number
};

TooltipButton.defaultProps = {
  big: false,
  children: null,
  className: '',
  color: theme.inverseColor,
  disabled: false,
  label: '',
  onClick: Function.prototype,
  onMouseDown: Function.prototype,
  onMouseUp: Function.prototype,
  title: '',
  toolTip: '',
  toolTipOffset: 0,
  toolTipPosition: 0
};

export default TooltipButton;

const Root = styled.div`
  position: relative;
`;

const Cover = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledTooltip = styled(TooltipPortal)`
  font-size: ${theme.fontSize.regular};
  max-width: 300px;
`;
