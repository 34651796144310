import styled from 'styled-components';

const Text = styled.p`
    color: ${props => (props.error ? props.theme.redColorPalette.main : props.theme.greyPalette.black)};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.regular};
    letter-spacing: 0.25px;
    margin: 0;
    padding: 0;
`;

export default Text;
