import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

const Input = (props) => {
  const {
    className,
    filter,
    maxLength,
    name,
    placeholder,
    type,
    value,
    withBorder,
    ...rest
  } = props;


  const [oldValueObject, setOldValueObject] = useState({
    value,
    selectionStart: 0,
    selectionEnd: 0
  });

  const onInputEventInternal = (e, eventName) => {

    const {
      value: currentValue,
      selectionStart,
      selectionEnd
    } = e.target;

    const filterResults = [
    ];

    // Filter by length
    if (maxLength) {
      filterResults.push(currentValue.length <= maxLength);
    }

    // Filter by RegExp or by function
    if (filter) {
      filterResults.push(typeof filter === 'function'
        ? filter(currentValue)
        : new RegExp(filter).test(currentValue));
    }

    const filtersPassed = filterResults.length === 0 || filterResults.reduce((acc, curr) => acc && curr);

    if (filtersPassed) {
      setOldValueObject({
        value: currentValue,
        selectionStart,
        selectionEnd
      });
    } else {
      e.target.value = oldValueObject.value;
      e.target.setSelectionRange(oldValueObject.selectionStart, oldValueObject.selectionEnd);
    }

    if (props[eventName]) {
      props[eventName](e);
    }
  };

  return (
    <InputFiled
      {...rest}
      className={className}
      name={name}
      onChange={e => onInputEventInternal(e, 'onChange')}
      onContextMenu={e => onInputEventInternal(e, 'onContextMenu')}
      onDrop={e => onInputEventInternal(e, 'onDrop')}
      onKeyDown={e => onInputEventInternal(e, 'onKeyDown')}
      onKeyUp={e => onInputEventInternal(e, 'onKeyUp')}
      onInput={e => onInputEventInternal(e, 'onInput')}
      onMouseDown={e => onInputEventInternal(e, 'onMouseDown')}
      onMouseUp={e => onInputEventInternal(e, 'onMouseUp')}
      onSelect={e => onInputEventInternal(e, 'onSelect')}
      placeholder={placeholder}
      type={type}
      value={value}
      withBorder={withBorder}
    />
  );
};

Input.propTypes = {
  className: PropTypes.string,

  // Can be a user-defined function OR
  // a RegExp value (its string representation without trailing and leading '/') to filter user input
  // Here are frequently used examples (some are Regexes and some are functions):
  // Integer values (positive only):
  // /^\d*$/.test(value)
  // Integer values (positive and up to a particular limit):
  // /^\d*$/.test(value) && (value === "" || parseInt(value) <= 500)
  // Integer values (both positive and negative):
  // /^-?\d*$/.test(value)
  // Floating point values (allowing both . and , as decimal separator):
  // /^-?\d*[.,]?\d*$/.test(value)
  // Currency values (i.e. at most two decimal places):
  // /^-?\d*[.,]?\d{0,2}$/.test(value)
  // A-Z only (i.e. basic Latin letters):
  // /^[a-z]*$/i.test(value)
  // Latin letters only (i.e. English and most European languages,
  // see https://unicode-table.com for details about Unicode character ranges):
  // /^[a-z\u00c0-\u024f]*$/i.test(value)
  // Hexadecimal values:
  // /^[0-9a-f]*$/i.test(value)
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,

  /* eslint-disable  react/no-unused-prop-types */
  onChange: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDrop: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onInput: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onSelect: PropTypes.func,
  /* eslint-enable  react/no-unused-prop-types */

  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  withBorder: PropTypes.bool
};

Input.defaultProps = {
  className: '',
  filter: '',
  maxLength: 0,
  onChange: Function.prototype,
  onContextMenu: Function.prototype,
  onDrop: Function.prototype,
  onKeyDown: Function.prototype,
  onKeyUp: Function.prototype,
  onInput: Function.prototype,
  onMouseDown: Function.prototype,
  onMouseUp: Function.prototype,
  onSelect: Function.prototype,
  placeholder: '',
  type: 'text',
  withBorder: false
};

export default Input;

const InputFiled = styled.input`
  background: ${props => props.theme.inverseColor};
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => (props.withBorder ? props.theme.greyPalette.grey : props.theme.inverseColor)};
  color: ${props => props.theme.greyPalette.black};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.regular};
  padding: 10px 16px;
  width: 100%;

  &:focus {
    border: 1px solid ${props => props.theme.greyPalette.black};
    color: ${props => props.theme.greyPalette.black};
    outline: none;
    }

  ::placeholder {
    color: ${props => props.theme.greyPalette.grey};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
    -webkit-text-fill-color: ${props => props.theme.greyPalette.black};
    font-size: ${props => props.theme.fontSize.medium};
  }
`;
