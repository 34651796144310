import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Textarea = (props) => {
  const {
    className,
    onChange,
    placeholder,
    value
  } = props;

  return (
    <InputFiled
      className={className}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired
};

Textarea.defaultProps = {
  className: '',
  placeholder: ''
};

export default Textarea;

const InputFiled = styled.textarea`
  background: ${props => props.theme.inverseColor};
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.greyPalette.grey};
  color: ${props => props.theme.greyPalette.black};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.regular};
  padding: 10px 16px;
  resize: none;
  width: 100%;

  &:focus {
    border: 1px solid ${props => props.theme.greyPalette.black};
    color: ${props => props.theme.greyPalette.black};
    outline: none;
    }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
    -webkit-text-fill-color: ${props => props.theme.greyPalette.black};
    font-size: ${props => props.theme.fontSize.medium};
  }
`;
