import { DateTime } from 'luxon';
import {
  ASSET_TYPE,
  ALARM_TYPES,
  ALARM_TYPES_DIC,
  ALARM_TYPES_TEXT,
  ALARM_TYPES_WEIGHT,
  HANDLED_STATE,
  manikinTypeWeights,
  PROGRAM,
  VA_OPTIONS,
  DECOMMISSIONED_STATE,
} from '../constants/constants';
import { sortAlarmTypes } from './alarm';

export const getManikinTypeByAssetId = (assetId) => (assetId && assetId.startsWith('16') ? ASSET_TYPE.infant : ASSET_TYPE.adult);

// Returns regexp filter for manikin's serial number
// based on type and program. Rules are the following:
// RQI_NUMBERS = ['178', '163']
// HEART_CODE = ['177', '162']
export const getAssetIdFilter = (type, program) => {
  // ^$|^1$|^16$|17$|(^1[6|7]+\d+$)
  // ^$|^1$|^16$|^16[8|3]?$|^16[8|3]\d+$

  let programGroup = '\\d+';
  if (program === PROGRAM.RQI) {
    programGroup = '[8|3]';
  } else if (program === PROGRAM.HeartCode) {
    programGroup = '[7|2]';
  }

  const typeDigit = type === ASSET_TYPE.adult ? '7' : '6';
  let result = `^$|^1$|^1${typeDigit}$|^1${typeDigit}`;

  if (program === PROGRAM.RQI || program === PROGRAM.HeartCode) {
    result += `${programGroup}?$|^1${typeDigit}${programGroup}\\d+`;
  } else {
    result += `[014569]$|^1${typeDigit}[014569]+${programGroup}`;
  }
  result += '$';

  return result;
};

const getStringFilterFunction = (weight) => {
  if (!weight) {
    weight = 1;
  }
  return (a, b) => {
    if (a < b) {
      return -weight;
    }

    if (a > b) {
      return weight;
    }

    return 0;
  };
};

export const formatDate = (date) => `${date.toString('MMMM').slice(3, 7)} ${date.getDate()} ${date.getFullYear()}`;

export class ManikinDecorator {
  decorateMaintenance = (manikin) => {
    const { lastMaintenance } = manikin.info;

    if (!lastMaintenance) {
      return {
        ...manikin,
        info: {
          ...manikin.info,
          lastMaintenance: 'none',
          lastMaintenanceMs: 0,
        },
      };
    }

    const lmDate = new Date(lastMaintenance);

    return {
      ...manikin,
      info: {
        ...manikin.info,
        lastMaintenance: formatDate(lmDate),
        lastMaintenanceMs: lmDate.getTime(),
      },
    };
  };

  decorateProduction = (manikin) => {
    const { productionDate } = manikin.info;

    if (!productionDate) {
      return {
        ...manikin,
        info: {
          ...manikin.info,
          productionDate: 'none',
          productionDateMs: 0,
        },
      };
    }

    const prDate = new Date(productionDate);

    return {
      ...manikin,
      info: {
        ...manikin.info,
        productionDate: formatDate(prDate),
        productionDateMs: prDate.getTime(),
      },
    };
  };

  decorateAlarms = (manikin) => {
    const alarms = [...manikin.alarms];
    const decoratedAlarms = alarms
      .map((alarm) => ({
        ...alarm,
        alarmType: ALARM_TYPES_DIC[alarm.alarmType],
      }))
      .sort(sortAlarmTypes);

    return {
      ...manikin,
      alarms: decoratedAlarms,
    };
  };

  decorate = (rawManikin) => {
    let manikin = {
      ...rawManikin,
    };
    manikin = this.decorateMaintenance(manikin);
    manikin = this.decorateProduction(manikin);
    manikin = this.decorateAlarms(manikin);

    return manikin;
  };
}

const compareVersions = (versionA, versionB) => {
  const numberVersionA = versionA.split('.').map(Number);
  const numberVersionB = versionB.split('.').map(Number);

  for (let i = 0; i < numberVersionA.length; i++) {
    const va = numberVersionA[i];
    const vb = numberVersionB[i];

    if (va === vb) {
      continue; // eslint-disable-line no-continue
    }

    return va - vb;
  }

  return 0;
};

export const sortFunctions = {
  compressions: (a, b) => {
    let compressionsA = a.info.compressions;
    let compressionsB = b.info.compressions;
    compressionsA = compressionsA.maintenance > -1 ? compressionsA.maintenance : compressionsA.production;
    compressionsB = compressionsB.maintenance > -1 ? compressionsB.maintenance : compressionsB.production;

    return compressionsA - compressionsB;
  },
  compressionsAsc: (a, b) => {
    let compressionsA = a.info.compressions;
    let compressionsB = b.info.compressions;
    compressionsA = compressionsA.maintenance > -1 ? compressionsA.maintenance : compressionsA.production;
    compressionsB = compressionsB.maintenance > -1 ? compressionsB.maintenance : compressionsB.production;

    return compressionsB - compressionsA;
  },
  multipleFailure: (a, b) => {
    const { alarms: alarmsA } = a;
    const { alarms: alarmsB } = b;

    return alarmsB.length - alarmsA.length;
  },
  multipleFailureAsc: (a, b) => 0 - sortFunctions.multipleFailure(a, b),
  failureType: (a, b) => {
    const { alarms: alarmsA } = a;
    const { alarms: alarmsB } = b;

    if (!alarmsA.length && alarmsB.length) {
      return 1;
    }
    if (alarmsA.length && !alarmsB.length) {
      return -1;
    }
    if (!alarmsA.length && !alarmsB.length) {
      return 0;
    }

    const failureA = alarmsA[0].alarmType;
    const failureB = alarmsB[0].alarmType;
    const failureWeightA = ALARM_TYPES_WEIGHT[failureA];
    const failureWeightB = ALARM_TYPES_WEIGHT[failureB];

    if (alarmsA.length > 1 || alarmsB.length > 1) {
      return sortFunctions.multipleFailure(a, b);
    }

    if (failureWeightA < failureWeightB) {
      return -1;
    }
    if (failureWeightA > failureWeightB) {
      return 1;
    }

    if (failureA === ALARM_TYPES.UNDERUTILIZED && failureB === ALARM_TYPES.UNDERUTILIZED) {
      return sortFunctions[ALARM_TYPES.UNDERUTILIZED](a, b);
    }

    if (failureA === ALARM_TYPES.ANOMALY && failureB === ALARM_TYPES.ANOMALY) {
      return sortFunctions[`${ALARM_TYPES.ANOMALY}`](a, b);
    }

    return 0;
  },
  failureTypeAsc: (a, b) => 0 - sortFunctions.failureType(a, b),
  hospital: (a, b) => {
    const hospitalA = a.placeName;
    const hospitalB = b.placeName;

    if (hospitalA < hospitalB) {
      return -1;
    }
    if (hospitalA > hospitalB) {
      return 1;
    }

    return 0;
  },
  hospitalFiltered: (filterValue) => (a, b) => {
    const hospitalA = a.placeName;
    const hospitalB = b.placeName;

    if (filterValue) {
      const hospitalAStarts = hospitalA && hospitalA.toLowerCase().startsWith(filterValue);
      const hospitalBStarts = hospitalB && hospitalB.toLowerCase().startsWith(filterValue);

      if (hospitalAStarts && !hospitalBStarts) {
        return -2;
      }

      if (!hospitalAStarts && hospitalBStarts) {
        return 2;
      }

      if (hospitalAStarts && hospitalBStarts) {
        return getStringFilterFunction(2)(hospitalA, hospitalB);
      }
    }

    return getStringFilterFunction()(hospitalA, hospitalB);
  },
  hospitalAsc: (a, b) => {
    const hospitalA = a.placeName;
    const hospitalB = b.placeName;

    if (hospitalA > hospitalB) {
      return -1;
    }
    if (hospitalA < hospitalB) {
      return 1;
    }

    return 0;
  },
  facility: (a, b) => {
    const facilityA = a.attrs.facility;
    const facilityB = b.attrs.facility;

    if (facilityA < facilityB) {
      return -1;
    }
    if (facilityA > facilityB) {
      return 1;
    }

    return 0;
  },
  facilityAsc: (a, b) => {
    const facilityA = a.attrs.facility;
    const facilityB = b.attrs.facility;

    if (facilityA > facilityB) {
      return -1;
    }
    if (facilityA < facilityB) {
      return 1;
    }

    return 0;
  },
  location: (a, b) => {
    const locationA = a.attrs.location;
    const locationB = b.attrs.location;

    if (locationA < locationB) {
      return -1;
    }
    if (locationA > locationB) {
      return 1;
    }

    return 0;
  },
  locationAsc: (a, b) => {
    const locationA = a.attrs.location;
    const locationB = b.attrs.location;

    if (locationA > locationB) {
      return -1;
    }
    if (locationA < locationB) {
      return 1;
    }

    return 0;
  },
  assetId: (a, b) => {
    const assetIdA = a.assetId;
    const assetIdB = b.assetId;

    if (assetIdA < assetIdB) {
      return -1;
    }
    if (assetIdA > assetIdB) {
      return 1;
    }

    return 0;
  },
  assetIdAsc: (a, b) => {
    const assetIdA = a.assetId;
    const assetIdB = b.assetId;

    if (assetIdA > assetIdB) {
      return -1;
    }
    if (assetIdA < assetIdB) {
      return 1;
    }

    return 0;
  },
  maintenance: (a, b) => {
    const maintA = a.info.lastMaintenanceMs;
    const maintB = b.info.lastMaintenanceMs;

    return maintB - maintA;
  },
  maintenanceAsc: (a, b) => {
    const maintA = a.info.lastMaintenanceMs;
    const maintB = b.info.lastMaintenanceMs;

    return maintA - maintB;
  },
  age: (a, b) => {
    let ageA = a.info.age;
    let ageB = b.info.age;
    ageA = ageA.maintenance > -1 ? ageA.maintenance : ageA.production;
    ageB = ageB.maintenance > -1 ? ageB.maintenance : ageB.production;

    return ageA - ageB;
  },
  ageAsc: (a, b) => {
    let ageA = a.info.age;
    let ageB = b.info.age;
    ageA = ageA.maintenance > -1 ? ageA.maintenance : ageA.production;
    ageB = ageB.maintenance > -1 ? ageB.maintenance : ageB.production;

    return ageB - ageA;
  },
  manikinType: (a, b) => {
    const { type: typeA, program: programA } = a;
    const { type: typeB, program: programB } = b;
    const programStringA = `${programA}-${typeA}`;
    const programStringB = `${programB}-${typeB}`;
    const weightA = manikinTypeWeights[programStringA];
    const weightB = manikinTypeWeights[programStringB];

    return weightB - weightA;
  },
  manikinTypeAsc: (a, b) => {
    const { type: typeA, program: programA } = a;
    const { type: typeB, program: programB } = b;
    const programStringA = `${programA}-${typeA}`;
    const programStringB = `${programB}-${typeB}`;
    const weightA = manikinTypeWeights[programStringA];
    const weightB = manikinTypeWeights[programStringB];

    return weightA - weightB;
  },
  [ALARM_TYPES.UNDERUTILIZED]: (a, b) => {
    const notUsedA = a.notUsedIn;
    const notUsedB = b.notUsedIn;

    return notUsedA - notUsedB;
  },
  [`${ALARM_TYPES.UNDERUTILIZED}Asc`]: (a, b) => {
    const notUsedA = a.notUsedIn;
    const notUsedB = b.notUsedIn;

    return notUsedB - notUsedA;
  },
  [ALARM_TYPES.ANOMALY]: (a, b) => {
    const { alarms: alarmsA } = a;
    const { alarms: alarmsB } = b;
    const alarmTextA = alarmsA.find((alarm) => alarm.alarmType === ALARM_TYPES.ANOMALY).alarmDescription;
    const alarmTextB = alarmsB.find((alarm) => alarm.alarmType === ALARM_TYPES.ANOMALY).alarmDescription;
    const anomaliesRegExp = new RegExp(/\d+/);
    const anomaliesA = parseInt(anomaliesRegExp.exec(alarmTextA)[0], 10);
    const anomaliesB = parseInt(anomaliesRegExp.exec(alarmTextB)[0], 10);

    return anomaliesB - anomaliesA;
  },
  [`${ALARM_TYPES.ANOMALY}Asc`]: (a, b) => 0 - sortFunctions[ALARM_TYPES.ANOMALY](a, b),
  alarmImportanceRating: (a, b) => {
    const impA = a.alarmImportanceRating;
    const impB = b.alarmImportanceRating;

    return impA - impB;
  },
  firmwareVersionAsc: (a, b) => {
    const fwVersionA = a.firmwareVersion;
    const fwVersionB = b.firmwareVersion;

    if (!fwVersionA && !fwVersionB) {
      return 0;
    }

    if (fwVersionA && !fwVersionB) {
      return -1;
    }

    if (!fwVersionA && fwVersionB) {
      return 1;
    }

    return compareVersions(fwVersionA, fwVersionB);
  },
  firmwareVersionGroup: (sourceManikins) => sortFunctions.firmwareVersionGroupAsc(sourceManikins),
  firmwareVersionGroupAsc: (sourceManikins) => {
    const manikins = [...sourceManikins];
    const groups = {};
    const ungrouped = [];
    const weird = [];

    manikins.sort((a, b) => {
      const versionArrayA = a.firmwareVersion;
      const versionArrayB = b.firmwareVersion;

      return compareVersions(versionArrayA, versionArrayB);
    });

    manikins.forEach((manikin) => {
      if (manikin.placeName === 'Unknown' || !manikin.firmwareVersion) {
        weird.push(manikin);
      } else if (compareVersions('1.14', manikin.firmwareVersion) < 0) {
        ungrouped.push(manikin);
      } else {
        const { hospitalId } = manikin;
        const group = groups[hospitalId] ? groups[hospitalId].slice() : [];

        groups[hospitalId] = group.concat(manikin);
      }
    });

    const groupArrays = Object.keys(groups).reduce((acc, hospitalId) => {
      const group = groups[hospitalId];

      return [...acc, group];
    }, []);

    groupArrays.sort((a, b) => {
      const lowestVerA = a[0].firmwareVersion;
      const lowestVerB = b[0].firmwareVersion;

      return compareVersions(lowestVerA, lowestVerB);
    });

    const grouped = groupArrays.reduce((acc, group) => acc.concat(group), []);

    return grouped.concat(ungrouped, weird);
  },
};

export const decodeAlarmType = (alarmType) => {
  const failureTypesTexts = Object.keys(ALARM_TYPES_DIC);

  for (let i = 0; i < failureTypesTexts.length; i++) {
    const failureTypeText = failureTypesTexts[i];

    if (ALARM_TYPES_DIC[failureTypeText] === alarmType) {
      return failureTypeText;
    }
  }

  return 'healthy';
};

export const humanReadableAlarmType = (failureType, manikinId) => {
  const failureTypesTexts = Object.keys(ALARM_TYPES_TEXT);
  let result = 'Healthy manikin';
  for (let i = 0; i < failureTypesTexts.length; i++) {
    const failureTypeText = failureTypesTexts[i];

    if (ALARM_TYPES_TEXT[failureTypeText] === failureType) {
      result = failureTypeText;
    }
  }

  if (manikinId) {
    result = `${result} for manikin ${manikinId}`;
  }

  return result;
};

export const getSortedManikins = (manikins, sortType, direction) => {
  const sortFunctionKey = `${sortType}${direction === 'asc' ? 'Asc' : ''}`;
  const sortFunction = sortFunctions[sortFunctionKey];

  if (sortFunctionKey.includes('firmwareVersionGroup')) {
    return sortFunction(manikins);
  }

  return manikins.slice().sort(sortFunction);
};

export const manikinHandled = (manikin) => manikin.action.actionType.length > 0;

export const manikinFromStartScreen = (manikin) => manikin.action.fromStartScreen;

export const filterByText = (manikin, text) => {
  if (!text) {
    return true;
  }

  const { placeName, assetId } = manikin;
  const lowerCaseText = text.toLowerCase();
  const lowerCasePlaceName = placeName.toLowerCase();
  const foundInPlaceName = lowerCasePlaceName.includes(lowerCaseText);
  const foundInId = String(assetId).includes(text);

  return foundInId || foundInPlaceName;
};

export const filterByManikinType = (manikin, allowedTypes) => {
  if (!allowedTypes.length) {
    return true;
  }

  return allowedTypes.includes(manikin.type);
};

export const filterByHealth = (manikin, allowedHealth) => {
  if (allowedHealth === 'any') {
    return true;
  }

  const healthy = manikin.alarms.length === 0;

  return healthy === (allowedHealth === 'healthy');
};

export const filterByVA = (manikin, hospitals, vaOption) => {
  const hospital = hospitals.find((h) => h.hospitalId === manikin.hospitalId);
  const isVA = hospital.isVAHospital;

  return isVA === (vaOption === VA_OPTIONS.VA);
};

export const filterByAlarmType = (manikin, allowedTypes) => {
  if (!allowedTypes.length) {
    return true;
  }
  const { alarms } = manikin;

  return alarms.find((alarm) => allowedTypes.includes(alarm.alarmType));
};

export const filterByProgram = (manikin, allowedPrograms) => {
  if (!allowedPrograms.length) {
    return true;
  }

  const { program } = manikin;

  return allowedPrograms.includes(program);
};

export const filterByDecommissioned = (manikin, decommission) => {
  const { isDecommissioned } = manikin.attrs;

  const includeDecommissioned = decommission.includes(DECOMMISSIONED_STATE.DECOMMISSIONED);
  const includeInService = decommission.includes(DECOMMISSIONED_STATE.IN_SERVICE);
  const includeAll = includeDecommissioned && includeInService;

  return includeAll || (includeInService && !isDecommissioned) || (includeDecommissioned && isDecommissioned);
};

export const filterByHandled = (manikin, allowedHandled) => {
  if (allowedHandled.length === 0) {
    return true;
  }

  if (allowedHandled === HANDLED_STATE.HANDLED) {
    return manikinHandled(manikin);
  }

  return !manikinHandled(manikin);
};

export const filterByHospitalId = (manikin, hospitalId) => {
  if (!Number.isInteger(hospitalId)) {
    return true;
  }

  return manikin.hospitalId === hospitalId;
};

export const filterByState = (manikin, hospitals, allowedStates) => {
  if (allowedStates.length === 0) {
    return true;
  }

  const { hospitalId } = manikin;
  const hospital = hospitals.find((h) => h.hospitalId === hospitalId);
  const { usState } = hospital.location;
  return allowedStates.includes(usState);
};

export const filterByCountry = (manikin, hospitals, allowedCountries) => {
  if (allowedCountries.length === 0) {
    return true;
  }

  const { hospitalId } = manikin;
  const hospital = hospitals.find((h) => h.hospitalId === hospitalId);
  const { country } = hospital.location;
  return allowedCountries.includes(country);
};

export const filterArray = (arr, fns) => fns.reduce((res, fn) => fn(res), arr);

export const passesAll = (val, fns) => fns.reduce((res, fn) => (!res ? res : fn(val)), true);

const escapeCsvField = (value) => `"${value.replace(/"/g, '""')}"`;

const getDate = (dateNum) => (dateNum ? DateTime.fromMillis(dateNum).toFormat('yyyy-MM-dd') : '');

const getDateFromIso = (dateNum) => (dateNum ? DateTime.fromISO(dateNum).toFormat('yyyy-MM-dd') : '');

export const generateCsv = (manikins) => {
  // Add headers for all columns
  const header =
    'Serial,Hospital,Facility,Cart location,Station serial,Laptop serial,Laptop model,Laptop SIM card,Type,Program,' +
    'Last maintenance,Production date,Compressions since production date,Compressions since last maintenance,' +
    'Ventilations since production date,Ventilations since last maintenance,' +
    'Decommissioned,Added manually,Location edited,Attributes last edited,Failuretypes and descriptions' +
    '\r\n';
  return manikins.reduce((res, m) => {
    const { assetId, attrs, info, placeName, program, type, alarms } = m;

    const { compressions, lastMaintenanceMs, productionDateMs, ventilations } = info;

    const { cartId, facility, isAddedManually, isDecommissioned, location, laptopId, laptopModel, laptopSimId } = attrs;

    // eslint-disable-next-line prefer-template
    return (
      res +
      assetId +
      ',' +
      escapeCsvField(placeName) +
      ',' +
      escapeCsvField(facility) +
      ',' +
      escapeCsvField(location) +
      ',' +
      escapeCsvField(cartId) +
      ',' +
      escapeCsvField(laptopId) +
      ',' +
      escapeCsvField(laptopModel) +
      ',' +
      escapeCsvField(laptopSimId) +
      ',' +
      type +
      ',' +
      program +
      ',' +
      getDate(lastMaintenanceMs) +
      ',' +
      getDate(productionDateMs) +
      ',' +
      compressions.production +
      ',' +
      compressions.maintenance +
      ',' +
      ventilations.production +
      ',' +
      ventilations.maintenance +
      ',' +
      (isDecommissioned ? 'yes' : 'no') +
      ',' +
      (isAddedManually ? 'yes' : 'no') +
      ',' +
      (attrs.hospitalId > 0 ? 'yes' : 'no') +
      ',' +
      (attrs.hospitalId > 0 ? getDateFromIso(attrs.dateTime) : '') +
      ',' +
      (alarms.map(x=> x != '' ? escapeCsvField(x.alarmType + ': ' + x.alarmDescription) + ',' : '')).join('').slice(0,-1) +
      '\r\n'
    );
  }, header);
};

export default {
  decodeFailureType: humanReadableAlarmType,
};
