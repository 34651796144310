import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AssetsListContainer from '../listview/AssetsList/AssetsListContainer';
import { CardDescription } from '../../components/Card/Card';
import HeaderFilter from '../listview/Header/HeaderFilter';
import NameValueListItem from '../listview/ListItem/NameValueListItem';
import Title from '../../components/Texts/Title';

import {
  ManikinShape,
  HospitalShape
} from '../../constants/types';

import { setHospitalFilterAction } from '../../actions/filters';

import s from './HospitalStatistics.style';
import SimpleList from '../listview/SimpleList/SimpleList';


function getHospitalManikins(manikins, hospitalId, filterFunc) {
  return hospitalId ? manikins.filter(m => m.hospitalId === hospitalId && (filterFunc ? filterFunc(m) : true)) : [];
}

const getCurrentHospital = (hospitals, hospitalId) => {
  const hid = Number.parseInt(hospitalId, 10);
  const hospital = hospitals.find(h => h.hospitalId === hid);
  return hospital || null;
};

const manikinHasDl = m => m.facility || m.location;
const manikinNoDl = m => !m.facility && !m.location;

const HospitalStatistics = (props) => {
  const {
    hospitals,
    loading,
    manikins,
    match,
    setFilterAction
  } = props;

  // Explicitly set current hospital in case user's just enterd Url in a browser
  useEffect(() => {
    const { params: { hospitalId: currentHospitalId } } = match;
    const hospital = getCurrentHospital(hospitals, currentHospitalId);

    if (hospital) {
      setFilterAction(hospital.hospitalId, hospital.placeName);
    }

  }, [match]);

  useEffect(() => () => setFilterAction(''));

  const { params: { hospitalId: currentHospitalId } } = match;
  const hospitalId = Number.parseInt(currentHospitalId, 10);
  const hospital = getCurrentHospital(hospitals, hospitalId);

  return (
    <s.Root>
      <s.PageTitle>
        <Title>
          {hospital && hospital.placeName}
        </Title>
      </s.PageTitle>
      <s.Cards>
        <s.Card
          title="Hospital manikins"
        >
          Some content goes here
        </s.Card>
        <s.Card
          fixed
          title={(
            <s.CardTitleWithDescription>
              <s.SubTitle>Manikins overview</s.SubTitle>
              <CardDescription> (click on a row to load details)</CardDescription>
            </s.CardTitleWithDescription>
          )}
        >
          <SimpleList
            header={<HeaderFilter />}
            rowRenderer={<NameValueListItem />}
            items={[{ label: 'filter', value: { val: 22 } }]}
            loading={loading}
          />
        </s.Card>
        <s.Card
          fixed
          title="Firmware upgrade required"
        >
          Some content goes here
        </s.Card>
      </s.Cards>
    </s.Root>
  );
};

HospitalStatistics.propTypes = {
  hospitals: PropTypes.arrayOf(HospitalShape).isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  manikins: PropTypes.arrayOf(ManikinShape).isRequired,
  setFilterAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : [],
  hospitals: state.manicDataReducer.data ? state.manicDataReducer.data.hospitals : []
});

const mapDispatchToProps = dispatch => ({
  setFilterAction: (hospitalId, hospitalName) => dispatch(setHospitalFilterAction(hospitalId, hospitalName))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HospitalStatistics));
