import styled from 'styled-components';

const s = {};
export const mainInfoHeight = 32;
export const defaultItemsVisible = 12;
export const defaultHeight = defaultItemsVisible * mainInfoHeight;
export const headerHeight = 56;

export const getListHeight = ({ itemsVisible, height }) => {
  if (height) {
    return height + headerHeight;
  }

  const resHeight = mainInfoHeight
    * (itemsVisible || defaultItemsVisible);

  return resHeight < (height || defaultHeight)
    ? resHeight
    : (height || defaultHeight);
};

s.Root = styled.div`
  height: auto;
  width: 100%;
`;

s.Header = styled.div`
  display: flex;
  height: 45px;
  min-height: 45px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  border-top: 1px solid ${props => props.theme.borderColor};
`;

export default s;
