/* eslint-disable @typescript-eslint/no-var-requires */
import {
  IoIosAlert,
  IoIosBug,
  IoIosBuild,
  IoIosHappy,
  IoIosSad,
  IoMdAnalytics,
  IoMdCloseCircle,
  IoMdConstruct,
  IoMdCodeDownload,
  IoMdHeartDislike,
  IoMdClose,
} from 'react-icons/io';

import adultIcon from '../ui/dashboard/FiltersPanel/adult.svg';
import infantIcon from '../ui/dashboard/FiltersPanel/baby.svg';
import duckIcon from '../ui/dashboard/FiltersPanel/duck.svg';

import DecommissionIcon from './DecommissionIcon';

export const SENT_DATA_REQUEST = 'SENT_DATA_REQUEST';
export const ERROR_DATA_REQUEST = 'ERROR_DATA_REQUEST';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const SET_GOOGLE_URL_DATA = 'SET_GOOGLE_URL_DATA';
export const UPDATE_REQ_DATA_MANIC = 'UPDATE_REQ_DATA_MANIC';
export const UPDATE_ASSET_ATTRS = 'UPDATE_ASSET_ATTRS';

export const SET_ASSET_UPDATE = 'SET_ASSET_UPDATE';
export const REMOVE_ASSET_UPDATE = 'REMOVE_ASSET_UPDATE';
export const RESET_UPDATES = 'RESET_UPDATES';

export const SET_ASSET_ALARMS = 'SET_ASSET_ALARMS';
export const RESET_ASSET_ALARM = 'RESET_ASSET_ALARM';

export const SET_ASSET_IS_DECOMMISSIONED = 'SET_ASSET_IS_DECOMMISSIONED';

export const RESET_FILTER = 'RESET_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_FILTER_HOSPITAL = 'UPDATE_FILTER_HOSPITAL';
export const UPDATE_FILTER_HIGHLIGHT_HOSPITAL = 'UPDATE_FILTER_HIGHLIGHT_HOSPITAL';
export const UPDATE_LAST_SELECTED_HOSPITAL_ID = 'UPDATE_LAST_SELECTED_HOSPITAL_ID';
export const UPDATE_PRE_SELECTED_ASSET_ID = 'UPDATE_PRE_SELECTED_ASSET_ID';
export const UPDATE_TEXT_FILTER = 'UPDATE_TEXT_FILTER';
export const UPDATE_TEXT_FILTER_AND_RESET_OTHER = 'UPDATE_TEXT_FILTER_AND_RESET_OTHER';
export const GET_FILTER_ARRAY = 'GET_FILTER_ARRAY';
export const ERROR_FILTER_ARRAY = 'ERROR_FILTER_ARRAY';
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';
export const ADD_MANIKIN_TYPE_FILTER = 'ADD_MANIKIN_TYPE_FILTER';
export const REMOVE_MANIKIN_TYPE_FILTER = 'REMOVE_MANIKIN_TYPE_FILTER';
export const ADD_ALARM_TYPE_FILTER = 'ADD_ALARM_TYPE_FILTER';
export const REMOVE_ALARM_TYPE_FILTER = 'REMOVE_ALARM_TYPE_FILTER';
export const ADD_PROGRAM_FILTER = 'ADD_PROGRAM_FILTER';
export const REMOVE_PROGRAM_FILTER = 'REMOVE_PROGRAM_FILTER';
export const ADD_DECOMMISSIONED_FILTER = 'ADD_DECOMMISSIONED_FILTER';
export const REMOVE_DECOMMISSIONED_FILTER = 'REMOVE_DECOMMISSIONED_FILTER';
export const SET_HANDLED_FILTER = 'SET_HANDLED_FILTER';
export const SET_STATE_FILTER = 'SET_STATE_FILTER';
export const SET_COUNTRY_FILTER = 'SET_COUNTRY_FILTER';
export const SET_HEALTH_FILTER = 'SET_HEALTH_FILTER';
export const SET_VA_FILTER = 'SET_VA_FILTER';
export const SET_SORT = 'SET_SORT';
export const SET_SORT_DIRECTION = 'SET_SORT_DIRECTION';
export const SET_CURRENT_ALARMS = 'SET_CURRENT_ALARMS';
export const SET_DAILY_ALARMS = 'SET_DAILY_ALARMS';
export const SET_PREFERRED_STATES = 'SET_PREFERRED_STATES';
export const SET_MONITOR_QUERIES = 'SET_MONITOR_QUERIES';
export const SET_AVAILABLE_COUNTRIES = 'SET_AVAILABLE_COUNTRIES';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_KEYS = 'SET_KEYS';

// Descriptive location related action constants
export const SENT_ASSET_HISTORY_REQUEST = 'SENT_ASSET_HISTORY_REQUEST';
export const SHOW_DL_DIALOG = 'SHOW_DL_DIALOG';
export const ERROR_FACILITY_LIST_REQUEST = 'ERROR_FACILITY_LIST_REQUEST';
export const RECEIVED_FACILITY_LIST = 'RECEIVED_FACILITY_LIST';
export const SENT_FACILITY_LIST_REQUEST = 'SENT_FACILITY_LIST_REQUEST';
export const ERROR_ASSET_HISTORY_REQUEST = 'ERROR_ASSET_HISTORY_REQUEST';
export const RECEIVED_ASSET_HISTORY = 'RECEIVED_ASSET_HISTORY';
export const SENT_SET_ASSET_ATTR_REQUEST = 'SENT_SET_ASSET_ATTR_REQUEST';
export const RECEIVED_SET_ASSET_ATTR_REQUEST = 'RECEIVE_SET_ASSET_ATTR_REQUEST';
export const ERROR_SET_ASSET_ATTR_REQUEST = 'ERROR_SET_ASSET_ATTR_REQUEST';
// Related to descriptive locations but handled in dataReducer
export const ADD_NEW_ASSET = 'ADD_NEW_ASSET';
export const RECEIVED_HOSPITAL_ADDRESS = 'RECEIVED_HOSPITAL_ADDRESS';
export const SENT_HOSPITAL_LOCATION_REQUEST = 'SENT_HOSPITAL_LOCATION_DATA_REQUEST';
export const RECEIVED_ADD_HOSPITAL = 'RECEIVED_ADD_HOSPITAL';
export const MOVE_MANIKIN_TO_HOSPITAL = 'MOVE_MANIKIN_TO_HOSPITAL';
export const CHANGE_MANIKIN_HOSPITAL = 'CHANGE_MANIKIN_HOSPITAL';
export const CLEANUP_EMPTY_HOSPITALS = 'CLEANUP_EMPTY_HOSPITALS';
export const REFRESH_HOSPITAL_ALARMS = 'REFRESH_HOSPITAL_ALARMS';

// Repair report related action constants
export const SENT_REPAIR_REPORT_DATA_REQUEST = 'SENT_REPAIR_REPORT_DATA_REQUEST';
export const RECEIVED_REPAIR_REPORT_DATA_REQUEST = 'RECEIVED_REPAIR_REPORT_DATA_REQUEST';
export const ERROR_REPAIR_REPORT_DATA_REQUEST = 'ERROR_REPAIR_REPORT_DATA_REQUEST';

// Manikin event counters related action constants
export const SENT_EVENT_COUNTER_DATA_REQUEST = 'SENT_EVENT_COUNTER_DATA_REQUEST';
export const RECEIVED_EVENT_COUNTER_DATA_REQUEST = 'RECEIVED_EVENT_COUNTER_DATA_REQUEST';
export const ERROR_EVENT_COUNTER_DATA_REQUEST = 'ERROR_EVENT_COUNTER_DATA_REQUEST';

export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const RESPOMS_TOKEN = 'RESPOMS_TOKEN';
export const ERROR_TOKEN = 'ERROR_TOKEN';

export const ALARM_TYPES_TEXT = {
  'Decommissioned asset': 'ASSET_DECOMMISSION',
  'Predicted failure (old)': 'PREDICTED_FAILURE', // Deprecated
  'Predicted ventilation failure': 'PREDICTED_FAILURE_VENT',
  'Predicted compression failure': 'PREDICTED_FAILURE_COMP',
  'Service request': 'PREDICTED_FAILURE_INCURABLE',
  'Predicted failure': 'PREDICTED_FAILURE_OTHER',
  'Firmware issue': 'FIRMWARE_ISSUE',
  'Software issue': 'SOFTWARE_ISSUE', // Deprecated issue type. only used to show historical information in logs
  Anomaly: 'ANOMALY',
  'Event counter changed': 'EVENT_COUNTER_CHANGED', // Event counter has been changed and there's no failure
  'Underutilized manikin': 'UNDERUTILIZED',
  'In repair': 'IN_REPAIR',
  'Healthy manikin': 'HEALTHY',
  'User generated': 'USER_GENERATED',
};
export const ALARM_TYPES_DIC = {
  anomaly: 'ANOMALY',
  asset_decommission: 'ASSET_DECOMMISSION', // eslint-disable-line @typescript-eslint/camelcase
  counter_changed: 'EVENT_COUNTER_CHANGED', // eslint-disable-line @typescript-eslint/camelcase
  firmware_issue: 'FIRMWARE_ISSUE', // eslint-disable-line @typescript-eslint/camelcase

  // Deprecated issue type. only used to show historical information in logs
  software_issue: 'SOFTWARE_ISSUE', // eslint-disable-line @typescript-eslint/camelcase

  underutilized_issue: 'UNDERUTILIZED', // eslint-disable-line @typescript-eslint/camelcase

  // Deprecated
  failure_detect: 'PREDICTED_FAILURE', // eslint-disable-line @typescript-eslint/camelcase

  comp_failure_detect: 'PREDICTED_FAILURE_COMP', // eslint-disable-line @typescript-eslint/camelcase
  vent_failure_detect: 'PREDICTED_FAILURE_VENT', // eslint-disable-line @typescript-eslint/camelcase
  incurable_failure_detect: 'PREDICTED_FAILURE_INCURABLE', // eslint-disable-line @typescript-eslint/camelcase
  other_failure_detect: 'PREDICTED_FAILURE_OTHER', // eslint-disable-line @typescript-eslint/camelcase

  in_repair: 'IN_REPAIR', // eslint-disable-line @typescript-eslint/camelcase
  user_generated: 'USER_GENERATED', // eslint-disable-line @typescript-eslint/camelcase
};

export const ALARM_TYPES = {
  ASSET_DECOMMISSION: 'ASSET_DECOMMISSION',
  PREDICTED_FAILURE: 'PREDICTED_FAILURE', // Deprecated, but used in filtering mechanism
  PREDICTED_FAILURE_COMP: 'PREDICTED_FAILURE_COMP',
  PREDICTED_FAILURE_VENT: 'PREDICTED_FAILURE_VENT',
  PREDICTED_FAILURE_INCURABLE: 'PREDICTED_FAILURE_INCURABLE',
  PREDICTED_FAILURE_OTHER: 'PREDICTED_FAILURE_OTHER',
  FIRMWARE_ISSUE: 'FIRMWARE_ISSUE',
  SOFTWARE_ISSUE: 'SOFTWARE_ISSUE', // Deprecated issue type. only used to show historical information in logs
  ANOMALY: 'ANOMALY',
  EVENT_COUNTER_CHANGED: 'EVENT_COUNTER_CHANGED', // This alarm type just indicates that event counter has been changed
  UNDERUTILIZED: 'UNDERUTILIZED',
  IN_REPAIR: 'IN_REPAIR',
  USER_GENERATED: 'USER_GENERATED',
  HEALTHY: 'HEALTHY',
};

export const ALARM_TYPES_WEIGHT = {
  [ALARM_TYPES.ASSET_DECOMMISSION]: 60,
  [ALARM_TYPES.IN_REPAIR]: 80,
  [ALARM_TYPES.PREDICTED_FAILURE]: 100, // Deprecated
  [ALARM_TYPES.PREDICTED_FAILURE_COMP]: 110,
  [ALARM_TYPES.PREDICTED_FAILURE_VENT]: 120,
  [ALARM_TYPES.ANOMALY]: 130,
  [ALARM_TYPES.PREDICTED_FAILURE_INCURABLE]: 150,
  [ALARM_TYPES.PREDICTED_FAILURE_OTHER]: 180,
  [ALARM_TYPES.UNDERUTILIZED]: 300,
  [ALARM_TYPES.SOFTWARE_ISSUE]: 400, // Deprecated issue type. only used to show historical information in logs
  [ALARM_TYPES.FIRMWARE_ISSUE]: 500,
  [ALARM_TYPES.HEALTHY]: 700,
  [ALARM_TYPES.USER_GENERATED]: 800,
};

export const MAX_FILE_SIZE = 5242880; // 5MB

export const MAX_FACILITY_NAME_SIZE = 60;
export const MAX_CART_LOCATION_SIZE = 120;
export const MAX_CART_ID_SIZE = 20;
export const MAX_LAPTOP_ID_SIZE = 30;
export const MAX_LAPTOP_MODEL_SIZE = 60;
export const MAX_LAPTOP_SIM_ID_SIZE = 20;
export const MAX_HOSPITAL_NAME_SIZE = 200;
export const MAX_HOSPITAL_ADDRESS_SIZE = 400;

export const alarmTypeIconMap = {
  [ALARM_TYPES.FIRMWARE_ISSUE]: IoIosBug,
  [ALARM_TYPES.UNDERUTILIZED]: IoIosSad,
  [ALARM_TYPES.PREDICTED_FAILURE]: IoMdConstruct, // Deprecated
  [ALARM_TYPES.PREDICTED_FAILURE_COMP]: IoMdCodeDownload,
  [ALARM_TYPES.PREDICTED_FAILURE_VENT]: IoMdAnalytics,
  [ALARM_TYPES.PREDICTED_FAILURE_INCURABLE]: IoMdHeartDislike,
  [ALARM_TYPES.PREDICTED_FAILURE_OTHER]: IoMdConstruct,
  [ALARM_TYPES.IN_REPAIR]: IoIosBuild,
  [ALARM_TYPES.ANOMALY]: IoIosAlert,
  [ALARM_TYPES.HEALTHY]: IoIosHappy,
  [ALARM_TYPES.USER_GENERATED]: IoMdCloseCircle,
  [ALARM_TYPES.ASSET_DECOMMISSION]: DecommissionIcon,
};

export const iconTypeMap = {
  adult: adultIcon,
  infant: infantIcon,
  'not-defined': duckIcon,
};

export const manikinTypeWeights = {
  'RQI-adult': 9,
  'RQI-infant': 8,
  'RQI-not-defined': 7,
  'HeartCode-adult': 6,
  'HeartCode-infant': 5,
  'HeartCode-not-defined': 4,
  'Other-adult': 3,
  'Other-infant': 2,
  'Other-not-defined': 1,
};

export const HANDLED_STATE = {
  HANDLED: 'Handled',
  UNHANDLED: 'Not handled',
};

export const DECOMMISSIONED_STATE = {
  IN_SERVICE: 'In service',
  DECOMMISSIONED: 'Decommissioned', // Hidden assets
};

export const ACTION_TYPES = {
  APPROVED: 'approve',
  REJECTED: 'reject',
};

export const ASSET_TYPE = {
  adult: 'adult',
  infant: 'infant',
};

export const PROGRAM = {
  RQI: 'RQI',
  HeartCode: 'HeartCode',
  Other: 'Other',
};

export const DEFAULT_FIRMWARE_VERSION = {
  adult: '1.9.1.126',
  infant: '1.15.2',
};

export const HOSPITAL_STATES = {
  CRITICAL: 'CRITICAL',
  RISK: 'RISK',
  WARN: 'WARN',
  HEALTHY: 'HEALTHY',
};

export const HOSPITAL_COLORS = {
  [HOSPITAL_STATES.CRITICAL]: 'crimson',
  [HOSPITAL_STATES.RISK]: 'orange',
  [HOSPITAL_STATES.WARN]: '#fff316',
  [HOSPITAL_STATES.HEALTHY]: '#88f35a',
};

export const VA_OPTIONS = {
  VA: 'VA',
  ALL: 'all',
};

export const MULTI_VALUE_FLAG_DIC = {
  yes: 'MULTI_VALUE_YES',
  no: 'MULTI_VALUE_NO',
  partial: 'MULTI_VALUE_PARTIAL',
};

export const MULTI_VALUE_FLAG = {
  MULTI_VALUE_YES: 'MULTI_VALUE_YES',
  MULTI_VALUE_NO: 'MULTI_VALUE_NO',
  MULTI_VALUE_PARTIAL: 'MULTI_VALUE_PARTIAL',
};

export const MULTI_VALUE_OPTIONS = [
  { label: 'Yes', value: MULTI_VALUE_FLAG.MULTI_VALUE_YES },
  { label: 'No', value: MULTI_VALUE_FLAG.MULTI_VALUE_NO },
  { label: 'Partially', value: MULTI_VALUE_FLAG.MULTI_VALUE_PARTIAL },
];

export const MANIKIN_PART_GROUP_TYPE = {
  SINGLE_COLUMN: 'singleColumn',
  MULTIPLE_COLUMN: 'multipleColumn',
};

export const MANIKIN_SUBMISSION_TYPE = {
  ADD_FAILURE: 'addFailure',
  REPAIR_REPORT: 'repairReport',
};

export const HOSPITAL_EDIT_MODE_EDIT = 'Edit';
export const HOSPITAL_EDIT_MODE_NEW = 'New';
