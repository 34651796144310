import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { MANIKIN_SUBMISSION_TYPE } from '../../../../constants/constants';

const SubmissionTypeModal = (props) => {
  const {
    onCancel,
    onResult,
    open
  } = props;

  return (
    <Modal
      title="Please select submission type"
      onCancel={onCancel}
      open={open}
      showFooter={false}
      width={500}
    >
      <Content>
        <StyledButton
          big
          color="normal"
          label="TC/RQI"
          onClick={() => onResult(MANIKIN_SUBMISSION_TYPE.REPAIR_REPORT)}
        />
        <StyledButton
          big
          color="normal"
          label="RQIP customer reported issue"
          onClick={() => onResult(MANIKIN_SUBMISSION_TYPE.ADD_FAILURE)}
        />
      </Content>
    </Modal>
  );
};

SubmissionTypeModal.propTypes = {
  onCancel: PropTypes.func,
  onResult: PropTypes.func,
  open: PropTypes.bool
};

SubmissionTypeModal.defaultProps = {
  onCancel: Function.prototype,
  onResult: Function.prototype,
  open: true
};

export default SubmissionTypeModal;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  flex-basis: 0;
  flex-grow: 1;
  margin: 5px 15px;
  white-space: normal;
`;
