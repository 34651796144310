import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd } from 'react-icons/io';
import { Tooltip, Col, Row } from 'reactstrap';

import { ALARM_TYPES, alarmTypeIconMap, ALARM_TYPES_DIC } from '../../../../constants/constants';

import s from './FailureTypeCell.style';
import { humanReadableAlarmType } from '../../../../utility/manikin';

const FailureTypeIcon = (props) => {
  const [tooltipOpened, setTooltipState] = useState(false);
  const { type, tooltipText, size, id, onClick, selected, tall } = props;
  const IconComponent = alarmTypeIconMap[type] || IoIosAdd;

  return (
    <s.IconWrapper selected={selected && tall}>
      <IconComponent size={size} id={`alarmTypeTooltip-${type}-${id}`} onClick={() => onClick(type)} />
      <Tooltip
        className="tt-basic"
        delay={{ show: 50, hide: 0 }}
        fade={false}
        placement="top"
        isOpen={tooltipOpened}
        target={`alarmTypeTooltip-${type}-${id}`}
        toggle={() => setTooltipState(!tooltipOpened)}
      >
        <Row className="py-2 px-2">
          <Col xs={12}>{tooltipText}</Col>
        </Row>
      </Tooltip>
    </s.IconWrapper>
  );
};

FailureTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  tall: PropTypes.bool.isRequired,
};

const FailureTypeCell = (props) => {
  const { alarmTypes, id, onAlarmTypeClick, onAddClick, tall, selectedType } = props;
  const iconSize = alarmTypes.length <= 1 ? 28 : 20;

  const allowAdd = alarmTypes && !alarmTypes.includes(ALARM_TYPES.ASSET_DECOMMISSION);

  if (alarmTypes.length === 0) {
    alarmTypes.push(ALARM_TYPES.HEALTHY);
  }

  const handleAddAlarmClick = () => {
    onAddClick(id);
  };

  const renderIcon = (alarmType) => (
    <FailureTypeIcon
      key={`${id}-${alarmType}`}
      size={iconSize}
      type={alarmType}
      tooltipText={humanReadableAlarmType(alarmType)}
      id={id}
      onClick={onAlarmTypeClick}
      selected={alarmType === selectedType}
      tall={tall}
    />
  );

  return (
    <s.Root tall={tall} className="FailureTypeCell">
      {alarmTypes.map(renderIcon)}
      {allowAdd && (
        <div className="ignoreClick">
          <FailureTypeIcon size={14} type="Add" tooltipText="Mark as failed" id={id} onClick={handleAddAlarmClick} selected={false} tall={tall} />
        </div>
      )}
    </s.Root>
  );
};

FailureTypeCell.propTypes = {
  alarmTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  tall: PropTypes.bool.isRequired,
  selectedType: PropTypes.string.isRequired,
  onAlarmTypeClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

export default FailureTypeCell;
