import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { logout } from '../../actions';

const User = ({ className }) => {
  const [open, setOpenState] = useState(false);
  const handleIconClick = () => setOpenState(!open);

  return (
    <div className={className}>
      <UserButton onClick={handleIconClick}>
        <UserIcon
          active={open}
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g fillRule="evenodd">
            <circle cx="8" cy="7" r="3" />
            // eslint-disable-next-line max-len
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 12.4c-.6-.7-1.4-1.4-3-1.4H7c-1.6 0-2.4.7-3 1.4C2.8 11.3 2 9.8 2 8c0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.8-.8 3.3-2 4.4z" />
          </g>
        </UserIcon>
      </UserButton>
      <UserPanel opened={open}>
        <StyledLink
          onClick={handleIconClick}
          to="/settings"
        >
          Settings
          <ArrowRightIcon width="12" height="8" viewBox="0 0 12 8">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
              <path d="M11.5 4H.5M7.5 0l4 4-4 4" />
            </g>
          </ArrowRightIcon>
        </StyledLink>
        <StyledLink
          to="/"
          onClick={() => logout(localStorage.getItem('email'))}
        >
          Logout
          <LogoutIcon width="12" height="12" viewBox="0 0 12 12">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
              <path d="M8.5 3l3 3-3 3M11.5 6h-8M5.5 11.5h-4a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h4" />
            </g>
          </LogoutIcon>
        </StyledLink>
      </UserPanel>
    </div>
  );
};

User.propTypes = {
  className: PropTypes.string
};
User.defaultProps = {
  className: ''
};

export default User;


const LogoutIcon = styled.svg`
  g {
    stroke: ${({ theme }) => theme.greyPalette.grey};
  }
`;

const ArrowRightIcon = styled.svg`
  g {
    stroke: ${({ theme }) => theme.greyPalette.grey};
  }
`;

const StyledLink = styled(Link)`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    color: ${props => props.theme.greyPalette.grey};
    display: flex;
    font-size: 12px;
    font-weight: ${props => props.theme.fontWeight.regular};
    justify-content: space-between;
    min-width: 140px;
    padding: 10px 15px;

    &:hover, &:active {
        background-color: ${props => props.theme.secondaryColorPalette.transparent};
        color: ${props => props.theme.greyPalette.black};

        ${ArrowRightIcon} {
          g {
            stroke: ${({ theme }) => theme.secondaryColorPalette.main};
          }
        }

        ${LogoutIcon} {
          g {
            stroke: ${({ theme }) => theme.secondaryColorPalette.main};
          }
        }
    }

    &:last-of-type {
        border-bottom: 0;
    }
`;

const UserIcon = styled.svg`
    width: 18px;

    g {
      fill: ${({ active, theme }) => (active ? theme.secondaryColorPalette.main : theme.greyPalette.grey)};
    }

    &:hover {
      g {
        fill: ${({ theme }) => theme.secondaryColorPalette.main};
      }
    }
`;

const UserButton = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
`;

const UserPanel = styled.section`
    background: ${props => props.theme.primaryBackground};
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadow};
    display: ${props => (props.opened ? 'block' : 'none')};
    margin-top: -10px;
    position: absolute;
    right: 20px;
    top: 100%;
    z-index: 1;
`;
