/**
 * Loads/caches repair report data (repair reasons, manikin parts/sub parts, replace types etc.)
 */
import {
  SENT_REPAIR_REPORT_DATA_REQUEST,
  RECEIVED_REPAIR_REPORT_DATA_REQUEST,
  ERROR_REPAIR_REPORT_DATA_REQUEST
} from '../constants/constants';

function updateRepairReportData(oldData, newData) {
  if (!oldData) return newData;
  const result = Object.assign({}, oldData);

  Object.keys(newData).forEach((key) => {
    if (newData[key]) {
      result[key] = newData[key];
    }
  });

  return result;
}

export default (state = {
  loading: false,
  error: '',
  data: null
}, action) => {
  switch (action.type) {
    case SENT_REPAIR_REPORT_DATA_REQUEST:
      return ({
        ...state,
        loading: true,
        error: '',
        data: { ...(state.data), repairReport: null }
      });
    case ERROR_REPAIR_REPORT_DATA_REQUEST:
      return ({
        ...state,
        loading: false,
        error: action.payload,
        data: null
      });
    case RECEIVED_REPAIR_REPORT_DATA_REQUEST:
      return ({
        ...state,
        loading: false,
        error: '',
        data: updateRepairReportData(state.data, action.payload)
      });
    default:
      return state;
  }
};
