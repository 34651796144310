/* eslint-disable @typescript-eslint/no-var-requires */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IoIosHeart,
  IoIosHeartHalf,
  IoIosHeartEmpty
} from 'react-icons/io';

import Select from '../../../components/Select';
import {
  addDecommissionedFilterAction,
  addFailureTypeFilterAction,
  addManikinTypeFilterAction,
  addProgramFilterAction,
  removeDecommissionedFilterAction,
  removeFailureTypeFilterAction,
  removeManikinTypeFilterAction,
  removeProgramFilterAction,
  resetFiltersAction,
  setHandledFilterAction,
  setHealthFilterAction,
  setSortAction,
  setSortDirectionAction,
  setStateFilterAction,
  setCountryFilterAction
} from '../../../actions/filters';
import {
  ALARM_TYPES,
  alarmTypeIconMap,
  DECOMMISSIONED_STATE,
  HANDLED_STATE
} from '../../../constants/constants';
import Button from '../../../components/Button';
import states from '../../../resources/usStates';
import Overline from '../../../components/Texts/Overline';
import Option from './Option/Option';

import s from './FiltersPanel.style';

class FiltersPanel extends Component {

  static propTypes = {
    addDecommissionedFilter: PropTypes.func.isRequired,
    addFailureTypeFilter: PropTypes.func.isRequired,
    addManikinTypeFilter: PropTypes.func.isRequired,
    addProgramFilter: PropTypes.func.isRequired,
    allowedStates: PropTypes.array.isRequired,
    failureTypeFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
    decommissionedFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
    handledFilter: PropTypes.string.isRequired,
    healthFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    manikinTypeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    programFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
    removeDecommissionedFilter: PropTypes.func.isRequired,
    removeFailureTypeFilter: PropTypes.func.isRequired,
    removeManikinTypeFilter: PropTypes.func.isRequired,
    removeProgramFilter: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    setHandledFilter: PropTypes.func.isRequired,
    setHealthFilter: PropTypes.func.isRequired,
    setSortDirection: PropTypes.func.isRequired,
    setSortType: PropTypes.func.isRequired,
    setStatesFilter: PropTypes.func.isRequired,
    setCountryFilter: PropTypes.func.isRequired,
    availableCountries: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      openedFilters: [],
      panelOpened: false
    };
  }

  setOpenedFilterState = openedFilters => this.setState({ openedFilters });

  filtersBlockOpened = (filterType) => {
    const { openedFilters } = this.state;

    return openedFilters.includes(filterType);
  };

  manikinTypeSelected = (filterOption) => {
    const { manikinTypeFilters } = this.props;

    return manikinTypeFilters.includes(filterOption);
  };

  failureTypeSelected = (filterOption) => {
    const { failureTypeFilter } = this.props;

    return failureTypeFilter.includes(filterOption);
  };

  programSelected = (filterOption) => {
    const { programFilter } = this.props;

    return programFilter.includes(filterOption);
  };

  healthyFilterSelected = (filterOption) => {
    const { healthFilter } = this.props;

    return filterOption === healthFilter;
  };

  decommissionedFilterSelected = (filterOption) => {
    const { decommissionedFilter } = this.props;

    return decommissionedFilter.includes(filterOption);
  };

  handledFilterSelected = (filterOption) => {
    const { handledFilter } = this.props;

    return filterOption === handledFilter;
  };

  handleToggleClick = () => {
    this.setState(prevState => ({
      panelOpened: !prevState.panelOpened
    }));
  };

  handleFiltersBlockHeaderClick = (event) => {
    const { currentTarget } = event;
    const { filtername: filterName } = currentTarget.dataset;
    const { openedFilters } = this.state;
    let value;

    if (openedFilters.includes(filterName)) {
      value = openedFilters.filter(filter => filter !== filterName);
    } else {
      value = openedFilters.concat(filterName);
    }

    this.setOpenedFilterState(value);
  };

  handleManikinTypeClick = (event) => {
    const { currentTarget } = event;
    const { type: manikinType } = currentTarget.dataset;
    const {
      manikinTypeFilters,
      addManikinTypeFilter,
      removeManikinTypeFilter
    } = this.props;
    const checked = manikinTypeFilters.includes(manikinType);

    if (checked) {
      removeManikinTypeFilter(manikinType);
    } else {
      addManikinTypeFilter(manikinType);
    }
  };

  handleFailureTypeClick = (event) => {
    const { currentTarget } = event;
    const { type } = currentTarget.dataset;
    const {
      failureTypeFilter,
      addFailureTypeFilter,
      setSortType,
      setSortDirection,
      removeFailureTypeFilter
    } = this.props;
    const checked = failureTypeFilter.includes(type);

    if (checked) {
      removeFailureTypeFilter(type);

      if (type === ALARM_TYPES.PREDICTED_FAILURE) {
        removeFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_COMP);
        removeFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_VENT);
        removeFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_INCURABLE);
        removeFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_OTHER);
      }

    } else {
      addFailureTypeFilter(type);

      if (type === ALARM_TYPES.UNDERUTILIZED) {
        setSortType(ALARM_TYPES.UNDERUTILIZED);
        setSortDirection('asc');
      }

      if (type === ALARM_TYPES.SOFTWARE_ISSUE) {
        setSortType('firmwareVersionGroup');
        setSortDirection('asc');
      }

      if (type === ALARM_TYPES.PREDICTED_FAILURE) {
        addFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_COMP);
        addFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_VENT);
        addFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_INCURABLE);
        addFailureTypeFilter(ALARM_TYPES.PREDICTED_FAILURE_OTHER);
      }
    }
  };

  handleProgramClick = (event) => {
    const { currentTarget } = event;
    const { type } = currentTarget.dataset;
    const {
      programFilter,
      addProgramFilter: addFilter,
      setSortType,
      setSortDirection,
      removeProgramFilter: removeFilter
    } = this.props;
    const checked = programFilter.includes(type);

    if (checked) {
      removeFilter(type);
    } else {
      addFilter(type);

      if (type === ALARM_TYPES.UNDERUTILIZED) {
        setSortType(ALARM_TYPES.UNDERUTILIZED);
        setSortDirection('asc');
      }
    }
  };

  handleHandledFilterClick = (event) => {
    const { currentTarget } = event;
    const { type } = currentTarget.dataset;
    const {
      handledFilter,
      setHandledFilter
    } = this.props;

    const valueToSet = type === handledFilter ? '' : type;

    setHandledFilter(valueToSet);
  };

  handleDecommissionedFilterClick = (event) => {
    const { currentTarget } = event;
    const { type } = currentTarget.dataset;
    const {
      decommissionedFilter,
      addDecommissionedFilter,
      removeDecommissionedFilter
    } = this.props;
    const checked = decommissionedFilter.includes(type);

    if (checked && decommissionedFilter.length > 1) {
      removeDecommissionedFilter(type);
    } else {
      addDecommissionedFilter(type);
    }
  };


  handleHealthClick = (event) => {
    const { currentTarget } = event;
    const { type } = currentTarget.dataset;
    const { setHealthFilter } = this.props;

    setHealthFilter(type);
  };

  handleStateFilterChange = (value) => {
    const { setStatesFilter } = this.props;
    const allowedStates = value ? value.map(state => state.value) : [];

    setStatesFilter(allowedStates);
  };

  handleCountryFilterChange = (value) => {
    const { setCountryFilter } = this.props;
    const allowedCountries = value ? value.map(country => country.value) : [];
    setCountryFilter(allowedCountries);
  };

  handleResetClick = () => {
    const { resetFilters } = this.props;
    resetFilters();

    // TODO: fix this workaround
    // const searchBox = document.getElementById('searchInput');
    // searchBox.value = '';
    // searchBox.dispatchEvent(new Event('change'));
  };

  renderHealthyFilters = () => {
    const options = [{
      type: 'any',
      icon: IoIosHeartHalf,
      description: 'All'
    }, {
      type: 'healthy',
      icon: IoIosHeart,
      description: 'Healthy'
    }, {
      type: 'faulty',
      icon: IoIosHeartEmpty,
      description: 'Unhealthy'
    }];

    return (
      <s.Block>
        <Overline>
          Healthy/Unhealthy
        </Overline>
        <s.ContentBlock>
          {options.map((option) => {
            const Icon = option.icon;
            return (
              <Option
                selected={this.healthyFilterSelected(option.type)}
                type={option.type}
                onClick={this.handleHealthClick}
                content={<Icon />}
                tooltipText={option.description}
              />
            );
          })}
        </s.ContentBlock>
      </s.Block>
    );
  };

  renderManikinTypeFilters = () => {
    const options = [{
      type: 'adult',
      icon: (() => (
        <svg fill="currentColor" stroke="currentColor" strokeWidth="0" width="20px" viewBox="0 0 53.545 53.545">
          <g>
            <circle cx="26.686" cy="4.507" r="4.507" />
            <path d="M28.256,11.163c-1.123-0.228-2.344-0.218-3.447,0.042c-7.493,0.878-9.926,9.551-9.239,16.164
              c0.298,2.859,4.805,2.889,4.504,0c-0.25-2.41-0.143-6.047,1.138-8.632c0,3.142,0,6.284,0,9.425c0,0.111,0.011,0.215,0.016,0.322
              c-0.003,0.051-0.015,0.094-0.015,0.146c0,7.479-0.013,14.955-0.322,22.428c-0.137,3.322,5.014,3.309,5.15,0
              c0.242-5.857,0.303-11.717,0.317-17.578c0.244,0.016,0.488,0.016,0.732,0.002c0.015,5.861,0.074,11.721,0.314,17.576
              c0.137,3.309,5.288,3.322,5.15,0c-0.309-7.473-0.32-14.949-0.32-22.428c0-0.232-0.031-0.443-0.078-0.646
              c-0.007-3.247-0.131-6.497-0.093-9.742c1.534,2.597,1.674,6.558,1.408,9.125c-0.302,2.887,4.206,2.858,4.504,0
              C38.678,20.617,36.128,11.719,28.256,11.163z"
            />
          </g>
        </svg>
      )),
      description: 'Adult'
    }, {
      type: 'infant',
      icon: (() => (
        <svg fill="currentColor" stroke="currentColor" strokeWidth="0" width="20px" viewBox="0 0 370.732 370.732">
          <g>
            <circle cx="181.925" cy="49.937" r="49.937" />
            <path d="M296.113,180.975c-27.012-20.86-54.961-40.366-85.332-56.059
              c-8.598-6.993-19.452-11.277-31.266-11.355c-13.121-0.09-25.311,5.126-34.518,13.563c-24.673,14.082-47.86,30.491-70.379,47.878
              c-10.281,7.936-15.77,20.347-8.568,32.651c5.943,10.15,22.328,16.534,32.657,8.568c9.756-7.53,19.649-14.881,29.715-21.946
              c0,3.425,0,6.862,0,10.293h101.461c0-4.278,0-8.568,0-12.841c14.416,9.589,28.402,19.858,42.144,30.461
              c10.323,7.972,26.714,1.587,32.657-8.568C311.89,201.322,306.388,188.911,296.113,180.975z"
            />
            <path d="M193.74,270.872c3.544-2.297,5.382-4.803,5.382-4.803l30.753-36.607v-21.91H128.528l0.34,17.924
              l27.585,38.946l0.638,0.662c4.057,3.031,8.085,5.382,11.981,6.981l0.585,0.161c3.401,0.638,6.295,0.943,9.111,0.967h0.292
              c4.499,0,9.159-0.662,13.855-1.957C192.916,271.236,193.74,270.872,193.74,270.872z M179.061,267.238v2.983l-0.257-2.983
              c-2.363-0.012-4.839-0.268-7.751-0.806c-3.228-1.354-6.587-3.324-10.012-5.842l-26.242-37.06l-0.185-10.012h89.306v13.766
              l-29.494,35.115c-0.024,0.024-1.223,1.629-3.544,3.216C186.842,266.689,182.868,267.238,179.061,267.238z"
            />
            <path d="M154.758,267.029l-0.477-0.501l-27.68-39.089c-19.285,18.318-32.454,39.996-33.074,67.199
              c-0.609,26.803,17.775,50.557,37.012,67.032c23.241,19.905,57.175-13.706,33.749-33.749
              C140.282,307.348,136.01,288.492,154.758,267.029z"
            />
            <path d="M232.238,231.294l-30.688,36.499c-0.012,0.024-0.137,0.173-0.221,0.304
              c20.723,22.256,16.994,41.631-7.739,62.801c-23.414,20.043,10.514,53.654,33.749,33.749c20.991-17.972,34.59-39.226,37.012-67.032
              C266.583,272.071,249.727,248.168,232.238,231.294z"
            />
          </g>
        </svg>
      )),
      description: 'Infant'
    }, {
      type: 'not-defined',
      icon: (() => (
        <svg fill="currentColor" stroke="currentColor" strokeWidth="0" width="20px" viewBox="0 0 216.561 216.561">
          <path d="M210.686,65.28v-30h-18.201C186.15,14.867,167.089,0,144.62,0c-27.628,0-50.105,22.477-50.105,50.104
            c0,18.048,9.595,33.894,23.949,42.716c-4.759,0.923-9.668,1.408-14.673,1.408c-24.799,0-47.321-11.719-60.247-31.349l-4.691-7.123
            l-6.071,5.989C15.43,78.864,5.874,101.277,5.874,124.858c0,50.564,43.926,91.702,97.917,91.702
            c53.992,0,97.918-41.138,97.918-91.702c0-17.227-5.121-33.82-14.634-48.191c2.215-3.527,4.006-7.344,5.294-11.387H210.686z
            M152.02,46.947c-5.339,0-9.667-4.328-9.667-9.667s4.328-9.667,9.667-9.667c5.339,0,9.667,4.328,9.667,9.667
            S157.359,46.947,152.02,46.947z"
          />
        </svg>
      )),
      description: 'Undefined'
    }];

    return (
      <s.Block>
        <Overline>
          Manikin type
        </Overline>
        <s.ContentBlock>
          {options.map((option) => {
            const Icon = option.icon;
            return (
              <Option
                key={option.type}
                selected={this.manikinTypeSelected(option.type)}
                type={option.type}
                onClick={this.handleManikinTypeClick}
                content={<Icon />}
                tooltipText={option.description}
              />
            );
          })}
        </s.ContentBlock>
      </s.Block>
    );
  };

  renderFailureTypeFilters = () => {
    const options = [{
      type: ALARM_TYPES.PREDICTED_FAILURE,
      description: 'Predicted'
    }, {
      type: ALARM_TYPES.FIRMWARE_ISSUE,
      description: 'Firmware'
    }, {
      type: ALARM_TYPES.ANOMALY,
      description: 'Anomaly'
    }, {
      type: ALARM_TYPES.UNDERUTILIZED,
      description: 'Underutilized'
    }, {
      type: ALARM_TYPES.IN_REPAIR,
      description: 'In repair'
    }];

    return (
      <s.Block>
        <Overline>
          Failure type
        </Overline>
        <s.ContentBlock>
          {options.map((option) => {
            const Icon = alarmTypeIconMap[option.type];
            return (
              <Option
                key={option.type}
                selected={this.failureTypeSelected(option.type)}
                type={option.type}
                onClick={this.handleFailureTypeClick}
                content={<Icon />}
                tooltipText={option.description}
              />
            );
          })}
        </s.ContentBlock>
      </s.Block>
    );
  };

  renderProgramFilters = () => {
    const options = [{
      type: 'HeartCode',
      description: ''
    }, {
      type: 'RQI',
      description: ''
    }, {
      type: 'Other',
      description: ''
    }];

    return (
      <s.Block>
        <Overline>
          Program
        </Overline>
        <s.ContentBlock>
          {options.map(option => (
            <Option
              key={option.type}
              selected={this.programSelected(option.type)}
              type={option.type}
              onClick={this.handleProgramClick}
              content={option.type}
              tooltipText={option.description}
            />
          ))}
        </s.ContentBlock>
      </s.Block>
    );
  };

  renderHandledFilters = () => {
    const options = [{
      type: HANDLED_STATE.HANDLED,
      description: 'Handled'
    }, {
      type: HANDLED_STATE.UNHANDLED,
      description: 'Not handled'
    }];
    const { decommissionedFilter } = this.props;

    return (
      <s.Block>
        <Overline>
          Issues
        </Overline>
        <s.ContentBlock>
          {options.map((option, index) => (
            <Option
              key={option.type}
              selected={this.handledFilterSelected(option.type)}
              type={option.type}
              onClick={this.handleHandledFilterClick}
              tooltipText={option.description}
            />
          ))}
        </s.ContentBlock>
      </s.Block>
    );
  };

  renderDecommissionFilters = () => {
    const options = [{
      type: DECOMMISSIONED_STATE.IN_SERVICE,
      description: 'In service'
    },
    {
      type: DECOMMISSIONED_STATE.DECOMMISSIONED,
      description: 'Decommissioned'
    }];
    const { decommissionedFilter } = this.props;

    return (
      <s.Block>
        <Overline>
          Assets
        </Overline>
        <s.ContentBlock>
          {options.map((option, index) => (
            <Option
              key={option.type}
              selected={this.decommissionedFilterSelected(option.type)}
              type={option.type}
              onClick={this.handleDecommissionedFilterClick}
              tooltipText={option.description}
            />
          ))}
        </s.ContentBlock>
      </s.Block>
    );
  };

  renderStateFilters = () => {
    const { allowedStates } = this.props;
    const value = states.filter(usState => allowedStates.includes(usState.value));

    return (
      <>
        <Overline>
          US State
        </Overline>
        <s.ContentBlock>
          <Select
            isMulti
            options={states}
            onChange={this.handleStateFilterChange}
            menuPortalTarget={document.body}
            value={value}
          />
        </s.ContentBlock>
      </>
    );
  };

  renderCountryFilters = () => {
    const { allowedCountries, availableCountries } = this.props;
    const value = availableCountries.filter(country => allowedCountries.includes(country.value));

    return (
      <>
        <Overline>
          Countries
        </Overline>
        <s.ContentBlock>
          <Select
            isMulti
            options={availableCountries}
            onChange={this.handleCountryFilterChange}
            menuPortalTarget={document.body}
            value={value}
          />
        </s.ContentBlock>
      </>
    );
  };

  render() {
    const { panelOpened } = this.state;

    return (
      <s.Root>
        <s.Toggle onClick={this.handleToggleClick}>
          <svg width="16" height="16" viewBox="0 0 16 16">
            <g
              fill="none"
              fillRule="evenodd"
              stroke="#004555"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M11.5 3.5h4M.5 3.5h2M4.5 12.5h-4M15.5 12.5h-2" />
              <circle cx="5.5" cy="3.5" r="3" />
              <circle cx="10.5" cy="12.5" r="3" />
            </g>
          </svg>
          <s.Label panelOpened={panelOpened}>
            Filters
          </s.Label>
        </s.Toggle>
        {panelOpened && (
          <s.StyledPopup>
            {this.renderManikinTypeFilters()}
            {this.renderHealthyFilters()}
            {this.renderFailureTypeFilters()}
            {this.renderProgramFilters()}
            {this.renderHandledFilters()}
            {this.renderDecommissionFilters()}
            {this.renderStateFilters()}
            {this.renderCountryFilters()}
            <s.Buttons>
              <Button
                color="ghost"
                label="Reset all"
                onClick={this.handleResetClick}
              />
              <Button
                color="normal"
                label="Close"
                onClick={this.handleToggleClick}
              />
            </s.Buttons>
          </s.StyledPopup>
        )}
      </s.Root>
    );
  }

}

const mapStateToProps = state => ({
  manikinTypeFilters: state.filters.manikinType,
  decommissionedFilter: state.filters.decommission,
  healthFilter: state.filters.health,
  handledFilter: state.filters.handled,
  failureTypeFilter: state.filters.failureType,
  programFilter: state.filters.program,
  allowedStates: state.filters.states === null ? state.user.settings.preferredStates : state.filters.states,
  allowedCountries: state.filters.countries === null ? [] : state.filters.countries,
  availableCountries: state.filters.availableCountries === null ? [] : state.filters.availableCountries
});
const mapDispatchToProps = dispatch => ({
  addManikinTypeFilter: manikinType => dispatch(addManikinTypeFilterAction(manikinType)),
  removeManikinTypeFilter: manikinType => dispatch(removeManikinTypeFilterAction(manikinType)),
  addFailureTypeFilter: failureType => dispatch(addFailureTypeFilterAction(failureType)),
  removeFailureTypeFilter: failureType => dispatch(removeFailureTypeFilterAction(failureType)),
  addProgramFilter: program => dispatch(addProgramFilterAction(program)),
  removeProgramFilter: program => dispatch(removeProgramFilterAction(program)),
  addDecommissionedFilter: decommissionType => dispatch(addDecommissionedFilterAction(decommissionType)),
  removeDecommissionedFilter: decommissionType => dispatch(removeDecommissionedFilterAction(decommissionType)),
  setHealthFilter: healthState => dispatch(setHealthFilterAction(healthState)),
  setHandledFilter: handled => dispatch(setHandledFilterAction(handled)),
  setStatesFilter: allowedStates => dispatch(setStateFilterAction(allowedStates)),
  setCountryFilter: allowedCountries => dispatch(setCountryFilterAction(allowedCountries)),
  setSortType: sortType => dispatch(setSortAction(sortType)),
  setSortDirection: sortDirection => dispatch(setSortDirectionAction(sortDirection)),
  resetFilters: () => dispatch(resetFiltersAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPanel);
