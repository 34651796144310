import styled from 'styled-components';

const style = {};

style.Root = styled.div`
  align-items: center;
  background: ${({ theme, selected }) => (selected ? theme.laerdalPalette.primary.default : theme.primaryBackground)};
  border-radius: 12px;
  border: 1px solid ${({ theme, selected }) => (selected ? theme.laerdalPalette.primary.default : theme.laerdalPalette.accent1.default)};
  color: ${({ theme, selected }) => (selected ? theme.inverseColor : theme.laerdalPalette.accent1.default)};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  height: 26px;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
`;

style.Icon = styled.div`
  margin-right: 3px;

  img,
  svg {
    width: 16px;
    height: 16px;
    outline: 0;
  }
`;

export default style;
