import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { theme } from '../../theme/theme';

export const styles = {
  multiValue: defaultStyles => ({
    ...defaultStyles,
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: theme.secondaryColorPalette.light1,
    borderRadius: '12px',
    marginRight: '5px'
  }),

  multiValueLabel: defaultStyles => ({
    ...defaultStyles,
    color: theme.greyPalette.black,
    fontSize: theme.fontSize.regular
  }),
  multiValueRemove: defaultStyles => ({
    ...defaultStyles,
    color: theme.secondaryColorPalette.light1,
    ':hover': {
      color: theme.secondaryColorPalette.main
    }
  }),

  clearIndicator: defaultStyles => ({
    ...defaultStyles,
    color: theme.secondaryColorPalette.light1,
    position: 'absolute',
    right: '20px',
    ':hover': {
      color: theme.secondaryColorPalette.main
    }
  }),
  container: defaultStyles => ({
    ...defaultStyles,
    width: '100%',
    minWidth: '245px',
    ':focus': {
      outline: 'none',
      border: `1px solid ${theme.secondaryColorPalette.light1}`
    }
  }),
  control: (defaultStyles, { isFocused }) => {
    let style = {
      ...defaultStyles,
      fontSize: theme.fontSize.medium,
      fontWeight: theme.fontWeight.regular,
      borderColor: theme.greyPalette.grey,
      borderRadius: theme.borderRadius,
      minWidth: '200px',
      padding: '2.5px 0',
      ':hover': {
        borderColor: theme.greyPalette.black,
        cursor: 'pointer'
      }
    };

    if (isFocused) {
      style = {
        ...style,
        borderColor: theme.greyPalette.black,
        boxShadow: 'none',
        outline: 'none'
      };
    }

    return style;
  },
  dropdownIndicator: defaultStyles => ({
    ...defaultStyles,
    color: theme.greyPalette.grey,
    ':hover': {
      color: theme.greyPalette.black
    }
  }),
  indicatorSeparator: defaultStyles => ({
    ...defaultStyles,
    backgroundColor: 'transparent'
  }),
  input: defaultStyles => ({
    ...defaultStyles,
    maxWidth: '200px'
  }),
  menuPortal: defaultStyles => ({
    ...defaultStyles,
    zIndex: 1000
  }),
  option: (defaultStyles, { isFocused, isSelected }) => {
    let style = {
      ...defaultStyles,
      color: theme.greyPalette.grey,
      fontSize: theme.fontSize.medium,
      fontWeight: theme.fontWeight.regular,
      ':hover': {
        backgroundColor: theme.secondaryColorPalette.light1,
        color: theme.inverseColor
      }
    };

    if (isFocused) {
      style = {
        ...style,
        backgroundColor: theme.secondaryColorPalette.light1,
        color: theme.inverseColor
      };
    }

    if (isSelected) {
      style = {
        ...style,
        backgroundColor: theme.secondaryColorPalette.transparent,
        color: theme.greyPalette.black
      };
    }

    return style;
  },
  singleValue: defaultStyles => ({
    ...defaultStyles,
    color: theme.greyPalette.black,
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.regular
  }),
  placeholder: defaultStyles => ({
    ...defaultStyles,
    color: theme.greyPalette.black,
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.regular
  })
};

const CustomSelect = (props) => {
  const {
    defaultValue,
    isCreatable,
    componentStyles,
    value,
    ...restProps
  } = props;

  // Overwrite styles if necessary
  const currentStyles = componentStyles
    ? {
      ...styles,
      ...componentStyles
    }
    : styles;

  return isCreatable
    ? (
      <CreatableSelect
        isCreatable
        styles={currentStyles}
        value={value || defaultValue}
        {...restProps}
      />
    )
    : (
      <Select
        styles={currentStyles}
        value={defaultValue ? null : value}
        defaultValue={value ? null : defaultValue}
        {...restProps}
      />
    );
};

export default CustomSelect;
