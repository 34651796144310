import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FailureTypeCell from './FailureTypeCell';
import Log from './LogItem';
import AdditionalInfo from './AdditionalInfo';
import { setHospitalFilterAction, setTextFilterAction } from '../../../actions/filters';
import { ACTION_TYPES, ALARM_TYPES, iconTypeMap } from '../../../constants/constants';

import s from './NameValueListItem.style';

// static propTypes = {
//   index: PropTypes.number.isRequired,
//   manikin: PropTypes.object.isRequired,
//   onCreateAlarm: PropTypes.func.isRequired,
//   onHospitalSelect: PropTypes.func.isRequired,
//   onItemToggle: PropTypes.func.isRequired,
//   onActionRemove: PropTypes.func.isRequired,
//   onActionSelect: PropTypes.func.isRequired,
//   opened: PropTypes.bool.isRequired,
//   style: PropTypes.object,
//   useLastRowWorkaround: PropTypes.bool
// };

const NameValueListItem = (props) => {
  const { index, onItemToggle, style, value } = props;

  if (!value) {
    return null;
  }

  const { value: val, label } = value;

  return (
    <s.ListItem
      className="ListItem"
      onClick={() => onItemToggle(value, index)}
      style={{
        ...style,
        overflow: 'visible',
      }}
    >
      <s.Content>
        <s.Cell label>{label || '-'}</s.Cell>
        <s.Cell value>{val || '-'}</s.Cell>
      </s.Content>
    </s.ListItem>
  );
};

NameValueListItem.propTypes = {
  index: PropTypes.number,
  onItemToggle: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.object,
};

NameValueListItem.defaultProps = {
  index: -1,
  onItemToggle: Function.prototype,
  style: {},
  value: null,
};

export default NameValueListItem;
