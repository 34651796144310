import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import Title from '../../components/Texts/Title';

import packageJson from '../../../package.json';
import logo from '../../styles/images/logo.svg';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../index';

import s from './Login.style';

import RQILOgo from './rqi.png';
import LaerdalLogo from './laerdal.png';
import AHALOgo from './aha.svg';
import Office365Icon from './office365.svg';

const Login = ({ instance }: { instance: PublicClientApplication }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [data, setData] = useState(null);

  const handleOffice365SignInClick = () => {
    void instance.loginRedirect(msalConfig.loginRequest);
  };

  const renderErrorMessage = () => <>{data}</>;

  const renderForm = () => (
    <s.Form id="form">
      <Title>{loading ? 'Signing in' : 'Please sign in'}</Title>
      <s.StyledCaption>{error ? renderErrorMessage() : ''}</s.StyledCaption>
      <s.LoginOffice365Button
        big
        color="ghost"
        label={loading ? <LoadingSpinner /> : <img src={Office365Icon} alt="Sign In With Office365" />}
        onClick={handleOffice365SignInClick}
        title="Sign In With Office365"
      />
    </s.Form>
  );

  const renderLoader = () => (
    <div className=" mx-auto my-5  animated fadeIn row ">
      <div className="my-5 d-flex justify-content-center align-items-stretch col">
        <div className="loader-big" />
      </div>
    </div>
  );

  return (
    <s.Root className="text-center align-items-center d-flex h-100-per">
      <s.MainLogo>
        <img src={RQILOgo} alt="RQI® 2020 An American Heart Association® and Laerdal® Program" />
      </s.MainLogo>
      {!loading || data === null ? renderForm() : renderLoader()}
      <s.BottomBlock>
        <s.LogoContainer>
          <img src={LaerdalLogo} className="laerdalLogo" alt="Laerdal® Logo" />
        </s.LogoContainer>
        <Title>Worry Free Support Systems</Title>
        <s.LogoContainer>
          <img src={AHALOgo} className="ahaLogo" alt="American Heart Association® logo" />
        </s.LogoContainer>
      </s.BottomBlock>
    </s.Root>
  );
};

export default Login;
