import styled from 'styled-components';

import SubTitle from '../../../../components/Texts/SubTitle';
import Text from '../../../../components/Texts/Text';

const style = {};

style.RadioButtonGroup = styled.div`
`;

style.RadioButton = styled.input`
  margin-bottom: 5px;
`;

style.RadioInputLabel = styled.label`
  color: ${props => props.theme.greyPalette.black};
  font-weight: ${props => props.theme.fontWeight.regular}
  margin-left: 5px;
  margin-top: -2px;
`;

style.Result = styled.div`
  margin-top: 10px;
`;

style.Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

style.Column = styled.div`
  display: flex;
  flex: ${({ fullWidth }) => (fullWidth ? ' 1 auto' : '0 245px')};
  flex-direction: column;
`;

style.StyledSubTitle = styled(SubTitle)`
  margin-top: 10px;
  margin-bottom: 8px;
`;

style.Toggle = styled(Text)`
  color: ${props => props.theme.secondaryColorPalette.light1};
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: ${props => props.theme.secondaryColorPalette.main};
  }
`;

export default style;
