import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import s from './ListItem.style';
import EditedLocation from './EditedLocation';

// Used to render both place name (hospital name) and location
// Trims lengthy values if needed
const LengthyTextCell = (props) => {
  const { maxTextLength, onClick, text, toolTipUid, edited } = props;
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const maxLength = window.innerWidth > 1300 ? maxTextLength : Math.floor(0.7 * maxTextLength);
  const needTrim = text.length > maxLength;

  const clickingProps = onClick
    ? {
        onClick,
        role: 'button',
        tabIndex: 0,
      }
    : {};

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <s.Wide>
      {edited && <EditedLocation edited={edited} />}
      <span className="ignoreClick" {...clickingProps} id={toolTipUid}>
        {needTrim ? `${text.slice(0, maxLength)}...` : text}
        {needTrim && (
          <Tooltip target={toolTipUid} isOpen={tooltipOpened} toggle={() => setTooltipOpened(!tooltipOpened)}>
            {text}
          </Tooltip>
        )}
      </span>
    </s.Wide>
  );
};

LengthyTextCell.propTypes = {
  maxTextLength: PropTypes.number,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  toolTipUid: PropTypes.string.isRequired,
  edited: PropTypes.string,
};

LengthyTextCell.defaultProps = {
  maxTextLength: 50,
  onClick: undefined,
  edited: null,
};

export default LengthyTextCell;
