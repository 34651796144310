import _ from "lodash";

export const concatArguments = (acc, args) => (acc || []).concat(args);

const debounceReduce = (func, wait, combine = concatArguments) => {
  let allArgs; // accumulator for args across calls

  // normally-debounced fn that we will call later with the accumulated args
  const wrapper = _.debounce(() => {
    const args = allArgs;
    allArgs = undefined;
    func(args);
  }, wait);

  // what we actually return is this function which will really just add the new args to
  // allArgs using the combine fn
  return (...args) => {
    allArgs = combine(allArgs, [...args]);
    wrapper();
  };
};

export default debounceReduce;
