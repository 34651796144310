import styled from 'styled-components';
import { progressHeight } from './ProgressBar.style';

const style = {};

style.Tooltip = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  margin-right: -45px;
  height: 25px;
  width: 100px;
  padding: 0 0.25rem;
  line-height: 25px;
  border-radius: 2px;
  background-color: #0a4453;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 0px #000;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #0a4453;
  }
`;

style.Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  width: ${({ width }) => width}%;
  height: ${progressHeight - 2}px;
  background-color: ${(props) => props.theme.laerdalPalette.accent1.default};
  border-radius: ${(props) => props.theme.borderRadius};

  &:hover {
    ${style.Tooltip} {
      display: block;
    }
  }
`;

export default style;
