import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { saveSettings } from '../../api/api';
import { setDailyAlarmsAction, setPreferredStatesAction, setMonitorQueriesAction } from '../../actions/user';
import Caption from '../../components/Texts/Caption';
import Select from '../../components/Select';
import statesDictionary from '../../resources/usStates';
import Input from '../../components/Input';
import Text from '../../components/Texts/Text';
import Button from '../../components/Button/Button';

import s from './Settings.style';

const Settings = (props) => {
  const { email, history, numberOfAlarmsSetting, preferredStatesSetting, setNumberOfAlarms, setPreferredStates, monitorQueriesSetting, setMonitorQueries } =
    props;
  const statesValue = statesDictionary.filter((usState) => preferredStatesSetting.includes(usState.value));
  const monitorQueriesValue = MapMonitorQuery(monitorQueriesSetting);
  const [queries, setQueries] = useState(monitorQueriesValue);
  const [states, setStates] = useState(statesValue);
  const [alarms, setAlarms] = useState(numberOfAlarmsSetting);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const userEmail = localStorage.getItem('email') || email;

  const handleSave = async () => await handleSaves(alarms, states, queries);

  const handleSaves = async (currentAlarms, currentStates, currentQueries) => {
    const isPreferredStatesChanged = !isEqual(preferredStatesSetting.sort(), currentStates.map((st) => st.value).sort());

    if (isPreferredStatesChanged) {
      setPreferredStates(currentStates.map((state) => state.value));
    }

    if (numberOfAlarmsSetting !== currentAlarms) {
      setNumberOfAlarms(currentAlarms);
    }

    if (currentQueries.length != (monitorQueriesSetting ?? []).length) {
      setMonitorQueries(currentQueries);
    }

    // Only save if something has been changed
    if (isPreferredStatesChanged || numberOfAlarmsSetting !== currentAlarms || currentQueries.length != (monitorQueriesSetting ?? []).length) {
      try {
        console.log('SAVE USER SETTINGS');
        await saveSettings({
          alarmsPerDay: currentAlarms,
          preferredStates: currentStates.map((state) => state.value),
          monitorQueries: currentQueries.map((query) => query.value),
        });
        // setSuccess('Settings saved successfully');
      } catch (e) {
        // setError(`Save settings error. ${e}`);
      }
    }
  };

  useEffect(() => {
    setStates(statesDictionary.filter(usState => preferredStatesSetting.includes(usState.value)));
    setAlarms(numberOfAlarmsSetting);
    setQueries(MapMonitorQuery(monitorQueriesSetting));
  }, [preferredStatesSetting, numberOfAlarmsSetting, monitorQueriesSetting]);

  const handleStatesChange = (value) => {
    if (!value) {
      value = [];
    } else if (value.find((v) => v.value === 'DEFAULT')) {
      value = value.filter((v) => v.value !== 'DEFAULT');
    }

    setStates(value);
  };

  const handleMonitorQueryChange = (value) => {
    if (!value) {
      value = [];
    } else if (value.find((v) => v.value === 'DEFAULT')) {
      value = value.filter((v) => v.value !== 'DEFAULT');
    }

    setQueries(value);
  };
  const handleAlarmsChange = (value) => {
    setAlarms(value);
  };

  return (
    <s.Root>
      <s.Header>
        <s.SettingsTitle>Settings</s.SettingsTitle>
        <s.MessageCont>
          <s.Message>
            {success && <Text>{success}</Text>}
            {error && <Text error>{error}</Text>}
          </s.Message>
        </s.MessageCont>
      </s.Header>
      <Text>{userEmail}</Text>
      <s.Content>
        <s.Block>
          <Caption>Desired number of alarms per day</Caption>
          <Input value={alarms} min={1} max={20} type="number" onChange={(e) => handleAlarmsChange(parseInt(e.target.value, 10))} withBorder />
        </s.Block>
        <s.Block>
          <Caption>States of interest:</Caption>
          <Select isMulti options={statesDictionary} placeholder="All states" value={states.length ? states : null} onChange={handleStatesChange} />
        </s.Block>
        <s.Block>
          <Caption>Monitoring search terms:</Caption>
          <Select
            isMulti
            options={monitorQueriesSetting.map(x=> {return {value: x, label: x};})}
            placeholder="Not monitoring any searches"
            value={queries.length > 0 ? queries : null}
            onChange={handleMonitorQueryChange}
          />
        </s.Block>
        <s.Block>
          <Button color="normal" label="Save" onClick={handleSave}></Button>
        </s.Block>
      </s.Content>
    </s.Root>
  );
};

Settings.propTypes = {
  email: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  numberOfAlarmsSetting: PropTypes.number.isRequired,
  preferredStatesSetting: PropTypes.arrayOf(PropTypes.string).isRequired,
  setNumberOfAlarms: PropTypes.func.isRequired,
  setPreferredStates: PropTypes.func.isRequired,
  setMonitorQueries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  numberOfAlarmsSetting: state.user.settings.alarmsPerDay,
  preferredStatesSetting: state.user.settings.preferredStates,
  email: state.user.email,
  monitorQueriesSetting: state.user.settings.monitorQueries,
});

const mapDispatchToProps = (dispatch) => ({
  setNumberOfAlarms: (numberOfAlarms) => dispatch(setDailyAlarmsAction(numberOfAlarms)),
  setPreferredStates: (preferredStates) => dispatch(setPreferredStatesAction(preferredStates)),
  setMonitorQueries: (monitorQueries) => dispatch(setMonitorQueriesAction(monitorQueries)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
function MapMonitorQuery(monitorQueriesSetting) {
  return (monitorQueriesSetting ?? []).map(x => { return { value: x, label: x }; });
}

