/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  UPDATE_FILTER,
  UPDATE_FILTER_HOSPITAL,
  UPDATE_LAST_SELECTED_HOSPITAL_ID,
  GET_FILTER_ARRAY,
  ERROR_FILTER_ARRAY,
  UPDATE_TEXT_FILTER_AND_RESET_OTHER,
  ADD_MANIKIN_TYPE_FILTER,
  REMOVE_MANIKIN_TYPE_FILTER,
  SET_HEALTH_FILTER,
  ADD_ALARM_TYPE_FILTER,
  REMOVE_ALARM_TYPE_FILTER,
  RESET_FILTER,
  UPDATE_FILTER_HIGHLIGHT_HOSPITAL,
  SET_SORT,
  SET_SORT_DIRECTION,
  ADD_PROGRAM_FILTER,
  REMOVE_PROGRAM_FILTER,
  SET_HANDLED_FILTER,
  ADD_DECOMMISSIONED_FILTER,
  UPDATE_TEXT_FILTER,
  SET_STATE_FILTER,
  SET_COUNTRY_FILTER,
  VA_OPTIONS,
  SET_VA_FILTER,
  REMOVE_DECOMMISSIONED_FILTER,
  DECOMMISSIONED_STATE,
  UPDATE_PRE_SELECTED_ASSET_ID,
  SET_AVAILABLE_COUNTRIES,
} from '../constants/constants';

const defaultState = {
  loading: false,
  error: false,
  hospitalId: null,
  highlightHospitalId: null,
  lastSelectedHospitalId: null, // Latest highlighted or selected hospitalId
  preSelectedAssetId: '',
  text: '',
  manikinType: [],
  health: 'any',
  failureType: [],
  program: [],
  handled: '',
  decommission: [DECOMMISSIONED_STATE.IN_SERVICE], // Decommissioned, In service. One value is always selected
  states: null,
  countries: null,
  availableCountries: null,
  sort: '',
  sortDirection: 'desc',
  va: VA_OPTIONS.ALL,
};

const DECOMMISSIONED_FILTER = {
  Both: [DECOMMISSIONED_STATE.DECOMMISSIONED, DECOMMISSIONED_STATE.IN_SERVICE],
  InService: [DECOMMISSIONED_STATE.IN_SERVICE],
  Decommissioned: [DECOMMISSIONED_STATE.DECOMMISSIONED],
};

const getLastSelectedHospitalId = (state: any, hospitalId: any) => hospitalId || state.lastSelectedHospitalId || state.hospitalId;

export default (state: any = defaultState, action: any) => {
  switch (action.type) {
    case RESET_FILTER:
      return {
        ...defaultState,
        sort: state.sort,
        sortDirection: state.sortDirection,
        availableCountries: state.availableCountries,
      };
    case UPDATE_FILTER:
      return {
        loading: false,
        error: false,
        data: action.payload,
      };
    case UPDATE_FILTER_HOSPITAL:
      return {
        ...state,
        hospitalId: action.hospitalId && action.hospitalId === state.hospitalId ? undefined : action.hospitalId,
        lastSelectedHospitalId: getLastSelectedHospitalId(state, action.hospitalId),
      };
    case UPDATE_FILTER_HIGHLIGHT_HOSPITAL:
      return {
        ...state,
        highlightHospitalId: action.hospitalId,
        lastSelectedHospitalId: getLastSelectedHospitalId(state, action.hospitalId),
      };
    case UPDATE_LAST_SELECTED_HOSPITAL_ID:
      return {
        ...state,
        lastSelectedHospitalId: action.hospitalId,
      };
    case UPDATE_PRE_SELECTED_ASSET_ID:
      return {
        ...state,
        preSelectedAssetId: action.preSelectedAssetId,
      };
    case UPDATE_TEXT_FILTER:
      return {
        ...state,
        text: action.text,
      };
    case UPDATE_TEXT_FILTER_AND_RESET_OTHER:
      return {
        ...defaultState,
        decommission: DECOMMISSIONED_FILTER.InService,
        text: action.text,
        sort: state.sort,
        sortDirection: state.sortDirection,
      };
    case ADD_MANIKIN_TYPE_FILTER:
      return {
        ...state,
        manikinType: state.manikinType.concat(action.manikinType),
      };
    case REMOVE_MANIKIN_TYPE_FILTER:
      return {
        ...state,
        manikinType: state.manikinType.filter((mt: any) => mt !== action.manikinType),
      };
    case ADD_ALARM_TYPE_FILTER:
      return {
        ...state,
        failureType: state.failureType.concat(action.failureType),
      };
    case REMOVE_ALARM_TYPE_FILTER:
      return {
        ...state,
        failureType: state.failureType.filter((ft: any) => ft !== action.failureType),
      };
    case ADD_PROGRAM_FILTER:
      return {
        ...state,
        program: state.program.concat(action.program),
      };
    case REMOVE_PROGRAM_FILTER:
      return {
        ...state,
        program: state.program.filter((p: any) => p !== action.program),
      };
    case SET_HEALTH_FILTER:
      return {
        ...state,
        health: action.healthState,
      };
    case SET_HANDLED_FILTER:
      return {
        ...state,
        handled: action.handled,
      };
    case ADD_DECOMMISSIONED_FILTER:
      return {
        ...state,
        decommission: !state.decommission.includes(action.decommissionType) ? [action.decommissionType, ...state.decommission] : state.decommission,
      };
    case REMOVE_DECOMMISSIONED_FILTER:
      return {
        ...state,
        decommission: state.decommission.filter((m: any) => m !== action.decommissionType),
      };
    case SET_STATE_FILTER:
      return {
        ...state,
        states: action.states,
      };
    case SET_COUNTRY_FILTER:
      return {
        ...state,
        countries: action.countries,
      };
    case SET_AVAILABLE_COUNTRIES:
      return {
        ...state,
        availableCountries: action.availableCountries,
      };
    case SET_VA_FILTER:
      return {
        ...state,
        va: action.vaOption,
      };
    case SET_SORT:
      return {
        ...state,
        sort: action.sort,
      };
    case SET_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: action.sortDirection,
      };
    case GET_FILTER_ARRAY:
      return {
        loading: false,
        error: false,
        data: action.payload,
      };
    case ERROR_FILTER_ARRAY:
      return {
        loading: false,
        error: true,
        msg: 'no data ',
      };
    default:
      return state;
  }
};
