import styled from 'styled-components';
import { mainInfoHeight } from '../ListItem/ListItem.style';
import { additionalInfoHeight, bottomInfoHeight } from '../ListItem/AdditionalInfo/AdditionalInfo.style';

const s = {};
export const defaultAssetsVisible = 5;
export const defaultHeight = defaultAssetsVisible * mainInfoHeight;
export const minTableWidth = '990px';
export const headerHeight = 56;

export const getListHeight = ({ hasSelected, assetsVisible, height }) => {
  if (height) {
    return height - headerHeight;
  }

  const resHeight = mainInfoHeight
    * (assetsVisible || defaultAssetsVisible)
    + (hasSelected ? (additionalInfoHeight + bottomInfoHeight) : 0);

  return resHeight < (height || defaultHeight)
    ? resHeight
    : (height || defaultHeight);
};

s.Root = styled.div`
  height: auto;
  min-width: ${minTableWidth};
`;

s.Header = styled.div`
  display: flex;
  height: 55px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  border-top: 1px solid ${props => props.theme.borderColor};
`;

export default s;
