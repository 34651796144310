import * as React from 'react';
import PropTypes from 'prop-types';

import s from './Progress.style';

const Progress = (props) => {
  const { value, text, secondary, warn } = props;

  return (
    <s.Root width={isNaN(value) ? 0 : value} secondary={secondary} warn={warn}>
      <s.Tooltip>{text}</s.Tooltip>
    </s.Root>
  );
};

Progress.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  warn: PropTypes.bool,
};

Progress.defaultProps = {
  secondary: false,
  warn: false,
};

export default Progress;
