import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CheckboxSquare = (props) => {
  const {
    className,
    checked,
    disabled,
    label,
    onClick,
    size
  } = props;

  const handleClick = () => disabled || onClick(label);

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox
        checked={checked || false}
        readOnly
      />

      <StyledCheckbox
        checked={checked}
        disabled={disabled}
        onClick={handleClick}
        size={size}
      >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>

      {label && (
        <Label
          disabled={disabled}
          onClick={handleClick}
        >
          {label}
        </Label>
      )}
    </CheckboxContainer>
  );
};

CheckboxSquare.propTypes = {
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf([null])
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number
};

CheckboxSquare.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  label: '',
  onClick: Function.prototype,
  size: 17
};

export default styled(CheckboxSquare)``;

const CheckboxContainer = styled.label`
  align-items: center;
  display: flex;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  margin-bottom: 5px;
  stroke: ${({ theme }) => theme.inverseColor};
  stroke-width: 2px;
`;

const Label = styled.span`
  color: ${({ disabled, theme }) => (disabled ? theme.greyPalette.grey4 : theme.greyPalette.grey)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeightRegular};
  margin-left: 8px;
  white-space: nowrap;
`;

const StyledCheckbox = styled.div`
  border: 1px solid;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ disabled, checked, theme }) => {
    if (checked === null) {
      return `
      background: ${theme.primaryBackground};
      border-color: ${theme.borderColor};
      `;
    }

    if (checked === false) {
      return `
      background: ${theme.tertiaryBackground};
      border-color: ${theme.borderColor};
      `;
    }

    if (checked === true && disabled) {
      return `
      background: ${theme.greyPalette.grey4};
      border-color: ${theme.greyPalette.grey4};
      cursor: default;
      `;
    }

    if (checked === true) {
      return `
      background: ${theme.secondaryColorPalette.light1};
      border-color: ${theme.secondaryColorPalette.light1};
      `;
    }

    return ``;
  }}

  border-radius: 15%;
  display: inline-block;
  height: ${({ size }) => size}px;
  transition: all 0.3s;
  width: ${({ size }) => size}px;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;
