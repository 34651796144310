import { SENT_ASSET_HISTORY_REQUEST, RECEIVED_ASSET_HISTORY, ERROR_ASSET_HISTORY_REQUEST } from '../constants/constants';

const defaultState = {
  error: false,
  data: [],
  assetId: null,
  loading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SENT_ASSET_HISTORY_REQUEST:
      return {
        ...defaultState,
        assetId: action.payload,
        loading: true,
      };
    case RECEIVED_ASSET_HISTORY:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case ERROR_ASSET_HISTORY_REQUEST:
      return {
        ...defaultState,
        showDlDialog: state.showDlDialog,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};
