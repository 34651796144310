import * as React from 'react';
import { Container } from 'reactstrap';
import Header from '../ui/header/Header';
import Footer from '../ui/Footer';

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <Header />
    {props.children}
    <Footer />
  </React.Fragment>
);
