import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingSpinner from '../../../../components/Loading/LoadingSpinner';
import Modal from '../../../../components/Modal';
import Text from '../../../../components/Texts/Text';

import { theme } from '../../../../theme/theme';
import { resetAssetEventCounter } from '../../../../api/api';

const s = {};
s.LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ConfirmResetCounterDialog = (props) => {
  const { assetId, ruleId, ruleName, onClose, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [healedAlarmTypeId, setHealedAlarmTypeId] = useState('');

  useEffect(() => {
    setError('');
  }, []);

  const onConfirmClick = async () => {
    if (success) {
      onClose(healedAlarmTypeId);
      return;
    }

    setError('');
    setLoading(true);

    try {
      const response = await resetAssetEventCounter(assetId, ruleId);
      setSuccess(true);
      setHealedAlarmTypeId(response.data.alarmTypeId);
      onSuccess(response.data.alarmTypeId);
    } catch (err) {
      setError(
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.response && err.response.status == 403
          ? 'You do not have rights to reset the counter.'
          : 'Something bad happened.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      cancelButtonDisabled={loading}
      cancelButtonText={success ? 'Close' : 'Cancel'}
      confirmButtonText={success ? 'Close' : 'Reset'}
      loading={loading}
      onCancel={onClose}
      onConfirm={onConfirmClick}
      open
      title="Confirmation"
      width={450}
    >
      {loading ? (
        <s.LoaderContainer>
          <LoadingSpinner size="50" color={theme.secondaryColorPalette.light1} />
        </s.LoaderContainer>
      ) : (
        <Text>{success ? `'${ruleName}' counter has been reset successfully.` : `Are you sure to reset '${ruleName}' counter?`}</Text>
      )}
      {error && <Text error>{error}</Text>}
    </Modal>
  );
};

ConfirmResetCounterDialog.propTypes = {
  assetId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  ruleId: PropTypes.string.isRequired,
  ruleName: PropTypes.string.isRequired,
};

export default ConfirmResetCounterDialog;
