import styled from 'styled-components';

import Button, { TooltipButton } from '../../../../components/Button';

const s = {};
export const additionalInfoHeight = 128;
export const bottomInfoHeight = 64;

s.Root = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.secondaryColorPalette.light1};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 0 ${additionalInfoHeight + bottomInfoHeight}px;
  justify-content: center;
  position: relative;
`;

s.ButtonContainer = styled.div`
  display: flex;
  flex: 1 auto;
  justify-content: flex-end;
`;

s.Button = styled(Button)`
  ${props => props.decommission && `
    color: #DD3D45;

    &:hover {
      color: #DD3D45;
    }
  `}
  text-transform: none;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    svg {
      path {
        fill: ${({ theme }) => theme.greyPalette.grey};
      }
    }
  }
`;

s.TooltipButton = styled(TooltipButton)`
  ${props => props.decommission && `
    color: #DD3D45;

    &:hover {
      color: #DD3D45;
    }
  `}
  text-transform: none;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    svg {
      path {
        fill: ${({ theme }) => theme.greyPalette.grey};
      }
    }
  }
`;

s.AdditionalText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 50%;
  height: ${additionalInfoHeight + 1}px;
  padding: 10px;
  background-color: rgba(243, 246, 247, 0.4);
  border-bottom: 1px solid rgb(32, 188, 173);
  border-right: 1px solid rgb(32, 188, 173);
`;

s.BottomInfo = styled.div`
  display: flex;
  padding: 5px 5px 5px 20px;
  align-items: center;
  height: ${bottomInfoHeight}px;
  width: 100%;
  background-color: rgba(243, 246, 247, 0.4);
`;

s.AdditionalTable = styled.table`
  flex: 1 1 50%;
  height: ${additionalInfoHeight}px;
  box-sizing: border-box;
  border: none;
  font-size: 14px;
  background-color: rgba(243, 246, 247, 0.4);

  td, th {
    padding-left: 10px;
    border: 1px solid #ccdadd;
    height: ${additionalInfoHeight / 4}px;
    border-left:none;
  }

  th {
    border-top: 0;
    font-weight: 500;
  }

  tr:last-child td {
  border-bottom: 1px solid rgb(32, 188, 173);
  }

  i {
    opacity: 0.6;
    font-style: normal;
  }
`;

s.BottomItem = styled.span`
  flex: 0 1 200px;
  min-width: 150px;
  margin-right: 30px;
`;

s.BottomLabel = styled.span`
  display: inline-block;
  margin-right: 5px;
  color: #7198a2;
`;

s.BottomValue = styled.span`
  display: inline-block;
`;

s.LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

s.MoreBtn = styled(Button)`
  position: absolute;
  right: 5px;
  bottom: 5px;
`;

export default s;
