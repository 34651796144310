import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import LoadingSpinner from '../Loading/LoadingSpinner';

import Button from '../Button';
import Title from '../Texts/Title';

const getPortalRoot = () => {
  let portalRoot = document.querySelector('#portal-root');

  if (!portalRoot) {
    portalRoot = document.createElement('div');
    portalRoot.setAttribute('id', 'portal-root');
  }

  return portalRoot;
};

const Modal = (props) => {
  const {
    children,
    cancelButtonDisabled,
    cancelButtonText,
    cancelButtonVisible,
    confirmButtonDisabled,
    confirmButtonText,
    height,
    id,
    loading,
    maxHeight,
    onCancel,
    onConfirm,
    open,
    parent,
    scrollable,
    showFooter,
    title,
    width,
  } = props;

  const [el] = useState(document.createElement('div'));
  const contentRef = useRef(null);
  let renderParent = parent || null;

  useEffect(() => {
    if (open) {
      if (!parent) {
        const defaultParent = getPortalRoot();

        document.body.appendChild(defaultParent);

        renderParent = defaultParent; // eslint-disable-line react-hooks/exhaustive-deps
      }

      renderParent.appendChild(el);
    }

    if (!open && renderParent) {
      renderParent.removeChild(el);
    }
  }, [open]);

  const handleClick = (event) => {
    if (contentRef.current.contains(event.target)) {
      event.stopPropagation();

      return;
    }

    onCancel();
  };

  return (
    open &&
    ReactDOM.createPortal(
      <Root id={id}>
        <Content height={height} maxHeight={maxHeight} scrollable={scrollable} width={width}>
          {!showFooter && (
            <Close onClick={onCancel}>
              <svg width="23" height="23" viewBox="0 0 23 23">
                <g fill="none" fillRule="evenodd" stroke="#004555" strokeLinecap="square">
                  <path d="M15.5 7.5l-8 8M15.5 15.5l-8-8" />
                  <circle cx="11.5" cy="11.5" r="11" />
                </g>
              </svg>
            </Close>
          )}

          {title && <StyledTitle>{title}</StyledTitle>}

          {children}

          {showFooter && (
            <Footer>
              {cancelButtonVisible && <Button color="ghost" disabled={cancelButtonDisabled} onClick={onCancel} label={cancelButtonText} />}
              <StyledButton
                color="normal"
                disabled={loading || confirmButtonDisabled}
                onClick={onConfirm}
                label={
                  loading ? (
                    <LabelCont>
                      <LoaderCont>
                        <LoadingSpinner />
                      </LoaderCont>
                      <HiddenLabel>{confirmButtonText}</HiddenLabel>
                    </LabelCont>
                  ) : (
                    confirmButtonText
                  )
                }
              />
            </Footer>
          )}
        </Content>
      </Root>,
      el,
    )
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  cancelButtonDisabled: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonVisible: PropTypes.bool,
  confirmButtonDisabled: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  id: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  scrollable: PropTypes.bool,
  showFooter: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.number,
};
Modal.defaultProps = {
  children: null,
  cancelButtonDisabled: false,
  cancelButtonText: 'Cancel',
  cancelButtonVisible: true,
  confirmButtonDisabled: false,
  confirmButtonText: 'Submit',
  id: '',
  loading: false,
  onCancel: Function.prototype,
  onConfirm: Function.prototype,
  open: false,
  scrollable: true,
  showFooter: true,
  title: null,
  width: 380,
};

export default Modal;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: 14px;
  max-height: ${({ maxHeight }) => maxHeight || '600px'};
  height: ${({ height }) => height || 'auto'};
  overflow: ${({ scrollable }) => (!scrollable ? 'hidden' : 'auto')};
  padding: 30px;
  width: ${({ width }) => width}px;
  position: relative;

  &::-webkit-scrollbar {
    width: 0.66rem !important;
  }

  &::-webkit-scrollbar-button {
    background-color: #ddf8f8;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f7fa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a1d9d5;
    width: 0.6rem !important;
  }

  & label {
    font-size: 14px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`;

const Root = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.greyPalette.transparent};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

export const StyledButton = styled(Button)`
  margin-left: 20px;
`;

export const LabelCont = styled.div`
  position: relative;
`;

export const HiddenLabel = styled.span`
  visibility: hidden;
`;

export const LoaderCont = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
