import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { theme } from '../../theme/theme';

const LoadingSpinner = (props) => {
  const {
    className,
    color,
    size,
    strokeWidth
  } = props;
  return (
    <SpinnerContainer
      className={className}
      size={size}
    >
      <Spinner viewBox="25 25 50 50">
        <Circle
          cx="50"
          cy="50"
          fill="none"
          r="20"
          stroke={color}
          strokeWidth={strokeWidth}
        />
      </Spinner>
    </SpinnerContainer>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number
};

LoadingSpinner.defaultProps = {
  className: '',
  color: theme.inverseColor,
  size: 18,
  strokeWidth: 3
};

export default LoadingSpinner;

const SpinnerContainer = styled.div`
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    ${({ size }) => `
        width: ${size}px;
        height: ${size}px;
        `}
    `;

const keyFrameDash = keyframes`
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
`;

const keyFrameRotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.svg`
    animation: ${keyFrameRotate} 2s linear infinite;
    transform-origin: center center;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 100%;
    `;

const Circle = styled.circle`
    animation: ${keyFrameDash} 1.5s ease-in-out infinite;
    stroke-dasharray: 150,200;
    stroke-dashoffset: -10;
    stroke-linecap: round;
`;
