export const FILE_CONTENT_PREFIX = {
  CSV: 'data:text/csv;charset=utf-8,'
};

export const downloadFileContent = (fileName, content, useBlob) => {
  const url = useBlob
    ? window.URL.createObjectURL(new Blob([content]))
    : encodeURI(content);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentElement.removeChild(link);
};
