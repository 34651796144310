import styled from 'styled-components';

import SubTitle from '../../components/Texts/SubTitle';
import Button from '../../components/Button';

const style = {};
export const titleHeight = 58;

style.Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 20px;
`;

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${props => `${props.height}px`};
  overflow: auto;
`;

style.StyledSubTitle = styled(SubTitle)`
  font-weight: ${props => props.theme.fontWeight.regular};
`;

style.SubTitleSpan = styled.span`
  font-weight: ${props => props.theme.fontWeight.medium}
  margin-left: 6px;
`;

style.VAControlOptions = styled.div`
  display: none;
  position: relative;
  top: 100%;
  z-index: 200;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
    left: 50%;
    margin-left: -4px;
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #212529;
    border-left: 4px solid transparent;
  }
`;

style.VAControlOption = styled.div`
  position: relative;
  padding: 4px 14px;
  text-align: center;
  cursor: pointer;
  font-size: 0.75rem;

  &:hover {
    background-color: rgba(0,0,0,0.05);
  }

  ${props => props.selected && `
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 8px;
      margin-top: -2px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #212529;
    }
  `}
`;

style.VAControl = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: relative;
  width: 105px;
  padding-bottom: 4px;

  //span {
  //  text-decoration: underline;
  //  text-shadow: 0 1px 0 white;
  //}

  // &:hover {
  //   ${style.VAControlOptions} {
  //     display: block;
  //   }
  // }
`;

export default style;
