import styled from 'styled-components';

const Overline = styled.span`
    color: ${({ theme }) => theme.greyPalette.grey};
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.medium};
    letter-spacing: 0.75px;
    text-transform: uppercase;
`;

export default Overline;
