import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

import Overline from '../../../components/Texts/Overline';
import { theme } from '../../../theme/theme';

class HeaderCell extends Component {

  static propTypes = {
    alignCenter: PropTypes.bool,
    grow: PropTypes.number,
    headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isCurrentSortType: PropTypes.bool,
    minWidth: PropTypes.number,
    onClick: PropTypes.func,
    pointer: PropTypes.bool,
    shrink: PropTypes.number,
    sortDirection: PropTypes.string,
    sortType: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  static defaultProps = {
    alignCenter: false,
    grow: 0,
    headerText: '',
    isCurrentSortType: false,
    minWidth: 100,
    onClick: () => {},
    pointer: true,
    shrink: 1,
    sortDirection: '',
    sortType: '',
    width: 120
  };

  constructor(props) {
    super(props);

    this.state = {
      tooltipOpened: false
    };
  }

  toggleTooltip = () => {
    const { tooltipOpened } = this.state;

    this.setState({
      tooltipOpened: !tooltipOpened
    });
  };

  renderArrow = () => {
    const {
      isCurrentSortType,
      sortDirection
    } = this.props;

    if (!isCurrentSortType) {
      return null;
    }

    return (
      sortDirection === 'asc'
        ? <IoIosArrowDown color={theme.secondaryColorPalette.main} />
        : <IoIosArrowUp color={theme.secondaryColorPalette.main} />
    );
  };

  render() {
    const {
      alignCenter,
      grow,
      headerText,
      isCurrentSortType,
      minWidth,
      onClick,
      pointer,
      shrink,
      sortType,
      width
    } = this.props;

    return (
      <StyledOverline
        alignCenter={alignCenter}
        data-sorttype={sortType}
        grow={grow}
        id={`SortBy${sortType}`}
        minWidth={minWidth}
        onClick={onClick}
        pointer={pointer}
        role="button"
        selected={isCurrentSortType}
        shrink={shrink}
        tabIndex={0}
        width={width}
      >
        {headerText}
        {this.renderArrow()}
      </StyledOverline>
    );
  }

}

export default HeaderCell;

export const StyledHeader = styled.div`
  display: flex;
  height: 55px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  border-top: 1px solid ${props => props.theme.borderColor};
`;

const StyledOverline = styled(Overline)`
  align-items: center;
  color: ${({ selected }) => (selected ? theme.greyPalette.black : theme.greyPalette.grey)}
  cursor: ${({ pointer }) => (pointer ? `pointer;` : 'default')}
  display: flex;
  justify-content: flex-start;
  min-width: ${({ width, minWidth }) => (minWidth > width ? width : minWidth)}px;
  outline: none;

  span {
    color: ${() => theme.greyPalette.black}
    margin-left: 5px;
  }
  ${({ width, grow, shrink }) => `flex: ${grow} ${shrink} ${width !== 'auto' ? `${width}px` : width}`};
`;
