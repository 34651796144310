/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-underscore-dangle */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';

import assetHistoryReducer from '../reducers/assetHistoryReducer';
import descriptiveLocationReducer from '../reducers/descriptiveLocationReducer';
import googleKeyReducer from '../reducers/googleKeyReducer';
import manicDataReducer from '../reducers/dataReducer';
import filtersReducer from '../reducers/filtersReducer';
import repairReportDataReducer from '../reducers/repairReportDataReducer';
import eventCounterDataReducer from '../reducers/eventCounterDataReducer';
import user from '../reducers/user';

export default function configureStore(history: History) {
  const middleware = [thunk, routerMiddleware(history)];

  const filters = filtersReducer;

  const rootReducer = combineReducers({
    assetHistoryReducer,
    descriptiveLocationReducer,
    googleKeyReducer,
    manicDataReducer,
    repairReportDataReducer,
    eventCounterDataReducer,
    user: user,
    filters,
    router: connectRouter(history),
  });

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(rootReducer, compose(applyMiddleware(...middleware), ...enhancers));
}
