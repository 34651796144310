/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import PropTypes, { InferProps } from 'prop-types';
import { getData, getSettingsAction, getKeysAction } from './actions';
import Layout from './components/Layout';
import './custom.css';
import DailyReport from './ui/DailyReport';
import { useEffect } from 'react';
import Dashboard from './ui/dashboard/Dashboard';
import Help from './ui/Help/Help';
import Settings from './ui/Settings/Settings';
import HospitalStatistics from './ui/HospitalStatistics/HospitalStatistics';
import ManikinData from './ui/ManikinData/ManikinData';

// @ts-ignore
const AppRouter = (props: InferProps<typeof AppRouter.propTypes>): JSX.Element => {
  const { loading, loadData, loadSettings, loadKeys } = props;

  useEffect(() => {
    loadData();
    loadSettings();
    loadKeys();
  }, []);

  return (
    <Layout>
      <Route exact path={['/daily_report', '/']}>
        <DailyReport loading={loading} />
      </Route>

      <Route path={'/hospitalstats/:hospitalId'}>
        <HospitalStatistics loading={loading} />
      </Route>

      <Route path={'/manikin/:manikinId'}>
        <ManikinData loading={loading} />
      </Route>

      <Route path={'/dashboard'}>
        <Dashboard loading={loading} />
      </Route>

      <Route path={'/help'} component={Help} />
      <Route path={'/settings'} component={Settings} />
    </Layout>
  );
};

AppRouter.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadData: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  loadKeys: PropTypes.func.isRequired,
};

// @ts-ignore
const mapStateToProps = (state) => ({
  loading: state.manicDataReducer.loading,
});

// @ts-ignore
const mapDispatchToProps = (dispatch) => ({
  loadData: () => dispatch(getData()),
  loadSettings: () => dispatch(getSettingsAction()),
  loadKeys: () => dispatch(getKeysAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
