import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import styled from 'styled-components';

import { getData } from '../../actions';
import BlockSkeleton from '../../components/Loading/BlockSkeleton';
import FiltersPanel from './FiltersPanel';
import ListView from '../listview/ListView';
import MapWrapper from '../maps/MapWrapper';

import './Dashboard.scss';
import EditLocationModal, { DialogMode } from '../listview/ListItem/EditLocationModal/EditLocationModal';
import { EmptyAssetAttributes, ManikinShape } from '../../constants/types';
import { setTextFilterAndResetOtherAction, setPreSelectedAssetAction, setFilterHospitalHighlightAction } from '../../actions/filters';

class Dashboard extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    manikins: PropTypes.arrayOf(ManikinShape).isRequired,
    preSelectedAssetId: PropTypes.string.isRequired,
    setHighlightedHospital: PropTypes.func.isRequired,
    setPreSelectedAsset: PropTypes.func.isRequired,
    setTextFilterAndResetOther: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      addAssetModalOpened: false,
      heightCorrection: 0,
      screenYStart: null,
      clusterByHospital: true,
    };

    this.handleDragMouseDown = this.handleDragMouseDown.bind(this);

    this.toggleAddAssetModal = this.toggleAddAssetModal.bind(this);

    this.toggleClusterByHospital = this.toggleClusterByHospital.bind(this);

    this.throttledMouseMoveHandler = throttle(this.handleMouseMove, 100, {
      trailing: true,
    });
  }

  onMapReady = () => {
    const { manikins, preSelectedAssetId, setHighlightedHospital, setPreSelectedAsset } = this.props;
    if (preSelectedAssetId) {
      setHighlightedHospital(manikins.find((m) => m.assetId === preSelectedAssetId).hospitalId);
      setPreSelectedAsset('');
    }
  };

  handleMainMouseUp = () => {
    document.removeEventListener('mousemove', this.throttledMouseMoveHandler, false);
  };

  handleMouseMove = (event) => {
    const { screenYStart: startPosition } = this.state;
    const verticalCoord = event.clientY;
    const heightCorrection = startPosition - verticalCoord;
    const mapElement = document.querySelector('.MapWrapper');
    const mapHeight = mapElement.offsetHeight;

    if (mapHeight - heightCorrection < 300) {
      return;
    }

    this.setState({
      heightCorrection,
    });
  };

  handleDragMouseDown(event) {
    event.preventDefault();
    const screenYStart = event.clientY;
    this.setState(
      {
        screenYStart,
      },
      () => {
        document.addEventListener('mousemove', this.throttledMouseMoveHandler, false);
        document.addEventListener('mouseup', this.handleMainMouseUp);
      },
    );
  }

  renderMap = () => {
    const { loading } = this.props;
    const { heightCorrection, clusterByHospital } = this.state;
    const correction = 84 + heightCorrection;

    return (
      <div
        style={{
          height: `calc(45vh - ${correction}px)`,
          overflowY: 'hidden',
        }}
        className="MapWrapper"
      >
        {loading ? <BlockSkeleton /> : <MapWrapper onMapReady={this.onMapReady} clusterByHospital={clusterByHospital} />}
      </div>
    );
  };

  toggleAddAssetModal() {
    const { addAssetModalOpened } = this.state;
    const { loading } = this.props;

    if (loading) {
      return;
    }

    this.setState({ addAssetModalOpened: !addAssetModalOpened });
  }

  toggleClusterByHospital() {
    const { loading } = this.props;

    if (loading) {
      return;
    }

    const { clusterByHospital } = this.state;

    this.setState({ clusterByHospital: !clusterByHospital });
  }

  handleAddAsset(attrs) {
    const { setPreSelectedAsset, setTextFilterAndResetOther } = this.props;
    this.toggleAddAssetModal();
    setTextFilterAndResetOther(attrs.assetId);
    setPreSelectedAsset(attrs.assetId);
  }

  render() {
    const { addAssetModalOpened, clusterByHospital } = this.state;
    return (
      <Root className="Dashboard">
        <Header>
          <FiltersPanel />

          <ButtonContainer onClick={this.toggleAddAssetModal}>
            <svg width="9" height="9" viewBox="0 0 9 9">
              <path fillRule="evenodd" clipRule="evenodd" d="M5 4V0H4V4H0V5H4V9H5V5H9V4H5Z" fill="#2b809d" />
            </svg>
            <ButtonCaption>Add asset</ButtonCaption>
          </ButtonContainer>

          <ButtonContainer onClick={this.toggleClusterByHospital}>
            <ButtonCaption>Clustered by: {clusterByHospital ? 'Hospital' : 'Assets'} </ButtonCaption>
          </ButtonContainer>
        </Header>
        <Map className="MapBlock">{this.renderMap()}</Map>
        <ListView />
        {addAssetModalOpened && (
          <EditLocationModal
            attrs={EmptyAssetAttributes}
            dialogMode={DialogMode.New}
            onCancel={() => this.toggleAddAssetModal()}
            onUpdate={(attrs) => this.handleAddAsset(attrs)}
            open
          />
        )}
      </Root>
    );
  }
}

const mapStateToProps = (state) => ({
  manikins: state.manicDataReducer.data ? state.manicDataReducer.data.manikins : [],
  loading: state.manicDataReducer.loading,
  preSelectedAssetId: state.filters.preSelectedAssetId,
});

const mapDispatchToProps = (dispatch) => ({
  setHighlightedHospital: (hospitalId) => dispatch(setFilterHospitalHighlightAction(hospitalId)),
  setPreSelectedAsset: (assetId) => dispatch(setPreSelectedAssetAction(assetId)),
  setTextFilterAndResetOther: (assetId) => dispatch(setTextFilterAndResetOtherAction(assetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const ButtonContainer = styled.div`
  align-items: center;
  color: ${(props) => props.theme.laerdalPalette.primary.default};
  cursor: pointer;
  display: flex;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  justify-content: center;
  flex: 0;
  flex-direction: row;
  font-size: ${(props) => props.theme.fontSize.medium};
  flex-wrap: nowrap;
  margin-left: 90px;
  position: relative;
  top: 3px;

  svg {
    width: 24px;
  }
`;

const ButtonCaption = styled.div`
  color: ${({ theme }) => theme.laerdalPalette.primary.default};
  white-space: nowrap;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  height: 55px;
`;

const Map = styled.div`
  display: flex;
  marign-top: 45px;
`;

const Root = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  padding: 55px 40px 20px;
`;
